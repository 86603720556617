import s from './Auth.module.scss';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Input from '../../components/common/Input';
import { useOutletContext } from 'react-router-dom';

//{ changeFormOption, sendEmail, error, clearError }
const FormEmail = () => {
  const [changeFormOption, sendEmail, error, clearError, optionValue] =
    useOutletContext();
  const { isLoading } = useSelector((state) => state.auth.authData);

  const [email, setEmail] = useState(optionValue);

  const handleClick = (e) => {
    if (e) e.preventDefault();
    sendEmail(email);
  };

  const handleChange = (val) => {
    setEmail(val);
    clearError('email');
  };

  return (
    <>
      <h1 className={s.title}>Email</h1>
      <p className={s.text}>
        Введите Email, на который мы отправим одноразовый код для регистрации
      </p>
      <form onSubmit={handleClick}>
        <div className={s.inputWrap}>
          <Input
            name="email"
            label="Email"
            value={email}
            error={error}
            handleChange={handleChange}
            placeholder={'Ваш адрес электронной почты'}
          />
        </div>
        <ul className={s.btnWrap + ' ' + s.oneWidth}>
          <button
            onClick={handleClick}
            className={'btnLight ' + s.btnForm}
            disabled={error || !email || isLoading}
          >
            <span> {isLoading ? 'Загрузка...' : 'Продолжить'}</span>
          </button>
        </ul>
      </form>
      <p className={s.agreement}>
        Нажимая кнопку, вы соглашаетесь с{' '}
        <a href="/">политикой обработки персональных данных</a> и{' '}
        <a href="/">политикой предоставления сервиса</a>
      </p>
      <ul className={s.btnWrap}>
        <button
          className={'btnLight ' + s.btnForm + ' ' + s.light}
          onClick={changeFormOption}
        >
          Другой способ авторизации
        </button>
      </ul>
    </>
  );
};

export default FormEmail;
