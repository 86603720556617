import React from "react";
import s from "./Menu.module.scss";
// import copy from '../../imgs/copy';
import delIcon from "../../imgs/trashBin";

const MenuHeaderItem = ({
	num = "",
	delMenu,
	activeMenu,
	isActive = false,
}) => {
	return (
		<li
			onClick={() => activeMenu(num)}
			className={s.header__item + " " + (isActive ? s.actived : "")}
		>
			<p>{num === 1 ? `Корневой уровень` : `Уровень${num - 1}`}</p>
			<div className={s.header__item__control}>
				{num !== 1 && (
					<button
						onClick={(e) => {
							e.stopPropagation();
							delMenu(num - 1);
						}}
						className={s.header__item__btnDel}
					>
						{delIcon}
					</button>
				)}
			</div>
		</li>
	);
};

export default MenuHeaderItem;
