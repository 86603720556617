import Endpoints from "../endpoints";
import { axiosInstance } from "../instance";
import defMenuData from "./defMenuJSON.json";
// import newMenujson from './newMenu.json';

const getMenu = async (project_name, bot_name, user_id) => {
	try {
		const method = "saved";
		const res = await axiosInstance.get(
			Endpoints.MENU.GET + `/${method}/${project_name}?author=${user_id}`
		);
		if (res.status === 200 && !res?.data?.data?.data) {
			return {
				...defMenuData,
				bot: {
					name: bot_name,
					slag: project_name,
				},
				author: {
					account: user_id,
					date: "",
				},
			};
		}
		if (res?.data?.data?.data) {
			return {
				...res.data.data.data,
				bot: {
					name: bot_name,
					slag: project_name,
				},
				author: {
					account: user_id,
					date: "",
				},
			};
		} else {
			throw new Error();
		}
	} catch (e) {
		console.log(e);
		return { error: "Не удалось получить данные о квизах" };
	}
};

export const saveMenu = async (project_name, bot_name, author_id, params) => {
	try {
		const method = "saved";
		const res = await axiosInstance.post(
			Endpoints.MENU.SAVE + `/${method}/${project_name}?author=${author_id}`,
			params
		);
		console.log(1, res);
		// `/${method}/${project_name}?ver_num=${ver_num}&tagname=${tag_name}`   -    пока без ver_num
		if (!res.data.error) {
			return res;
		} else {
			throw new Error();
		}
	} catch (e) {
		console.log(e);
		return { error: "Не удалось получить данные о квизах" };
	}
};

export const publishMenu = async (params, user_id) => {
	try {
		const res = await axiosInstance.post(Endpoints.MENU.PUBLISH, params);
		if (!res.data.error) {
			return res;
		} else {
			throw new Error();
		}
	} catch (e) {
		console.log(e);
		return { error: "Не удалось получить данные о квизах" };
	}
};

export const updateMenu = async (body) => {
	try {
		const res = await axiosInstance.post(Endpoints.MENU.UPDATE, body);
		console.log(1, res);
		if (!res.data.error) {
			return res;
		} else {
			throw new Error();
		}
	} catch (e) {
		console.log(e);
		return { error: "Не удалось получить данные о квизах" };
	}
};

const menu = {
	getMenu,
	saveMenu,
	updateMenu,
	publishMenu,
};

export default menu;
