import s from './Main.module.scss';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../store';
import Input from '../../components/common/Input';
import PopUp from '../../components/common/PopUp';
import React, { useEffect, useState } from 'react';
import { addProject, updateTree } from '../../store/main/actionCreators';

const PopupAddProject = ({ close }) => {
  const dispatch = useAppDispatch();
  const [title, setTitle] = useState('');
  const [project, setProject] = useState('');
  const [errors, setErrors] = useState({ project: '' });

  const main = useSelector((state) => state.main);
  const { user_id } = useSelector((state) => state.auth.profileData);
  const isLoading = useSelector((state) => state.main.projectLoading);
  const updatingTree = useSelector((state) => state.main.updatingTree);

  useEffect(() => {
    if (main.projectAdded && !updatingTree) {
      dispatch(updateTree());
      close();
    }
    if (main.errors.project) {
      setErrors({ project: main.errors.project });
    }
    // eslint-disable-next-line
  }, [main]);

  const changeData = (key, val) => {
    setErrors({});
    if (key === 'project') {
      setProject(val);
    } else if (key === 'title') {
      setTitle(val);
    }
  };

  const handleSubmit = async () => {
    if (!project) {
      setErrors((prevState) => ({ ...prevState, project: 'Заполните поле' }));
    } else if (!main?.curCompany?.name) {
      setErrors((prevState) => ({
        ...prevState,
        project: 'Компания не найдена',
      }));
    } else {
      const companyIndex = main.tree.findIndex(
        (item) => item.name === main.curCompany.name
      );
      const company = main.curCompany.name;
      dispatch(addProject({ title, project, company, companyIndex, user_id }));
    }
  };

  return (
    <PopUp close={close} isOpen={true}>
      <div className={s.popupAddCompany}>
        <h3 className={s.title}>Добавить проект</h3>
        <Input
          type="text"
          value={project}
          name={'project'}
          error={errors.project}
          label="Название проекта"
          handleChange={(val) => changeData('project', val)}
        />
        <Input
          type="text"
          value={title}
          name={'title'}
          error={errors.title}
          label="Заголовок проекта"
          handleChange={(val) => changeData('title', val)}
        />
        <ul className={s.btnWrap}>
          <button
            disabled={isLoading}
            onClick={handleSubmit}
            className="btnLight"
          >
            {isLoading ? 'Длбавление...' : 'Добавить'}
          </button>
          <button onClick={close} className="btnDark">
            Отмена
          </button>
        </ul>
      </div>
    </PopUp>
  );
};

export default PopupAddProject;
