import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  authData: {
    tg: null,
    email: null,
    error: null,
    tgAccess: false,
    isLoading: false,
    accessToken: null,
    refreshToken: null,
    // authLoading: true,
  },
  profileData: {
    error: null,
    isLoading: true,
    authorized: false,
    // profileLoading: true,
  },
};

export const authReducer = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // tg accesss
    checkTgAccessStart: (state) => ({
      ...state,
      authData: {
        ...state.authData,
        isLoading: true,
      },
    }),
    checkTgAccessSuccess: (state, action) => ({
      ...state,
      authData: {
        ...state.authData,
        tgAccess: true,
        isLoading: false,
        accessToken: action.payload.jwt,
        refreshToken: action.payload.refresh_token,
      },
    }),
    checkTgAccessFailure: (state, action) => ({
      ...state,
      authData: {
        ...state.authData,
        isLoading: false,
        error: { tg: action.payload },
      },
    }),

    // send tg
    getTgLinkStart: (state) => ({
      ...state,
      authData: {
        ...state.authData,
        isLoading: true,
      },
    }),
    getTgLinkSuccess: (state, action) => ({
      ...state,
      authData: {
        ...state.authData,
        isLoading: false,
        error: null,
        tg: action.payload,
      },
    }),
    getTgLinkFailure: (state, action) => ({
      ...state,
      authData: {
        ...state.authData,
        isLoading: false,
        error: { tg: `${action.payload}` },
      },
    }),
    // send email
    sendEmailStart: (state) => ({
      ...state,
      authData: {
        ...state.authData,
        isLoading: true,
      },
    }),
    sendEmailSuccess: (state, action) => ({
      ...state,
      authData: {
        ...state.authData,
        isLoading: false,
        error: null,
        email: action.payload,
      },
    }),
    sendEmailFailure: (state) => ({
      ...state,
      authData: {
        ...state.authData,
        isLoading: false,
        error: { email: 'Почта введена неверно' },
      },
    }),
    // send key
    loginStart: (state) => ({
      ...state,
      authData: {
        ...state.authData,
        isLoading: true,
      },
    }),
    loginSuccess: (state, action) => ({
      ...state,
      authData: {
        ...state.authData,
        refreshToken: action.payload.refresh_token,
        accessToken: action.payload.jwt,
        isLoading: false,
        error: null,
      },
    }),
    loginFailure: (state) => ({
      ...state,
      authData: {
        ...state.authData,
        isLoading: false,
        error: { code: 'Код введен неверно' },
      },
    }),
    // check profile authorized
    loadProfileStart: (state) => ({
      ...state,
      profileData: {
        ...state.profileData,
        isLoading: true,
      },
    }),
    loadProfileSuccess: (state, action) => ({
      ...state,
      profileData: {
        ...state.profileData,
        user_id: action.payload.user_id,
        isLoading: false,
        authorized: true,
        error: null,
      },
    }),
    loadProfileFailure: (state, action) => ({
      ...state,
      profileData: {
        ...state.profileData,
        error: action.payload,
        isLoading: false,
      },
    }),
    // logout
    logoutSuccess: () => ({
      ...initialState,
      profileData: {
        ...initialState.profileData,
        isLoading: false,
      },
    }),
  },
});

export const {
  checkTgAccessStart,
  checkTgAccessSuccess,
  checkTgAccessFailure,
  getTgLinkStart,
  getTgLinkSuccess,
  getTgLinkFailure,
  sendEmailStart,
  sendEmailSuccess,
  sendEmailFailure,
  loginStart,
  loginSuccess,
  loginFailure,
  loadProfileStart,
  loadProfileSuccess,
  loadProfileFailure,
  logoutSuccess,
} = authReducer.actions;

export default authReducer.reducer;
