const copyIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.2 5.2V3.8C5.2 2.2536 6.4536 1 8 1L12.2 1C13.7464 1 15 2.2536 15 3.8V8C15 9.5464 13.7464 10.8 12.2 10.8H10.8M5.2 5.2H3.8C2.2536 5.2 1 6.4536 1 8V12.2C1 13.7464 2.2536 15 3.8 15H8C9.5464 15 10.8 13.7464 10.8 12.2V10.8M5.2 5.2H8C9.5464 5.2 10.8 6.4536 10.8 8V10.8"
      stroke="#8A8A8A"
      strokeLinejoin="round"
    />
  </svg>
);

export default copyIcon;
