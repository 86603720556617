import { store } from '..';
import api from '../../api';
import { isTokenExpired } from '../../utils/jwt';
import { clearBot } from '../bot/botReducer';
import { cleanTree } from '../main/mainReducer';
import {
  loadProfileFailure,
  loadProfileStart,
  loadProfileSuccess,
  loginFailure,
  loginStart,
  loginSuccess,
  logoutSuccess,
  sendEmailStart,
  sendEmailSuccess,
  sendEmailFailure,
  getTgLinkStart,
  getTgLinkSuccess,
  getTgLinkFailure,
  checkTgAccessStart,
  checkTgAccessSuccess,
  checkTgAccessFailure,
} from './authReducer';

const setTokensLS = (data) => {
  localStorage.setItem('refreshToken', JSON.stringify(data.refresh_token));
  localStorage.setItem('accessToken', JSON.stringify(data.jwt));
};

export const getTgLink = () => async (dispatch) => {
  try {
    dispatch(getTgLinkStart());
    const res = await api.auth.getTgLink();
    dispatch(getTgLinkSuccess(res.data));
  } catch (e) {
    console.error(e);
    dispatch(getTgLinkFailure(e.message));
  }
};

export const checkTgAccess = (data) => async (dispatch) => {
  try {
    dispatch(checkTgAccessStart());
    const res = await api.auth.checkTgAccess(data);
    if (res.data.error) {
      console.log('err', res.data.error);
      return dispatch(checkTgAccessFailure(res.data.error));
    }
    setTokensLS(res.data);
    dispatch(checkTgAccessSuccess(res.data));
    dispatch(getProfile());
  } catch (e) {
    console.error(e);
    dispatch(checkTgAccessFailure(e.message));
  }
};

export const sendEmail = (data) => async (dispatch) => {
  try {
    dispatch(sendEmailStart());
    await api.auth.sendEmail(data);
    dispatch(sendEmailSuccess(data.email));
  } catch (e) {
    console.error(e);
    dispatch(sendEmailFailure(e.message));
  }
};

export const loginUser = (data) => async (dispatch) => {
  try {
    dispatch(loginStart());
    const res = await api.auth.login(data);
    setTokensLS(res.data);
    dispatch(loginSuccess(res.data));
    dispatch(getProfile());
  } catch (e) {
    console.error(e);
    dispatch(loginFailure(e.message));
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    const refreshTokenLS = localStorage.getItem('refreshToken');
    if (!refreshTokenLS) {
      dispatch(clearBot());
      dispatch(cleanTree());
      return console.log('refresh token not found in LS');
    }
    const res = await api.auth.logout({
      token: JSON.parse(refreshTokenLS),
    });
    if (res?.data?.success) {
      console.log('logout success');
      localStorage.clear();
      dispatch(clearBot());
      dispatch(cleanTree());
      dispatch(logoutSuccess());
    } else {
      throw new Error();
    }
  } catch (e) {
    console.log('logout failure');
    localStorage.clear();
    dispatch(clearBot());
    dispatch(cleanTree());
    // console.error(e);
  }
};

export const getProfile = () => async (dispatch) => {
  try {
    dispatch(loadProfileStart());
    const res = await api.auth.getProfile();
    // getMyBotsApi
    dispatch(loadProfileSuccess(res.data));
    return res.data;
  } catch (e) {
    if (String(e?.response?.status) === '403') {
      dispatch(logoutUser());
    }
    dispatch(loadProfileFailure(e.message));
  }
};

let refreshTokenRequest;
export const getAccessToken = () => async (dispatch) => {
  try {
    const accessTokenLS = localStorage.getItem('accessToken');
    const refreshTokenLS = localStorage.getItem('refreshToken');
    // check Redux Store
    let accessToken = store.getState().auth.authData.accessToken;
    // check LS
    if (!accessToken) {
      accessToken = accessTokenLS ? JSON.parse(accessTokenLS) : '';
      const refreshToken = refreshTokenLS ? JSON.parse(refreshTokenLS) : '';
      if (accessToken && refreshToken) {
        dispatch(
          loginSuccess({
            jwt: accessToken,
            refresh_token: refreshToken,
          })
        );
      }
    }
    // check Refresh Token
    if (!accessToken || isTokenExpired(accessToken)) {
      const refreshToken = refreshTokenLS ? JSON.parse(refreshTokenLS) : '';
      if (!refreshToken) {
        throw new Error();
      }
      if (!refreshTokenRequest) {
        refreshTokenRequest = await api.auth.refreshToken({
          token: refreshToken,
        });
      }
      console.log('refreshTokenRequest', refreshTokenRequest);
      if (refreshTokenRequest.data) {
        setTokensLS(refreshTokenRequest.data);
        dispatch(loginSuccess(refreshTokenRequest.data));
        const jwt = refreshTokenRequest.data.jwt;
        refreshTokenRequest = undefined;
        accessToken = jwt;
        return jwt;
      } else {
        throw new Error();
      }
    }
    if (!accessToken) {
      throw new Error();
    }
    return accessToken;
  } catch (e) {
    console.log('AccessToken NotFound');
    logoutUser();
    return null;
  }
};
