import React from "react";
import s from "./Common.module.scss";
import plus from "../../imgs/plusSelect.js";
import arrow from "../../imgs/arrowSelect.js";

const Select = ({
	icon,
	name,
	label,
	select,
	topLabel,
	isAdding,
	isShowed,
	disabled,
	showList,
	addToList,
	value = "",
	selectList,
	btnClassName,
	selected = "",
	className = "",
	noBorder = false,
}) => {
	const getNameBtnAdd = (name) => {
		const lastIndex = [name.length - 1];
		const last2Symb = name.slice(lastIndex - 1, lastIndex + 1);
		let editName = name.toLowerCase().slice(0, lastIndex - 1);
		switch (last2Symb) {
			case "ия":
				editName = editName + "ию";
				break;
			case "от":
				editName = editName + "ота";
				break;
			default:
				editName = editName + last2Symb;
				break;
		}
		return editName;
	};

	return (
		<>
			<div
				className={
					s.select__wrap + (noBorder ? " " + s.noBorder : "") + " " + className
				}
			>
				{topLabel && <label>{topLabel}</label>}
				{isAdding ? (
					<button
						onClick={() => showList(name)}
						className={
							"btnGrey add " +
							(isShowed ? " " + s.showed : "") +
							(disabled ? " " + s.disabled : "")
						}
					>
						<span>{label}</span>
					</button>
				) : (
					<button
						onClick={() => showList(name)}
						className={
							s.select__btnShow +
							(isShowed ? " " + s.showed : "") +
							(disabled ? " " + s.disabled : "") +
							(selected ? " " + s.selected : "") +
							" " +
							btnClassName
						}
					>
						{/* {!!icon && icon} */}
						{!!icon && (
							<img
								src={icon}
								alt=""
							></img>
						)}
						<span>{(name && !icon ? name + ": " : "") + selected}</span>
						{/* <img className={s.imgArrow} src={arrow} alt="arrow" /> */}
						{arrow}
					</button>
				)}

				{isShowed && (
					<div className={s.select__listWrap}>
						{!!addToList && (
							<button
								className={s.select__btnAdd}
								onClick={(e) => {
									e.stopPropagation();
									showList("");
									addToList(value);
								}}
							>
								{plus}
								<p>Добавить {getNameBtnAdd(name)}</p>
							</button>
						)}
						<ul className={s.select__list + " scroll"}>
							{selectList.length > 0 &&
								selectList.map((selectItem, index) => (
									<li
										key={index}
										className={s.select__item}
										onClick={(e) => {
											e.stopPropagation();
											showList("");
											select(selectItem);
										}}
									>
										{typeof selectItem === "string" ||
										typeof selectItem === "number"
											? selectItem
											: selectItem.label
											? selectItem.label
											: selectItem.title
											? selectItem.title
											: selectItem.name}
									</li>
								))}
							{selectList.length <= 0 && (
								<li
									className={s.select__item + " " + s.disabled}
									onClick={(e) => {
										e.stopPropagation();
										showList("");
									}}
								>
									Список пуст
								</li>
							)}
						</ul>
					</div>
				)}
			</div>
		</>
	);
};

export default Select;
