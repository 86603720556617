export default (
  <svg
    width="24"
    height="21"
    viewBox="0 0 24 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.4 21V13.5882H9.6V21H3.6V11.1176H-4.69387e-07L12 0L24 11.1176H20.4V21H14.4Z"
      fill="white"
    />
  </svg>
);
