import React from 'react';
import s from './StartModules.module.scss';

const GreetingRowBtns = ({ handleClick, btnsList }) => {
  return (
    <>
      {btnsList.map(({ icon, text, name }, index) => (
        <button
          key={index}
          className={s.btnRow}
          onClick={() => handleClick(name)}
        >
          {icon && icon}
          <span>{text}</span>
        </button>
      ))}
    </>
  );
};

export default GreetingRowBtns;
