const dbIcon = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.7551 0C11.7821 0 12.7532 0.467617 13.3936 1.2705L16.1385 4.71196C16.6153 5.30977 16.875 6.05178 16.875 6.81646V14.625C16.875 16.489 15.364 18 13.5 18H4.5C2.63604 18 1.125 16.489 1.125 14.625V3.375C1.125 1.51104 2.63604 0 4.5 0H10.7551ZM10.7551 1.125H4.5C3.29501 1.125 2.31127 2.07223 2.25275 3.2627L2.25 3.375V14.625C2.25 15.83 3.19723 16.8137 4.3877 16.8722L4.5 16.875H13.5C14.705 16.875 15.6887 15.9278 15.7472 14.7373L15.75 14.625V6.81646C15.75 6.35302 15.6069 5.90206 15.3421 5.52447L15.259 5.41346L12.5141 1.972C12.1138 1.4702 11.5198 1.16484 10.883 1.12863L10.7551 1.125ZM9 12.0938C9.43492 12.0938 9.7875 12.4463 9.7875 12.8812C9.7875 13.3162 9.43492 13.6687 9 13.6687C8.56508 13.6687 8.2125 13.3162 8.2125 12.8812C8.2125 12.4463 8.56508 12.0938 9 12.0938ZM9 5.625C10.2426 5.625 11.25 6.63236 11.25 7.875C11.25 8.92325 10.5332 9.80408 9.56293 10.054L9.5625 10.6875C9.5625 10.9982 9.31066 11.25 9 11.25C8.68934 11.25 8.4375 10.9982 8.4375 10.6875V9.5625C8.4375 9.25184 8.68934 9 9 9C9.62132 9 10.125 8.49632 10.125 7.875C10.125 7.25368 9.62132 6.75 9 6.75C8.37868 6.75 7.875 7.25368 7.875 7.875C7.875 8.18566 7.62316 8.4375 7.3125 8.4375C7.00184 8.4375 6.75 8.18566 6.75 7.875C6.75 6.63236 7.75736 5.625 9 5.625Z"
      fill="white"
    />
  </svg>
);

export default dbIcon;
