import s from './Auth.module.scss';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../store';
import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { checkTgAccess } from '../../store/auth/actionCreators';

//{ changeFormOption, sendTg, clearError, error }
const FormTg = () => {
  const dispatch = useAppDispatch();
  const [checking, setChecking] = useState(false);
  const { tg, tgAccess, isLoading } = useSelector(
    (state) => state.auth.authData
  );
  // , sendTg, clearError, error, optionValue
  const [changeFormOption] = useOutletContext();

  useEffect(() => {
    if (checking) {
      if (!isLoading && !tgAccess) {
        checkAccess();
      }
    }
    // eslint-disable-next-line
  }, [checking, isLoading, tgAccess]);

  const checkAccess = () => {
    setTimeout(() => {
      dispatch(checkTgAccess({ token: tg.token }));
    }, 1000);
  };

  const openLink = (link) => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      // window.location = link;
      const otherWindow = window.open();
      otherWindow.opener = null;
      otherWindow.location = link;
    } else {
      const otherWindow = window.open();
      otherWindow.opener = null;
      otherWindow.location = link;
    }
  };

  return (
    <>
      <h1 className={s.title}>Telegram</h1>
      <p className={s.text}>
        Перейдите по ссылке на Telegram бота для подтверждения регистрации
        {/* Введите Telegram, на который мы отправим одноразовый код для регистрации */}
      </p>
      {tg ? (
        <button
          // target="_blank"
          // rel="noreferrer"
          // href={tg.tg_link}
          className={s.tg_link}
          onClick={() => {
            localStorage.setItem('tgToken', tg.token);
            setTimeout(() => {
              setChecking(true);
              openLink(tg.tg_link);
            }, 1);
          }}
        >
          Перейти в Telegram бота
        </button>
      ) : (
        // <a
        //   target="_blank"
        //   rel="noreferrer"
        //   href={tg.tg_link}
        //   className={s.tg_link}
        //   onClick={() => setChecking(true)}
        // >
        //   Перейти в Telegram бота
        // </a>
        <p className={s.tg_link}>...</p>
      )}

      <p className={s.agreement}>
        Нажимая кнопку, вы соглашаетесь с{' '}
        <a href="/">политикой обработки персональных данных</a> и{' '}
        <a href="/">политикой предоставления сервиса</a>
      </p>
      <ul className={s.btnWrap}>
        <button
          className={'btnLight ' + s.btnForm + ' ' + s.light}
          onClick={changeFormOption}
        >
          Другой способ авторизации
        </button>
      </ul>
    </>
  );
};

export default FormTg;

// const [tg, setTg] = useState(optionValue);

// const handleChange = (val) => {
//   setTg(val);
//   clearError('tg');
// };

// const handleClick = (e) => {
//   if (e) e.preventDefault();
//   sendTg(tg);
// };

/*<form onSubmit={handleClick}>
         <Input
          name="tg"
          value={tg}
          label="Telegram"
          handleChange={handleChange}
          placeholder={'Ваш telegram'}
        />
        <ul>
          <button
            onClick={handleClick}
            disabled={!tg || error}
            className={'btn ' + s.btnForm}
          >
            <span>Продолжить</span>
          </button>
        </ul> 
      </form>*/
