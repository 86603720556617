const resetSvg = (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.71775 9.77996C9.01108 10.0733 9.48665 10.0733 9.77997 9.77996C10.0733 9.48664 10.0733 9.01107 9.77997 8.71774L6.06222 5L9.77996 1.28226C10.0733 0.988932 10.0733 0.513361 9.77996 0.220037C9.48664 -0.0732868 9.01107 -0.0732868 8.71774 0.220037L5.00001 3.93778L1.28222 0.219993C0.988897 -0.0733312 0.513325 -0.0733308 0.220002 0.219993C-0.073322 0.513317 -0.0733225 0.988888 0.220002 1.28221L3.93779 5L0.219993 8.71779C-0.0733312 9.01111 -0.0733308 9.48668 0.219993 9.78001C0.513317 10.0733 0.988888 10.0733 1.28221 9.78001L5.00001 6.06221L8.71775 9.77996Z"
      fill="#777A96"
    />
  </svg>
);

export default resetSvg;
