const addMagic = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.95261 2.74286L6.85737 3.80952L5.79068 1.90476L6.85737 0L4.95261 1.06667L3.04785 0L4.11452 1.90476L3.04785 3.80952L4.95261 2.74286Z"
      fill="white"
    />
    <path
      d="M14.0952 10.2095L12.1904 9.14282L13.2571 11.0476L12.1904 12.9523L14.0952 11.8857L16 12.9523L14.9333 11.0476L16 9.14282L14.0952 10.2095Z"
      fill="white"
    />
    <path
      d="M16 0L14.0952 1.06667L12.1904 0L13.2571 1.90476L12.1904 3.80952L14.0952 2.74286L16 3.80952L14.9333 1.90476L16 0Z"
      fill="white"
    />
    <path
      d="M10.2095 4.03814C9.90479 3.73338 9.44764 3.73338 9.14288 4.03814L0.228571 12.9524C-0.0761904 13.2572 -0.0761904 13.7143 0.228571 14.0191L1.98095 15.7714C2.28571 16.0762 2.74286 16.0762 3.04762 15.7714L11.9619 6.85719C12.2667 6.55243 12.2667 6.09528 11.9619 5.79052L10.2095 4.03814ZM9.37145 8.22862L7.77145 6.62862L9.60003 4.80005L11.2 6.40005L9.37145 8.22862Z"
      fill="white"
    />
  </svg>
);

export default addMagic;
