import s from './Common.module.scss';
import React, { useEffect } from 'react';
import popUpClose from '../../imgs/popUpClose';

const PopUp = ({
  close,
  isOpen,
  children,
  className = '',
  contentClassName = '',
}) => {
  useEffect(() => {
    const html = document.querySelector('html');
    if (!html) return;
    if (isOpen) {
      html.style.overflowY = 'hidden';
    } else {
      html.style.overflowY = 'scroll';
    }
  }, [isOpen]);

  useEffect(() => {
    return () => {
      const html = document.querySelector('html');
      html.style.overflowY = 'scroll';
    };
  }, []);

  return (
    isOpen && (
      <div className={s.popUp__wrapper}>
        <div className={s.popUp__window + ' ' + className}>
          <div className={s.popUp__btnCloseWrap}>
            <button className={s.popUp__btnClose} onClick={close}>
              {popUpClose}
            </button>
          </div>
          <div className={s.popUp__content + ' ' + contentClassName}>
            {children}
          </div>
        </div>
      </div>
    )
  );
};

export default PopUp;
