import Endpoints from "../endpoints";
import { axiosInstance } from "../instance";

export const getQuizesApi = async (project_name, author_id) => {
	try {
		const method = "saved";
		const tag_name = "latest";
		const res = await axiosInstance.get(
			Endpoints.QUIZ.GET +
				`/${method}/${project_name}?tagname=${tag_name}&author=${author_id}`
		);
		// `/${method}/${project_name}?ver_num=${ver_num}&tagname=${tag_name}`   -    пока без ver_num
		if (!res.data.error) {
			return res;
		} else {
			throw new Error();
		}
	} catch (e) {
		console.log(e);
		return { error: "Не удалось получить данные о квизах" };
	}
};

export const postQuizesApi = async (project_name, params) => {
	try {
		console.log(params);
		const res = await axiosInstance.post(Endpoints.QUIZ.POST, params);
		if (!res.data.error) {
			return res;
		} else {
			throw new Error();
		}
	} catch (e) {
		console.log(e);
		return { error: "Не удалось получить данные о квизах" };
	}
};

export const saveQuizesApi = async (
	project_name,
	bot_name,
	author_id,
	params
) => {
	try {
		// console.log(bot_name);
		// console.log(author_id);
		// console.log(params);
		params.bot.name = bot_name;
		params.bot.slag = project_name;
		const method = "saved";
		const tag_name = "latest";
		const res = await axiosInstance.post(
			Endpoints.QUIZ.SAVE +
				`/${method}/${project_name}?tagname=${tag_name}&author=${author_id}`,
			params
		);
		// `/${method}/${bot_name}?ver_num=${ver_num}&tagname=${tag_name}`   -    пока без ver_num
		if (!res.data.error) {
			return res;
		} else {
			throw new Error();
		}
	} catch (e) {
		console.log(e);
		return { error: "Не удалось получить данные о квизах" };
	}
};
