import api from '../../api';
import sorting from './sorting';
import s from './Shop.module.scss';
import ShopTable from './ShopTableUI';
import * as XLSX from 'xlsx/xlsx.mjs';
import debounce from 'lodash.debounce';
import ShopMobTable from './ShopMobTable';
import PopUp from '../../components/common/PopUp';
import Input from '../../components/common/Input';
import Select from '../../components/common/Select';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BtnLoader from '../../components/common/BtnLoader';

const ShopTablePage = ({ slug, isShared, base }) => {
  const timer = useRef(null);
  const listRef = useRef([]);
  const lastUUIDRef = useRef('');
  const globalUpdatingTimer = useRef(null);
  const loadedRef = useRef(false);
  const tableListRef = useRef(null);
  const [list, setList] = useState([]);
  const navigate = useNavigate();

  const [activePage] = useState(1);

  const [editGood, setEditGood] = useState();
  const [sortList, setSortList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [shareMess, setShareMess] = useState('');
  const [lightList, setLightList] = useState([]);
  const [updating, setUpdating] = useState(false);
  const [showedList, setShowedList] = useState('');
  const [filteredList, setFilteredList] = useState([]);
  const [excelLoading, setExcelLoading] = useState(false);
  const [btnUpdating, setBtnUpdating] = useState(false);

  useEffect(() => {
    if (lightList.length) {
      setTimeout(() => {
        setLightList([]);
      }, 1000);
    }
  }, [lightList]);

  useEffect(() => {
    if (tableListRef.current) {
      const innerElements = tableListRef.current.childNodes;
      if (innerElements.length > 10) {
        let maxHeight = 0;
        let i = 0;
        while (i < 10) {
          maxHeight += innerElements[i].scrollHeight;
          i++;
        }
        tableListRef.current.style.height = maxHeight + 'px';
      } else {
        tableListRef.current.style.height = 'unset';
      }
    }
  }, [filteredList]);

  const [settingsDefList] = useState({
    type: ['Все покупки', 'Товар забрали', 'Товар не забрали'],
    time: [
      { name: '1 мин', value: 60 },
      { name: '5 сек', value: 5 },
      { name: '10 сек', value: 10 },
      { name: '15 сек', value: 15 },
    ],
    limit: 50,
    sort: { key: 'Дата и время', direction: 'desc' },
  });
  const [settings, setSettings] = useState({
    sku: null,
    offset: 0,
    name: null,
    tg_id: null,
    event_id: null,
    anketa: null,
    datetimeStart: null,
    datetimeFinish: null,
    type: settingsDefList.type[0],
    time: settingsDefList.time[0],
    curProjectName: slug,
    base: base,
    sort: settingsDefList.sort, // desc ||  asc
  });
  const limit = useRef(settingsDefList.limit);

  useEffect(() => {
    setList([]);
    setLoading(true);
    loadedRef.current = false;
    changeSettings('curProjectName', slug);
  }, [slug]);

  const [isShowMore, setIsShowMore] = useState(false);
  const [showMoreLoading, setShowMoreLoading] = useState(false);

  useEffect(() => {
    if (settings.type === 'Товар забрали') {
      setFilteredList([...sortList.filter((item) => item.collected)]);
    } else if (settings.type === 'Товар не забрали') {
      setFilteredList([...sortList.filter((item) => !item.collected)]);
    } else {
      setFilteredList([...sortList]);
    }
  }, [sortList, settings.type]);

  const getData = async (settings, list) => {
    if (!settings.curProjectName) {
        lastUUIDRef.current = '';
        setLoading(false);
        return;
    }
    !loadedRef.current && setLoading(true);
    
    // Форматирование дат
    let datetimeFinish = '';
    if (settings.datetimeFinish) {
        const dateSplitted = settings.datetimeFinish.split('T');
        const date = dateSplitted[0].split('-').reverse().join('.');
        const time = dateSplitted[1] + ':00';
        datetimeFinish = date + ' ' + time;
    }
    let datetimeStart = '';
    if (settings.datetimeStart) {
        const dateSplitted = settings.datetimeStart.split('T');
        const date = dateSplitted[0].split('-').reverse().join('.');
        const time = dateSplitted[1] + ':00';
        datetimeStart = date + ' ' + time;
    }
    
    const filters = {
        tg_id: settings.tg_id || '',
        event_id: settings.event_id || '',
        good_sku: settings.sku || '',
        good_name: settings.name || '',
        user_anketa: settings.anketa || '',
        end_date: datetimeFinish,
        start_date: datetimeStart,
    };
    
    // Проверка наличия значений в фильтрах
    const hasFilterValues = Object.values(filters).some(value => value !== '');
    
    // Запрос данных
    const data = await api.shop.getShop(
        settings.curProjectName,
        filters,
        limit.current || settingsDefList.limit,
        settings.offset,
        settings.base,
        hasFilterValues ? '' : lastUUIDRef.current // Не передаем lastUUID, если есть значения в фильтрах
    );
    
    if (data.error && data.error !== 'data not found') {
        // Обработка ошибки
    } else if (data.anketa || lastUUIDRef.current) {
        if (!data.anketa) return;
    
        const transformData = [];
        let count = data.anketa.length - 1;
        while (count >= 0) {
         for (let key of Object.keys(data)) {
            transformData[count] = {
             ...transformData[count],
             [key]: data[key][count],
            };
         }
         count--;
        }
    
        const isGlobalUpdating = !lastUUIDRef.current;
        if (transformData.length) {
         lastUUIDRef.current = transformData[0].uuid;
         startGlobalUpdating();
        } else {
         lastUUIDRef.current = '';
        }
    
        if (hasFilterValues) {
         // Если есть значения в фильтрах, заменяем старые данные новыми
         setList([...transformData]);
         listRef.current = [...transformData];
        } else {
         // Логика обновления списка, если нет значений в фильтрах
         const newTransfItems = [];
         for (let transformItem of transformData) {
            const itemIndex = list.findIndex(
             (item) => item.uuid === transformItem.uuid
            );
            if (itemIndex >= 0) {
             if (
                JSON.stringify(list[itemIndex]) !== JSON.stringify(transformItem)
             ) {
                newTransfItems.push(transformItem);
             }
            } else {
             newTransfItems.push(transformItem);
            }
         }
    
         const updatedList = [];
         for (let transformD of transformData) {
            const index = list.findIndex((item) => item.uuid === transformD.uuid);
            if (index < 0) {
             updatedList.push(transformD);
            }
         }
         for (let item of list) {
            if (transformData.findIndex((tItem) => tItem.uuid === item.uuid) >= 0) {
             const index = newTransfItems.findIndex(
                (tItem) => tItem.uuid === item.uuid
             );
             if (index >= 0) {
                updatedList.push(newTransfItems[index]);
             } else {
                updatedList.push(item);
             }
            }
         }
    
         setLightList([...newTransfItems.map((item) => item.uuid)]);
    
         const updatedOldList = [...list];
         for (let updatedItem of updatedList) {
            const oldIndex = updatedOldList.findIndex(
             (updatedOldItem) => updatedOldItem.uuid === updatedItem.uuid
            );
            if (oldIndex >= 0) {
             updatedOldList.splice(oldIndex, 1);
            }
         }
    
         if (updatedOldList.length + updatedList.length >= limit.current) {
            setIsShowMore(true);
         } else {
            setIsShowMore(false);
         }
    
         if (isGlobalUpdating) {
            setList([...updatedList]);
            listRef.current = [...updatedList];
         } else {
            if (newTransfItems.length) {
             setList([...updatedOldList, ...updatedList]);
             listRef.current = [...updatedOldList, ...updatedList];
            }
         }
        }
    } else {
        setIsShowMore(false);
        setList([]);
        lastUUIDRef.current = '';
        listRef.current = [];
    }
    setTimeout(() => {
        loadedRef.current = true;
        setLoading(false);
        setShowMoreLoading(false);
    }, 10);
    };
  useEffect(() => {
    setSortList([
      ...sorting(
        list,
        settings.sort.key,
        settings.sort.direction === 'desc' ? 1 : 2
      ),
    ]);
  }, [list, settings.sort]);

  const startUpdatingData = async (settings) => {
    const list = listRef.current || [];
    await getData(settings, list);
    setBtnUpdating(false);
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      startUpdatingData(settings);
    }, settings.time.value * 1000);
  };

  const startGlobalUpdating = () => {
    if (globalUpdatingTimer.current) clearTimeout(globalUpdatingTimer.current);
    globalUpdatingTimer.current = setTimeout(() => {
      if (lastUUIDRef.current) {
        lastUUIDRef.current = '';
        // startUpdatingData(settings);
        // startGlobalUpdating();
      }
      // }, 1000 * 60 * 0.5);
    }, 1000 * 60 * 15);
  };

  useEffect(() => {
    return () => clearTimeout(timer.current);
  }, []);

  const changeSettings = (key, val) => {
    setSettings((prev) => ({ ...prev, [key]: val }));
  };

  // eslint-disable-next-line
  const debounceLoadData = useCallback(debounce(startUpdatingData, 700), []);

  useEffect(() => {
    if (limit.current !== settingsDefList.limit) {
      limit.current = settingsDefList.limit;
      // changeSettings('limit', settingsDefList.limit);
    }
    debounceLoadData({ ...settings, limit: settingsDefList.limit });
    // eslint-disable-next-line
  }, [
    settings.sku,
    settings.name,
    settings.base,
    settings.tg_id,
    settings.anketa,
    settings.event_id,
    settings.time.value,
    settings.datetimeStart,
    settings.curProjectName,
    settings.datetimeFinish,
  ]);

  const changeStatus = async () => {
    if (!editGood) return;
    setUpdating(true);
    const putJSON = {
      bot: {
        base: settings.base,
        slag: settings.curProjectName,
      },
      collectedPurchaseUuids:
        editGood.key !== 'cancel' && editGood.uuid ? [editGood.uuid] : [],
      cancelCollectedPurchaseUuids:
        editGood.key === 'cancel' && editGood.uuid ? [editGood.uuid] : [],
    };
    const resp = await api.shop.updateStatus(putJSON);
    if (
      editGood.key === 'cancel' &&
      resp?.resultCancelPUT ===
        'нет найденных uuid для отмены, данные не обновлены'
    ) {
      setUpdating(false);
      return console.log('Ошибка: ' + resp?.resultCancelPUT);
    }
    if (
      !editGood.key &&
      resp?.resultPUT === 'нет найденных uuid, данные не обновлены'
    ) {
      setUpdating(false);
      return console.log('Ошибка: ' + resp?.resultPUT);
    }
    console.log('editGood', editGood);
    console.log('list', list);
    const editGoodIndex = list.findIndex((item) => item.uuid === editGood.uuid);
    // console.log('editGoodIndex', editGoodIndex);
    // console.log('list[editGoodIndex]', list[editGoodIndex]);
    const updatedList = [...list];
    updatedList.splice(editGoodIndex, 1, {
      ...editGood,
      collected: editGood.collected ? false : true,
    });
    setList([...updatedList]);
    // listRef.current = [...list.filter((item) => item.uuid !== deletedItem)];

    // await startUpdatingData(settings, list);
    setEditGood();
    setUpdating(false);
  };

  const delItem = async () => {
    if (!editGood) return;
    setUpdating(true);
    const delJSON = {
      bot: {
        base: settings.base,
        slag: settings.curProjectName,
      },
      deletePurchaseUuids: editGood.uuid ? [editGood.uuid] : [],
    };
    const deletedItem = await api.shop.deleteItem(delJSON);

    // console.log(deletedItem);
    if (deletedItem.error) {
      // console.log(deletedItem.error);
      setUpdating(false);
      return;
    }

    setList([...list.filter((item) => item.uuid !== deletedItem)]);
    listRef.current = [...list.filter((item) => item.uuid !== deletedItem)];
    // await startUpdatingData(settings, list);
    setEditGood();
    // setUpdating(true);
    setTimeout(() => {
      setUpdating(false);
    }, 35);
  };

  const share = (e) => {
    if (!settings.curProjectName) return;
    const shareLink =
      process.env.REACT_APP_SITE_URI +
      '/share/shop/' +
      btoa('slug=' + settings.curProjectName + '&page=table&base=' + base);

    setShareMess('Ссылка скопирована!');
    navigator.clipboard
      .writeText(shareLink)
      .then(() => console.log('Успешно скопировано'))
      .catch((err) => console.error(err));
    setTimeout(() => {
      setShareMess('');
    }, 2000);
  };

  const showMore = () => {
    limit.current = limit.current + settingsDefList.limit;
    setShowMoreLoading(true);
    startUpdatingData(settings);
  };

  const saveExcel = async () => {
    if (!filteredList.length) {
      return alert('Данных нет');
    }
    setExcelLoading(true);

    const resArr = [
      [
        'Анкета', //0
        'Статус', //1
        'Дата и время', //2
        'Название', //3
        'Цена', //4
        'sku', //5
        'tg', //6
        'uuid', //7
      ],
    ];

    for (let item of filteredList) {
      let sortedArr = [];
      sortedArr[0] = item.anketa;
      sortedArr[1] = item.collected ? 'Забрали' : 'Не забрали';
      sortedArr[2] = item.date + ' ' + item.time;
      sortedArr[3] = item.good;
      sortedArr[4] = item.price;
      sortedArr[5] = item.sku;
      sortedArr[6] = item.tg;
      sortedArr[7] = item.uuid;
      resArr.push(sortedArr);
    }
    const ws = XLSX.utils.aoa_to_sheet(resArr);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'SheetJS');
    XLSX.writeFile(wb, 'sheetjs.xlsx');

    // let curRowIndex = 0;
    for (let item of filteredList) {
      resArr.push(Object.values(item));
    }
    await api.shop.updateExcel(resArr);

    setExcelLoading(false);
  };

  const switchPage = (pageN) => {
    if (isShared) {
      if (pageN !== activePage) {
        let url = '';
        switch (pageN) {
          case 1:
            url = btoa(
              'slug=' + settings.curProjectName + '&page=table&base=' + base
            );
            break;
          case 2:
            url = btoa(
              'slug=' + settings.curProjectName + '&page=statistic&base=' + base
            );
            break;
          case 3:
            url = btoa(
              'slug=' + settings.curProjectName + '&page=goods&base=' + base
            );
            break;
          case 4:
            url = btoa(
              'slug=' + settings.curProjectName + '&page=settings&base=' + base
            );
            break;
          default:
            return (url = '');
        }
        navigate('/share/shop/' + url);
      }
    } else {
      let url = '';
      switch (pageN) {
        case 1:
          url = '';
          break;
        case 2:
          url = '/statistic';
          break;
        case 3:
          url = '/goods';
          break;
        case 4:
          url = '/settings';
          break;
        default:
          return (url = '');
      }
      navigate('/shop' + url);
    }
  };

  return (
    <div className={'page ' + s.page + ' ' + (isShared ? s.shared : '')}>
      <h3 className={s.title}>Магазин {isShared ? ': ' + slug : ''}</h3>
      <div className={s.main}>
        <>
          <div className={s.pageSwitcher}>
            <button
              className={
                (activePage === 4 ? s.active : '') +
                (isShared ? ' ' + s.shared : '')
              }
              onClick={() => switchPage(4)}
            >
              Настройки
            </button>
            <button
              className={
                (activePage === 3 ? s.active : '') +
                (isShared ? ' ' + s.shared : '')
              }
              onClick={() => switchPage(3)}
            >
              Товары
            </button>
            <button
              className={
                (activePage === 1 ? s.active : '') +
                (isShared ? ' ' + s.shared : '')
              }
              onClick={() => switchPage(1)}
            >
              Таблица покупок
            </button>
            <button
              className={
                (activePage === 2 ? s.active : '') +
                (isShared ? ' ' + s.shared : '')
              }
              onClick={() => switchPage(2)}
            >
              Статистика
            </button>
          </div>
          <p className={s.subTitle + ' ' + s.desc}>Таблица покупок</p>
          <p className={s.subTitle + ' ' + s.mob}>Покупки</p>
          <div className={s.shopSettings}>
            <div className={s.left}>
              <Input
                className={s.input}
                name={'datetimeStart'}
                value={settings.datetimeStart}
                innerLabel="Дата и время начала"
                handleChange={(v, name) => changeSettings(name, v)}
              />
              <Input
                className={s.input}
                name={'datetimeFinish'}
                innerLabel="Дата и время конца"
                value={settings.datetimeFinish}
                handleChange={(v, name) => changeSettings(name, v)}
              />

              <Input
                name={'anketa'}
                innerLabel="Имя"
                className={s.input}
                value={settings.anketa}
                handleChange={(v, name) => changeSettings(name, v)}
              />
              <Input
                name={'name'}
                innerLabel="Имя товара"
                className={s.input}
                value={settings.name}
                handleChange={(v, name) => changeSettings(name, v)}
              />
              <Select
                value={settings.type}
                selected={settings.type}
                className={s.select}
                btnClassName={s.selectBtn}
                selectList={settingsDefList.type}
                isShowed={showedList === 'surveyType'}
                showList={() => {
                  'surveyType' === showedList
                    ? setShowedList('')
                    : setShowedList('surveyType');
                }}
                select={(val) => changeSettings('type', val)}
              />
              <Input
                name={'sku'}
                innerLabel="sku"
                className={s.input}
                value={settings.sku}
                handleChange={(v, name) => changeSettings(name, v)}
              />
              <Input
                name={'tg_id'}
                innerLabel="tg_id"
                className={s.input}
                value={settings.tg_id}
                handleChange={(v, name) => changeSettings(name, v)}
              />
              <Input
                name={'event_id'}
                innerLabel="event_id"
                className={s.input}
                value={settings.event_id}
                handleChange={(v, name) => changeSettings(name, v)}
              />
            </div>
            <div className={s.right}>
              <Select
                btnClassName={s.selectBtn}
                value={settings.time.name}
                selected={settings.time.name}
                isShowed={showedList === 'time'}
                selectList={settingsDefList.time}
                className={s.select + ' ' + s.time}
                showList={() => {
                  'time' === showedList
                    ? setShowedList('')
                    : setShowedList('time');
                }}
                select={(val) => changeSettings('time', val)}
              />
              <button
                disabled={loading}
                onClick={() => {
                  // loadedRef.current = false;
                  setBtnUpdating(true);
                  startUpdatingData(settings, list);
                }}
                className={'btnLight ' + (btnUpdating ? 'loading' : '')}
              >
                Обновить данные
                {btnUpdating && <BtnLoader />}
              </button>
              <button
                onClick={share}
                disabled={shareMess}
                className={'btnDark ' + s.btnShare}
              >
                Поделиться
                {shareMess && <div className={s.shareMess}>{shareMess}</div>}
              </button>
            </div>
          </div>
          <ShopTable
            loading={loading}
            list={filteredList}
            settings={settings}
            saveExcel={saveExcel}
            lightList={lightList}
            tableListRef={tableListRef}
            excelLoading={excelLoading}
            changeSettings={changeSettings}
            del={(item) => setEditGood({ ...item, key: 'del' })}
            changeStatus={(item, key) => setEditGood({ ...item, key: key })}
          />
          <ShopMobTable
            loading={loading}
            list={filteredList}
            settings={settings}
            lightList={lightList}
            saveExcel={saveExcel}
            excelLoading={excelLoading}
            changeSettings={changeSettings}
            del={(item) => setEditGood({ ...item, key: 'del' })}
            changeStatus={(item, key) => setEditGood({ ...item, key: key })}
          />
          {isShowMore && (
            <button
              className={'btnLight ' + s.btnShowMore}
              disabled={!isShowMore}
              onClick={showMore}
            >
              {showMoreLoading ? 'Загрузка...' : 'Показать ещё'}
            </button>
          )}
        </>
      </div>
      <PopUp
        isOpen={editGood}
        close={() => setEditGood()}
        className={s.editGoodPopup}
      >
        <h3>
          {editGood?.key === 'del'
            ? 'Удалить?'
            : editGood?.key
            ? 'Отменить заказ?'
            : 'Товар забрали?'}
        </h3>
        <div className={s.popupBtns}>
          <button onClick={() => setEditGood()} className="btnDark">
            Отмена
          </button>
          <button
            disabled={updating}
            className="btnLight"
            onClick={editGood?.key === 'del' ? delItem : changeStatus}
          >
            {updating ? 'Подтверждение...' : 'Подтвердить'}
          </button>
        </div>
      </PopUp>
    </div>
  );
};

export default ShopTablePage;
