import { useState } from 'react';
import s from './Menu.module.scss';
import PopUp from '../../components/common/PopUp';
import Input from '../../components/common/Input';
import Select from '../../components/common/Select';
import Checkbox from '../../components/common/Checkbox';

const MenuSettingsPopup = ({ close, save, data, changeData }) => {
  const [showedSelect, setShowedSelect] = useState('');

  const showSelectList = (listName) => {
    if (listName === showedSelect) {
      setShowedSelect('');
    } else {
      setShowedSelect(listName);
    }
  };

  return (
    <PopUp
      close={close}
      isOpen={true}
      className={s.popupSettings}
      contentClassName={s.popupSettingsContent}
    >
      <h2 className={s.title}>Настройки меню</h2>

      <Input
        name="id"
        type="number"
        onReset={true}
        label="ID меню"
        className={s.input}
        value={data.menu_number.data}
        handleChange={(val) => changeData('menu_number', +val)}
      />

      <Select
        className={s.select}
        topLabel="Тип кнопок"
        value={data.buts_type.data}
        selected={data.buts_type.data}
        selectList={['виртуальные кнопки']}
        isShowed={showedSelect === 'buts_type'}
        showList={() => showSelectList('buts_type')}
        select={(val) => changeData('buts_type', val)}
      />
      <div className={s.bottomRow}>
        <Input
          onReset={true}
          type="number"
          name="buts_in_row"
          label="Кнопок в строке"
          value={data.buts_in_row.data}
          className={s.input + ' ' + s.mini}
          handleChange={(val) => changeData('buts_in_row', +val)}
        />
        <Checkbox
          className={s.checkbox}
          isOn={data.delete_menu_after.data}
          handleClick={() =>
            changeData('delete_menu_after', !data.delete_menu_after.data)
          }
          label="Удалять меню после нажатия кнопки"
        />
      </div>
      <div className={s.btns}>
        <button onClick={save} className={'btnLight ' + s.btnSave}>
          Сохранить
        </button>
        <button onClick={close} className={'btnDark ' + s.btnCancel}>
          Отмена
        </button>
      </div>
    </PopUp>
  );
};

export default MenuSettingsPopup;
