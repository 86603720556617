import React from 'react';
import s from './Quizzes.module.scss';

const QuizRowBtns = ({ handleClick, btnsList, rowId }) => {
  return (
    <>
      {btnsList.map(({ icon, text, name }, index) => (
        <button
          key={index}
          className={s.btnRow}
          onClick={() => handleClick(name, rowId)}
        >
          {icon ? icon : icon}
          <span>{text}</span>
        </button>
      ))}
    </>
  );
};

export default QuizRowBtns;
