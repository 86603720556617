import s from "./Menu.module.scss";
import trashBin from "../../imgs/quizDel";
import activeList from "./activeList.json";
// import delIcon from '../../imgs/trashBin';
// import addMagic from '../../imgs/addMagic';
import { useEffect, useState } from "react";
import plusSelect from "../../imgs/plusSelect";
import arrowIcon from "../../imgs/arrowSelect";
// import QuizRowBtns from '../Quizzes/QuizRowBtns';
import Input from "../../components/common/Input";
import Select from "../../components/common/Select";
import Textarea from "../../components/common/Textarea";
import InputImg from "../../components/common/InputImg";
import Switcher from "../../components/common/Switcher";
import InputBlock from "../../components/common/InputBlock";
import Checkbox from "../../components/common/Checkbox";

const MenuItem = ({
	num,
	data = {},
	menuBtns,
	addAction,
	delAction,
	changeData,
	// delItem,
	// menuKeys,
	handleChoose,
	choosedActionList,
}) => {
	const [isOpened, setIsOpened] = useState(false);
	const [showedSelect, setShowedSelect] = useState("");

	//, setLists
	const [lists] = useState({
		after: ["Рестарт", "Ничего"],
		actives: activeList,
	});

	// const btnsList = [
	//   {
	//     icon: addMagic,
	//     name: 'addMagic',
	//     text: 'добавить магию',
	//   },
	//   {
	//     name: 'checkAI',
	//     text: 'проверка ИИ',
	//   },
	// ];

	const toggleActive = (e) => {
		e.stopPropagation();
	};

	const showSelectList = (listName) => {
		if (listName === showedSelect) {
			setShowedSelect("");
		} else {
			setShowedSelect(listName);
		}
	};

	// const handleBtnRow = () => {
	//   console.log('handleBtnRow');
	// };

	const changeAdditionalKey = (val, key, moduleIndex) => {
		if (key === "add") {
			changeData(
				"buts",
				[...data.modules[moduleIndex].buts.data, val],
				moduleIndex
			);
		} else {
			changeData(
				"buts",
				[...data.modules[moduleIndex].buts.data.filter((v) => v !== val)],
				moduleIndex
			);
		}
	};

	useEffect(() => {
		// console.log(data);
		// console.log(data.modules);
	}, [data]);

	const getModulePatameter = (module, key) => {
		if (!module?.parameters[0] || !module.parameters[0]?.data) {
			return "";
		} else {
			const parameters = module.parameters[0].data.split("=");
			if (!parameters) return "";
			return parameters[key] || "";
		}
	};

	return (
		<div className={s.item + " " + (isOpened ? s.opened : "")}>
			<div
				onClick={() => setIsOpened((prev) => !prev)}
				className={s.header}
			>
				<div className={s.itemHeader}>
					<span>
						{
							`Кнопка ${num}: `
							// +
							//   (menuBtns.findIndex((btn) => btn === data.keys[0]?.data) + 1) +
							//   ': ' +
							//   data.keys[0]?.data ?? ''
						}
					</span>
					<span className={s.itemKey}>Key: {menuBtns[num - 1]}</span>
					<Switcher
						isOn={data.after.data}
						label={"Рестарт"}
						className={s.switcher + " " + s.restart}
						handleClick={(e) => {
							e.stopPropagation();
							changeData(`after`, !data.after.data);
						}}
					/>
					<Switcher
						isOn={data.after.data}
						label={"Показывать кнопку"}
						className={s.switcher + " " + s.restart}
						handleClick={(e) => {
							e.stopPropagation();
							// changeData(`after`, !data.after.data);
						}}
					/>
				</div>

				<div className={s.settings}>
					<button
						onClick={addAction}
						className={"btnTransp " + s.btnAddAction}
					>
						{plusSelect} <span>Добавить активность</span>
					</button>
					<Switcher
						isOn={true}
						label={"Вкл"}
						className={s.switcher}
						handleClick={toggleActive}
					/>
					{/* <button
            onClick={(e) => {
              e.stopPropagation();
              delItem(num - 1);
            }}
            className={s.btnDel}
          >
            {delIcon}
          </button> */}
					<div className={s.arrowWrap}>{arrowIcon}</div>
				</div>
			</div>
			{isOpened && (
				<div className={s.main}>
					{data?.modules?.length &&
						data.modules.map((module, i) => (
							<div
								key={i}
								className={s.main__action}
							>
								<div className={s.main__action__header}>
									<div className={s.main__action__header}>
										<Checkbox
											isOn={
												choosedActionList.findIndex(
													(act) =>
														act.activeIndex === i &&
														act.menuLevel === data.menu_level.data &&
														act.menuItemIndex === num - 1
												) >= 0
											}
											handleClick={(e) => {
												e.stopPropagation();
												handleChoose(data.menu_level.data, num - 1, i);
											}}
										/>
										<span>Активность {i + 1}</span>
									</div>
									{i > 0 && (
										<button
											onClick={() => delAction(i)}
											className={s.main__action__btnDel}
										>
											{trashBin}
										</button>
									)}
								</div>
								<div className={s.main__action__main}>
									<div className={s.top}>
										{/* <Select
                  topLabel="Ключ"
                  className={s.select}
                  selectList={menuKeys}
                  value={data.keys[0]?.data ?? ''}
                  isShowed={showedSelect === 'key'}
                  selected={data.keys[0]?.data ?? ''}
                  showList={() => showSelectList('key')}
                  select={(val) => changeData('keys', val)}
                /> */}
										<Select
											value={module.name}
											className={s.select}
											topLabel="Активность"
											selected={module.name}
											selectList={lists.actives}
											select={(val) => changeData("name", val, i)}
											isShowed={showedSelect === `active_${num}_${i}`}
											showList={() => showSelectList(`active_${num}_${i}`)}
										/>

										{module.name === "баллы за челлендж" ? (
											<>
												<Input
													type="number"
													placeholder=""
													onReset={true}
													label="Кол-во баллов"
													name="activeParameter0"
													className={s.input + " " + s.parameter}
													value={getModulePatameter(module, 0)}
													handleChange={(val) =>
														changeData("parameter0", val, i)
													}
												/>
												<Input
													placeholder=""
													onReset={true}
													type="number"
													label="Лимит баллов"
													name="activeParameter1"
													className={s.input + " " + s.parameter}
													value={getModulePatameter(module, 1)}
													handleChange={(val) =>
														changeData("parameter1", val, i)
													}
												/>
												<Input
													label="Тег"
													onReset={true}
													name="activeParameter2"
													placeholder=""
													value={getModulePatameter(module, 2)}
													className={s.input + " " + s.parameter}
													handleChange={(val) =>
														changeData("parameter2", val, i)
													}
												/>
											</>
										) : (
											<Input
												placeholder=""
												onReset={true}
												name="activeParameter"
												label="Параметр активности"
												className={s.input + " " + s.parameter}
												value={module.parameters[0]?.data ?? ""}
												handleChange={(val) => changeData("parameter", val, i)}
											/>
										)}
										{/* <Select
                      className={s.select}
                      value={data.after.data}
                      topLabel="Действие после"
                      selected={data.after.data}
                      selectList={lists.after}
                      select={(val) => changeData(`after`, val, i)}
                      isShowed={showedSelect === `after_${num}_${i}`}
                      showList={() => showSelectList(`after_${num}_${i}`)}
                    /> */}
									</div>
									<div className={s.middle}>
										{/* <Input
                  name="text"
                  placeholder=""
                  onReset={true}
                  className={s.input}
                  label="Текст по кнопке"
                  value={data.keys[0]?.data ?? ''}
                  handleChange={(val) => console.log(val)}
                /> */}
										<Textarea
											label="Текст"
											placeholder=""
											className={s.textarea}
											val={module.text?.data || ""}
											wrapClassName={s.textareaWrap}
											handleChange={(val) => changeData("text", val, i)}
										/>
										<InputImg
											className={s.imgInput}
											img={module.media?.data}
											id={`menuItemImg_${num}_${i}`}
											setImg={(img) => changeData("media", img, i)}
										/>
										{/* <ul className={'scroll ' + s.btnRowList}>
                  <QuizRowBtns
                    rowId={data.name}
                    handleClick={handleBtnRow}
                    btnsList={module.buts?.data}
                  />
                </ul> */}
									</div>
									<div className={s.bottom}>
										<InputBlock
											className=""
											name={`menuItem_${1}`}
											value={module.buts?.data}
											placeholder={"Введите ключ"}
											label="Дополнительные ключи"
											handleChange={(val, key) =>
												changeAdditionalKey(val, key, i)
											}
										/>
									</div>
								</div>
							</div>
						))}
				</div>
			)}
		</div>
	);
};

export default MenuItem;
