import s from './Shop.module.scss';
import exelIcon from './exelIcon.png';
import quizDel from '../../imgs/quizDel';
import Loader from '../../components/common/Loader';

const ShopTableUI = ({
  del,
  list,
  loading,
  saveExcel,
  settings,
  lightList,
  excelLoading,
  tableListRef,
  changeStatus,
  changeSettings,
  prodPerPage = 10,
}) => {
  const changeSort = (sortKey) => {
    if (settings.sort.key === sortKey) {
      if (settings.sort.direction === 'asc') {
        changeSettings('sort', { key: '', direction: '' });
      } else if (settings.sort.direction === 'desc') {
        changeSettings('sort', { key: sortKey, direction: 'asc' });
      } else {
        changeSettings('sort', { key: sortKey, direction: 'desc' });
      }
    } else {
      changeSettings('sort', { key: sortKey, direction: 'desc' });
    }
  };

  return (
    <>
      <div className={s.table + ' ' + (loading ? s.loading : '')}>
        {/* table head */}
        <div className={s.tableHead}>
          {[
            'event_id',
            'sku',
            'Дата и время',
            'Цена',
            'Название',
            'tg',
            'Анкета',
            'Статус',
          ].map((item, i) => (
            <button
              key={i}
              onClick={() => changeSort(item)}
              className={
                'btnTransp ' +
                (settings.sort.key === item ? s.active : '') +
                ' ' +
                (settings.sort.direction === 'asc' ? s.rotated : '')
              }
            >
              {item}
            </button>
          ))}
          <p></p>
          <button
            disabled={excelLoading}
            className={s.btnExcel}
            onClick={() => saveExcel()}
          >
            <img src={exelIcon} alt="" />
          </button>
        </div>
        {/* table body */}
        <ul ref={tableListRef} className={s.tableList}>
          {list.map((item, i) => (
            <li
              className={
                s.tableRow +
                (i + 1 === prodPerPage ? ' ' + s.lastRow : '') +
                (lightList.includes(item.uuid) ? ' ' + s.new : '')
              }
              key={i}
            >
              <p>{item.event_id}</p>
              <p>{item.sku}</p>
              <p>{item.date + ' ' + item.time}</p>
              <p>{item.price} </p>
              <p>{item.good} </p>
              <p>{item.tg} </p>
              <p>{item.anketa} </p>
              <p
                className={
                  s.listItemStatus + ' ' + (item.collected ? s.green : s.red)
                }
              >
                {item.collected ? 'Забрали' : 'Не забрали'}
              </p>
              {!item.collected ? (
                <button
                  onClick={() => changeStatus(item)}
                  className={'btnLight ' + s.listItemBtn}
                >
                  Товар забрали
                </button>
              ) : (
                <button
                  onClick={() => changeStatus(item, 'cancel')}
                  className={'btnDark ' + s.listItemBtn}
                >
                  Отменить
                </button>
              )}
              {item.collected ? (
                ''
              ) : (
                <button onClick={() => del(item)} className={s.btnDel}>
                  {quizDel}
                </button>
              )}
            </li>
          ))}
          {!loading && !list.length && (
            <h3 className={s.emptyMes}>Нет записей</h3>
          )}
        </ul>
        {loading && <Loader />}
      </div>
    </>
  );
};

export default ShopTableUI;
