import s from './Auth.module.scss';
import { useSelector } from 'react-redux';
import ReactCodeInput from 'react-code-input';
import { useOutletContext } from 'react-router-dom';
// import InputCode from '../../components/auth/InputCode';
import React, { useEffect, useRef, useState } from 'react';

const FormKeyCheck = () => {
  const [
    email,
    error,
    sendCode,
    clearError,
    changeFormOption,
    codeValue,
    sendEmail,
  ] = useOutletContext();
  const { isLoading } = useSelector((state) => state.auth.authData);
  const [timeToReRequest, setTimeToReRequest] = useState(60);
  const [code, setCode] = useState(codeValue);
  const [codeLength] = useState(6);
  const codeInput = useRef();

  const changeClassCodeForm = () => {
    if (codeInput.current?.textInput) {
      for (let input of codeInput.current.textInput) {
        if (input.value) {
          input.classList.add('filled');
        } else {
          input.classList.remove('filled');
        }
      }
    }
  };

  useEffect(() => {
    if (timeToReRequest <= 0) {
      setTimeToReRequest(0);
      return;
    }
    setTimeout(() => {
      setTimeToReRequest(timeToReRequest - 1);
    }, 1000);
  }, [timeToReRequest]);

  const handleChange = (val) => {
    setCode(val);
    clearError('code');
  };

  const handleClick = (e) => {
    if (e) {
      e.preventDefault();
    }
    sendCode(code);
  };

  return (
    <>
      <h1 className={s.title}>Мы отправили код на {email}</h1>
      <p className={s.text}>Введите код, чтобы продолжить</p>
      <form onSubmit={handleClick}>
        <div className={s.input__wrap}>
          <label className={s.input__label}>Код</label>
          <ReactCodeInput
            value={code}
            ref={codeInput}
            onChange={(code) => {
              changeClassCodeForm();
              handleChange(code);
            }}
            fields={6}
            type="number"
            className="formCodeInput"
          />
          <p className={s.input__textError}>{error}</p>
        </div>
        {/* <InputCode
          name="code"
          label="Код"
          value={code}
          error={error}
          codeLength={codeLength}
          handleChange={handleChange}
        /> */}
        {!!timeToReRequest ? (
          <p className={s.text}>
            Отправить повторно через {timeToReRequest} сек.{' '}
          </p>
        ) : (
          <p className={s.text}>
            <button
              style={{ color: '#fff' }}
              onClick={() => {
                sendEmail(email);
                setTimeToReRequest(60);
              }}
            >
              Отправить повторно
            </button>
          </p>
        )}
        <ul className={s.btnWrap + ' ' + s.oneWidth}>
          <button
            disabled={code.length !== codeLength || error || isLoading}
            className={'btnLight ' + s.btnForm}
            onClick={handleClick}
          >
            <span>{isLoading ? 'Загрузка...' : 'Продолжить'}</span>
          </button>
        </ul>
      </form>
      <ul className={s.btnWrap}>
        <button
          className={'btnLight ' + s.btnForm + ' ' + s.light}
          onClick={changeFormOption}
        >
          Другой способ авторизации
        </button>
      </ul>
    </>
  );
};

export default FormKeyCheck;
