const IconOk = (
  <svg
    version="1.1"
    id="svg2"
    width="800px"
    height="800px"
    viewBox="0 0 1200 1200"
    enableBackground="new 0 0 1200 1200"
  >
    <path
      id="path15124"
      d="M1004.237,99.152l-611.44,611.441L194.492,512.288L0,706.779
   l198.305,198.306l195.762,195.763L588.56,906.355L1200,294.916L1004.237,99.152z"
    />
  </svg>
);

export default IconOk;
