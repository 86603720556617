import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../store';
import ShopSettingsPage from './ShopSettingsPage';
import { setLastShopTab } from '../../store/main/actionCreators';

const ShopSettings = () => {
  const dispatch = useAppDispatch();
  const { curProject } = useSelector((state) => state.main);
  const curProjectName = curProject?.name;
  const { curBot } = useSelector((state) => state.bot);
  // const curProjectName = 'mts-true-summer24-serverless221';

  useEffect(() => {
    dispatch(setLastShopTab('/shop/settings'));
    // eslint-disable-next-line
  }, []);

  return (
    <ShopSettingsPage slug={curProjectName || ''} base={curBot.base || ''} />
  );
};

export default ShopSettings;
