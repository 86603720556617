import React from 'react';
import s from '../Auth.module.scss';

const ProgressBar = ({ percent, text }) => {
  return (
    <div className={s.progress__wrap}>
      <p className={s.progress__text}>{text}</p>
      <div className={s.progress__bar}>
        <div
          className={s.progress__bar__aggregate}
          style={{ maxWidth: percent + '%' }}
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;
