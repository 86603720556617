import PopupAddBot from './PopupAddBot';
import { useSelector } from 'react-redux';
import quizEdit from '../../imgs/quizEdit';
import { getMyBotsApi } from '../../api/bot';
import { useAppDispatch } from '../../store';
import plusSelect from '../../imgs/plusSelect';
// import { useNavigate } from 'react-router-dom';
import PopUp from '../../components/common/PopUp';
import React, { useEffect, useState } from 'react';
import s from '../Controller/Controller.module.scss';
import Loader from '../../components/common/Loader';
import PopupConfirmRebind from './PopupConfirmRebind';
import { updateTree } from '../../store/main/actionCreators';
import { clearBotErrors, rebindBot } from '../../store/bot/actionCreators';

const PopupChooseBot = ({ close }) => {
  //   const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [botList, setBotList] = useState([]);
  const botData = useSelector((state) => state.bot);
  const [, setErrors] = useState({ token: '' }); // errors
  const { curProject, curCompany } = useSelector((state) => state.main);
  const [botListLoading, setBotListLoading] = useState(true);
  const isLoading = useSelector((state) => state.bot.isLoading);
  const [popupAddBotOpened, setPopupAddBotOpened] = useState(false);
  const { user_id } = useSelector((state) => state.auth.profileData);
  const updatingTree = useSelector((state) => state.main.updatingTree);
  const [confirmRebindBot, setConfirmRebindBot] = useState();

  useEffect(() => {
    if (botData.isAdded && !updatingTree) {
      getMyBots();
      dispatch(updateTree());
      dispatch(clearBotErrors());
    }
    if (botData?.errors?.addBot) {
      setErrors({ token: botData.errors.addBot });
    }
    // eslint-disable-next-line
  }, [botData]);

  const getMyBots = async () => {
    setBotListLoading(true);
    const botList = await getMyBotsApi();
    if (!botList) {
      return console.log('getMyBots error');
    }
    if (botList?.name?.length) {
      let i = 0;
      const newBotList = [];
      for (let botName of botList.name) {
        newBotList.push({ bot: botName, project: botList.project[i] });
        i++;
      }
      setBotList([...newBotList]);
    }
    setBotListLoading(false);
  };

  useEffect(() => {
    getMyBots();
  }, [user_id]);

  const isActiveBot = (bot) => {
    // console.log('`````````````````````');
    // console.log(botItem);
    // console.log(curProject);
    // console.log(botData);
    // console.log('`````````````````````');
    return bot.bot === botData.curBot.username;
  };

  const reBindBot = (botItem) => {
    console.log('reBindBot');
    confirmRebindBot && setConfirmRebindBot();
    dispatch(rebindBot(botItem.bot, user_id, curCompany.name, curProject.name));
  };

  const bindBot = (bot) => {
    if (bot.project) {
      setConfirmRebindBot(bot);
    } else {
      reBindBot(bot);
    }
  };

  return (
    <>
      <PopUp close={close} isOpen={true}>
        <div className={s.popupChooseBot}>
          <h3 className={s.title}>
            Добавить бота в проект {curProject.title || curProject.name}
          </h3>
          {!botListLoading && !isLoading && (
            <button
              className={s.btnAdd + ' ' + s.btnAddBot}
              onClick={() => {
                setPopupAddBotOpened(true);
              }}
            >
              {plusSelect}
              <p>Создать нового бота</p>
            </button>
          )}

          <ul className={'scroll ' + s.botList}>
            {!botListLoading &&
              !isLoading &&
              botList.map((botItem, i) => (
                <li
                  className={
                    s.botItem + (isActiveBot(botItem) ? ' ' + s.active : '')
                  }
                  key={i}
                >
                  <p>{botItem.bot}</p>
                  {isActiveBot(botItem) ? (
                    <button className={s.btnEdit}>
                      <span>Отвязать</span>
                      {quizEdit}
                    </button>
                  ) : (
                    <button
                      className={s.btnEdit}
                      onClick={() => bindBot(botItem)}
                    >
                      <span>Выбрать</span>
                      {quizEdit}
                    </button>
                  )}
                  {botItem.project ? (
                    isActiveBot(botItem) ? (
                      <p className={s.botProject + ' ' + s.blue}>
                        <span>Привязан к текущему проекту</span>
                      </p>
                    ) : (
                      <p className={s.botProject + ' ' + s.green}>
                        <span>Привязан к проекту: {botItem.project}</span>
                      </p>
                    )
                  ) : (
                    <p className={s.botProject + ' ' + s.red}>
                      <span>Не привязан к проекту{botItem.project}</span>
                    </p>
                  )}
                </li>
              ))}
            {(botListLoading || isLoading) && <Loader />}
          </ul>
          {/* <ul className={s.btnWrap}>
            <button
              disabled={isLoading}
              className="btnLight"
              onClick={() => setPopupAddBotOpened(true)}
            >
              {isLoading ? 'Подключение...' : 'Подключить'}
            </button>
            <button onClick={close} className="btnDark">
              Отмена
            </button>
          </ul> */}
        </div>
      </PopUp>
      {popupAddBotOpened && (
        <PopupAddBot close={() => setPopupAddBotOpened(false)} />
      )}
      {confirmRebindBot && (
        <PopupConfirmRebind
          bot={confirmRebindBot.bot}
          close={() => setConfirmRebindBot()}
          ok={() => reBindBot(confirmRebindBot)}
        />
      )}
    </>
  );
};

export default PopupChooseBot;
