import api from "../../api";
import MenuMain from "./MenuMain";
import s from "./Menu.module.scss";
import MenuHeader from "./MenuHeader";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import defMenuItem from "./defMenuItem.json";
import defMenuText from "./defMenuText.json";
import PopupAddBot from "../Bot/PopupAddBot";
import { useNavigate } from "react-router-dom";
import defMenuModule from "./defMenuModule.json";
import MenuSettingsPopup from "./MenuSettingsPopup";
import Loader from "../../components/common/Loader";
import MenuUpdatingPopup from "./MenuUpdatingPopup";

const Menu = () => {
	const [json, setJson] = useState();
	const [data, setData] = useState();

	const navigate = useNavigate();
	const [curMenu, setCurMenu] = useState({});
	const [menuList, setMenuList] = useState([]);
	const [curMenuNum, setCurMenuNum] = useState(0);
	const [curMenuItems, setCurMenuItems] = useState([]);

	const [btnSave, setBtnSave] = useState("Сохранить");
	const [btnPublish, setBtnPublish] = useState("Опубликовать");
	const [btnPublishChoosed, setBtnPublishChoosed] = useState(
		" Опубликовать выбранные "
	);

	const [loading, setLoading] = useState(false);
	const [settingsOpened, setSettingsOpened] = useState("");
	const [updatingPopup, setUpdatingPopup] = useState(false);

	const curBot = useSelector((state) => state.bot.curBot);
	const botIsLoaded = useSelector((state) => state.bot.isLoaded);
	const { curProject } = useSelector((state) => state.main);
	const { user_id } = useSelector((state) => state.auth.profileData);
	const [choosedActionList, setChoosedActionList] = useState([]);

	const getData = async () => {
		setLoading(true);
		const data = await api.menu.getMenu(
			curProject.name,
			curBot.username,
			user_id
			// surveyData
		);
		if (data.data) {
			setJson(data);
			setData(data.data);
		}
		setLoading(false);
	};

	useEffect(() => {
		if (data?.menu_texts) {
			setMenuList(data.menu_texts);
		} else {
			setMenuList([]);
		}
	}, [data]);

	useEffect(() => {
		if (curMenuNum > 0 && menuList[curMenuNum - 1]) {
			setCurMenu(menuList[curMenuNum - 1]);
		} else {
			setCurMenu({});
		}
	}, [menuList, curMenuNum]);

	useEffect(() => {
		if (data?.menu_items?.length >= 0 && curMenuNum > 0) {
			setCurMenuItems([
				...data.menu_items.filter(
					(item) => item.menu_level.data.toLowerCase() === `меню${curMenuNum}`
				),
			]);
		} else {
			setCurMenuItems([]);
		}
		if (!!data?.menu_texts?.length && !curMenuNum) {
			setCurMenuNum(1);
		}
	}, [data, curMenuNum]);

	useEffect(() => {
		if (curBot.username) {
			getData();
		} else {
			console.log("bot not found");
		}
		// eslint-disable-next-line
	}, [curBot.username]);

	const delMenu = (menuIndex) => {
		if (menuIndex < 0) return;
		if (curMenuNum === menuIndex + 1) {
			if (data.menu_texts.length > 1) {
				setCurMenuNum(1);
			} else {
				setCurMenuNum(0);
			}
		} else {
			if (data.menu_texts.length === 1) {
				setCurMenuNum(0);
			} else if (data.menu_texts.length > 1 && curMenuNum > menuIndex + 1) {
				setCurMenuNum((prev) => prev - 1);
			}
		}
		setData((prev) => ({
			...prev,
			menu_texts: [
				...prev.menu_texts.slice(0, menuIndex),
				...prev.menu_texts.slice(menuIndex + 1),
			],
			menu_items: !!prev.menu_items?.length
				? [
						...prev.menu_items
							.filter((item) => item.menu_level.data !== `меню${menuIndex + 1}`)
							.map((item) => {
								const menuItemLvl = +item.menu_level.data.slice(4);
								return menuItemLvl > menuIndex + 1
									? { ...item, menu_level: { data: `меню${menuItemLvl - 1}` } }
									: item;
							}),
				  ]
				: [],
		}));
		// setChoosedActionList((prev) =>
		// 	prev
		// 		.map((item) =>
		// 			item !== menuIndex ? (item > menuIndex ? item - 1 : item) : null
		// 		)
		// 		.filter((i) => typeof i === "number")
		// );
	};

	const addMenu = () => {
		setData((prev) => ({
			...prev,
			menu_texts: prev?.menu_texts
				? [...prev.menu_texts, defMenuText]
				: [defMenuText],
		}));
		setCurMenuNum(!!data?.menu_texts?.length ? data.menu_texts.length + 1 : 1);
	};

	const changeData = (key, val) => {
		setData((prev) => ({ ...prev, [key]: { data: val } }));
	};

	const addMenuItem = () => {
		const newItem = {
			...defMenuItem,
			menu_level: { data: `меню${curMenuNum}` },
		};
		setData((prev) => ({
			...prev,
			menu_items: prev.menu_items ? [...prev.menu_items, newItem] : [newItem],
		}));
	};

	const addAction = (e, itemIndex) => {
		e.stopPropagation();

		const editedMenuItems = data.menu_items.filter(
			(item) => item.menu_level.data === `меню${curMenuNum}`
		);
		if (!editedMenuItems?.length) return;
		const edMenuItem = editedMenuItems[itemIndex];
		const editedIndex = data.menu_items.findIndex(
			(item) => item === edMenuItem
		);
		if (editedIndex < 0) return;

		const editedMenuItem = {
			...data.menu_items[editedIndex],
			modules: [...data.menu_items[editedIndex].modules, defMenuModule],
		};
		setData((prev) => ({
			...prev,
			menu_items: [
				...prev.menu_items.slice(0, editedIndex),
				editedMenuItem,
				...prev.menu_items.slice(editedIndex + 1),
			],
		}));
	};
	const delAction = (itemIndex, actIndex) => {
		const editedMenuItems = data.menu_items.filter(
			(item) => item.menu_level.data === `меню${curMenuNum}`
		);
		if (!editedMenuItems?.length) return;
		const edMenuItem = editedMenuItems[itemIndex];
		const editedIndex = data.menu_items.findIndex(
			(item) => item === edMenuItem
		);
		if (editedIndex < 0) return;

		const editedMenuItem = {
			...data.menu_items[editedIndex],
			modules: [
				...data.menu_items[editedIndex].modules.slice(0, actIndex),
				...data.menu_items[editedIndex].modules.slice(actIndex + 1),
			],
		};
		setData((prev) => ({
			...prev,
			menu_items: [
				...prev.menu_items.slice(0, editedIndex),
				editedMenuItem,
				...prev.menu_items.slice(editedIndex + 1),
			],
		}));
		//
		const updatedChoosedActionList = choosedActionList
			// .filter((act) => act.menuLevel === edMenuItem.menu_level.data)
			.map((act) =>
				act.activeIndex === actIndex &&
				act.menuLevel === edMenuItem.menu_level.data
					? null
					: act.activeIndex > actIndex
					? { ...act, activeIndex: act.activeIndex - 1 }
					: act
			)
			.filter((act) => act?.menuLevel);
		setChoosedActionList(updatedChoosedActionList);
	};

	const changeMenu = (key, val) => {
		const editMenuIndex = curMenuNum - 1;
		const editMenuTexts = data.menu_texts;
		const editMenu = editMenuTexts[editMenuIndex];
		let editedMenu = editMenu;

		let butIndex;
		let editedItem;

		if (key === "addBut") {
			editedMenu = { ...editMenu, buts: [...editMenu.buts, ""] };
			addMenuItem();
		} else if (key.startsWith("but")) {
			butIndex = +key.slice(4);
			editedItem = { ...data.menu_items[butIndex] };
			editedItem.keys = [{ data: val }, ...editedItem.keys.slice(1)];
			editedMenu = {
				...editMenu,
				buts: [
					...editMenu.buts.slice(0, butIndex),
					val,
					...editMenu.buts.slice(butIndex + 1),
				],
			};
		} else {
			editedMenu = { ...editMenu, [key]: { data: val } };
		}
		setData((prev) => ({
			...prev,
			menu_texts: [
				...editMenuTexts.slice(0, editMenuIndex),
				editedMenu,
				...editMenuTexts.slice(editMenuIndex + 1),
			],
			menu_items: editedItem
				? [
						...prev.menu_items.slice(0, butIndex),
						editedItem,
						...prev.menu_items.slice(butIndex + 1),
				  ]
				: [...prev.menu_items],
		}));
	};

	const changeMenuItems = (i, key, val, moduleIndex) => {
		const editedMenuItems = data.menu_items.filter(
			(item) => item.menu_level.data === `меню${curMenuNum}`
		);
		if (!editedMenuItems?.length) return;
		const editedMenuItem = editedMenuItems[i];
		const editedIndex = data.menu_items.findIndex(
			(item) => item === editedMenuItem
		);
		if (editedIndex < 0) return;
		let editedItem = { ...data.menu_items[editedIndex] };
		// console.log(data);
		if (["after"].includes(key)) {
			editedItem = { ...data.menu_items[editedIndex], [key]: { data: val } };
		} else if (key === "keys") {
			editedItem.keys = [{ data: val }, ...editedItem.keys.slice(1)];
		} else {
			const editedModule = editedItem.modules[moduleIndex];
			if (["media", "text", "buts"].includes(key)) {
				editedModule[key] = { data: val };
			} else if (key === "name") {
				editedModule[key] = val;
			} else if (key === "parameter") {
				editedModule.parameters = [{ data: val }];
			} else if (key.startsWith("parameter")) {
				const parameterIndex = +key.slice(9);
				const params = editedModule.parameters[0].data.split("=");
				console.log(parameterIndex);
				console.log(params);
				params[parameterIndex] = val;
				const resParameter = params.join("=");
				editedModule.parameters = [{ data: resParameter }];
			}
			// else if (key === 'buts'){
			//   editedModule.buts = { ...data.menu_items[i], [key]: { data: val } };
			//   }
			editedItem = {
				...data.menu_items[editedIndex],
				modules: [
					...data.menu_items[editedIndex].modules.slice(0, moduleIndex),
					{ ...editedModule },
					...data.menu_items[editedIndex].modules.slice(moduleIndex + 1),
				],
			};
		}
		setData((prev) => ({
			...prev,
			menu_items: [
				...prev.menu_items.slice(0, editedIndex),
				editedItem,
				...prev.menu_items.slice(editedIndex + 1),
			],
		}));
	};

	// const delMenuItem = (i) => {
	//   setData((prev) => ({
	//     ...prev,
	//     menu_items: [
	//       ...prev.menu_items.filter(
	//         (item) => item.menu_level.data !== `меню${curMenuNum}`
	//       ),
	//       ...[...curMenuItems.slice(0, i), ...curMenuItems.slice(i + 1)],
	//     ],
	//   }));
	// };

	const delMenuBtn = (i) => {
		const editMenuIndex = curMenuNum - 1;
		const editMenuTexts = data.menu_texts;
		const editMenu = editMenuTexts[editMenuIndex];
		const editedMenu = {
			...editMenu,
			buts: [...editMenu.buts.slice(0, i), ...editMenu.buts.slice(i + 1)],
		};
		setData((prev) => ({
			...prev,
			menu_texts: [
				...editMenuTexts.slice(0, editMenuIndex),
				editedMenu,
				...editMenuTexts.slice(editMenuIndex + 1),
			],
			menu_items: [
				...prev.menu_items.filter(
					(item) => item.menu_level.data !== `меню${curMenuNum}`
				),
				...[...curMenuItems.slice(0, i), ...curMenuItems.slice(i + 1)],
			],
		}));
	};

	const save = async () => {
		setBtnSave("Сохранение...");
		const resp = await api.menu.saveMenu(
			curProject.name,
			curBot.username,
			user_id,
			{
				...json,
				data,
			}
		);
		if (resp?.data?.result) {
			setBtnSave("Сохранено");
		} else {
			setBtnSave("Ошибка");
		}
		setTimeout(() => {
			setBtnSave("Сохранить");
		}, 2000);
	};

	const publish = async () => {
		console.log({
			...json,
			data,
		});
		setBtnPublish("Публикация...");
		const resp = await api.menu.publishMenu({
			...json,
			data,
		});
		console.log(resp);
		if (resp?.data?.result) {
			setBtnPublish("Опубликовано");
		} else {
			setBtnPublish("Ошибка");
		}
		setTimeout(() => {
			setBtnPublish("Опубликовать");
		}, 2000);
	};

	const publishChoosed = async () => {
		const dataMenuItems = JSON.parse(JSON.stringify(data.menu_items));
		const updatedMenuItems = [];

		for (let choosedAction of choosedActionList) {
			const menuItem = dataMenuItems.filter(
				(mi) => mi.menu_level.data === choosedAction.menuLevel
			)[choosedAction.menuItemIndex];
			if (!menuItem) return;

			menuItem.modules = menuItem.modules.filter((mim, i) => {
				return choosedActionList
					.filter(
						(cai) =>
							cai.menuItemIndex === choosedAction.menuItemIndex &&
							cai.menuLevel === choosedAction.menuLevel
					)
					.map((cai) => cai.activeIndex)
					.includes(i);
			});

			const updatedMenuItemIndex = updatedMenuItems.findIndex(
				(umi) =>
					umi.menuItemIndex === choosedAction.menuItemIndex &&
					umi.menuLevel === choosedAction.menuLevel
			);

			if (updatedMenuItemIndex >= 0) {
			} else {
				updatedMenuItems.push({
					menuItem,
					menuItemIndex: choosedAction.menuItemIndex,
					menuLevel: choosedAction.menuLevel,
				});
			}
		}

		const choosedData = {
			...data,
			menu_texts: data.menu_texts.filter((mt, i) =>
				choosedActionList.findIndex((cai) => +cai.menuLevel.slice(4) === i)
			),
			menu_items: updatedMenuItems.map((umi) => umi.menuItem),
		};
		setBtnPublishChoosed("Публикация...");
		const resp = await api.menu.publishMenu({
			...json,
			choosedData,
		});
		if (resp?.data?.result) {
			setBtnPublishChoosed("Опубликовано");
		} else {
			setBtnPublishChoosed("Ошибка");
		}
		setTimeout(() => {
			setChoosedActionList([]);
			setBtnPublishChoosed(" Опубликовать выбранные ");
		}, 2000);
	};

	const handleChoose = (menuLevel, menuItemIndex, activeIndex) => {
		const isChoosedIndex = choosedActionList.findIndex(
			(act) =>
				act.activeIndex === activeIndex &&
				act.menuLevel === menuLevel &&
				act.menuItemIndex === menuItemIndex
		);
		if (isChoosedIndex >= 0) {
			setChoosedActionList((prev) => [
				...prev.slice(0, isChoosedIndex),
				...prev.slice(isChoosedIndex + 1),
			]);
		} else {
			setChoosedActionList((prev) => [
				...prev,
				{ menuLevel, activeIndex, menuItemIndex },
			]);
		}
	};

	useEffect(() => {
		if (choosedActionList.length) {
			setBtnPublishChoosed("Опубликовать выбранные");
		} else {
			setBtnPublishChoosed(" Опубликовать выбранные ");
		}
	}, [choosedActionList]);

	return (
		<div className={"page " + s.page + " " + (loading ? s.loading : "")}>
			{!curBot.username && botIsLoaded ? (
				<PopupAddBot close={() => navigate("/")} />
			) : (
				<>
					{loading ? (
						<Loader />
					) : (
						<>
							<MenuHeader
								addMenu={addMenu}
								delMenu={delMenu}
								menuList={menuList}
								curMenuNum={curMenuNum}
								handleChoose={handleChoose}
								choosedList={choosedActionList}
								openSettings={() => setSettingsOpened(true)}
								activeMenu={(menuNum) => setCurMenuNum(menuNum)}
							/>
							{curMenuNum > 0 && (
								<MenuMain
									save={save}
									menu={curMenu}
									publish={publish}
									btnSave={btnSave}
									delAction={delAction}
									addAction={addAction}
									btnPublish={btnPublish}
									delMenuBtn={delMenuBtn}
									changeMenu={changeMenu}
									menuItems={curMenuItems}
									handleChoose={handleChoose}
									// delMenuItem={delMenuItem}
									publishChoosed={publishChoosed}
									changeMenuItems={changeMenuItems}
									btnsInRow={data?.buts_in_row?.data}
									btnPublishChoosed={btnPublishChoosed}
									choosedActionList={choosedActionList}
									updateMenu={() => setUpdatingPopup(true)}
								/>
							)}
							{settingsOpened && data && (
								<MenuSettingsPopup
									data={data}
									changeData={changeData}
									save={() => setSettingsOpened(false)}
									close={() => setSettingsOpened(false)}
								/>
							)}
							{updatingPopup && data && (
								<MenuUpdatingPopup
									menuNum={curMenuNum}
									changeData={changeData}
									save={() => setUpdatingPopup(false)}
									close={() => setUpdatingPopup(false)}
								/>
							)}
						</>
					)}
				</>
			)}
		</div>
	);
};

export default Menu;
