import s from "./Quizzes.module.scss";
import debounce from "lodash.debounce";
import Textarea from "../../components/common/Textarea";
import Switcher from "../../components/common/Switcher";
import React, { useCallback, useEffect, useState } from "react";

const QuizQuestionsPopupRow = ({
	ans,
	index,
	isActive,
	changeAns,
	withOutCorrect = false,
}) => {
	const [value, setValue] = useState(ans);
	const [switchIsActive, setSwitchIsActive] = useState(isActive);

	useEffect(() => {
		if (isActive !== switchIsActive) {
			setSwitchIsActive(isActive);
		}
		// eslint-disable-next-line
	}, [isActive]);

	useEffect(() => {
		if (ans !== value) {
			setValue(ans);
		}
		// eslint-disable-next-line
	}, [ans]);

	// eslint-disable-next-line
	const updateAns = useCallback(
		debounce((val) => {
			changeAns(val, index, "text");
		}, 100),
		[]
	);

	useEffect(() => {
		if (switchIsActive !== isActive) {
			changeAns(switchIsActive, index, "correct");
		}
		// eslint-disable-next-line
	}, [switchIsActive]);

	return (
		<div className={s.editPopUp__inputWrap}>
			<Textarea
				placeholder=""
				val={value}
				name={"textValue"}
				label={"Вариант ответа"}
				onReset={() => {
					setValue("");
					updateAns("");
				}}
				className={s.textarea}
				handleChange={(val) => {
					setValue(val);
					updateAns(val);
				}}
			/>
			{!withOutCorrect && (
				<div className={s.editPopUp__switcherWrap}>
					<Switcher
						isOn={isActive}
						handleClick={() => setSwitchIsActive((prevState) => !prevState)}
						label={isActive ? "Ответ правильный" : "Ответ неправильный"}
					/>
				</div>
			)}
		</div>
	);
};

export default QuizQuestionsPopupRow;
