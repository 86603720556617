import React from 'react';
import ActivityColumn from './ActivityСolumn';
import s from './Controller.module.scss';

const BlockActivity = ({ bot }) => {
  const activityList = [
    { name: 'Landing', value: 30 },
    { name: 'Site', value: 40 },
    { name: 'App', value: 50 },
    { name: 'Board', value: 60 },
    { name: 'Bill', value: 70 },
  ];

  return (
    <div className={s.controller__block}>
      <h3 className={s.controller__block__title}>
        Активность пользователей за последний час
      </h3>
      {bot.name ? (
        <div className={s.diagram}>
          <div className={s.diagram__vScale}>
            {[75, 50, 25, 0].map((item) => (
              <p key={item} className={s.diagram__vScale__item}>
                {item}
              </p>
            ))}
          </div>
          <div className={s.diagram__hScale}>
            {activityList.map((item) => (
              <ActivityColumn item={item} key={item.name} />
            ))}
          </div>
        </div>
      ) : (
        <div className={s.controller__warning}>
          <p>Для просмотра статистики вам нужно подключить телеграм-бота</p>
        </div>
      )}
    </div>
  );
};

export default BlockActivity;
