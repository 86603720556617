const pasHidden = (
  <svg
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.6 1L15.4 13M10.6 8.67708C10.1754 9.03336 9.61468 9.25 9 9.25C7.67452 9.25 6.6 8.24264 6.6 7C6.6 6.42373 6.83108 5.89807 7.21111 5.5M15.0862 9.70578C15.5433 9.3327 15.9522 8.95743 16.3042 8.61018C17.2319 7.69512 17.2319 6.30488 16.3042 5.38982C14.7396 3.8465 12.0524 1.75 9 1.75C8.2869 1.75 7.59374 1.86442 6.93016 2.05948M4.6 3.10254C3.43315 3.80083 2.43811 4.65759 1.69576 5.38982C0.768079 6.30488 0.768078 7.69512 1.69576 8.61018C3.26039 10.1535 5.94759 12.25 9 12.25C10.4945 12.25 11.9014 11.7474 13.1319 11.0533"
      stroke="#777A96"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export default pasHidden;
