import { useState } from "react";
import s from "./Polls.module.scss";
import PollsQuestionItem from "./PollsQuestionItem";

const PollsQuestions = ({ list = [], changeItem, delItem, addItem }) => {
	return (
		<>
			<div className={s.titleWrap}>
				<p className={s.subTitle}>Вопросы</p>
				<button
					onClick={() => addItem()}
					className={"btnDark add " + s.btnAddQues}
				>
					Создать вопрос
				</button>
			</div>
			<div className={s.questionList}>
				{list.map((item, i) => (
					<PollsQuestionItem
						key={i}
						index={i}
						question={item}
						delItem={delItem}
						changeItem={changeItem}
						{...item}
					/>
				))}
			</div>
		</>
	);
};

export default PollsQuestions;
