const navChallenge = (
  <svg width="20" height="16" viewBox="0 0 20 16" fill="none">
    <g clipPath="url(#clip0_1345_1131)">
      <path
        d="M18.5 6H14.7894C15.1859 6.92469 15.0119 8.0375 14.2581 8.79125L10 13.0494V14.5C10 15.3284 10.6716 16 11.5 16H18.5C19.3284 16 20 15.3284 20 14.5V7.5C20 6.67156 19.3284 6 18.5 6ZM15 11.75C14.5859 11.75 14.25 11.4141 14.25 11C14.25 10.5856 14.5859 10.25 15 10.25C15.4141 10.25 15.75 10.5856 15.75 11C15.75 11.4141 15.4141 11.75 15 11.75ZM13.5509 5.91563L8.08438 0.449062C7.48563 -0.149687 6.51469 -0.149687 5.91594 0.449062L0.449062 5.91563C-0.149687 6.51438 -0.149687 7.48531 0.449062 8.08406L5.91563 13.5509C6.51438 14.1497 7.48531 14.1497 8.08406 13.5509L13.5509 8.08438C14.1497 7.48531 14.1497 6.51438 13.5509 5.91563ZM3 7.75C2.58594 7.75 2.25 7.41406 2.25 7C2.25 6.58563 2.58594 6.25 3 6.25C3.41406 6.25 3.75 6.58563 3.75 7C3.75 7.41406 3.41406 7.75 3 7.75ZM7 11.75C6.58594 11.75 6.25 11.4141 6.25 11C6.25 10.5856 6.58594 10.25 7 10.25C7.41406 10.25 7.75 10.5856 7.75 11C7.75 11.4141 7.41406 11.75 7 11.75ZM7 7.75C6.58594 7.75 6.25 7.41406 6.25 7C6.25 6.58563 6.58594 6.25 7 6.25C7.41406 6.25 7.75 6.58563 7.75 7C7.75 7.41406 7.41406 7.75 7 7.75ZM7 3.75C6.58594 3.75 6.25 3.41406 6.25 3C6.25 2.58562 6.58594 2.25 7 2.25C7.41406 2.25 7.75 2.58562 7.75 3C7.75 3.41406 7.41406 3.75 7 3.75ZM11 7.75C10.5859 7.75 10.25 7.41406 10.25 7C10.25 6.58563 10.5859 6.25 11 6.25C11.4141 6.25 11.75 6.58563 11.75 7C11.75 7.41406 11.4141 7.75 11 7.75Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1345_1131">
        <rect width="20" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default navChallenge;
