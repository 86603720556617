export default (
  <svg
    width="24"
    height="20"
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.69712 3.04614C4.34132 3.04614 0 6.4549 0 10.6618C0 12.7241 1.0458 14.5991 2.74354 15.9699C2.64507 17.3681 2.31537 19.0786 1.36058 20.0001C3.26121 20.0001 5.20362 18.7846 6.43142 17.8342C7.45186 18.1205 8.54987 18.2774 9.69712 18.2774C15.0529 18.2774 19.3942 14.8702 19.3942 10.6618C19.3942 6.4549 15.0529 3.04614 9.69712 3.04614Z"
      fill="#777A96"
    />
    <path
      d="M22.5869 11.4037C23.403 10.2902 23.8699 8.99559 23.8699 7.61564C23.8699 3.40876 19.5286 0 14.1728 0C11.8156 0 9.65542 0.661037 7.97559 1.75769C8.76627 1.60538 9.59277 1.52313 10.4431 1.52313C16.2107 1.52313 20.8862 5.27459 20.8862 9.90033C20.8862 11.7235 20.1626 13.4127 18.9288 14.785C20.1537 15.7354 22.0991 16.9539 23.9997 16.9539C22.6481 15.644 22.5511 12.741 22.5869 11.4037Z"
      fill="#777A96"
    />
  </svg>
);
