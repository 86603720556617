import React from 'react';
import UsersInfo from './UsersInfo';
import s from './Controller.module.scss';
import userIcon from '../../imgs/serviceUserIcon.svg';

const BlockUsers = ({ bot }) => {
  return (
    <div className={s.controller__block}>
      <h3 className={s.controller__block__title}>Пользователи</h3>
      {bot.name ? (
        <ul className={s.users__block__list}>
          <UsersInfo
            value={567}
            icon={userIcon}
            name={'Общее число пользователей в боте'}
          />
          <UsersInfo
            isNew
            value={12}
            icon={userIcon}
            name={'Новые пользователи за сегодня'}
          />
        </ul>
      ) : (
        <div className={s.controller__warning}>
          <p>Для просмотра статистики вам нужно подключить телеграм-бота</p>
        </div>
      )}
    </div>
  );
};

export default BlockUsers;
