import Endpoints from "../endpoints";
import defPollJson from "./defPollJson.json";
import { axiosInstance } from "../instance";

export const getPolls = async (project_name, author_id, bot_name) => {
	try {
		const method = "saved";
		const tag_name = "latest";
		const res = await axiosInstance.get(
			Endpoints.POLLS.GET +
				`/${method}/${project_name}?tagname=${tag_name}&author=${author_id}`
		);
		// `/${method}/${project_name}?ver_num=${ver_num}&tagname=${tag_name}`   -    пока без ver_num
		// return defPollJson;

		if (!res.data.error) {
			if (res.data.data?.data?.polls) {
				return {
					...res.data.data.data,
					bot: {
						name: bot_name,
						slag: project_name,
					},
					author: {
						account: author_id,
						date: "",
					},
				};
			} else {
				return {
					...defPollJson,
					bot: {
						name: bot_name,
						slag: project_name,
					},
					author: {
						account: author_id,
						date: "",
					},
				};
			}
		} else {
			throw new Error();
		}
	} catch (e) {
		console.log(e);
		return { error: "Не удалось получить данные о квизах" };
	}
};

export const publish = async (params) => {
	try {
		const res = await axiosInstance.post(Endpoints.POLLS.POST, params);
		if (!res.data.error) {
			return res;
		} else {
			throw new Error();
		}
	} catch (e) {
		console.log(e);
		return { error: "Не удалось получить данные о квизах" };
	}
};

export const save = async (project_name, bot_name, author_id, params) => {
	try {
		params.bot.name = bot_name;
		params.bot.slag = project_name;
		const method = "saved";
		const tag_name = "latest";
		const res = await axiosInstance.post(
			Endpoints.POLLS.SAVE +
				`/${method}/${project_name}?tagname=${tag_name}&author=${author_id}`,
			params
		);
		if (!res.data.error) {
			return res;
		} else {
			throw new Error();
		}
	} catch (e) {
		console.log(e);
		return { error: "Не удалось получить данные о квизах" };
	}
};

const polls = {
	getPolls,
	save,
	publish,
};

export default polls;
