import s from './Quizzes.module.scss';
import QuizCommonRow from './QuizCommonRow';
import Input from '../../components/common/Input';
import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import Switcher from '../../components/common/Switcher';

const QuizCommon = () => {
  const { curQuiz, changeQuizzes } = useOutletContext();
  const [textList, setTextList] = useState([]);
  const [quizNameInput, setQuizNameInput] = useState(curQuiz.name);
  const [quizStatusInput, setQuizStatusInput] = useState({ ...curQuiz.status });

  useEffect(() => {
    if (curQuiz.num >= 0) {
      setQuizNameInput(curQuiz.name);
      setQuizStatusInput(curQuiz.status);
      setTextList([
        { ...curQuiz.start, label: 'Приветствие' },
        { ...curQuiz.already, label: 'Уже проходил квиз' },
        { ...curQuiz.time_out, label: 'Время вышло' },
        { ...curQuiz.right_answer, label: 'Правильный ответ' },
        { ...curQuiz.wrong_answer, label: 'Неправильный ответ' },
        { ...curQuiz.final_all_right, label: 'Все ответы правильные' },
        { ...curQuiz.final_notall_right, label: 'Не все ответы правильные' },
        { ...curQuiz.final, label: 'Завершение' },
        { ...curQuiz.off, label: 'Квиз выключен' },
      ]);
    }
  }, [curQuiz]);

  const handleBtnRow = (action, rowId) => {
    console.log(action);
    console.log(rowId);
  };

  // change json

  const changeText = (val, key, quizIndex) => {
    // console.log('changeText');
    changeQuizzes(key, quizIndex, val);
  };

  useEffect(() => {
    if (curQuiz.num >= 0 && curQuiz.name !== quizNameInput) {
      // console.log('quiz name');
      changeQuizzes('name', curQuiz.num, quizNameInput);
    }
    // eslint-disable-next-line
  }, [quizNameInput]);

  useEffect(() => {
    if (curQuiz.num >= 0 && curQuiz.status.data !== quizStatusInput.data) {
      // console.log('quiz status');
      changeQuizzes('status', curQuiz.num, {
        ...quizStatusInput,
        data: quizStatusInput.data,
      });
    }
    // eslint-disable-next-line
  }, [quizStatusInput]);

  // change json end

  return (
    curQuiz.num >= 0 && (
      <>
        <div className={'scroll ' + s.quiz__main__body}>
          <div className={s.inputWrap}>
            <Input
              max={20}
              placeholder=""
              label={'Имя квиза'}
              value={quizNameInput}
              name={curQuiz.form_name}
              handleChange={(val) => setQuizNameInput(val)}
            />
          </div>
          <div className={s.switchWrap}>
            <Switcher
              isOn={quizStatusInput.data}
              label={quizStatusInput.data ? 'Включен' : 'Выключен'}
              handleClick={() =>
                setQuizStatusInput((prevState) => ({
                  ...prevState,
                  data: !prevState.data,
                }))
              }
            />
          </div>
          <h3 className={s.title}>Настройки текстов</h3>
          <ul className={s.textList}>
            {textList.length &&
              textList.map((textItem, index) => (
                <QuizCommonRow
                  key={index}
                  textItem={textItem}
                  quizIndex={curQuiz.num}
                  changeText={changeText}
                  handleBtnRow={handleBtnRow}
                />
              ))}
          </ul>
        </div>
      </>
    )
  );
};

export default QuizCommon;
