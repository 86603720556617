import React from 'react';
import s from './Quizzes.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';

const QuizNav = ({
  btnDarkLabel,
  btnLightLabel,
  handleBtnDark,
  handleBtnLight,
}) => {
  const navigate = useNavigate();
  const curLocation = useLocation().pathname;
  return (
    <nav className={s.quiz__main__nav}>
      <ul className={s.quiz__main__navItemList}>
        {[
          { name: 'Основное', value: '/quizzes' },
          { name: 'Вопросы', value: '/quizzes/questions' },
          { name: 'Настройки', value: '/quizzes/edit' },
        ].map(({ name, value }) => (
          <button
            key={value}
            onClick={() => navigate(value)}
            className={
              s.quiz__main__navItem +
              ' ' +
              (curLocation === value ? s.actived : '')
            }
          >
            {name}
          </button>
        ))}
      </ul>
      <ul className={s.quiz__main__navBtnList}>
        {btnDarkLabel && (
          <button
            disabled={btnDarkLabel.slice(btnDarkLabel.length - 3) === '...'}
            onClick={handleBtnDark}
            className={'btnDark'}
          >
            {btnDarkLabel}
          </button>
        )}
        {btnLightLabel && (
          <button onClick={handleBtnLight} className={'btnLight'}>
            {btnLightLabel}
          </button>
        )}
      </ul>
    </nav>
  );
};

export default QuizNav;
