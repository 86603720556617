import s from "./Quizzes.module.scss";
import React, { useEffect } from "react";
import plusSelect from "../../imgs/plusSelect";
import PopUp from "../../components/common/PopUp";
import QuizQuestionsPopupRow from "./QuizQuestionsPopupRow";

const QuizQuestionsPopup = ({
	save,
	editType,
	addAnswer,
	changeAns,
	editedAns,
	correctAnsIndex = -1,
	editPopupOpened,
	setEditPopupOpened,
	withOutCorrect = false,
}) => {
	const isActive = (index) => {
		if (editType === "all") {
			return correctAnsIndex === index;
		} else {
			return correctAnsIndex === editType;
		}
	};

	useEffect(() => {
		if (!editedAns.length) {
			addAnswer();
		}
		// eslint-disable-next-line
	}, [editedAns]);

	return (
		<PopUp
			isOpen={editPopupOpened}
			close={() => setEditPopupOpened(false)}
		>
			<h3 className={s.editPopUp__title}>Варианты ответов</h3>
			<ul className={"scroll " + s.editPopUp__inputList}>
				{editedAns.map((ans, index) => (
					<QuizQuestionsPopupRow
						ans={ans}
						key={index}
						withOutCorrect
						changeAns={changeAns}
						isActive={isActive(index)}
						index={editType === "all" ? index : editType}
					/>
				))}
			</ul>
			{editType === "all" ? (
				<button
					onClick={addAnswer}
					className={s.editPopUp__addAns}
				>
					{plusSelect}
					<span>Добавить ответ</span>
				</button>
			) : (
				""
			)}
			<div className={s.editPopUp__btnList}>
				<button
					onClick={() => save()}
					className="btnLight"
				>
					Сохранить
				</button>
				<button
					onClick={() => setEditPopupOpened(false)}
					className="btnDark"
				>
					Отмена
				</button>
			</div>
		</PopUp>
	);
};

export default QuizQuestionsPopup;
