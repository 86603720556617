// import api from '../../api';
import QuizNav from "./QuizNav";
import { debounce } from "lodash";
import dbIcon from "../../imgs/db";
import s from "./Quizzes.module.scss";
import QuizHeader from "./QuizHeader";
import plus from "../../imgs/plusSelect";
import { useSelector } from "react-redux";
import { getNewQuiz } from "../../api/main";
import PopupAddBot from "../Bot/PopupAddBot";
import React, { useEffect, useState } from "react";
import Loader from "../../components/common/Loader";
// import prodDataModel from './prodDataModelOld.json';
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { getQuizesApi, postQuizesApi, saveQuizesApi } from "../../api/quiz";

const Quizzes = () => {
	const curBot = useSelector((state) => state.bot.curBot);
	const botIsLoaded = useSelector((state) => state.bot.isLoaded);
	const { user_id } = useSelector((state) => state.auth.profileData);
	const { curProject } = useSelector((state) => state.main);
	const [quizzesData, setQuizzesData] = useState({
		modul: "quiz",
		description: "квиз маринхелс",
		schema: "panel/setups/modules/quiz/schema.json",
		bot: {
			name: curBot?.username || "",
			slag: curBot?.username || "",
		},
		author: {
			account: user_id || "",
			date: "",
		},
		version: "1.0",
		quizes: [],
		questions: [],
	}); // all json file
	const navigate = useNavigate();
	const curLocation = useLocation().pathname;
	const [quizzes, setQuizzes] = useState([]);
	const [curQuiz, setCurQuiz] = useState({});
	const [loading, setLoading] = useState(false);
	const [questions, setQuestions] = useState([]);
	// const [loading, setLoading] = useState(false);
	const [navHidden, setNavHidden] = useState(false);
	const [btnPubName, setBtnPubName] = useState("Опубликовать");
	const [btnSaveName, setBtnSaveName] = useState("Сохранить как черновик");

	// console.log(prodDataModel);
	// json заглушка
	// useEffect(() => {
	//   setQuizzesData(prodDataModel);
	// }, []);

	// useEffect(() => {
	//   console.log(quizzesData.quizes[curQuiz.num]);
	//   console.log(curQuiz);
	//   if (
	//     curQuiz.num >= 0 &&
	//     quizzesData.quizes[curQuiz.num] &&
	//     JSON.stringify(quizzesData.quizes[curQuiz.num]) !==
	//       JSON.stringify(curQuiz)
	//   ) {
	//     setCurQuiz(quizzesData.quizes[curQuiz.num]);
	//   }
	//   // eslint-disable-next-line
	// }, [curLocation]);

	useEffect(() => {
		if (!["Сохранить как черновик", "Сохранение..."].includes(btnSaveName)) {
			setTimeout(() => {
				setBtnSaveName("Сохранить как черновик");
			}, 2000);
		}
	}, [btnSaveName]);

	useEffect(() => {
		if (!["Опубликовать", "Публикация..."].includes(btnPubName)) {
			setTimeout(() => {
				setBtnPubName("Опубликовать");
			}, 2000);
		}
	}, [btnPubName]);

	useEffect(() => {
		const hiddenList = ["/quizzes/questions-db"];
		if (hiddenList.includes(curLocation)) {
			setNavHidden(true);
		} else setNavHidden(false);
	}, [curLocation]);

	useEffect(() => {
		// console.log(quizzesData.quizes);
		if (quizzesData.quizes?.length) {
			// auto choose first quiz
			if (!curQuiz.num) {
				setCurQuiz(quizzesData.quizes[0]);
			}
			if (JSON.stringify(quizzesData.quizes) !== JSON.stringify(quizzes)) {
				setQuizzes(quizzesData.quizes);
			}
		}
		// eslint-disable-next-line
	}, [quizzesData.quizes, curQuiz]);

	const getQuizzes = async () => {
		setLoading(true);
		// const quizzesJson = prodDataModel;
		// postQuizesApi(curBot.username, quizzesJson);

		//old
		// const res = await getQuizesApi(curBot.username, user_id);

		//new
		const res = await getQuizesApi(curProject.name, user_id);

		console.log(res?.data?.data?.data);
		if (res?.data?.data?.data) {
			setQuizzesData(res.data.data.data);
		}
		setLoading(false);
	};

	useEffect(() => {
		if (quizzesData.questions?.length && curQuiz.num >= 0) {
			setQuestions([
				...quizzesData.questions.filter((quest) =>
					quest.in_quizes.includes(curQuiz.num)
				),
			]);
		}
	}, [quizzesData.questions, curQuiz.num]);

	useEffect(() => {
		if (curProject && curBot.username) {
			getQuizzes();
		}
		// eslint-disable-next-line
	}, [curBot.username, curProject]);

	useEffect(() => {
		// eslint-disable-next-line
	}, []);

	const createQuiz = async () => {
		const newQuiz = await getNewQuiz(quizzes.length);
		// console.log(newQuiz);
		if (!newQuiz) return;

		if (!curBot.username) return alert("Бот не подключен");
		if (!user_id) return console.log("user_id не найден");

		if (quizzesData.quizes) {
			setQuizzesData((prevState) => ({
				...prevState,
				quizes: [...prevState.quizes, newQuiz],
			}));
		} else {
			setQuizzesData((prevState) => ({
				...prevState,
				quizes: [newQuiz],
			}));
		}
	};

	const changeQuizzes = (key, quizIndex, val) => {
		console.log(quizzesData.quizes[quizIndex]);
		// console.log(key);
		// console.log(val);

		setQuizzesData((prevState) => ({
			...prevState,
			quizes: [
				...prevState.quizes.slice(0, quizIndex),
				{ ...prevState.quizes[quizIndex], [key]: val },
				...prevState.quizes.slice(quizIndex + 1),
			],
		}));
	};

	const addQuestion = (quest, num) => {
		const questIndex = quizzesData.questions.findIndex(
			(quest) => quest.num === num
		);
		if (questIndex >= 0) {
			const quizIndex = quizzesData.questions[questIndex].in_quizes.findIndex(
				(q) => q === curQuiz.num
			);
			let updatedQuest = quizzesData.questions[questIndex];
			if (quizIndex >= 0) {
				updatedQuest.in_quizes = [
					...updatedQuest.in_quizes.slice(0, quizIndex),
					...updatedQuest.in_quizes.slice(quizIndex + 1),
				];
			} else {
				updatedQuest.in_quizes = [...updatedQuest.in_quizes, curQuiz.num];
			}

			setQuizzesData((prevState) => ({
				...prevState,
				questions: [
					...prevState.questions.slice(0, questIndex),
					updatedQuest,
					...prevState.questions.slice(questIndex + 1),
				],
			}));
		}

		// if (questIndex >= 0) {
		//   setQuizzesData((prevState) => ({
		//     ...prevState,
		//     questions: [
		//       ...prevState.questions.slice(0, questIndex),
		//       quest,
		//       ...prevState.questions.slice(questIndex + 1),
		//     ],
		//   }));
	};

	const changeQuestion = (quest, num, allQuests) => {
		if (allQuests) {
			setQuizzesData((prevState) => ({
				...prevState,
				questions: [...allQuests],
			}));
			return true;
		}
		const questIndex = quizzesData.questions.findIndex(
			(quest) => quest.num === num
		);
		if (questIndex >= 0) {
			setQuizzesData((prevState) => ({
				...prevState,
				questions: [
					...prevState.questions.slice(0, questIndex),
					quest,
					...prevState.questions.slice(questIndex + 1),
				],
			}));
		} else {
			setQuizzesData((prevState) => ({
				...prevState,
				questions: [...prevState.questions, quest],
			}));
		}
	};

	const publish = async () => {
		if (!curBot || !user_id) return;
		setBtnPubName("Публикация...");

		//old
		// const res = await postQuizesApi(curBot.username, {
		//   ...quizzesData,
		//   // quizes: [], //  для стирания квизов
		//   // questions: [], //  для стирания квизов
		// });

		//new
		const res = await postQuizesApi(curProject.name, {
			...quizzesData,
		});

		console.log("publish", res);
		const resMes = res?.data?.result;
		if (resMes === "ok") {
			setBtnPubName("Опубликовано");
		} else if (resMes) {
			setBtnPubName("Ошибка");
			alert(resMes);
		} else {
			setBtnPubName("Ошибка");
		}
	};

	const save = async () => {
		if (!curBot || !user_id) return;
		setBtnSaveName("Сохранение...");

		//old
		// const res = await saveQuizesApi(curBot.username, user_id, {
		//   ...quizzesData,
		//   // quizes: [], // костыль для стирания квизов
		//   // questions: [], // костыль для стирания квизов
		// });

		//new
		const res = await saveQuizesApi(curProject.name, curBot.username, user_id, {
			...quizzesData,
			// quizes: [], // костыль для стирания квизов
			// questions: [], // костыль для стирания квизов
		});

		console.log("save", res);
		const resMes = res?.data?.result;
		if (resMes === "успешно записали в БД") {
			setBtnSaveName("Сохранено");
		} else if (resMes) {
			setBtnSaveName("Ошибка");
			alert(resMes);
		} else {
			setBtnSaveName("Ошибка");
		}
	};

	return (
		<>
			<div
				className={"page " + s.quiz__page + " " + (loading ? s.loading : "")}
			>
				{!curBot.username && botIsLoaded ? (
					<PopupAddBot close={() => navigate("/")} />
				) : (
					<>
						{!navHidden && (
							<nav className={s.quiz__header}>
								<QuizHeader
									quizzesList={quizzes}
									curQuizNum={curQuiz.num}
									questions={quizzesData.questions || []}
									changeQuiz={(quiz) => setCurQuiz(quiz)}
								/>
								{/* btns */}
								<div className={s.quiz__header__btns}>
									<button
										onClick={createQuiz}
										className={s.quiz__header__btn}
									>
										<div className={s.quiz__header__btn__imgWrap}>{plus}</div>
										<p>Создать квиз</p>
									</button>
									<button
										disabled={!(curQuiz.num >= 0)}
										className={s.quiz__header__btn}
										onClick={() => navigate("questions-db")}
									>
										<div className={s.quiz__header__btn__imgWrap}>{dbIcon}</div>
										<p>База вопросов</p>
									</button>
								</div>
								{/* btns end */}
							</nav>
						)}
						<div
							className={s.quiz__main + (navHidden ? " " + s.navHidden : "")}
						>
							{loading && <Loader />}
							{curQuiz.num >= 0 && (
								<>
									<QuizNav
										btnDarkLabel={btnSaveName}
										btnLightLabel={btnPubName}
										handleBtnDark={save}
										handleBtnLight={publish}
									/>
									{/* [curQuiz, quizzes, questions, changeQuizzes, changeQuestion] */}

									<Outlet
										context={{
											questions: questions,
											addQuestion: addQuestion,
											// quizzesData.questions.length,
											allQuestions: quizzesData.questions,
											changeQuizzes: debounce(changeQuizzes, 100),
											changeQuestion: debounce(changeQuestion, 100),
											curQuiz: curQuiz.num >= 0 ? quizzes[curQuiz.num] : {},
										}}
										// context={[
										//   curQuiz.num >= 0 ? quizzes[curQuiz.num] : {},
										//   quizzes,
										//   questions,
										//   debounce(changeQuizzes, 100),
										//   debounce(changeQuestion, 100),
										//   quizzesData.questions.length,
										//   quizzesData.questions,
										//   addQuestion,
										// ]}
									/>
								</>
							)}
						</div>
					</>
				)}
			</div>
		</>
	);
};

export default Quizzes;
