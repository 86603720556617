import React from 'react';
import s from './Main.module.scss';
import { useSelector } from 'react-redux';
import Nav from '../../components/common/Nav';
import Loader from '../../components/common/Loader';
import Header from '../../components/header/Header';
import { Outlet } from 'react-router-dom';

const Main = () => {
  const mainLoading = useSelector((state) => state.main.mainLoading);

  return (
    <div className={s.main__wrap}>
      {mainLoading ? (
        <Loader />
      ) : (
        <>
          <Header />
          <main className={s.main}>
            <Nav />
            <Outlet />
          </main>
        </>
      )}
    </div>
  );
};

export default Main;
