import "swiper/css";
import s from "./Menu.module.scss";
import plusIcon from "../../imgs/plusSelect";
import MenuHeaderItem from "./MenuHeaderItem";
import settingsIcon from "../../imgs/settings";
// import MenuHeaderItem from './MenuHeaderItem';
import { Swiper, SwiperSlide } from "swiper/react";
import React, { useEffect, useRef, useState } from "react";

const MenuHeader = ({
	addMenu,
	delMenu,
	curMenuNum,
	activeMenu,
	openSettings,
	menuList = [],
}) => {
	const [slider, setSlider] = useState({ next: false, back: false });
	const swiperRef = useRef();

	const checkSwiper = (swiper) => {
		if (!swiperRef.current) {
			swiperRef.current = swiper;
		}
		if (swiper.isBeginning) {
			setSlider((prevState) => ({ ...prevState, back: false }));
		} else {
			setSlider((prevState) => ({ ...prevState, back: true }));
		}
		if (swiper.isEnd) {
			setSlider((prevState) => ({ ...prevState, next: false }));
		} else {
			setSlider((prevState) => ({ ...prevState, next: true }));
		}
	};

	useEffect(() => {
		if (menuList.length && swiperRef.current) {
			checkSwiper(swiperRef.current);
		}
	}, [menuList]);

	return (
		<div className={s.header}>
			<div className={s.header__listWrap}>
				<ul className={s.header__list}>
					<Swiper
						spaceBetween={10}
						slidesPerView={4}
						onSwiper={(swiper) =>
							setTimeout(() => {
								checkSwiper(swiper);
							}, 0)
						}
						onSlideChange={(swiper) => checkSwiper(swiper)}
					>
						{menuList.map((menu, index) => (
							<SwiperSlide key={index}>
								<MenuHeaderItem
									num={index + 1}
									delMenu={delMenu}
									activeMenu={activeMenu}
									isActive={curMenuNum === index + 1}
								/>
							</SwiperSlide>
						))}
						<SwiperSlide>
							<button
								onClick={addMenu}
								className={s.header__btn}
							>
								<div className={s.header__btn__imgWrap}>{plusIcon}</div>
								<p>Добавить</p>
							</button>
						</SwiperSlide>
					</Swiper>
				</ul>
				{slider.back && (
					<button
						onClick={() => swiperRef.current?.slidePrev()}
						className={s.header__btnBack}
					></button>
				)}
				{slider.next && (
					<button
						onClick={() => swiperRef.current?.slideNext()}
						className={s.header__btnNext}
					></button>
				)}
			</div>
			<div className={s.header__btns}>
				<button
					onClick={openSettings}
					className={s.header__btn}
				>
					<div className={s.header__btn__imgWrap}>{settingsIcon}</div>
					<p>Настройки</p>
				</button>
			</div>
		</div>
	);
};

export default MenuHeader;
