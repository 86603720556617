import s from './Quizzes.module.scss';
import Input from '../../components/common/Input';
import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import Select from '../../components/common/Select';
import Checkbox from '../../components/common/Checkbox';

const QuizEdit = () => {
  const { curQuiz, changeQuizzes } = useOutletContext();

  // useEffect(() => {
  //   console.log(curQuiz);
  // }, [curQuiz]);

  const [showedList, setShowedList] = useState('');
  const [editRowBtns] = useState([
    {
      name: 'recommend',
      text: 'рекомендации',
    },
    {
      name: 'help',
      text: 'нужна помощь?',
    },
    {
      name: 'clear',
      text: 'очистить данные',
    },
  ]);
  const [editRowList, setEditRowList] = useState();

  const handleChangeCheck = (key, val) => {
    const editedIndex = editRowList.findIndex((row) => row.key === key);
    if (editedIndex < 0) return;
    setEditRowList((prev) => [
      ...prev.slice(0, editedIndex),
      {
        ...prev[editedIndex],
        check: { value: val },
      },
      ...prev.slice(editedIndex + 1),
    ]);
    // if (key)
    changeQuizzes(key, curQuiz.num, { ...curQuiz[key], data: val });
  };

  useEffect(() => {
    setEditRowList([
      {
        id: 1,
        editRowBtns: editRowBtns,
        key: 'shuffle',
        label: 'Перемешивать вопросы',
        check: { value: curQuiz?.shuffle?.data || false },
      },
      {
        id: 2,
        max: 600,
        key: 'time_delay',
        editRowBtns: editRowBtns,
        placeholder: 'Время ожидания, сек',
        label: 'Время ожидания ответа в секундах',
        input: { value: curQuiz?.time_delay?.data || 0 },
        note: '600 максимум, можно оставить пустым для ожидания без лимита времени',
      },
      {
        id: 3,
        label: 'После таймаута',
        key: 'action_after_timeout',
        editRowBtns: editRowBtns,
        select: {
          name: '',
          value: curQuiz?.action_after_timeout?.data || 'Следующий',
          list: ['Следующий', 'Повтор', 'Меню'],
        },
        note: 'выберите какое действие будет после того, как истечет время на ответ',
      },
      {
        id: 4,
        label: 'После ответа',
        editRowBtns: editRowBtns,
        key: 'proc_next',
        select: {
          name: '',
          list: ['Следующий', 'Повтор', 'Меню'],
          value: curQuiz?.proc_next?.data || 'Следующий',
        },
        note: 'выберите какое действие будет после того, как ответят на вопрос',
      },
      {
        id: 5,
        key: 'inbox',
        editRowBtns: editRowBtns,
        label: 'Текстовые команды',
        note: 'выберите какое действие будет после того, как отправится текст',
        dataList: [
          {
            form_name: 'quiz_next_inbox0',
            text: curQuiz?.inbox[0]?.text || '',
            next: curQuiz?.inbox[0]?.next || '',
          },
          {
            form_name: 'quiz_next_inbox1',
            text: curQuiz?.inbox[1]?.text || '',
            next: curQuiz?.inbox[1]?.next || '',
          },
          ['Следующий', 'Повтор', 'Меню'],
        ],
      },
      {
        id: 6,
        editRowBtns: editRowBtns,
        label: 'Удалять вопрос после ответа',
        key: 'delete_last_question',
        check: { value: curQuiz?.delete_last_question?.data || false },
      },
      {
        id: 7,
        key: 'allow_retry',
        check: { value: curQuiz?.allow_retry?.data || false },
        editRowBtns: editRowBtns,
        label: 'Можно проходить повторно',
      },
      {
        id: 8,
        key: 'bonus_time',
        label: 'Бонусное время',
        editRowBtns: editRowBtns,
        placeholder: 'Бонусное время, сек',
        input: { value: curQuiz?.bonus_time?.data || 0 },
      },
      {
        id: 9,
        placeholder: 'Баллы',
        editRowBtns: editRowBtns,
        key: 'bonus_time_balls',
        label: 'Баллы за ответ за бонусное время',
        input: { value: curQuiz?.balls?.bonus_time?.data || 0 },
      },
    ]);
    // eslint-disable-next-line
  }, [curQuiz.num]);

  const handleChange = (key, val) => {
    const editedIndex = editRowList.findIndex((row) => row.key === key);
    if (editedIndex < 0) return;
    setEditRowList((prev) => [
      ...prev.slice(0, editedIndex),
      {
        ...prev[editedIndex],
        input: { value: val },
      },
      ...prev.slice(editedIndex + 1),
    ]);
    if (key === 'bonus_time_balls') {
      changeQuizzes('balls', curQuiz.num, {
        ...curQuiz.balls,
        bonus_time: { data: val, form_name: 'quiz_bonus_time_balls' },
      });
    } else {
      changeQuizzes(key, curQuiz.num, { ...curQuiz[key], data: val });
    }
  };

  const handleBtnRowClick = (name) => {
    console.log(name);
  };

  const handleSelect = (key, val) => {
    const editedIndex = editRowList.findIndex((row) => row.key === key);
    if (editedIndex < 0) return;
    setEditRowList((prev) => [
      ...prev.slice(0, editedIndex),
      {
        ...prev[editedIndex],
        select: { ...prev[editedIndex].select, value: val },
      },
      ...prev.slice(editedIndex + 1),
    ]);
    changeQuizzes(key, curQuiz.num, { ...curQuiz[key], data: val });
  };

  const handleChangeInbox = (name, key, val) => {
    const index = name.endsWith('0') ? 0 : 1;
    const editedIndex = editRowList.findIndex((row) => row.key === 'inbox');

    const editedLocalInbox = {
      ...editRowList[editedIndex].dataList[index],
      [key]: val,
    };

    if (editedIndex < 0) return;
    setEditRowList((prev) => [
      ...prev.slice(0, editedIndex),
      {
        ...prev[editedIndex],
        dataList: [
          ...prev[editedIndex].dataList.slice(0, index),
          editedLocalInbox,
          ...prev[editedIndex].dataList.slice(index + 1),
        ],
      },
      ...prev.slice(editedIndex + 1),
    ]);

    const editedInbox = { ...curQuiz.inbox[index], [key]: val };
    changeQuizzes('inbox', curQuiz.num, [
      ...curQuiz.inbox.slice(0, index),
      editedInbox,
      ...curQuiz.inbox.slice(index + 1),
    ]);
  };

  const showList = (listName) => {
    if (showedList === listName) {
      setShowedList();
    } else {
      setShowedList(listName);
    }
  };

  // useEffect(() => {
  //   console.log(editRowList);
  // }, [editRowList]);

  return (
    editRowList && (
      <>
        <div className={'scroll ' + s.quiz__main__body + ' ' + s.quizEdit}>
          <h3>Настройки {curQuiz.name} </h3>
          <ul className={s.editRowList}>
            {editRowList.map((row, i) => (
              <li className={'row ' + s.editRow} key={i}>
                <div>
                  <p>{row.label}</p>
                  {row.note && <p className={s.note}>{row.note}</p>}
                </div>
                {row.check && (
                  <Checkbox
                    isOn={row.check.value}
                    handleClick={() =>
                      handleChangeCheck(row.key, !row.check.value)
                    }
                  />
                )}
                {row.input && (
                  <Input
                    type="number"
                    name={row.key}
                    value={row.input.value}
                    placeholder={row.placeholder}
                    handleChange={(val) =>
                      handleChange(
                        row.key,
                        row.max ? (val > row.max ? row.max : +val) : +val
                      )
                    }
                  />
                )}
                {row.select && (
                  <Select
                    className={s.select}
                    value={row.select.value}
                    selectList={row.select.list}
                    isShowed={showedList === row.label}
                    selected={row.select.value}
                    showList={() => showList(row.label)}
                    select={(val) => handleSelect(row.key, val)}
                  />
                )}

                {row.key === 'inbox' && (
                  <div className={s.inboxForm}>
                    {row.dataList.map(
                      (item, i) =>
                        !item.length && (
                          <div className={s.inboxForm__row} key={i}>
                            <Input
                              type="text"
                              value={item.text}
                              name={item.form_name}
                              handleChange={(val) =>
                                handleChangeInbox(item.form_name, 'text', val)
                              }
                            />
                            <Select
                              value={item.next}
                              className={s.select}
                              selected={item.next}
                              selectList={row.dataList[2]}
                              isShowed={showedList === item.form_name}
                              showList={() => showList(item.form_name)}
                              select={(val) =>
                                handleChangeInbox(item.form_name, 'next', val)
                              }
                            />
                          </div>
                        )
                    )}
                  </div>
                )}

                <ul className={'row ' + s.editRowBtnList}>
                  {row.editRowBtns?.length &&
                    row.editRowBtns.map((btn, index) => (
                      <li
                        key={index}
                        className={s.editRowBtn}
                        onClick={() => handleBtnRowClick(btn.name)}
                      >
                        {btn.text}
                      </li>
                    ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </>
    )
  );
};

export default QuizEdit;
