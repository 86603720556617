export default (
  <svg
    width="20"
    height="24"
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.6607 9.55722L4.32102 0.434373C3.88965 0.160824 3.39434 0.0109971 2.88696 0.000582596C2.37958 -0.00983189 1.87879 0.119549 1.43705 0.375176C0.995305 0.630803 0.628856 1.00327 0.376078 1.45357C0.1233 1.90388 -0.00650684 2.41545 0.000251013 2.93471V21.1032C0.000251013 21.8713 0.298432 22.6079 0.829197 23.1511C1.35996 23.6942 2.07984 23.9993 2.83045 23.9993C3.35351 24.0109 3.86947 23.8739 4.32102 23.6035L18.6607 14.5193C19.0703 14.2595 19.4082 13.8971 19.6426 13.4663C19.877 13.0355 20 12.5506 20 12.0576C20 11.5645 19.877 11.0796 19.6426 10.6488C19.4082 10.218 19.0703 9.85557 18.6607 9.59584V9.55722Z"
      fill="#777A96"
    />
  </svg>
);
