import { useState } from 'react';
import s from './Quizzes.module.scss';
import Checkbox from '../../components/common/Checkbox';

const PopupAddQuestFromDBRow = ({ quest, isAdded, addQuest, index }) => {
  const [value] = useState(quest.question);

  return (
    <div className={s.popupDB__row}>
      {/* <div className={s.inputLabel}>
        <label htmlFor="questValue">Вопрос {+index + 1}</label>
      </div> */}
      <div className={s.popupDB__inputWrap}>
        <Checkbox
          label={value}
          isOn={isAdded}
          handleClick={() => addQuest(quest)}
        />
      </div>
    </div>
  );
};

export default PopupAddQuestFromDBRow;
