export default (
  <svg
    width="19"
    height="22"
    fill="none"
    viewBox="0 0 19 22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 13L17.7519 11.8321C18.3457 11.4362 18.3457 10.5638 17.7519 10.168L16 9"
      stroke="#fff"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 10.7126L11.2 10.7126M1 16.3959V5.60412M14.6 16.3959C14.6 17.5879 13.5852 18.5542 12.3333 18.5542H7.8M14.6 5.60412C14.6 4.4121 13.5852 3.44577 12.3333 3.44577H7.8M2.00935 19.195L4.27601 20.6339C5.78234 21.5901 7.8 20.5619 7.8 18.838V3.16198C7.8 1.43812 5.78234 0.409895 4.27601 1.36612L2.00935 2.80503C1.37876 3.20533 1 3.87923 1 4.60088V17.3991C1 18.1208 1.37876 18.7947 2.00935 19.195Z"
      stroke="#fff"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
