import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ShopTablePage from './ShopTablePage';
import s from './Shop.module.scss';
import ShopStatisticPage from './ShopStatisticPage';
import Loader from '../../components/common/Loader';
import ShopSettingsPage from './ShopSettingsPage';
import ShopGoods from './ShopGoods';

const ShopShared = () => {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [pageParams, setPageParams] = useState({
    slug: '',
    page: '',
    base: '',
  });

  useEffect(() => {
    if (params.slug) {
      try {
        const pageParams = {};
        const hideSlug = window.atob(params.slug);
        if (!hideSlug) throw new Error('slug not found');
        const splitted = hideSlug.split('&');
        if (!splitted?.length) throw new Error('splitted not found');
        for (let item of splitted) {
          const itemSplit = item.split('=');
          if (!itemSplit?.length) throw new Error('item split not found');
          pageParams[itemSplit[0]] = itemSplit[1];
        }
        // pageParams.slug = 'mts-true-summer24-dedic';
        setPageParams((prev) => ({ ...prev, ...pageParams }));
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
    }
  }, [params.slug]);

  console.log(pageParams);

  return (
    <>
      {!pageParams.page && (
        <div className={'page ' + s.page + ' ' + s.shared}>
          {loading && <Loader />}
          {!loading && <p>Страница не найдена</p>}
        </div>
      )}
      {pageParams.page === 'table' && (
        <ShopTablePage
          isShared={true}
          slug={pageParams.slug}
          base={pageParams.base}
        />
      )}
      {pageParams.page === 'statistic' && (
        <ShopStatisticPage
          isShared={true}
          slug={pageParams.slug}
          base={pageParams.base}
        />
      )}

      {pageParams.page === 'goods' && (
        <ShopGoods
          isShared={true}
          slug={pageParams.slug}
          base={pageParams.base}
        />
      )}
      {pageParams.page === 'settings' && (
        <ShopSettingsPage
          isShared={true}
          slug={pageParams.slug}
          base={pageParams.base}
        />
      )}
    </>
  );
  //
};

export default ShopShared;
