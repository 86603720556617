import api from '../../api';
import sorting from './sorting';
import s from './Shop.module.scss';
import COLORS from './colors.json';
import debounce from 'lodash.debounce';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/common/Loader';
import Select from '../../components/common/Select';
import ShopStatisticTable from './ShopStatisticTable';
import PieChart from '../../components/diagrams/PieChart';
import ShopStatisticMobTable from './ShopStatisticMobTable';
import { useCallback, useEffect, useRef, useState } from 'react';
import BtnLoader from '../../components/common/BtnLoader';

const ShopStatisticPage = ({ slug, isShared, base }) => {
  const listRef = useRef([]);
  const timerRef = useRef(null);
  const navigate = useNavigate();
  const lastUUIDRef = useRef('');
  const loadedRef = useRef(false);
  const [activePage] = useState(2);
  const tableListRef = useRef(null);
  const [list, setList] = useState([]);
  const [sortList, setSortList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lightList, setLightList] = useState([]);
  const [shareMess, setShareMess] = useState('');
  const [showedList, setShowedList] = useState('');
  const [isShowMore, setIsShowMore] = useState(false);
  const [showMoreLoading, setShowMoreLoading] = useState(false);
  const [btnUpdating, setBtnUpdating] = useState(false);
  const [settingsDefList] = useState({
    time: [
      { name: '1 мин', value: 60 },
      { name: '5 сек', value: 5 },
      { name: '10 сек', value: 10 },
      { name: '15 сек', value: 15 },
    ],
    limit: 1000,
    sort: { key: '', direction: 'desc' },
  });
  const [settings, setSettings] = useState({
    sku: null,
    offset: 0,
    name: null,
    tg_id: null,
    event_id: null,
    anketa: null,
    datetimeStart: null,
    datetimeFinish: null,
    time: settingsDefList.time[0],
    base: base,
    curProjectName: slug,
    sort: settingsDefList.sort, // desc ||  asc
  });

  const limitRef = useRef(settingsDefList.limit);
  const [transfListRes, setTransfListRes] = useState([]);

  useEffect(() => {
    if (tableListRef.current) {
      const innerElements = tableListRef.current.childNodes;
      if (innerElements.length > 10) {
        let maxHeight = 0;
        let i = 0;
        while (i < 10) {
          maxHeight += innerElements[i].scrollHeight;
          i++;
        }
        console.log(maxHeight);
        tableListRef.current.style.height = maxHeight + 'px';
      } else {
        tableListRef.current.style.height = 'unset';
      }
    }
  }, [sortList]);

  const getStatisticData = async (settings, list) => {
    if (
      // !settings.slug &&
      !settings.curProjectName
    ) {
      lastUUIDRef.current = '';
      setLoading(false);
      return;
    }
    !loadedRef.current && setLoading(true);
    let datetimeFinish = '';
    if (settings.datetimeFinish) {
      const dateSplitted = settings.datetimeFinish.split('T');
      const date = dateSplitted[0].split('-').reverse().join('.');
      const time = dateSplitted[1] + ':00';
      datetimeFinish = date + ' ' + time;
    }
    let datetimeStart = '';
    if (settings.datetimeStart) {
      const dateSplitted = settings.datetimeStart.split('T');
      const date = dateSplitted[0].split('-').reverse().join('.');
      const time = dateSplitted[1] + ':00';
      datetimeStart = date + ' ' + time;
    }
    const filters = {
      tg_id: settings.tg_id || '',
      event_id: settings.event_id || '',
      good_sku: settings.sku || '',
      good_name: settings.name || '',
      user_anketa: settings.anketa || '',
      end_date: datetimeFinish,
      start_date: datetimeStart,
    };

    const data = await api.shop.getShopStatistic(
      // fake
      // 'mts-true-summer24-dedic',
      settings.curProjectName,
      filters,
      limitRef.current || settingsDefList.limit,
      settings.offset,
      settings.base
      // lastUUIDRef.current
    );
    if (data.error && data.error !== 'data not found') {
    } else if (data.good || lastUUIDRef.current) {
      const transformData = [];
      let count = data.good.length - 1;
      while (count >= 0) {
        for (let key of Object.keys(data)) {
          transformData[count] = {
            ...transformData[count],
            [key]: data[key][count],
          };
        }
        count--;
      }
      if (transformData.length) {
        lastUUIDRef.current = transformData[0].uuid;
      } else {
        lastUUIDRef.current = '';
      }
      if (transformData.length >= limitRef.current) {
        setIsShowMore(true);
      } else {
        setIsShowMore(false);
      }
      setList([...transformData]);
      // setList((prev) => [...prev, ...transformData]);
    } else {
      setIsShowMore(false);
      setList([]);
      lastUUIDRef.current = '';
      listRef.current = [];
    }
    setTimeout(() => {
      setLoading(false);
      loadedRef.current = true;
      setShowMoreLoading(false);
    }, 10);
  };

  const startUpdatingData = async (settings) => {
    const list = listRef.current || [];
    await getStatisticData(settings, list);
    setBtnUpdating(false);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      startUpdatingData(settings);
    }, settings.time.value * 1000);
  };

  const changeSettings = (key, val) => {
    setSettings((prev) => ({ ...prev, [key]: val }));
  };

  useEffect(() => {
    setList([]);
    setLoading(true);
    loadedRef.current = false;
    changeSettings('curProjectName', slug);
  }, [slug]);

  const showMore = () => {
    limitRef.current = limitRef.current + settingsDefList.limit;
    setShowMoreLoading(true);
    startUpdatingData(settings);
  };

  // eslint-disable-next-line
  const debounceLoadData = useCallback(debounce(startUpdatingData, 700), []);

  useEffect(() => {
    if (limitRef.current !== settingsDefList.limit) {
      limitRef.current = settingsDefList.limit;
      // changeSettings('limit', settingsDefList.limit);
    }
    debounceLoadData({ ...settings, limit: settingsDefList.limit });
    // eslint-disable-next-line
  }, [
    settings.sku,
    settings.base,
    settings.name,
    settings.tg_id,
    settings.anketa,
    settings.event_id,
    settings.time.value,
    settings.datetimeStart,
    settings.curProjectName,
    settings.datetimeFinish,
  ]);

  useEffect(() => {
    if (lightList.length) {
      setTimeout(() => {
        setLightList([]);
      }, 1000);
    }
  }, [lightList]);

  useEffect(() => {
    setSortList([
      ...sorting(
        list,
        settings.sort.key,
        settings.sort.direction === 'desc' ? 1 : 2
      ),
    ]);
  }, [list, settings.sort]);

  // useEffect(() => {
  //   setTransfListRes([
  //     ...list.map((item) => ({ name: item.good, value: item.ini })),
  //   ]);
  // }, [list]);

  useEffect(() => {
    setTransfListRes([
        ...list
         .filter((item) => item.sold !== null) // Фильтрация элементов, у которых sold не равен null, sold кол-во покупок
         .map((item) => ({ name: item.good, value: item.sold })),
    ]);
    }, [list]);

  const share = (e) => {
    if (!settings.curProjectName) return;
    const shareLink =
      process.env.REACT_APP_SITE_URI +
      '/share/shop/' +
      btoa('slug=' + settings.curProjectName + '&page=statistic&base=' + base);

    setShareMess('Ссылка скопирована!');
    navigator.clipboard
      .writeText(shareLink)
      .then(() => console.log('Успешно скопировано'))
      .catch((err) => console.error(err));
    setTimeout(() => {
      setShareMess('');
    }, 2000);
  };

  const switchPage = (pageN) => {
    if (isShared) {
      if (pageN !== activePage) {
        let url = '';
        switch (pageN) {
          case 1:
            url = btoa(
              'slug=' + settings.curProjectName + '&page=table&base=' + base
            );
            break;
          case 2:
            url = btoa(
              'slug=' + settings.curProjectName + '&page=statistic&base=' + base
            );
            break;
          case 3:
            url = btoa(
              'slug=' + settings.curProjectName + '&page=goods&base=' + base
            );
            break;
          case 4:
            url = btoa(
              'slug=' + settings.curProjectName + '&page=settings&base=' + base
            );
            break;
          default:
            break;
        }
        navigate('/share/shop/' + url);
      }
    } else {
      let url = '';
      switch (pageN) {
        case 1:
          url = '';
          break;
        case 2:
          url = '/statistic';
          break;
        case 3:
          url = '/goods';
          break;
        case 4:
          url = '/settings';
          break;
        default:
          break;
      }
      navigate('/shop' + url);
    }
  };

  // useEffect(() => {
  //   if (transfListRes.length) {

  //   }
  // }, [transfListRes]);

  return (
    <div className={'page ' + s.page + ' ' + (isShared ? s.shared : '')}>
      <h3 className={s.title}>Магазин {isShared ? ': ' + slug : ''}</h3>
      <div className={s.main}>
        <div className={s.pageSwitcher}>
          <button
            className={
              (activePage === 4 ? s.active : '') +
              (isShared ? ' ' + s.shared : '')
            }
            onClick={() => switchPage(4)}
          >
            Настройки
          </button>
          <button
            className={
              (activePage === 3 ? s.active : '') +
              (isShared ? ' ' + s.shared : '')
            }
            onClick={() => switchPage(3)}
          >
            Товары
          </button>
          <button
            className={
              (activePage === 1 ? s.active : '') +
              (isShared ? ' ' + s.shared : '')
            }
            onClick={() => switchPage(1)}
          >
            Таблица покупок
          </button>
          <button
            className={
              (activePage === 2 ? s.active : '') +
              (isShared ? ' ' + s.shared : '')
            }
            onClick={() => switchPage(2)}
          >
            Статистика
          </button>
        </div>
        <div className={s.mainRes}>
          <div className={s.controls + ' mobile'}>
            <Select
              btnClassName={s.selectBtn}
              value={settings.time.name}
              selected={settings.time.name}
              isShowed={showedList === 'time'}
              selectList={settingsDefList.time}
              className={s.select + ' ' + s.time}
              showList={() => {
                'time' === showedList
                  ? setShowedList('')
                  : setShowedList('time');
              }}
              select={(val) => changeSettings('time', val)}
            />
            <button
              disabled={loading}
              onClick={() => {
                // loadedRef.current = false;
                setBtnUpdating(true);
                startUpdatingData(settings, list);
              }}
              className={'btnLight ' + (btnUpdating ? 'loading' : '')}
            >
              Обновить данные
              {btnUpdating && <BtnLoader />}
            </button>
            <button
              onClick={share}
              disabled={shareMess}
              className={'btnDark ' + s.btnShare}
            >
              Поделиться
              {shareMess && <div className={s.shareMess}>{shareMess}</div>}
            </button>
          </div>
          <div className={s.left}>
            <p className={s.subTitle}>График</p>
            {loading && <Loader />}
            {!loading && (
              <div className={s.chartWrap}>
                <PieChart type="B" className={s.chart} data={transfListRes} />
                <div className={s.chartLegend}>
              
                  {transfListRes.map((dataItem, i) => (
                    <div key={i} className={s.chartLegendItem}>
                      <div
                        className={s.chartLegendCircle}
                        style={{ backgroundColor: COLORS[i] }}
                      ></div>
                      <p>
                        {dataItem.name}: {dataItem.value}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {!loading && !transfListRes.length && (
              <h3 className={s.emptyMes}>Нет записей</h3>
            )}
          </div>
          <div className={s.shopResTableWrap}>
        
            <div className={s.controls + ' desktop'}>
              <Select
                btnClassName={s.selectBtn}
                value={settings.time.name}
                selected={settings.time.name}
                isShowed={showedList === 'time'}
                selectList={settingsDefList.time}
                className={s.select + ' ' + s.time}
                showList={() => {
                  'time' === showedList
                    ? setShowedList('')
                    : setShowedList('time');
                }}
                select={(val) => changeSettings('time', val)}
              />
              <button
                disabled={loading}
                onClick={() => {
                  // loadedRef.current = false;
                  setBtnUpdating(true);
                  startUpdatingData(settings, list);
                }}
                className={'btnLight ' + (btnUpdating ? 'loading' : '')}
              >
                Обновить данные
                {btnUpdating && <BtnLoader />}
              </button>
              <button
                onClick={share}
                disabled={shareMess}
                className={'btnDark ' + s.btnShare}
              >
                Поделиться
                {shareMess && <div className={s.shareMess}>{shareMess}</div>}
              </button>
            </div>

            <p>Итого покупок и остаток на складе</p>

            <ShopStatisticTable
              list={sortList}
              loading={loading}
              settings={settings}
              lightList={lightList}
              tableListRef={tableListRef}
              changeSettings={changeSettings}
            />
            <ShopStatisticMobTable
              list={sortList}
              loading={loading}
              lightList={lightList}
            />
            {isShowMore && (
              <button
                className={'btnLight ' + s.btnShowMore}
                disabled={!isShowMore}
                onClick={showMore}
              >
                {showMoreLoading ? 'Загрузка...' : 'Показать ещё'}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopStatisticPage;
