const quizEdit = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.69529 2.32431L13.6767 6.30565L4.98836 14.9938L0.689569 15.9858C0.646013 15.9956 0.603345 16 0.560677 16C0.211334 16 -0.068674 15.672 0.0148839 15.3102L1.00691 11.0124L9.69529 2.32431ZM10.3238 1.69577L11.3592 0.660444C11.7983 0.220444 12.3752 0 12.9512 0C13.5282 0 14.1042 0.220444 14.5442 0.660444L15.3406 1.45689C16.2198 2.336 16.2198 3.76178 15.3406 4.64178L14.3053 5.67711L10.3238 1.69577Z"
      fill="#777A96"
    />
  </svg>
);

export default quizEdit;
