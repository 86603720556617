import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../store';
import { useNavigate } from 'react-router-dom';
import Input from '../../components/common/Input';
import PopUp from '../../components/common/PopUp';
import React, { useEffect, useState } from 'react';
import s from '../Controller/Controller.module.scss';
import { updateTree } from '../../store/main/actionCreators';
import { addBot, clearBotErrors } from '../../store/bot/actionCreators';

const PopupAddBot = ({ close }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [token, setToken] = useState('');
  const botData = useSelector((state) => state.bot);
  const [errors, setErrors] = useState({ token: '' });
  const isLoading = useSelector((state) => state.bot.isLoading);
  const { user_id } = useSelector((state) => state.auth.profileData);
  const { curProject, curCompany } = useSelector((state) => state.main);
  const updatingTree = useSelector((state) => state.main.updatingTree);

  // useEffect(() => {
  //   return () => {
  //     console.log(12);
  //     dispatch(clearBotErrors());
  //   };
  // }, []);

  useEffect(() => {
    if (botData.isAdded && !updatingTree) {
      dispatch(updateTree());
      dispatch(clearBotErrors());
      navigate('/bot-edit');
      close();
    }
    if (botData?.errors?.addBot) {
      setErrors({ token: botData.errors.addBot });
    }
    // eslint-disable-next-line
  }, [botData]);

  const changeData = (val) => {
    setErrors({});
    setToken(val);
  };

  const handleSubmit = async () => {
    if (!token) {
      setErrors((prevState) => ({ ...prevState, token: 'Заполните поле' }));
    } else {
      if (curCompany.name && curProject.name) {
        dispatch(
          addBot({
            token,
            user_id,
            company: curCompany.name,
            project: curProject.name,
          })
        );
      } else {
        dispatch(
          addBot({
            token,
            user_id,
          })
        );
      }

      // const res = await addBotApi(botData.token, user_id);
      // if (res.error) setErrors({ token: res.error });
      // if (res.data) {
      //   const data = await getBotApi(botData.token);
      //   const updatedRes = {
      //     ...data,
      //     token: botData.token,
      //     name: data.first_name,
      //   };
      //   dispatch(setCurBot(updatedRes));
      //   navigate('/bot-edit');
      //   close();
      // }
    }
  };

  return (
    <PopUp close={close} isOpen={true}>
      <div className={s.popupAddBot}>
        <h3 className={s.title}>Подключить телеграм-бот</h3>
        <ul className={s.stepList}>
          <li className={s.stepItem}>
            1. отправьте сообщение официальному боту в телеграмм: @BotFather или
            перейдите по
            <a href="https://t.me/BotFather" target="_blank" rel="noreferrer">
              ссылке
            </a>
          </li>
          <li className={s.stepItem}>
            2. отправьте команду /newbot или выберите ее в выпадающем слева меню
          </li>
          <li className={s.stepItem}>
            3. укажите имя бота, на русском или английском, например "Игровой
            помощник на мероприятии"
          </li>
          <li className={s.stepItem}>
            4. укажите username или имя аккаунта бота. может быть только на
            английском, больше 5 символов и обязательно заканчиваться на bot,
            например, gameOn_bot
          </li>
          <li className={s.stepItem}>
            5. найдите в сообщении текст после слов: Use this token to access
            the HTTP API: например:
            6755917287:AAH7Whgdfsgdzna9g1YnyrVxeZnukdRnX_cbynn30
          </li>
          <li className={s.stepItem + ' ' + s.input}>
            <p>6. вставьте токен в поле</p>
            <Input
              name={'token'}
              type="password"
              error={errors.token}
              value={token}
              handleChange={(val) => changeData(val, 'token')}
            />
          </li>
        </ul>
        <ul className={s.btnWrap}>
          <button
            disabled={isLoading}
            onClick={handleSubmit}
            className="btnLight"
          >
            {isLoading ? 'Подключение...' : 'Подключить'}
          </button>
          <button onClick={close} className="btnDark">
            Отмена
          </button>
        </ul>
      </div>
    </PopUp>
  );
};

export default PopupAddBot;
