import BlockUsers from './BlockUsers';
import React, { useState } from 'react';
import BlockService from './BlockService';
import { useSelector } from 'react-redux';
import BlockActivity from './BlockActivity';
import PopupAddBot from '../Bot/PopupAddBot';
import s from './Controller.module.scss';

const Controller = () => {
  const [popupAddBot, setPopupAddBot] = useState(false);
  const botData = useSelector((state) => state.bot.curBot);

  return (
    <div className={'page ' + s.controller}>
      {popupAddBot && <PopupAddBot close={() => setPopupAddBot(false)} />}
      <div className={s.controller__headerWrap}>
        <h2 className={s.controller__header}>
          Пульт управления геймификациями
        </h2>
        {botData.name ? (
          <span className={s.controller__headerNote}>{botData.name}</span>
        ) : (
          <span className={s.controller__headerNote + ' ' + s.dark}>
            Бот - не подключен
          </span>
        )}
      </div>
      <div className={s.controller__main}>
        <BlockService bot={botData} connectBot={() => setPopupAddBot(true)} />
        <BlockActivity bot={botData} />
        <BlockUsers bot={botData} />
      </div>
    </div>
  );
};

export default Controller;
