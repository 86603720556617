import Endpoints from "../endpoints";
import { axiosInstance } from "../instance";

export const getStartModulesApi = async (bot_name, project_name, author_id) => {
	try {
		const method = "saved";
		const tag_name = "latest";
		const res = await axiosInstance.get(
			Endpoints.MODULE.GET +
				`/${method}/${project_name}?tagname=${tag_name}&author=${author_id}`
		);
		// `/${method}/${bot_name}?ver_num=${ver_num}&tagname=${tag_name}`   -    пока без ver_num
		if (!res.data.error) {
			return res;
		} else {
			throw new Error();
		}
	} catch (e) {
		console.log(e);
		return { error: "Не удалось получить данные о квизах" };
	}
};

export const postStartModulesApi = async (
	project_name,
	bot_name,
	author_id,
	params
) => {
	try {
		params.bot.name = bot_name;
		params.bot.slag = project_name;
		params.author.date = +new Date();
		params.author.account = author_id;
		const res = await axiosInstance.post(Endpoints.MODULE.POST, params);
		if (!res.data.error) {
			return res;
		} else {
			throw new Error();
		}
	} catch (e) {
		console.log(e);
		return { error: "Не удалось получить данные о квизах" };
	}
};

export const saveStartModulesApi = async (
	bot_name,
	project_name,
	author_id,
	params
) => {
	try {
		params.bot.name = bot_name;
		params.bot.slag = project_name;
		params.author.date = +new Date();
		params.author.account = author_id;
		const method = "saved";
		const tag_name = "latest";
		const res = await axiosInstance.post(
			Endpoints.MODULE.SAVE +
				`/${method}/${project_name}?tagname=${tag_name}&author=${author_id}`,
			params
		);
		// `/${method}/${bot_name}?ver_num=${ver_num}&tagname=${tag_name}`   -    пока без ver_num
		if (!res.data.error) {
			return res;
		} else {
			throw new Error();
		}
	} catch (e) {
		console.log(e);
		return { error: "Не удалось получить данные о квизах" };
	}
};
