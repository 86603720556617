import s from './Challenge.module.scss';

const ChallengeHeader = ({ save, publish, btnPublish, btnSave }) => {
  return (
    <div className={s.header}>
      <h1 className={s.title}>Челлендж</h1>
      <div className={s.btns}>
        <button
          onClick={save}
          className={'btnDark'}
          disabled={btnSave !== 'Сохранить'}
        >
          {btnSave}
        </button>
        <button
          onClick={publish}
          className={'btnLight'}
          disabled={btnPublish !== 'Опубликовать'}
        >
          {btnPublish}
        </button>
      </div>
    </div>
  );
};

export default ChallengeHeader;
