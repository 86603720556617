import { useState } from 'react';
import s from './Challenge.module.scss';
import ChallengeHeader from './ChallengeHeader';
import Loader from '../../components/common/Loader';
import ChallengeMain from './ChallengeMain';

const Challenge = () => {
  const [loading] = useState(false); // setLoading
  const [btnSave] = useState('Сохранить'); //setBtnSave
  const [btnPublish] = useState('Опубликовать'); //setBtnPublish

  const publish = () => {
    console.log('publish');
  };

  const save = () => {
    console.log('save');
  };

  return (
    <div className={'page ' + s.page + ' ' + (loading ? s.loading : '')}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <ChallengeHeader
            save={save}
            btnSave={btnSave}
            publish={publish}
            btnPublish={btnPublish}
          />
          <ChallengeMain />
        </>
      )}
    </div>
  );
};

export default Challenge;
