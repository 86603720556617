const addImgIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.6665 5.00008C1.6665 3.15913 3.15889 1.66675 4.99984 1.66675H14.9998C16.8408 1.66675 18.3332 3.15913 18.3332 5.00008V10.3743L16.4611 9.14889C15.0157 8.20281 13.1237 8.30334 11.7867 9.39725L7.4215 12.9687C6.50671 13.7172 5.21219 13.786 4.22324 13.1387L1.6665 11.4652V5.00008ZM15.7765 10.1948L18.3332 11.8682V15.0001C18.3332 16.841 16.8408 18.3334 14.9998 18.3334H4.99984C3.15889 18.3334 1.6665 16.841 1.6665 15.0001V12.9591L3.53866 14.1846C4.98406 15.1306 6.87605 15.0301 8.21305 13.9362L12.5782 10.3647C13.493 9.61623 14.7875 9.54745 15.7765 10.1948ZM7.08317 9.16675C8.23376 9.16675 9.1665 8.23401 9.1665 7.08342C9.1665 5.93282 8.23376 5.00008 7.08317 5.00008C5.93258 5.00008 4.99984 5.93282 4.99984 7.08342C4.99984 8.23401 5.93258 9.16675 7.08317 9.16675Z"
      fill="#777A96"
    />
  </svg>
);

export default addImgIcon;
