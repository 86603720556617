import s from './Common.module.scss';
import resetSvg from '../../imgs/reset';
import pasHidden from '../../imgs/pasHidden';
import pasShowed from '../../imgs/pasShowed';
import React, { useEffect, useState } from 'react';

const Input = ({
  max,
  name,
  value,
  label,
  disabled,
  error = '',
  defaultPasN,
  placeholder,
  handleChange,
  type = 'text',
  focus = false,
  onReset = false,
  className = '',
  innerLabel = '',
  wrapClassName = '',
}) => {
  const [fieldShowed, setFieldShowed] = useState(false);
  const [dummy, setDummy] = useState('');

  useEffect(() => {
    if (defaultPasN) {
      let str = '';
      for (let i = 1; i <= defaultPasN; i++) {
        str += '*';
      }
      setDummy(str);
    }
  }, [defaultPasN]);

  return (
    <div className={s.input__wrap + ' ' + wrapClassName}>
      {label && (
        <label htmlFor={name} className={s.input__label}>
          <span>{label}</span>
        </label>
      )}
      <div className={s.input__inputWrap + ' ' + className}>
        {innerLabel && (
          <span className={s.input__innerLabel}>{innerLabel}: </span>
        )}
        <input
          name={name}
          maxLength={max}
          autoFocus={focus}
          disabled={disabled}
          placeholder={placeholder}
          onChange={(e) => handleChange(e.target.value, name)}
          value={dummy && !fieldShowed ? dummy : value || ''}
          className={
            s.input__input +
            (error ? ' ' + s.error : '') +
            (onReset ? ' ' + s.withReset : '')
          }
          type={type === 'password' ? (fieldShowed ? 'text' : type) : type}
        />
        {onReset && (
          <button onClick={() => handleChange('')} className={s.input__reset}>
            {resetSvg}
          </button>
        )}
        {type === 'password' && (
          <button
            className={s.input__show}
            onClick={() => {
              setFieldShowed((prev) => !prev);
            }}
          >
            {fieldShowed ? pasShowed : pasHidden}
          </button>
        )}
      </div>
      <p className={s.input__textError}>{error}</p>
    </div>
  );
};

export default Input;
