import api from "../../api";
import s from "./Shop.module.scss";
import quizDel from "../../imgs/quizDel";
import { useSelector } from "react-redux";
import PopupAddBot from "../Bot/PopupAddBot";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../../components/common/Input";
import Loader from "../../components/common/Loader";
import Textarea from "../../components/common/Textarea";
import InputImg from "../../components/common/InputImg";
import Switcher from "../../components/common/Switcher";
import PopUp from "../../components/common/PopUp";
import pointsIcon from "../../imgs/pointsIcon";
import popUpClose from "../../imgs/popUpClose";

const ShopGoodsPage = ({ slug, isShared, base }) => {
	const navigate = useNavigate();
	const [activePage] = useState(3);

	const [defItem] = useState({
		description: "",
		ini_balance: "",
		media: "",
		name: "",
		price: "",
		sku: "",
		text_w_num: "",
	});

	const [json, setJson] = useState({});
	const [list, setList] = useState([]);
	const [editGood, setEditGood] = useState();
	const [loading, setLoading] = useState(false);
	const [updating, setUpdating] = useState(false);
	const [btnSave, setBtnSave] = useState("Сохранить");
	const { curProject } = useSelector((state) => state.main);
	const [btnPublish, setBtnPublish] = useState("Опубликовать");
	const { curBot, isLoaded } = useSelector((state) => state.bot);
	const { user_id } = useSelector((state) => state.auth.profileData);
	const [moreSettingsIndex, setMoreSettingsIndex] = useState();

	const getData = async (...params) => {
		setLoading(true);
		const data = await api.shop.getGoods(...params);
		if (data) {
			setJson(data);
		}
		if (data?.data?.goods) {
			setList([...data.data.goods]);
		}
		setLoading(false);
	};

	const saveData = async () => {
		if (!curProject.name || !curBot.name) return;
		setBtnSave("Сохранение...");
		const resp = await api.shop.saveGoods(
			curProject.name,
			curBot.username,
			user_id,
			{ ...json, data: { ...json.data, goods: list } }
		);
		console.log(resp);
		if (resp?.data?.result === "успешно записали в БД") {
			setBtnSave("Сохранено");
		} else {
			setBtnSave("Ошибка");
		}
		setTimeout(() => {
			setBtnSave("Сохранить");
		}, 2000);
	};

	const publishData = async () => {
		if (!curProject.name || !curBot.name) return;
		setBtnPublish("Публикация...");

		const resp = await api.shop.publishGoods({
			...json,
			data: { ...json.data, goods: list },
		});
		console.log(resp);
		if (resp?.data?.result === "ok") {
			setBtnPublish("Опубликовано");
		} else {
			setBtnPublish("Ошибка");
		}
		setTimeout(() => {
			setBtnPublish("Опубликовать");
		}, 2000);
	};

	useEffect(() => {
		if (user_id && curBot.name && curProject.name) {
			getData(curProject.name, user_id, curBot);
		}
		// eslint-disable-next-line
	}, [user_id, curBot.name, curProject.name]);

	const switchPage = (pageN) => {
		if (isShared) {
			if (pageN !== activePage) {
				let url = "";
				switch (pageN) {
					case 1:
						url = btoa("slug=" + slug + "&page=table&base=" + base);
						break;
					case 2:
						url = btoa("slug=" + slug + "&page=statistic&base=" + base);
						break;
					case 3:
						url = btoa("slug=" + slug + "&page=goods&base=" + base);
						break;
					case 4:
						url = btoa("slug=" + slug + "&page=settings&base=" + base);
						break;
					default:
						break;
				}
				navigate("/share/shop/" + url);
			}
		} else {
			let url = "";
			switch (pageN) {
				case 1:
					url = "";
					break;
				case 2:
					url = "/statistic";
					break;
				case 3:
					url = "/goods";
					break;
				case 4:
					url = "/settings";
					break;
				default:
					break;
			}
			navigate("/shop" + url);
		}
	};

	const changeItem = (index, key, value) => {
		setList((prev) => [
			...prev.slice(0, index),
			{ ...prev[index], [key]: value },
			...prev.slice(index + 1),
		]);
	};

	const delItem = (index) => {
		setUpdating(true);
		setList((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)]);
		setUpdating(false);
		setEditGood();
	};

	const addItem = () => {
		setList((prev) => [{ ...defItem, sku: `good${prev.length + 1}` }, ...prev]);
	};

	return (
		<div className={"page " + s.page + " " + (isShared ? s.shared : "")}>
			{!curBot.username && isLoaded ? (
				<PopupAddBot close={() => navigate("/")} />
			) : (
				<>
					<h3 className={s.title}>Магазин {isShared ? ": " + slug : ""}</h3>
					<div className={[s.main, s.goods].join(" ")}>
						<>
							<div className={s.pageSwitcher}>
								<button
									className={
										(activePage === 4 ? s.active : "") +
										(isShared ? " " + s.shared : "")
									}
									onClick={() => switchPage(4)}
								>
									Настройки
								</button>
								<button
									className={
										(activePage === 3 ? s.active : "") +
										(isShared ? " " + s.shared : "")
									}
									onClick={() => switchPage(3)}
								>
									Товары
								</button>
								<button
									className={
										(activePage === 1 ? s.active : "") +
										(isShared ? " " + s.shared : "")
									}
									onClick={() => switchPage(1)}
								>
									Таблица покупок
								</button>
								<button
									className={
										(activePage === 2 ? s.active : "") +
										(isShared ? " " + s.shared : "")
									}
									onClick={() => switchPage(2)}
								>
									Статистика
								</button>
							</div>
							{/* {isShowMore && (
            <button
              className={'btnLight ' + s.btnShowMore}
              disabled={!isShowMore}
              onClick={showMore}
            >
              {showMoreLoading ? 'Загрузка...' : 'Показать ещё'}
            </button>
          )} */}
						</>
						{loading ? (
							<Loader />
						) : (
							<div className={s.shopGoodsWrap}>
								<div className={s.top}>
									<div>
										<button
											onClick={addItem}
											disabled={loading}
											className="btnDark btnAdd"
										>
											+ Добавить товар
										</button>
									</div>
									<div className={s.btns}>
										<button
											disabled={loading || btnSave !== "Сохранить"}
											onClick={() => saveData()}
											className="btnDark"
										>
											{btnSave}
										</button>

										<button
											disabled={loading || btnPublish !== "Опубликовать"}
											onClick={() => publishData()}
											className="btnLight"
										>
											{btnPublish}
										</button>
									</div>
								</div>
								<ul className={s.shopGoods}>
									{list.map((item, i) => (
										<li key={i}>
											<Textarea
												val={item.name}
												label="Название"
												placeholder=""
												wrapClassName=""
												className={s.textarea}
												handleChange={(val) => changeItem(i, "name", val)}
											/>
											<InputImg
												img={item.media}
												id={"good_img_" + i}
												className={s.inputImg}
												setImg={(val) => changeItem(i, "media", val)}
											/>
											<Textarea
												label="Описание"
												placeholder=""
												wrapClassName=""
												className={s.textarea}
												val={item.description}
												handleChange={(val) =>
													changeItem(i, "description", val)
												}
											/>
											<div className={s.inputWrap}>
												<Input
													type="number"
													// onReset={true}
													label="Стоимость"
													value={item.price}
													className={s.input}
													handleChange={(val) => changeItem(i, "price", val)}
												/>
											</div>
											<div className={s.inputWrap}>
												<Input
													type="number"
													label="Остаток"
													// onReset={true}
													className={s.input}
													value={item.ini_balance}
													handleChange={(val) =>
														changeItem(i, "ini_balance", val)
													}
												/>
											</div>
											<div className={s.btnShowMoreWrap}>
												{moreSettingsIndex === i && (
													<div className={s.showMoreSettings}>
														<button
															className={s.btnClose}
															onClick={() => setMoreSettingsIndex()}
														>
															{popUpClose}
														</button>
														<Input
															label="Sku"
															// onReset={true}
															value={item.sku}
															className={s.input}
															wrapClassName={s.inputWrapClassName}
															handleChange={(val) => changeItem(i, "sku", val)}
														/>
														<div className={s.switcherWrap}>
															<span>Активность</span>
															<Switcher
																isOn={false}
																label="Вкл"
																className={s.switcher}
															/>
														</div>

														<button
															onClick={() =>
																setEditGood({ index: i, key: "del" })
															}
															className={s.btnDel}
														>
															<span>Удалить товар</span> {quizDel}
														</button>
													</div>
												)}
												<button
													className={s.btnShowMore}
													onClick={() =>
														moreSettingsIndex === i
															? setMoreSettingsIndex()
															: setMoreSettingsIndex(i)
													}
												>
													{pointsIcon}
												</button>
											</div>
											{/* <div className={s.inputWrap}>
                        <Input
                          label="Sku"
                          // onReset={true}
                          value={item.sku}
                          className={s.input}
                          handleChange={(val) => changeItem(i, 'sku', val)}
                        />
                      </div> */}
											{/* <div className={s.controls}>
                        <Switcher
                          isOn={false}
                          label="Вкл"
                          className={s.switcher}
                          handleClick={() => console.log("a")}
                        />
                        <button
                          onClick={() => setEditGood({ index: i, key: "del" })}
                          className={s.btnDel}
                        >
                          {quizDel}
                        </button>
                      </div> */}
										</li>
									))}
								</ul>
							</div>
						)}
					</div>

					<PopUp
						isOpen={editGood}
						close={() => setEditGood()}
						className={s.editGoodPopup}
					>
						<h3>
							{editGood?.key === "del"
								? "Удалить товар?"
								: editGood?.key
								? "Отменить заказ?"
								: "Товар забрали?"}
						</h3>
						<div className={s.popupBtns}>
							<button
								onClick={() => setEditGood()}
								className="btnDark"
							>
								Отмена
							</button>
							<button
								disabled={updating}
								className="btnLight"
								onClick={() => delItem(editGood.index)}
							>
								{updating ? "Подтверждение..." : "Подтвердить"}
							</button>
						</div>
					</PopUp>
				</>
			)}
		</div>
	);
};

export default ShopGoodsPage;
