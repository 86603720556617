const URI = process.env.REACT_APP_API_URI;
const URI2 = process.env.REACT_APP_API_URI2;

const Endpoints = {
	AUTH: {
		LOGOUT: URI + "/logout",
		PROFILE: URI + "/auth",
		SENDTG: URI + "/tg_login",
		SENDEMAIL: URI + "/login",
		CHECKTG: URI + "/bot_login",
		LOGIN: URI + "/confirm_code",
		REFRESH: URI + "/refresh_token_multi",
		// REFRESH: URI + '/refresh_token', old
	},
	MAIN: {
		ADD_IMG: URI2 + "/save-image/Content-Type",
		GET_TREE: URI2 + "/tree",
		ADD_COMPANY: URI2 + "/add_company",
		ADD_PROJECT: URI2 + "/add_project",
	},
	BOT: {
		ADD: URI2 + "/add_bot",
		DEL: URI2 + "/add_bot",
		GET_INFO: URI2 + "/bot_info",
		GET_MY_BOTS: URI2 + "/my_bots",
	},
	QUIZ: {
		GET: URI2 + "/get_quiz",
		DEL: URI2 + "/get_quiz",
		SAVE: URI2 + "/get_quiz",
		POST: URI2 + "/quizes_deploy",
	},
	MODULE: {
		GET: URI2 + "/module",
		DEL: URI2 + "/module",
		SAVE: URI2 + "/module",
		POST: URI2 + "/quizes_deploy",
	},
	SURVEY: {
		GET: URI2 + "/anketa",
		SAVE: URI2 + "/anketa",
		PUBLISH: URI2 + "/quizes_deploy",
	},
	MENU: {
		GET: URI2 + "/menu",
		SAVE: URI2 + "/menu",
		PUBLISH: URI2 + "/quizes_deploy",
		UPDATE: URI2 + "/quizes_deploy",
	},
	SHOP: {
		GET: URI2 + "/shop",
		DELETE_ITEM: URI2 + "/shop",
		UPDATE_STATUS: URI2 + "/shop",
		UPDATE_EXCEL: URI2 + "/shop_excel",
		GET_GOODS: URI2 + "/shop_goods",
		PUBLISH: URI2 + "/quizes_deploy",
	},
	POLLS: {
		GET: URI2 + "/polls",
		SAVE: URI2 + "/polls",
		POST: URI2 + "/quizes_deploy",
	},
};

export default Endpoints;
