import api from "../../api";
import s from "./Shop.module.scss";
import { useSelector } from "react-redux";
import PopupAddBot from "../Bot/PopupAddBot";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../../components/common/Input";
import Loader from "../../components/common/Loader";
import Textarea from "../../components/common/Textarea";
import InputImg from "../../components/common/InputImg";
import Checkbox from "../../components/common/Checkbox";
import Select from "../../components/common/Select";
// import quizDel from '../../imgs/quizDel';
// import Switcher from '../../components/common/Switcher';

const ShopSettingsPage = ({ slug, isShared, base }) => {
	const navigate = useNavigate();
	const [activePage] = useState(4);
	const [lists] = useState({
		after: ["Рестарт", "Ничего"],
		actives: [
			"опрос",
			"профиль",
			"Тех поддержка",
			"переход в меню",
			// 'переход в магазин',
			"баллы за челлендж",
		],
	});

	const [json, setJson] = useState({});
	const [btnSave, setBtnSave] = useState("Сохранить");
	const [data, setData] = useState();
	const [loading, setLoading] = useState(false);
	const [showedSelect, setShowedSelect] = useState("");
	const { curProject } = useSelector((state) => state.main);
	const [btnPublish, setBtnPublish] = useState("Опубликовать");
	const { curBot, isLoaded } = useSelector((state) => state.bot);
	const { user_id } = useSelector((state) => state.auth.profileData);

	const showSelectList = (listName) => {
		if (listName === showedSelect) {
			setShowedSelect("");
		} else {
			setShowedSelect(listName);
		}
	};

	// useEffect(() => {
	//   console.log(curBot, isLoaded);
	// }, [curBot]);

	const getData = async (...params) => {
		setLoading(true);
		const data = await api.shop.getGoods(...params);
		if (data) {
			setJson(data);
		}
		if (data?.data) {
			// console.log(data.data);
			setData(data.data);
		}
		setLoading(false);
	};

	const puplishData = async () => {
		if (!curProject.name || !curBot.name) return;
		setBtnPublish("Публикация...");
		const resp = await api.shop.publishGoods({
			...json,
			data: { ...data, goods: json.data.goods },
		});
		// console.log(resp);
		if (resp?.data?.result === "ok") {
			setBtnPublish("Опубликовано");
		} else {
			setBtnPublish("Ошибка");
		}
		setTimeout(() => {
			setBtnPublish("Опубликовать");
		}, 2000);
	};

	const saveData = async () => {
		if (!curProject.name || !curBot.name) return;
		setBtnSave("Сохранение...");
		const resp = await api.shop.saveGoods(
			curProject.name,
			curBot.username,
			user_id,
			{ ...json, data: { ...data, goods: json.data.goods } }
		);
		// console.log(resp);
		if (resp?.data?.result === "успешно записали в БД") {
			setBtnSave("Сохранено");
		} else {
			setBtnSave("Ошибка");
		}
		setTimeout(() => {
			setBtnSave("Сохранить");
		}, 2000);
	};

	useEffect(() => {
		if (user_id && curBot.name && curProject.name) {
			getData(curProject.name, user_id, curBot);
		}
		// eslint-disable-next-line
	}, [user_id, curBot.name, curProject.name]);

	const switchPage = (pageN) => {
		if (isShared) {
			if (pageN !== activePage) {
				let url = "";
				switch (pageN) {
					case 1:
						url = btoa("slug=" + slug + "&page=table&base=" + base);
						break;
					case 2:
						url = btoa("slug=" + slug + "&page=statistic&base=" + base);
						break;
					case 3:
						url = btoa("slug=" + slug + "&page=goods&base=" + base);
						break;
					case 4:
						url = btoa("slug=" + slug + "&page=settings&base=" + base);
						break;
					default:
						break;
				}
				navigate("/share/shop/" + url);
			}
		} else {
			let url = "";
			switch (pageN) {
				case 1:
					url = "";
					break;
				case 2:
					url = "/statistic";
					break;
				case 3:
					url = "/goods";
					break;
				case 4:
					url = "/settings";
					break;
				default:
					break;
			}
			navigate("/shop" + url);
		}
	};

	const changeData = (value, key1, key2) => {
		if (!key2) {
			setData((prev) => ({
				...prev,
				[key1]: { data: value },
			}));
		} else {
			setData((prev) => ({
				...prev,
				[key1]: { ...prev[key1], [key2]: { data: value } },
			}));
		}
	};

	// useEffect(() => {
	//   console.log(data);
	// }, [data]);

	return (
		<div className={"page " + s.page + " " + (isShared ? s.shared : "")}>
			{!curBot.username && !isLoaded ? (
				<PopupAddBot close={() => navigate("/")} />
			) : (
				<>
					<div className={[s.top, s.settings].join(" ")}>
						<h3 className={s.title}>Магазин {isShared ? ": " + slug : ""}</h3>
						<div className={s.btns}>
							<button
								disabled={loading || btnSave !== "Сохранить"}
								onClick={() => saveData()}
								className="btnDark"
							>
								{btnSave}
							</button>

							<button
								disabled={loading || btnPublish !== "Опубликовать"}
								onClick={() => puplishData()}
								className="btnLight"
							>
								{btnPublish}
							</button>
						</div>
					</div>
					<div className={s.main}>
						<div className={s.pageSwitcher}>
							<button
								className={
									(activePage === 4 ? s.active : "") +
									(isShared ? " " + s.shared : "")
								}
								onClick={() => switchPage(4)}
							>
								Настройки
							</button>
							<button
								className={
									(activePage === 3 ? s.active : "") +
									(isShared ? " " + s.shared : "")
								}
								onClick={() => switchPage(3)}
							>
								Товары
							</button>
							<button
								className={
									(activePage === 1 ? s.active : "") +
									(isShared ? " " + s.shared : "")
								}
								onClick={() => switchPage(1)}
							>
								Таблица покупок
							</button>
							<button
								className={
									(activePage === 2 ? s.active : "") +
									(isShared ? " " + s.shared : "")
								}
								onClick={() => switchPage(2)}
							>
								Статистика
							</button>
						</div>
						{loading ? (
							<Loader />
						) : (
							data && (
								<>
									<div className={s.shopSettingsWrap}>
										<div className={s.shopSettingsL}>
											<div className={s.shopSettingsBlock}>
												<p className={s.subTitle}>Настройки текста</p>
												<div
													className={[s.shopSettingsContent, s.text].join(" ")}
												>
													<div className={s.inputWithImg}>
														<Textarea
															autoHeigth
															wrapClassName=""
															className={s.textarea}
															label="Старт магазина"
															placeholder="Введите текст"
															val={data.shop_start.text.data}
															handleChange={(val) =>
																changeData(val, "shop_start", "text")
															}
														/>
														<InputImg
															id={"shop_start_img"}
															className={s.inputImg}
															img={data.shop_start.media.data}
															setImg={(val) =>
																changeData(val, "shop_start", "media")
															}
														/>
														<div className={s.desc}>
															<p className={s.textGrey}>
																{"{$bonus},{$good_list_text}"}
															</p>
															<p className={s.text}>
																Укажите как можно купить товар в магазине и
																покажите фотку с призами
															</p>
														</div>
													</div>

													<div className={s.inputWithImg}>
														<Textarea
															placeholder="Введите текст"
															wrapClassName=""
															className={s.textarea}
															label="Ошибка в выборе товара"
															val={data.shop_choise_error.text.data}
															handleChange={(val) =>
																changeData(val, "shop_choise_error", "text")
															}
														/>
														<InputImg
															id={"start_img"}
															className={s.inputImg}
															img={data.shop_choise_error.media.data}
															setImg={(val) =>
																changeData(val, "shop_choise_error", "media")
															}
														/>
														<div className={s.desc}>
															{/* <p className={s.textGrey}>
                            {'{$bonus},{$good_list_text}'}
                          </p> */}
															<p className={s.text}>
																когда это не кнопка выбора товара и не выход в
																меню
															</p>
														</div>
													</div>

													<div className={s.inputWithImg}>
														<Textarea
															placeholder="Введите текст"
															wrapClassName=""
															className={s.textarea}
															label="не хватает баллов на счете"
															val={data.shop_not_enough_balls.text.data}
															handleChange={(val) =>
																changeData(val, "shop_not_enough_balls", "text")
															}
														/>
														<InputImg
															className={s.inputImg}
															id={"shop_not_enough_balls_img"}
															img={data.shop_not_enough_balls.media.data}
															setImg={(val) =>
																changeData(
																	val,
																	"shop_not_enough_balls",
																	"media"
																)
															}
														/>
														<div className={s.desc}>
															<p className={s.textGrey}>
																{
																	"{$ostatok},{$good},{$bonus},{$price},{$description}"
																}
															</p>
															{/* <p className={s.text}>
                            когда это не кнопка выбора товара и не выход в меню
                          </p> */}
														</div>
													</div>

													<div className={s.inputWithImg}>
														<Textarea
															placeholder="Введите текст"
															wrapClassName=""
															className={s.textarea}
															label="подтверждение выбора в магазине"
															val={data.shop_confirmation.text.data}
															handleChange={(val) =>
																changeData(val, "shop_confirmation", "text")
															}
														/>
														<InputImg
															className={s.inputImg}
															id={"shop_confirmation_img"}
															img={data.shop_confirmation.media.data}
															setImg={(val) =>
																changeData(val, "shop_confirmation", "media")
															}
														/>
														<div className={s.desc}>
															<p className={s.textGrey}>
																{
																	"{$ostatok},{$good},{$bonus},{$price},{$description}"
																}
															</p>
															{/* <p className={s.text}>
                            когда это не кнопка выбора товара и не выход в меню
                          </p> */}
														</div>
													</div>

													<div className={s.inputWithImg}>
														<Textarea
															placeholder="Введите текст"
															wrapClassName=""
															className={s.textarea}
															label="успешная покупка"
															val={data.shop_after_purchase.text.data}
															handleChange={(val) =>
																changeData(val, "shop_after_purchase", "text")
															}
														/>
														<InputImg
															id={"shop_after_purchase_img"}
															className={s.inputImg}
															img={data.shop_after_purchase.media.data}
															setImg={(val) =>
																changeData(val, "shop_after_purchase", "media")
															}
														/>
														<div className={s.desc}>
															<p className={s.textGrey}>
																{"{$ostatok},{$good}"}
															</p>
															{/* <p className={s.text}>
                            когда это не кнопка выбора товара и не выход в меню
                          </p> */}
														</div>
													</div>

													<div className={s.inputWithImg}>
														<Select
															value={data.shop_next_action.text.data}
															className={s.select}
															topLabel="перейти после покупки"
															selected={data.shop_next_action.text.data}
															selectList={["В меню", "Остаться в магазине"]}
															select={(val) =>
																changeData(val, "shop_next_action", "text")
															}
															isShowed={showedSelect === `shop_next_action`}
															showList={() =>
																showSelectList(`shop_next_action`)
															}
														/>
														{/* <Textarea
                              placeholder="Введите текст"
                              wrapClassName=""
                              className={s.textarea}
                              label="перейти после покупки"
                              val={data.shop_next_action.text.data}
                              handleChange={(val) =>
                                changeData(val, "shop_next_action", "text")
                              }
                            /> */}
														{/* <InputImg
															className={s.inputImg}
															id={"shop_next_action_action"}
															img={data.shop_next_action.media.data}
															setImg={(val) =>
																changeData(val, "shop_next_action", "media")
															}
														/> */}
													</div>

													<div className={s.inputWithImg}>
														<Textarea
															placeholder="Введите текст"
															wrapClassName=""
															className={s.textarea}
															label="разделитель списка товаров"
															val={data.shop_delim.data}
															handleChange={(val) =>
																changeData(val, "shop_delim")
															}
														/>
													</div>

													<div className={s.inputWithImg}>
														<Textarea
															placeholder="Введите текст"
															wrapClassName=""
															className={s.textarea}
															val={data.shop_concat.data}
															label="разделитель списка товаров"
															handleChange={(val) =>
																changeData(val, "shop_concat")
															}
														/>
													</div>

													<div className={s.inputWithImg}>
														<Textarea
															wrapClassName=""
															className={s.textarea}
															label="название валюты"
															val={data.shop_valute.data}
															placeholder="Введите текст"
															handleChange={(val) =>
																changeData(val, "shop_valute")
															}
														/>
													</div>

													<div className={s.inputWithImg}>
														<Textarea
															placeholder="Введите текст"
															wrapClassName=""
															className={s.textarea}
															val={data.shop_view_ostatok.data}
															label="текст визуализации остатков"
															handleChange={(val) =>
																changeData(val, "shop_view_ostatok")
															}
														/>
													</div>

													<Checkbox
														className={s.checkbox}
														isOn={data.shop_only_one_good.data}
														label={"Купить только один экземпляр товара"}
														handleClick={() =>
															changeData(
																!data.shop_only_one_good.data,
																"shop_only_one_good"
															)
														}
													/>
												</div>
											</div>

											<div className={s.shopSettingsBlock}>
												<p className={s.subTitle}>Дополнительные настройки</p>
												<div
													className={[
														s.shopSettingsContent,
														s.addSettings,
													].join(" ")}
												>
													<Select
														value={""} //data.shop_but_ok.text.data
														className={s.select}
														topLabel="Активность"
														selected={module.name}
														selectList={lists.actives}
														select={(val) => changeData("name", val)}
														isShowed={showedSelect === `active_select`}
														showList={() => showSelectList(`active_select`)}
													/>
												</div>
											</div>
										</div>

										<div className={s.shopSettingsR}>
											<div className={s.shopSettingsBlock}>
												<p className={s.subTitle}>Параметры</p>
												<div
													className={[s.shopSettingsContent, s.btns].join(" ")}
												>
													<div className={s.inputWithImg}>
														<Textarea
															placeholder="Введите текст"
															wrapClassName=""
															className={s.textarea}
															val={data.shop_but_ok.text.data}
															label="кнопка подтверждения выбора в магазине"
															handleChange={(val) =>
																changeData(val, "shop_but_ok", "text")
															}
														/>
														<InputImg
															id={"shop_but_ok_img"}
															className={s.inputImg}
															img={data.shop_but_ok.media.data}
															setImg={(val) =>
																changeData(val, "shop_but_ok", "media")
															}
														/>
													</div>
													<div className={s.inputWithImg}>
														<Textarea
															placeholder="Введите текст"
															wrapClassName=""
															className={s.textarea}
															val={data.shop_but_stop.text.data}
															handleChange={(val) =>
																changeData(val, "shop_but_stop", "text")
															}
															label="кнопка отказ при подтверждении выбора в магазине"
														/>
														<InputImg
															className={s.inputImg}
															id={"shop_but_stop_img"}
															img={data.shop_but_stop.media.data}
															setImg={(val) =>
																changeData(val, "shop_but_stop", "media")
															}
														/>
													</div>
													<div className={s.inputWithImg}>
														<Textarea
															placeholder="Введите текст"
															wrapClassName=""
															className={s.textarea}
															label="кнопка выхода в меню"
															val={data.shop_but_menu.text.data}
															handleChange={(val) =>
																changeData(val, "shop_but_menu", "text")
															}
														/>
														<InputImg
															className={s.inputImg}
															id={"shop_but_menu_img"}
															img={data.shop_but_menu.media.data}
															setImg={(val) =>
																changeData(val, "shop_but_menu", "media")
															}
														/>
													</div>
												</div>
											</div>
											<div className={s.shopSettingsBlock}>
												<p className={s.subTitle}>Параметры</p>
												<div
													className={[s.shopSettingsContent, s.btns].join(" ")}
												>
													<div className={s.inputWithImg}>
														<Input
															type="number"
															label="лимит на покупки"
															wrapClassName={s.textarea}
															placeholder={"Введите число"}
															value={data.shop_limit_total.data}
															handleChange={(val) =>
																changeData(val, "shop_limit_total")
															}
														/>
													</div>
													<div className={s.inputWithImg}>
														<Textarea
															placeholder="Введите текст"
															wrapClassName=""
															className={s.textarea}
															label="превышение лимита"
															val={data.shop_limit_over.text.data}
															handleChange={(val) =>
																changeData(val, "shop_limit_over", "text")
															}
														/>
														<InputImg
															id={"shop_limit_over_img"}
															className={s.inputImg}
															img={data.shop_limit_over.media.data}
															setImg={(val) =>
																changeData(val, "shop_limit_over", "media")
															}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</>
							)
						)}
					</div>

					{/* <PopUp
        isOpen={editGood}
        close={() => setEditGood()}
        className={s.editGoodPopup}
      >
        <h3>
          {editGood?.key === 'del'
            ? 'Удалить?'
            : editGood?.key
            ? 'Отменить заказ?'
            : 'Товар забрали?'}
        </h3>
        <div className={s.popupBtns}>
          <button onClick={() => setEditGood()} className="btnDark">
            Отмена
          </button>
          <button
            disabled={updating}
            className="btnLight"
            onClick={editGood?.key === 'del' ? delItem : changeStatus}
          >
            {updating ? 'Подтверждение...' : 'Подтвердить'}
          </button>
        </div>
      </PopUp> */}
				</>
			)}
		</div>
	);
};

export default ShopSettingsPage;
