export default (
  <svg
    width="13"
    height="14"
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.22222 1.22222C7.22222 0.82335 6.89887 0.5 6.5 0.5C6.10113 0.5 5.77778 0.82335 5.77778 1.22222V6.27778H0.722222C0.323349 6.27778 0 6.60113 0 7C0 7.39887 0.323349 7.72222 0.722222 7.72222H5.77778V12.7778C5.77778 13.1767 6.10113 13.5 6.5 13.5C6.89887 13.5 7.22222 13.1767 7.22222 12.7778V7.72222H12.2778C12.6767 7.72222 13 7.39887 13 7C13 6.60113 12.6767 6.27778 12.2778 6.27778H7.22222V1.22222Z"
      fill="white"
    />
  </svg>
);
