import { useEffect } from "react";
import MenuItem from "./MenuItem";
import s from "./Menu.module.scss";
import delIcon from "../../imgs/quizDel";
import Input from "../../components/common/Input";
import Textarea from "../../components/common/Textarea";
import InputImg from "../../components/common/InputImg";

const MenuMain = ({
	menu,
	save,
	publish,
	btnSave,
	btnsInRow,
	delAction,
	addAction,
	menuItems,
	btnPublish,
	delMenuBtn,
	changeMenu,
	updateMenu,
	// delMenuItem,
	handleChoose,
	publishChoosed,
	changeMenuItems,
	btnPublishChoosed,
	choosedActionList,
}) => {
	useEffect(() => {
		if (menuItems.length) {
		}
	}, [menuItems]);

	useEffect(() => {
		// console.log(menu.buts);
		// console.log(menu.text?.data);
	}, [menu]);

	return (
		<div className={s.main}>
			<div className={s.main__top__wrap}>
				<div className={s.main__top}>
					<Textarea
						name="text"
						label="Текст"
						placeholder=""
						onReset={true}
						val={menu.text?.data || ""}
						wrapClassName={s.main__top__textarea}
						handleChange={(val) => changeMenu("text", val)}
					/>
					<InputImg
						// id={data.num}
						img={menu.media?.data}
						className={s.main__top__img}
						setImg={(img) => changeMenu("media", img)}
					/>
				</div>
				<div className={s.main__top__btns}>
					<button
						onClick={save}
						className={"btnDark"}
						disabled={btnSave !== "Сохранить"}
					>
						{btnSave}
					</button>
					<button
						onClick={publish}
						className={"btnLight"}
						disabled={btnPublish !== "Опубликовать"}
					>
						{btnPublish}
					</button>
					<button
						className={"btnDark"}
						onClick={publishChoosed}
						style={{ gridArea: "2/1/3/3" }}
						disabled={btnPublishChoosed !== "Опубликовать выбранные"}
					>
						{btnPublishChoosed}
					</button>
					<button
						onClick={updateMenu}
						className={"btnLight"}
						style={{ gridArea: "3/1/4/3" }}
					>
						Обновить меню
					</button>
				</div>
			</div>
			<div className={s.main__btnsWrap}>
				<div
					className={
						s.btns +
						" scroll" +
						(btnsInRow > 0 ? ` ${s["btnsInRow" + btnsInRow]}` : "")
					}
				>
					{menu.buts?.map((btn, i) => (
						<div
							key={i}
							className={s.main__btnItemWrap}
						>
							<Input
								value={btn}
								name="text"
								placeholder=""
								onReset={true}
								className={s.input}
								label={`Кнопка ${i + 1}`}
								handleChange={(val) => changeMenu(`but_${i}`, val)}
							/>
							<button
								className={s.main__btnItemDel}
								onClick={() => delMenuBtn(i)}
							>
								{delIcon}
							</button>
						</div>
					))}
					<button
						onClick={() => changeMenu("addBut")}
						className={s.btnAdd}
					>
						Добавить кнопку
					</button>
				</div>
			</div>
			<div className={s.main__items}>
				{menuItems.map((item, i) => (
					<MenuItem
						key={i}
						num={i + 1}
						data={item}
						menuBtns={menu.buts}
						// delItem={delMenuItem}
						menuKeys={menu.buts || []}
						addAction={(e) => addAction(e, i)}
						changeData={(key, val, moduleIndex) =>
							changeMenuItems(i, key, val, moduleIndex)
						}
						delAction={(actIndex) => delAction(i, actIndex)}
						handleChoose={handleChoose}
						choosedActionList={choosedActionList}
					/>
				))}
			</div>
		</div>
	);
};

export default MenuMain;
