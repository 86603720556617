import s from "./Quizzes.module.scss";
import QuizRowBtns from "./QuizRowBtns";
import resetSvg from "../../imgs/reset";
import quizDel from "../../imgs/quizDel";
import copyIcon from "../../imgs/copyIcon";
import quizEdit from "../../imgs/quizEdit";
// import addImgIcon from '../../imgs/addImgIcon';
import Input from "../../components/common/Input";
import React, { useEffect, useState } from "react";
import InputImg from "../../components/common/InputImg";

const QuizQuestionsRow = ({
	index,
	quizList,
	questItem,
	delAnswer,
	handleBtnRow,
	openPopUpEdit,
	changeQuestItem,
	btnsList = [],
}) => {
	const [quizes, setQuizes] = useState([]);
	const [value, setValue] = useState(questItem.question);
	const [ballsValue, setBallsValue] = useState(questItem.balls);

	useEffect(() => {
		if (questItem.question !== value) {
			setValue(questItem.question);
		}
		if (questItem.balls !== ballsValue) {
			setBallsValue(questItem.balls);
		}
		// eslint-disable-next-line
	}, [questItem.num]);

	useEffect(() => {
		if (value !== questItem.question) {
			changeQuestItem({ ...questItem, question: value }, questItem.num);
		}
		// eslint-disable-next-line
	}, [value]);

	useEffect(() => {
		if (ballsValue !== questItem.balls) {
			changeQuestItem({ ...questItem, balls: ballsValue }, questItem.num);
		}
		// eslint-disable-next-line
	}, [ballsValue]);

	useEffect(() => {
		if (quizList) {
			const filteredArr = quizList.filter((quizItem) =>
				questItem.in_quizes.includes(quizItem.num)
			);
			const resArr = filteredArr.map((item) => item.name);
			setQuizes([...resArr]);
		}
		// eslint-disable-next-line
	}, []);

	const changeImg = (img) => {
		if (!img) return;
		changeQuestItem({ ...questItem, media: img }, questItem.num);
	};

	return (
		<li>
			<div className={s.inputLabel}>
				<label htmlFor="questValue">Вопрос {+index + 1}</label>
				<button className={"btnCopy"}>{copyIcon}</button>
				{quizes.length > 0 && (
					<ul className={s.notes}>
						<li className={s.title}>Квизы:</li>
						{quizes.map((quiz, index) => (
							<li
								key={index}
								className={s.item}
							>
								{quiz}
							</li>
						))}
					</ul>
				)}
			</div>
			<div className={s.textItem}>
				<div className={s.inputWrap}>
					<Input
						value={value}
						onReset={true}
						placeholder=""
						name={"questValue"}
						handleChange={(val) => setValue(val)}
					/>
				</div>
				<InputImg
					img={questItem.media}
					id={questItem.num}
					setImg={(img) => changeImg(img)}
				/>
				{/* <div className={s.inputImgWrap}>
          <input type="file" id={'file_input' + +questItem.num} name="img" />
          <label htmlFor={'file_input' + +questItem.num}></label>
          {questItem.media && <img src={questItem.media} alt="" />}
          {!questItem.media && addImgIcon}
        </div> */}
				<label
					htmlFor={"point_input" + questItem.num}
					className={s.inputPointsWrap}
				>
					Баллы:
					<input
						type="number"
						name="point_input"
						value={String(ballsValue).replace(/^0+/, "")}
						id={"point_input" + questItem.num}
						onChange={(e) => setBallsValue(+e.target.value)}
					/>
					<button
						className={s.inputPoints__reset}
						onClick={() => setBallsValue(0)}
					>
						{resetSvg}
					</button>
				</label>
				<ul className={"scroll " + s.btnRowList}>
					<QuizRowBtns
						btnsList={btnsList}
						questItem={questItem}
						rowId={questItem.num}
						handleClick={handleBtnRow}
					/>
				</ul>
			</div>
			<div className={s.textItem + " " + s.answerListWrap}>
				<div className={s.answerListTitle}>
					<span>Варианты ответа:</span>
					<button
						className={s.btnEdit}
						onClick={() => openPopUpEdit("all", questItem.num)}
					>
						редактировать все
					</button>
				</div>
				<div className={"scroll " + s.answerList}>
					{questItem.options.map((option, index) => (
						<div
							key={index}
							className={
								s.answer +
								(index === questItem.correct_option_id ? " " + s.active : "")
							}
						>
							<p className={s.answerText}>{option}</p>
							<button
								className={s.answerBtn}
								onClick={() => openPopUpEdit(index, questItem.num)}
							>
								{quizEdit}
							</button>
							<button
								className={s.answerBtn}
								onClick={() => delAnswer(index, questItem.num)}
							>
								{quizDel}
							</button>
						</div>
					))}
				</div>
			</div>
		</li>
	);
};

export default QuizQuestionsRow;
