const sorting = (list, keyName, sort) => {
  if (!keyName || !sort) {
    return list;
  }
  const tList = [...list];
  let key = '';
  switch (keyName) {
    case 'Анкета':
      key = 'anketa';
      break;
    case 'Кнопка':
    case 'Статус':
      key = 'collected';
      break;
    case 'Дата и время':
      key = 'datetime';
      break;
    case 'Дата':
      key = 'date';
      break;
    case 'Название':
      key = 'good';
      break;
    case 'Цена':
      key = 'price';
      break;
    case 'uuid':
      key = 'uuid';
      break;
    case 'tg':
      key = 'tg';
      break;
    case 'Время':
      key = 'time';
      break;
    case 'Всего':
      key = 'ini';
      break;
    case 'Осталось на складе':
      key = 'sklad';
      break;
    case 'Продано':
      key = 'sold';
      break;
    default:
      key = 'good';
      break;
  }
  return tList.sort((a, b) => {
    if (key === 'datetime') {
      const aDateArr = a.date.split('.');
      const bDateArr = b.date.split('.');
      const aTimeArr = a.time.split(':');
      const bTimeArr = b.time.split(':');

      const aDate = new Date(
        aDateArr[2] +
          '-' +
          aDateArr[1] +
          '-' +
          aDateArr[0] +
          'T' +
          aTimeArr[0] +
          ':' +
          aTimeArr[1] +
          ':' +
          aTimeArr[2]
      );
      const bDate = new Date(
        bDateArr[2] +
          '-' +
          bDateArr[1] +
          '-' +
          bDateArr[0] +
          'T' +
          bTimeArr[0] +
          ':' +
          bTimeArr[1] +
          ':' +
          bTimeArr[2]
      );
      if (sort === 1) {
        return +bDate - +aDate;
      } else {
        return +aDate - +bDate;
      }
    }
    if (key === 'date') {
      const aDateArr = a[key].split('.');
      const bDateArr = b[key].split('.');
      const aDate = new Date(
        aDateArr[2] + '-' + aDateArr[1] + '-' + aDateArr[0]
      );
      const bDate = new Date(
        bDateArr[2] + '-' + bDateArr[1] + '-' + bDateArr[0]
      );
      if (sort === 1) {
        return +bDate - +aDate;
      } else {
        return +aDate - +bDate;
      }
    }
    if (key === 'time') {
      const aDateArr = a[key].split(':');
      const bDateArr = b[key].split(':');
      const aDate = aDateArr[0] * 3600 + aDateArr[1] * 60 + aDateArr[1];
      const bDate = bDateArr[0] * 3600 + bDateArr[1] * 60 + bDateArr[1];
      if (sort === 1) {
        return +bDate - +aDate;
      } else {
        return +aDate - +bDate;
      }
    }
    if (['price', 'sold', 'sklad', 'ini'].includes(key)) {
      if (sort === 1) {
        return (+b[key] || 0) - (+a[key] || 0);
      } else {
        return (+a[key] || 0) - (+b[key] || 0);
      }
    }
    if (['good', 'tg', 'anketa', 'uuid', 'sku'].includes(key)) {
      const regExp = /[^a-zA-Zа-яА-ЯЕё0-9]+/g;
      const aStr = String(a[key]).replace(regExp, '');
      const bStr = String(b[key]).replace(regExp, '');
      if (sort === 1) {
        if (aStr.toLowerCase() < bStr.toLowerCase()) {
          return -1;
        }
        if (bStr.toLowerCase() > aStr.toLowerCase()) {
          return 1;
        }
        return 0;
      } else {
        if (aStr.toLowerCase() > bStr.toLowerCase()) {
          return -1;
        }
        if (bStr.toLowerCase() < aStr?.toLowerCase()) {
          return 1;
        }
        return 0;
      }
    }
    if (key === 'collected') {
      if (sort === 1) {
        if (a[key] < b[key]) {
          return -1;
        }
        if (b[key] > a[key]) {
          return 1;
        }
        return 0;
      } else {
        if (a[key] > b[key]) {
          return -1;
        }
        if (b[key] < a[key]) {
          return 1;
        }
        return 0;
      }
    }
    return 0;
  });
};

export default sorting;
