import React from 'react';
import s from '../Auth.module.scss';

const CheckList = () => {
  return (
    <div className={s.checkList__wrap}>
      <h2 className={s.checkList__title}>Воспользуйтесь нашим чек-листом </h2>
      <ul className={s.checkList__list}>
        <li className={s.checkList__item}>
          1 отправьте сообщение официальному боту в телеграмм: @BotFather или
          перейдите по{' '}
          <a href="https://t.me/BotFather" target="_blank" rel="noreferrer">
            ссылке
          </a>
        </li>
        <li className={s.checkList__item}>
          2 отправьте команду /newbot или выберите ее в выпадающем слева меню
        </li>
        <li className={s.checkList__item}>
          3 укажите имя бота, на русском или английском, например "Игровой
          помощник на мероприятии"
        </li>
        <li className={s.checkList__item}>
          4 укажите username или имя аккаунта бота. может быть только на
          английском, больше 5 символов и обязательно заканчиваться на bot,
          например, gameOn_bot
        </li>
        <li className={s.checkList__item}>
          5 найдите в сообщении текст после слов: Use this token to access the
          HTTP API: например:
          6755917287:AAH7Whgdfsgdzna9g1YnyrVxeZnukdRnX_cbynn30{' '}
        </li>
        <li className={s.checkList__item}>6 вставьте токен в поле выше</li>
      </ul>
      <a
        className={'btnDark ' + s.checkList__btn}
        href="https://t.me/BotFather"
        target="_blank"
        rel="noreferrer"
      >
        Написать в телеграм чат
      </a>
    </div>
  );
};

export default CheckList;
