import s from "./Common.module.scss";
import resetSvg from "../../imgs/reset";
import { useEffect, useRef } from "react";

const Textarea = ({
	id,
	label,
	val = "",
	className,
	handleChange,
	wrapClassName,
	onReset = false,
	placeholder = "",
}) => {
	// изменение высоты поля ввода в зависимости от содержимого
	const textareaCloneRef = useRef(null);
	useEffect(() => {
		if (!textareaCloneRef.current) return;
		const cloneTextareaHeight =
			textareaCloneRef.current.getBoundingClientRect().height;

		textareaCloneRef.current.parentNode.style.height =
			textareaCloneRef.current.nextElementSibling.style.height =
				cloneTextareaHeight + "px";
	}, [val]);

	return (
		<div
			className={
				s.textareaWrap + " " + className + (onReset ? " " + s.withReset : "")
			}
		>
			{label && <label htmlFor={id}>{label}</label>}
			<div className={s.textareaSubWrap + " " + wrapClassName}>
				<div
					ref={textareaCloneRef}
					className={s.textarea + " " + s.clone}
				>
					{val.replace(/\n/g, "\n ")}
				</div>
				<textarea
					id={id}
					value={val}
					className={s.textarea}
					placeholder={placeholder}
					onChange={(e) => handleChange(e.target.value)}
				></textarea>
				{onReset && (
					<button
						onClick={() => handleChange("")}
						className={s.input__reset}
					>
						{resetSvg}
					</button>
				)}
			</div>
		</div>
	);
};

export default Textarea;
