import { useSelector } from 'react-redux';
import AppAuthLogic from './AppAuthLogic';
import { Outlet } from 'react-router-dom';

function App() {
  const { isLoading } = useSelector((state) => state.auth.profileData);

  return (
    <>
      <AppAuthLogic />
      {isLoading ? <></> : <Outlet />}
    </>
  );
}

export default App;
