import s from './Shop.module.scss';
import Loader from '../../components/common/Loader';

const ShopStatisticTable = ({
  list,
  settings,
  loading,
  lightList,
  tableListRef,
  changeSettings,
}) => {
  const changeSort = (sortKey) => {
    if (settings.sort.key === sortKey) {
      if (settings.sort.direction === 'asc') {
        changeSettings('sort', { key: '', direction: '' });
      } else if (settings.sort.direction === 'desc') {
        changeSettings('sort', { key: sortKey, direction: 'asc' });
      } else {
        changeSettings('sort', { key: sortKey, direction: 'desc' });
      }
    } else {
      changeSettings('sort', { key: sortKey, direction: 'desc' });
    }
  };

  return (
    <>
      <div className={s.table + ' ' + (loading ? s.loading : '')}>
        {/* table head */}
        <div className={s.tableHead}>
          {['Название', 'Продано', 'Осталось на складе', 'Всего', 'sku'].map(
            (item, i) => (
              <button
                key={i}
                onClick={() => changeSort(item)}
                className={
                  'btnTransp ' +
                  (settings.sort.key === item ? s.active : '') +
                  ' ' +
                  (settings.sort.direction === 'asc' ? s.rotated : '')
                }
              >
                {item}
              </button>
            )
          )}
        </div>
        {/* table body */}
        <ul ref={tableListRef} className={s.tableList}>
          {list.map((item, i) => (
            <li
              className={
                s.tableRow +
                // (i + 1 === prodPerPage ? ' ' + s.lastRow : '') +
                (lightList.includes(item.uuid) ? ' ' + s.new : '')
              }
              key={i}
            >
              <p>{item.good}</p>
              <p>{item.sold}</p>
              <p>{item.sklad}</p>
              <p>{item.ini} </p>
              <p>{item.sku} </p>
            </li>
          ))}
          {!loading && !list.length && (
            <h3 className={s.emptyMes}>Нет записей</h3>
          )}
        </ul>
        {loading && <Loader />}
      </div>
    </>
  );
};

export default ShopStatisticTable;
