import Endpoints from "../endpoints";
import { axiosInstance } from "../instance";
// import defShopData from './defShopData.json';
// import goods from './goods.json';
// import goodsRes from './goodsRes.json';
import shopGoodsDef from "./shopGoodsDef.json";

const getShop = async (
	slug,
	filters,
	limit = 10,
	offset = 0,
	base = "",
	lastUUID = ""
) => {
	try {
		// return goods.result_log;
		const res = await axiosInstance.post(Endpoints.SHOP.GET, {
			filter: {
				...filters,
			},
			bot: {
				slag: slug,
				base: base || "",
			},
			limit: limit || 10,
			offset: offset || 0,
			lastUUID: lastUUID || "",
			aggragateResult: false,
		});
		if (res.data?.result_log) {
			return res.data.result_log;
		} else {
			throw new Error();
		}
	} catch (e) {
		console.log();
		return {
			error:
				e.response?.status === 200
					? "data not found"
					: "Не удалось получить данные о магазине",
		};
	}
};

const getGoods = async (project_name = "", author_id, curBot) => {
	try {
		const method = "saved";
		const tag_name = "latest";
		const res = await axiosInstance.get(
			Endpoints.SHOP.GET_GOODS +
				`/${method}/${project_name}?tagname=${tag_name}&author=${author_id}`
		);
		if (res.data) {
			if (res?.data?.data?.data) {
				return {
					...res.data.data.data,
					author: {
						account: author_id,
						date: "",
					},
					bot: {
						name: curBot.username,
						slag: project_name,
					},
				};
			} else {
				return {
					...shopGoodsDef,
					author: {
						account: author_id,
						date: "",
					},
					bot: {
						name: curBot.username,
						slag: project_name,
					},
				};
			}
		} else {
			throw new Error();
		}
	} catch (e) {
		// console.log({
		//   ...shopGoodsDef,
		//   bot: {
		//     name: curBot.username,
		//     slag: curBot.slag,
		//   },
		// });
		return {
			error:
				e.response?.status === 200
					? "data not found"
					: "Не удалось получить данные о магазине",
		};
	}
};

const saveGoods = async (project_name, bot_name, author_id, params) => {
	try {
		params.bot.name = bot_name;
		params.bot.slag = project_name;
		const method = "saved";
		const tag_name = "latest";
		const res = await axiosInstance.post(
			Endpoints.SHOP.GET_GOODS +
				`/${method}/${project_name}?tagname=${tag_name}&author=${author_id}`,
			params
		);
		// `/${method}/${bot_name}?ver_num=${ver_num}&tagname=${tag_name}`   -    пока без ver_num
		if (!res.data.error) {
			return res;
		} else {
			throw new Error();
		}
	} catch (e) {
		console.log(e);
		return { error: "Не удалось получить данные о квизах" };
	}
};

const publishGoods = async (params) => {
	try {
		const res = await axiosInstance.post(Endpoints.SHOP.PUBLISH, params);
		if (!res.data.error) {
			return res;
		} else {
			throw new Error();
		}
	} catch (e) {
		console.log(e);
		return { error: "Не удалось получить данные о квизах" };
	}
};

const getShopStatistic = async (
	slug,
	filters,
	limit = 10,
	offset = 0,
	base = "",
	lastUUID = ""
) => {
	try {
		// return goodsRes.result_log;
		const res = await axiosInstance.post(Endpoints.SHOP.GET, {
			filter: {
				...filters,
			},
			bot: {
				base: base || "",
				slag: slug,
			},
			limit: limit || 10,
			offset: offset || 0,
			lastUUID: lastUUID || "",
			aggragateResult: true,
		});
		if (res.data?.result_aggregate) {
			return res.data.result_aggregate;
		} else {
			throw new Error();
		}
	} catch (e) {
		console.log();
		return {
			error:
				e.response?.status === 200
					? "data not found"
					: "Не удалось получить данные о магазине",
		};
	}
};

const updateStatus = async (data) => {
	try {
		const res = await axiosInstance.put(Endpoints.SHOP.UPDATE_STATUS, data);
		if (res.data) {
			return res.data;
		} else {
			throw new Error();
		}
	} catch (e) {
		console.log(e);
		return { error: "Не удалось получить данные о квизах" };
	}
};

const updateExcel = async (data) => {
	try {
		const res = await axiosInstance.post(Endpoints.SHOP.UPDATE_EXCEL, data);
		if (res.data) {
			return res.data;
		} else {
			throw new Error();
		}
	} catch (e) {
		console.log(e);
		return { error: "Не удалось получить данные о квизах" };
	}
};

const deleteItem = async (data) => {
	try {
		const res = await axiosInstance.delete(Endpoints.SHOP.DELETE_ITEM, {
			data: data,
		});
		if (res.data?.resultDelete?.length) {
			return res.data.resultDelete[0];
		} else {
			throw new Error();
		}
	} catch (e) {
		console.log(e);
		return { error: "Не удалось удалить товар" };
	}
};

const shop = {
	getShop,
	getGoods,
	saveGoods,
	deleteItem,
	updateStatus,
	updateExcel,
	publishGoods,
	getShopStatistic,
};

export default shop;
