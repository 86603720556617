import PopUp from '../../components/common/PopUp';
import s from '../Controller/Controller.module.scss';

const PopupConfirmRebind = ({ bot, ok, close }) => {
  return (
    <PopUp close={close} isOpen={true}>
      <div className={s.popupConfirm}>
        <p className={s.title}>
          Бот <strong>{bot}</strong> будет отвязан от проекта first project и
          будет привзяан к проекту интеллектуальная игра
        </p>
        <ul className={s.btnWrap}>
          <button onClick={ok} className="btnLight">
            Сохранить
          </button>
          <button onClick={close} className="btnDark">
            Отмена
          </button>
        </ul>
      </div>
    </PopUp>
  );
};

export default PopupConfirmRebind;
