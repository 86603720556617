import { useState } from "react";
import s from "./Polls.module.scss";
import Textarea from "../../components/common/Textarea";
import InputImg from "../../components/common/InputImg";

const PollsTexts = ({ data, changeData }) => {
	return (
		<>
			<p className={s.subTitle}>Тексты</p>
			<div className={[s.row, s.text].join(" ")}>
				<Textarea
					id="startText"
					onReset={false}
					val={data.start.text}
					className={s.textarea}
					placeholder="Введите текст"
					label="Еще не проходил опрос"
					wrapClassName={s.textareaWrap}
					handleChange={(val) => changeData(val, "start", "text")}
				/>
				<InputImg
					id="startMedia"
					className={s.inputImg}
					img={data.start.media}
					setImg={(val) => changeData(val, "start", "media")}
				/>
			</div>
			<div className={[s.row, s.text].join(" ")}>
				<Textarea
					id="passed"
					onReset={false}
					val={data.already.text}
					className={s.textarea}
					label="Уже проходил опрос"
					placeholder="Введите текст"
					wrapClassName={s.textareaWrap}
					handleChange={(val) => changeData(val, "already", "text")}
				/>
				<InputImg
					id="alreadyMedia"
					className={s.inputImg}
					img={data.already.media}
					setImg={(val) => changeData(val, "already", "media")}
				/>
			</div>
			<div className={[s.row, s.text].join(" ")}>
				<Textarea
					onReset={false}
					id="after_answer_text"
					label="После ответа"
					className={s.textarea}
					placeholder="Введите текст"
					wrapClassName={s.textareaWrap}
					val={data.after_answer_text.text}
					handleChange={(val) => changeData(val, "after_answer_text", "text")}
				/>
				<InputImg
					id="afterAnswerTextImg"
					className={s.inputImg}
					img={data.after_answer_text.media}
					setImg={(val) => changeData(val, "after_answer_text", "media")}
				/>
			</div>
			<div className={[s.row, s.text].join(" ")}>
				<Textarea
					id="finalText"
					onReset={false}
					val={data.final.text}
					className={s.textarea}
					placeholder="Введите текст"
					label="Финал"
					wrapClassName={s.textareaWrap}
					handleChange={(val) => changeData(val, "final", "text")}
				/>
				<InputImg
					id="finalTextImg"
					img={data.final.media}
					className={s.inputImg}
					setImg={(val) => changeData(val, "final", "media")}
				/>
				<span className={s.inputSubTitle}>
					{`{$balls_itogo}, {$total_right}, {$total_answers}`}
				</span>
			</div>
			<div className={[s.row, s.text].join(" ")}>
				<Textarea
					id="time_out"
					onReset={false}
					val={data.time_out.text}
					className={s.textarea}
					placeholder="Введите текст"
					label="Вышло время на ответ"
					wrapClassName={s.textareaWrap}
					handleChange={(val) => changeData(val, "time_out", "text")}
				/>
				{/* <InputImg
					id="timeOutImg"
					className={s.inputImg}
					img={data.time_out.media}
					setImg={(val) => changeData(val, "time_out", "media")}
				/> */}
			</div>
			<div className={[s.row, s.text].join(" ")}>
				<Textarea
					id="isOff"
					onReset={false}
					val={data.off.text}
					label="Опрос выключен"
					className={s.textarea}
					placeholder="Введите текст"
					wrapClassName={s.textareaWrap}
					handleChange={(val) => changeData(val, "off", "text")}
				/>
				{/* <InputImg
					id="isOffImg"
					img={data.off.media}
					className={s.inputImg}
					setImg={(val) => changeData(val, "off", "media")}
				/> */}
			</div>
			<div className={[s.row, s.text].join(" ")}>
				<Textarea
					id="limitError"
					onReset={false}
					val={data.error_limit_text.text}
					className={s.textarea}
					label="ошибка лимита"
					placeholder="Введите текст"
					wrapClassName={s.textareaWrap}
					handleChange={(val) => changeData(val, "error_limit_text", "text")}
				/>
				{/* <InputImg
					id="limitErrorImg"
					className={s.inputImg}
					img={data.error_limit_text.media}
					setImg={(val) => changeData(val, "error_limit_text", "media")}
				/> */}
			</div>

			<div className={[s.row, s.text].join(" ")}>
				<Textarea
					id="sendText"
					label="вопрос"
					onReset={false}
					val={data.send.text}
					className={s.textarea}
					placeholder="Введите текст"
					wrapClassName={s.textareaWrap}
					handleChange={(val) => changeData(val, "send", "text")}
				/>
			</div>
		</>
	);
};

export default PollsTexts;
