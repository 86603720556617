import s from './Quizzes.module.scss';
import PopUp from '../../components/common/PopUp';
import PopupAddQuestFromDBRow from './PopupAddQuestFromDBRow';

const PopupAddQuestFromDB = ({
  close,
  isOpen,
  addQuest,
  questions,
  quizQuestList,
}) => {
  return (
    isOpen && (
      <PopUp close={close} isOpen={true} className={s.popupDB}>
        <h3 className={s.editPopUp__title}>
          Выберите вопросы из базы, которые хотите добавить в квиз
        </h3>
        <ul
          className={'scroll ' + s.editPopUp__inputList + ' ' + s.popupDB__list}
        >
          {questions?.map((quest, index) => (
            <PopupAddQuestFromDBRow
              key={index}
              index={index}
              quest={quest}
              addQuest={addQuest}
              isAdded={quizQuestList.find((q) => q.num === quest.num)}
            />
          ))}
        </ul>
      </PopUp>
    )
  );
};

export default PopupAddQuestFromDB;
