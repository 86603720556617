import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
  curBot: {
    name: '',
    username: '',
  },
  errors: {},
  isLoaded: false,
  isLoading: true,
  isDeleted: false,
  isAdded: false,
};

export const botReducer = createSlice({
  name: 'bot',
  initialState,
  reducers: {
    clearBot: () => ({ ...initialState }),
    setBotSuccess: (state, action) => ({
      ...state,
      isLoading: false,
      curBot: action.payload,
    }),
    // add bot
    addBotStart: (state) => ({
      ...state,
      errors: {},
      isLoading: true,
    }),
    addBotSuccess: (state, action) => ({
      ...state,
      isAdded: true,
      isLoading: false,
      curBot: action.payload,
      list: [...state.list, action.payload],
    }),
    addBotFailure: (state, action) => ({
      ...state,
      isLoading: false,
      errors: { ...state.errors, addBot: action.payload },
    }),
    // del bot
    delBotStart: (state) => ({
      ...state,
      errors: {},
      isLoading: true,
    }),
    delBotSuccess: (state) => ({
      ...state,
      list: [],
      errors: {},
      curBot: {
        name: '',
        username: '',
      },
      isDeleted: true,
      isLoading: false,
    }),
    delBotFailure: (state, action) => ({
      ...state,
      isDeleted: false,
      isLoading: false,
      errors: { ...state.errors, delBot: action.payload },
    }),
    // clearBotErrorsSuccess
    clearBotErrorsSuccess: (state) => ({
      ...state,
      errors: {},
      isAdded: false,
      isDeleted: false,
      isLoading: false,
    }),
    // My Bots
    loadMyBotsStart: (state) => ({
      ...state,
      errors: {},
      isLoading: true,
      isDeleted: false,
    }),
    loadMyBotsSuccess: (state, action) => ({
      ...state,
      errors: {},
      isLoading: false,
      list: action.payload,
    }),
    loadMyBotsFailure: (state, action) => ({
      ...state,
      isLoading: false,
      errors: { ...state.errors, myBots: action.payload },
    }),

    setBotListSuccess: (state, action) => ({
      ...state,
      list: action.payload,
    }),
    //

    // My Bot Info - Token
    getBotInfoStart: (state) => ({
      ...state,
      errors: {},
      // isLoaded: false,
      isLoading: true,
    }),
    getBotInfoSuccess: (state, action) => ({
      ...state,
      errors: {},
      isLoaded: true,
      isLoading: false,
      curBot: { ...state.curBot, ...action.payload, name: state.curBot.name },
    }),
    getBotInfoFailure: (state, action) => ({
      ...state,
      isLoaded: true,
      isLoading: false,
      errors: { ...state.errors, myBots: action.payload },
    }),

    setBotIsLoaded: (state) => ({
      ...state,
      isLoaded: true,
    }),
  },
});

export const {
  clearBot,
  setBotSuccess,
  loadMyBotsStart,
  loadMyBotsSuccess,
  loadMyBotsFailure,
  addBotStart,
  addBotFailure,
  addBotSuccess,
  delBotStart,
  delBotSuccess,
  delBotFailure,
  clearBotErrorsSuccess,
  setBotListSuccess,
  //
  getBotInfoStart,
  getBotInfoSuccess,
  getBotInfoFailure,

  setBotIsLoaded,
} = botReducer.actions;
export default botReducer.reducer;
