import React from 'react';
import s from './Controller.module.scss';

const ActivityColumn = ({ item }) => {
  return (
    <div className={s.activity__column__wrap}>
      {item.value && (
        <div
          className={s.activity__column}
          style={{ height: `${+item.value * 2 + 15}px` }}
        >
          <div className={s.activity__column__valueWrap}>
            <div className={s.activity__column__valueSubWrap}>
              <span className={s.activity__column__value}>{item.value}</span>
            </div>
          </div>
        </div>
      )}
      <p className={s.activity__column__name}>{item.name}</p>
    </div>
  );
};

export default ActivityColumn;
