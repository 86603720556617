const copy = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.4 6.4V4.6C6.4 2.61177 8.01177 1 10 1L15.4 1C17.3882 1 19 2.61177 19 4.6V10C19 11.9882 17.3882 13.6 15.4 13.6H13.6M6.4 6.4H4.6C2.61177 6.4 1 8.01177 1 10V15.4C1 17.3882 2.61177 19 4.6 19H10C11.9882 19 13.6 17.3882 13.6 15.4V13.6M6.4 6.4H10C11.9882 6.4 13.6 8.01177 13.6 10V13.6"
      stroke="#777A96"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
);

export default copy;
