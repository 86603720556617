import * as auth from "./auth";
import * as main from "./main";
import * as bot from "./bot";
import survey from "./survey";
import polls from "./polls";
import menu from "./menu";
import shop from "./shop";

const api = {
	auth,
	main,
	bot,
	survey,
	menu,
	shop,
	polls,
};

export default api;
