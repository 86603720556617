import s from './Auth.module.scss';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Input from '../../components/common/Input';
import CheckList from './components/CheckList';
import { useNavigate, useOutletContext } from 'react-router-dom';

const FormToken = () => {
  const [sendToken, clearError, error] = useOutletContext();
  const [token, setToken] = useState('');
  const navigate = useNavigate();
  const isLoading = useSelector((state) => state.bot.isLoading);

  const handleChange = (val) => {
    setToken(val);
    clearError('token');
  };

  const sendLater = () => {
    navigate('/');
  };

  const handleClick = (e) => {
    if (e) e.preventDefault();
    sendToken(token);
  };

  return (
    <>
      <h1 className={s.title}>
        Поздравляем. Вы прямо сейчас начнете изучать геймификацию и настраивать
        ваш первый проект
      </h1>
      <p className={s.text}>
        Для создания телеграм бота вам нужно зарегистрировать его в мессенджере
        телеграм и сообщить нам токен
      </p>
      <form onSubmit={handleClick}>
        <div className={s.inputWrap}>
          <Input
            name="token"
            value={token}
            label="Токен"
            error={error}
            handleChange={handleChange}
            placeholder={'Введите токен'}
          />
          <div className={s.tokenBtns}>
            <button
              onClick={handleClick}
              className={'btnLight ' + s.btnForm}
              disabled={!token || error || isLoading}
            >
              <span>{!isLoading ? 'Сохранить' : 'Сохранение...'}</span>
            </button>
            <button
              onClick={() => sendLater(token)}
              className={'btnLight ' + s.btnForm + ' ' + s.light}
            >
              <span>Заполнить позже</span>
            </button>
          </div>
        </div>
      </form>
      <span className={s.btnLink}>Не понятно или не получается?</span>
      <CheckList />
    </>
  );
};

export default FormToken;
