import React from "react";
import copy from "../../imgs/copy";
import s from "./Polls.module.scss";
import quizDel from "../../imgs/quizDel";

const PollsHeaderItem = ({ item, handleClick, delItem, isActive = false }) => {
	return (
		<li
			onClick={() => handleClick(item)}
			className={s.headerItem + " " + (isActive ? s.active : "")}
		>
			<div className={s.headerItem__top}>
				<p>{item.name}</p>
				<div className={s.btns}>
					<button
						onClick={(e) => {
							e.stopPropagation();
							delItem(item);
						}}
						className={s.headerItem__btnDel}
					>
						{quizDel}
					</button>
				</div>
			</div>
		</li>
	);
};

export default PollsHeaderItem;
