import {
  loginUser,
  getTgLink,
  sendEmail as sendEmailAct,
} from '../../store/auth/actionCreators';
import s from './Auth.module.scss';
import logoImg from '../../imgs/logo.svg';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../store';
import Cookie from './components/Cookie';
import React, { useEffect, useState } from 'react';
import Loader from '../../components/common/Loader';
import { getTree } from '../../store/main/actionCreators';
import ProgressBar from './components/ProgressBar';
// import { checkTgAccess } from '../../store/auth/actionCreators';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { addBot, clearBotErrors } from '../../store/bot/actionCreators';

const Auth = () => {
  const dispatch = useAppDispatch();
  const [errors, setErrors] = useState({
    tg: '',
    code: '',
    email: '',
    token: '',
  });
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const curLocation = useLocation().pathname;
  const [progress, setProgress] = useState(0);
  const botData = useSelector((state) => state.bot);
  const [optionValue, setOptionValue] = useState(''); // email or tg name
  const [formOption, setFormOption] = useState(''); // email | tg
  const [codeValue, setCodeValue] = useState(''); // code value
  const [codeIsValid, setCodeIsValid] = useState(''); // code is valid
  const [, setTokenValue] = useState(false); // tokenValue,
  const authData = useSelector((state) => state.auth.authData);
  const { user_id } = useSelector((state) => state.auth.profileData);
  const tree = useSelector((state) => state.main.tree);
  const treeLoading = useSelector((state) => state.main.isLoading);
  const [botLoading, setBotLoading] = useState(false);
  const { curProject, curCompany } = useSelector((state) => state.main);
  // const state = useSelector((state) => state);

  // const checkAccess = (tgToken) => {
  //   if (!tgToken) return;
  //   dispatch(checkTgAccess({ token: tgToken }));
  // };

  useEffect(() => {
    // const tgToken = localStorage.getItem('tgToken');
    // if (tgToken) {
    //   const tokenData = JSON.parse(tgToken);
    //   if (tokenData.token){
    //     checkAccess(tokenData.token);
    //   }
    //   localStorage.removeItem('tgToken');
    // }
  }, []);

  useEffect(() => {
    if (tree.length) {
      setBotLoading(true);
      for (let company of tree) {
        if (company.project.length) {
          for (let project of company.project) {
            if (project.bots.length) {
              for (let bot of project.bots) {
                if (bot.name) {
                  navigate('/');
                }
              }
            }
          }
        }
      }
    }
    setBotLoading(false);
    // eslint-disable-next-line
  }, [tree]);

  useEffect(() => {
    if (authData.email) {
      setOptionValue(authData.email);
      navigate('/auth/keyCheck');
    }
    // eslint-disable-next-line
  }, [authData.email]);

  useEffect(() => {
    if (authData.accessToken) {
      setCodeIsValid(true);
      // navigate('/auth/token');
    }
    // eslint-disable-next-line
  }, [authData.accessToken]);

  useEffect(() => {
    if (authData.error) {
      if (authData.error.email) {
        setErrors((prevState) => ({
          ...prevState,
          email: authData.error.email,
        }));
      }
      if (authData.error.code) {
        setErrors((prevState) => ({
          ...prevState,
          code: authData.error.code,
        }));
      }
    }
  }, [authData.error]);

  useEffect(() => {
    switch (curLocation) {
      // 1 step
      case '/auth':
        setProgress(0);
        setStep(1);
        break;
      // 2 step
      case '/auth/tg':
      case '/auth/email':
        if (!formOption) {
          navigate('/auth');
        }
        setProgress(33);
        setStep(2);
        break;
      // 3 step
      case '/auth/keyCheck':
        if (!optionValue) {
          if (formOption === 'tg') {
            navigate('/auth/tg');
          } else if (formOption === 'email') {
            navigate('/auth/email');
          } else {
            navigate('/auth');
          }
        }
        setProgress(66);
        setStep(3);
        break;
      // 4 step
      case '/auth/token':
        if (!authData.accessToken || !codeIsValid) {
          navigate('/auth/keyCheck');
        }
        setProgress(100);
        setStep(4);
        break;
      default:
        return navigate('/auth');
    }
    // eslint-disable-next-line
  }, [curLocation, formOption, optionValue, codeIsValid]);

  const chooseOption = (option) => {
    if (option === 'tg') {
      dispatch(getTgLink());
    }
    setFormOption(option);
    navigate(`/auth/${option}`);
  };

  const sendEmail = (email) => {
    // clear ahead values
    setCodeValue('');
    setOptionValue('');
    setTokenValue(false);
    if (/\S+@\S+\.\S+/.test(email) && !email.includes('=')) {
      dispatch(sendEmailAct({ email }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        email: 'Адрес электронной почты введен неправильно!',
      }));
    }
  };

  const resendEmail = (email) => {
    dispatch(sendEmailAct({ email }));
  };

  const sendCode = (code) => {
    // clear ahead values
    setTokenValue(false);
    setCodeValue(code);
    dispatch(loginUser({ email: authData.email, code }));
  };

  const sendToken = async (token) => {
    if (!token) {
      setErrors((prevState) => ({ ...prevState, token: 'Заполните поле' }));
    } else {
      if (curCompany.name && curProject.name) {
        dispatch(
          addBot({
            token,
            user_id,
            company: curCompany.name,
            project: curProject.name,
          })
        );
      } else {
        dispatch(
          addBot({
            token,
            user_id,
          })
        );
      }
      // const res = await addBotApi(token, user_id);
      // if (res.error)
      //   setErrors((prevState) => ({ ...prevState, token: res.error }));
      // if (res.data) {
      //   dispatch(setCurBotData(res.data));
      //   // !! временно
      //   // const data = await getBotApi(token);
      //   // const updatedRes = {
      //   //   ...data,
      //   //   token: data.token,
      //   //   name: data.first_name,
      //   // };
      //   // dispatch(setCurBotData(updatedRes));
      //   // !!
      //   navigate('/bot-edit');
      // }
    }
  };

  const changeFormOption = () => {
    setOptionValue('');
    if (formOption === 'tg') {
      setFormOption('email');
      navigate('/auth/email');
    } else if (formOption === 'email') {
      setFormOption('tg');
      navigate('/auth/tg');
      dispatch(getTgLink());
    } else {
      navigate('/auth');
      setFormOption(null);
    }
  };

  const clearError = (key) => {
    switch (key) {
      case 'code':
        setErrors((prevState) => ({ ...prevState, code: '' }));
        break;
      case 'email':
        setErrors((prevState) => ({ ...prevState, email: '' }));
        break;
      case 'tg':
        setErrors((prevState) => ({ ...prevState, tg: '' }));
        break;
      case 'token':
        setErrors((prevState) => ({ ...prevState, token: '' }));
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    if (botData.isAdded) {
      dispatch(getTree());
      dispatch(clearBotErrors());
      navigate('/bot-edit');
    }
    if (botData?.errors?.addBot) {
      setErrors({ token: botData.errors.addBot });
    }
    // eslint-disable-next-line
  }, [botData]);

  // useEffect(() => {
  //   return () => {
  //     dispatch(clearBotErrors());
  //   };
  // }, []);

  const content = () => {
    switch (curLocation) {
      case '/auth/email':
        return (
          <Outlet
            context={[
              changeFormOption,
              sendEmail,
              errors.email,
              clearError,
              optionValue,
            ]}
          />
        );
      case '/auth/tg':
        return (
          <Outlet
            context={[
              changeFormOption,
              // sendTg,
              // clearError,
              // errors.tg,
              // optionValue,
            ]}
          />
        );
      case '/auth/keyCheck':
        return (
          <Outlet
            context={[
              optionValue,
              errors.code,
              sendCode,
              clearError,
              changeFormOption,
              codeValue,
              resendEmail,
            ]}
          />
        );
      case '/auth/token':
        return <Outlet context={[sendToken, clearError, errors.token]} />;

      case '/auth':
        return <Outlet context={[chooseOption]} />;
      default:
        setTimeout(() => {
          navigate('/auth');
        }, 1);
    }
  };

  return (
    <main className={s.main}>
      {treeLoading || botLoading ? (
        <Loader />
      ) : (
        <>
          <div className={s.wrapper}>
            <ProgressBar
              percent={progress}
              text={
                step >= 4
                  ? 'Завершите этот последний шаг чтобы начать получать результат'
                  : `Шаг ${step} из 3 для получения результата`
              }
            />
            <div className={s.logoWrap}>
              <img src={logoImg} alt="logo" />
            </div>
            {content()}
          </div>
          <Cookie />
        </>
      )}
    </main>
  );
};

export default Auth;
