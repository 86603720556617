import { useEffect } from 'react';
import { useAppDispatch } from './store';
import { useSelector } from 'react-redux';
import { getBotInfo, setBotList, setCurBot } from './store/bot/actionCreators';
import { getProfile } from './store/auth/actionCreators';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getTree,
  setCompanyList,
  setCurCompany,
  setCurProject,
  setLastShopTab,
  setProjectList,
} from './store/main/actionCreators';
import { getBotApi } from './api/bot';

const AppAuthLogic = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const botLoading = useSelector((state) => state.bot.isLoading);
  const { tree, mainLoading } = useSelector((state) => state.main);
  const updatingTree = useSelector((state) => state.main.updatingTree);

  const botList = useSelector((state) => state.bot.list);
  const companyList = useSelector((state) => state.main.companyList);
  const projectList = useSelector((state) => state.main.projectList);

  const curLocation = useLocation().pathname;
  const curBot = useSelector((state) => state.bot.curBot);
  const curCompany = useSelector((state) => state.main.curCompany);
  const curProject = useSelector((state) => state.main.curProject);

  const { authorized, isLoading } = useSelector(
    (state) => state.auth.profileData
  );
  // const bot = useSelector((state) => state.bot);

  // Step 1 - getting profile

  useEffect(() => {
    // console.log('Step 1');
    // for Shop Page
    const lastShopTabHrefLS = localStorage.getItem('lastShopTab');
    if (lastShopTabHrefLS) {
      dispatch(setLastShopTab(JSON.parse(lastShopTabHrefLS)));
    }

    dispatch(getProfile());
    // eslint-disable-next-line
  }, []);

  // Step 2 - initial redirect (if necessary)

  useEffect(() => {
    if (!isLoading) {
      // console.log('Step 2');
      if (!authorized && !/\/auth/.test(curLocation)) {
        if (/\/share/.test(curLocation)) {
          localStorage.setItem('preAuthLocation', curLocation);
        }
        return navigate('/auth');
      } else if (authorized) {
        const preAuthLocation = localStorage.getItem('preAuthLocation');
        if (preAuthLocation) {
          navigate(preAuthLocation);
          localStorage.removeItem('preAuthLocation');
          return;
        } else if (/\/auth/.test(curLocation)) {
          return navigate('/auth/token');
        }
      }
    }
  }, [curLocation, authorized, navigate, isLoading]);

  // Step 3 - getting tree if user authorized

  useEffect(() => {
    if (!isLoading && authorized) {
      // console.log('Step 3');
      dispatch(getTree());
    }
    // eslint-disable-next-line
  }, [authorized]);

  // Step 4 - getting tree if user authorized

  useEffect(() => {
    if (curBot.username && !curBot.token) {
      // console.log('Step 4');
      dispatch(getBotInfo({ bot: { name: curBot.username } }));
    }
    // eslint-disable-next-line
  }, [curBot]);

  // Step 5 - setting main users lists

  function updateCompanyList(tree) {
    // console.log('Step 5 - updateCompanyList');
    if (tree?.length) {
      const treeCompany = [
        ...tree.map((curCompany) => ({
          name: curCompany.name,
          title: curCompany.title,
        })),
      ];
      if (JSON.stringify(companyList) !== JSON.stringify(treeCompany))
        dispatch(setCompanyList(treeCompany));
    } else {
      if (botList.length) dispatch(setBotList([]));
      if (companyList.length) dispatch(setCompanyList([]));
      if (projectList.length) dispatch(setProjectList([]));
    }
  }

  function updateProjectList(tree, curCompany) {
    if (tree?.length && curCompany.name) {
      // console.log('Step 5 - updateProjectList');
      const companyIndex = tree.findIndex(
        (item) => item.name === curCompany.name
      );
      if (companyIndex < 0) return;
      if (tree[companyIndex].project) {
        const treeProjectList = [
          ...tree[companyIndex].project
            .filter((proj) => proj.name)
            .map((curProject) => ({
              name: curProject.name,
              title: curProject.title,
            })),
        ];
        if (JSON.stringify(treeProjectList) !== JSON.stringify(projectList))
          dispatch(setProjectList(treeProjectList));
      } else {
        if (projectList.length) dispatch(setProjectList([]));
      }
    } else {
      if (projectList.length) dispatch(setProjectList([]));
      dispatch(setBotList([]));
    }
  }

  function updateBotList(tree, curCompany, curProject) {
    if (tree?.length && curCompany.name && curProject.name) {
      // console.log('Step 5 - updateBotList');
      const companyIndex = tree.findIndex(
        (item) => item.name === curCompany.name
      );
      if (companyIndex < 0) return;

      if (!tree[companyIndex].project) {
        return dispatch(setBotList([]));
      }

      const projectIndex = tree[companyIndex].project.findIndex(
        (item) => item.name === curProject.name
      );

      if (projectIndex < 0) return;
      dispatch(
        setBotList([
          ...tree[companyIndex].project[projectIndex].bots
            .filter((bot) => bot.name)
            .map((curCompany) => ({
              name: curCompany.name,
              title: curCompany.title,
            })),
        ])
      );
    } else {
      dispatch(setBotList([]));
    }
  }

  useEffect(() => {
    if (mainLoading) return;
    updateCompanyList(tree, curCompany);
  }, [tree, mainLoading]);

  useEffect(() => {
    if (mainLoading) return;
    updateProjectList(tree, curCompany);
  }, [tree, curCompany, mainLoading]);

  useEffect(() => {
    if (mainLoading) return;
    updateBotList(tree, curCompany, curProject);
  }, [tree, curCompany, curProject, mainLoading]);

  // Step 6 - setting cur company / project / bot

  const updateCurCompany = (companyList) => {
    console.log('Step 6 - updateCurCompany');
    if (companyList.length > 0) {
      if (companyList[0].name) {
        const curCompanyLS = localStorage.getItem('curCompany')
          ? JSON.parse(localStorage.getItem('curCompany'))
          : '';
        if (curCompanyLS?.name) {
          const indexCompany = companyList.findIndex(
            (item) => item.name === curCompanyLS.name
          );
          if (indexCompany >= 0) {
            dispatch(setCurCompany(companyList[indexCompany]));
          } else {
            dispatch(setCurCompany(companyList[0]));
          }
        } else {
          dispatch(setCurCompany(companyList[0]));
        }
      } else {
        dispatch(setCurCompany({}));
      }
    } else {
      if (!companyList.length && curCompany.name) {
        dispatch(setCurCompany({}));
      }
    }
  };

  const undateCurProject = (projectList, curProject) => {
    // console.log('Step 6 - undateCurProject');
    if (
      projectList.length > 0 &&
      (!curProject.name ||
        !projectList.find((proj) => proj.name === curProject.name))
    ) {
      if (projectList[0].name) {
        const curProjectLS = localStorage.getItem('curProject')
          ? JSON.parse(localStorage.getItem('curProject'))
          : '';
        if (curProjectLS?.name) {
          const indexProject = projectList.findIndex(
            (item) => item.name === curProjectLS.name
          );
          if (indexProject >= 0) {
            dispatch(setCurProject(projectList[indexProject]));
          } else {
            dispatch(setCurProject(projectList[0]));
          }
        } else {
          dispatch(setCurProject(projectList[0]));
        }
      } else {
        curProject.name && dispatch(setCurProject({}));
      }
    } else {
      if (!projectList.length && curProject.name) {
        dispatch(setCurProject({}));
      }
    }
  };

  const undateCurBot = (botList, curBot) => {
    // console.log('Step 6 - undateCurBot');
    // установка бота
    if (botList.length > 0 && curBot?.username !== botList[0].name) {
      if (botList[0].name) {
        const curBotLS = localStorage.getItem('curBot')
          ? JSON.parse(localStorage.getItem('curBot'))
          : '';
        if (curBotLS?.name) {
          const indexBot = botList.findIndex(
            (item) => item.name === curBotLS.name
          );
          if (indexBot >= 0) {
            selectBot(botList[indexBot]);
            selectBot(botList[0]);
          } else {
            selectBot(botList[0]);
          }
        } else {
          selectBot(botList[0]);
        }
        // selectBot(botList[0]);
      } else curBot.name && dispatch(setCurBot({ name: '', username: '' }));
    } else if (!botList.length && curBot?.username) {
      dispatch(setCurBot({ name: '', username: '' }));
    } else if (botList.length && curBot?.username) {
      if (!botList[0].name) {
        dispatch(setCurBot({ name: '', username: '' }));
      }
    } else if (botLoading && !botList.length) {
      dispatch(setCurBot({ name: '', username: '' }));
    }
  };

  useEffect(() => {
    if (updatingTree || mainLoading) return;
    updateCurCompany(companyList);
    // eslint-disable-next-line
  }, [companyList, updatingTree, mainLoading]);

  useEffect(() => {
    if (updatingTree || mainLoading) return;
    undateCurProject(projectList, curProject);
    // eslint-disable-next-line
  }, [projectList, curProject, updatingTree, mainLoading]);

  useEffect(() => {
    if (updatingTree || mainLoading) return;
    undateCurBot(botList, curBot);
    // eslint-disable-next-line
  }, [botList, curBot, updatingTree, mainLoading]);

  // Step 7 - setting additional user data

  useEffect(() => {
    if (curBot.token && !curBot.username) {
      loadingUsername(); //для только что добавленного бота
    }
    // eslint-disable-next-line
  }, [curBot]);

  async function loadingUsername() {
    const data = await getBotApi(curBot.token);
    if (!data) return;
    const botData = { ...data, ...curBot, name: data.first_name };
    dispatch(setCurBot(botData));
  }

  // common functions

  async function selectBot(bot) {
    if (bot.token) {
      const data = await getBotApi(bot.token);
      const botData = { ...data, name: data.first_name, token: bot.token };
      dispatch(setCurBot(botData));
    } else {
      dispatch(setCurBot({ name: bot.title, username: bot.name }));
    }
  }

  return <></>;
};

export default AppAuthLogic;
