export default (
  <svg
    width="11"
    height="6"
    viewBox="0 0 11 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.157228 5.24409C0.404734 5.53813 0.856181 5.5858 1.16556 5.35057L5.5 2.05496L9.83444 5.35057C10.1438 5.5858 10.5953 5.53813 10.8428 5.24409C11.0903 4.95005 11.0401 4.52098 10.7307 4.28575L5.94815 0.649407C5.68614 0.450198 5.31385 0.450198 5.05185 0.649407L0.269266 4.28575C-0.0401164 4.52098 -0.0902772 4.95005 0.157228 5.24409Z"
      fill="white"
    />
  </svg>
);
