import api from "../../api";
import { useState } from "react";
import s from "./Menu.module.scss";
import { useSelector } from "react-redux";
import PopUp from "../../components/common/PopUp";
import Checkbox from "../../components/common/Checkbox";

const MenuUpdatingPopup = ({ close, menuNum }) => {
	const [params, setParams] = useState({
		is_delete: false,
		start_menu_after: false,
	});
	const [btnUpdate, setBtnUpdate] = useState("Обновить");
	const curBot = useSelector((state) => state.bot.curBot);
	const { curProject } = useSelector((state) => state.main);
	const { user_id } = useSelector((state) => state.auth.profileData);

	const update = async () => {
		setBtnUpdate("Обновление...");
		if (curBot.username && curProject.name && user_id && menuNum) {
			const body = {
				schema: "",
				description: "",
				version: "1.0",
				modul: "update menu",
				author: { account: user_id },
				bot: {
					name: curBot.username,
					slag: curProject.name,
				},
				data: {
					menu_number: menuNum,
					is_delete: params.is_delete,
					start_menu_after: params.start_menu_after,
				},
			};
			const resp = await api.menu.updateMenu(body);
			if (resp.data?.result) {
				setBtnUpdate("Обновлено");
			} else {
				setBtnUpdate("Ошибка");
			}
		} else {
			setBtnUpdate("Ошибка");
			console.log("no required parameters bot/slag/menu_number");
		}
		setTimeout(() => {
			setBtnUpdate("Обновить");
		}, 2000);
	};

	return (
		<PopUp
			close={close}
			isOpen={true}
			className={s.popupUpdatings}
			contentClassName={s.popupUpdatingsContent}
		>
			<h2 className={s.title}>Обновление меню</h2>

			<Checkbox
				className={s.checkbox}
				label={"отправить меню после обновления"}
				isOn={params.start_menu_after}
				handleClick={(e) => {
					setParams((prev) => ({
						...prev,
						start_menu_after: !prev.start_menu_after,
					}));
				}}
			/>
			<Checkbox
				className={s.checkbox}
				label={"удалить пункты меню перед обновлением"}
				isOn={params.is_delete}
				handleClick={(e) => {
					setParams((prev) => ({
						...prev,
						is_delete: !prev.is_delete,
					}));
				}}
			/>

			<div className={s.btns}>
				<button
					disabled={btnUpdate !== "Обновить"}
					onClick={update}
					className={"btnLight " + s.btnSave}
				>
					{btnUpdate}
				</button>
				<button
					onClick={close}
					className={"btnDark " + s.btnCancel}
				>
					Отмена
				</button>
			</div>
		</PopUp>
	);
};

export default MenuUpdatingPopup;
