// import logger from 'redux-logger';
import botReducer from './bot/botReducer';
import { useDispatch } from 'react-redux';
import authReducer from './auth/authReducer';
import mainReducer from './main/mainReducer';
import { configureStore } from '@reduxjs/toolkit';

export const store = configureStore({
  reducer: {
    bot: botReducer,
    auth: authReducer,
    main: mainReducer,
  },
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware()
  //   .concat(
  //     ...(process.env.NODE_ENV !== 'production' ? [logger] : [])
  //   ),
});

export const useAppDispatch = useDispatch;
