import React from 'react';
import s from '../styles/Error.module.scss';

const ErrorPage = () => {
  return (
    <div className={s.error__wrap}>
      <i className={s.error__img}></i>
      <h1 className={s.error__title}>Упс, что-то пошло не так...</h1>
      <p className={s.error__text}>Попробуйте перезагрузить страницу</p>
    </div>
  );
};

export default ErrorPage;
