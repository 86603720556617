import React from 'react';
import s from './Controller.module.scss';

const Service = ({
  name,
  icon,
  status,
  controlImg,
  controlName,
  handleClick,
}) => {
  return (
    <li className={s.controller__service}>
      <img className={s.controller__service__icon} src={icon} alt="" />
      <span className={s.controller__service__name}>{name}</span>
      <span
        className={
          s.controller__service__status +
          ' ' +
          (status.value ? s.success : s.fail)
        }
      >
        {status.name}
      </span>
      <div className={s.controller__service__aggregate}></div>
      {!!controlImg && (
        <button
          onClick={handleClick}
          className={s.controller__service__btn + ' ' + s.refresh}
        >
          {controlImg}
          {/* <img src={controlImg} alt="" /> */}
        </button>
      )}
      {!!controlName && (
        <button
          onClick={handleClick}
          className={s.controller__service__btn + ' ' + s.tune}
        >
          {controlName}
        </button>
      )}
    </li>
  );
};

export default Service;
