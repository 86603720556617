const checkSpelling = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.78189 10.6667H9.40993L5.42941 0H3.98053L0 10.6667H1.62804L2.50049 8.20513H6.89387L7.78189 10.6667ZM3.0925 6.5641L4.70497 2.03487L6.31743 6.5641H3.0925ZM14.9017 7.04821L8.5998 13.6862L5.74099 10.6667L4.64265 11.8236L8.60759 16L16 8.20513L14.9017 7.04821Z"
      fill="white"
    />
  </svg>
);

export default checkSpelling;
