import s from './StartModules.module.scss';
import { useEffect, useState } from 'react';
import InputImg from '../../components/common/InputImg';
import PopUp from '../../components/common/PopUp';

const EditGreetingPopup = ({ close, module, save }) => {
  const [greetingData, setGreetingData] = useState(module);

  useEffect(() => {
    if (!module) {
      close();
    }
    // eslint-disable-next-line
  }, [module]);

  const handleSave = () => {
    save(greetingData);
    close();
  };

  const handleChange = (e) => {
    setGreetingData((prev) => ({ ...prev, text: e.target.value }));
  };

  const handleChangeImg = (img) => {
    setGreetingData((prev) => ({ ...prev, media: img }));
  };

  return (
    greetingData && (
      <PopUp close={close} isOpen={true}>
        <div className={s.popupEditGreeting}>
          <h3 className={s.title}>Редактировать приветствие</h3>
          <label className={s.inputImgWrap}>
            <InputImg
              className={s.inputImg}
              img={greetingData.media}
              setImg={handleChangeImg}
              id={greetingData.form_name_media}
            />
            {!greetingData.media ? (
              <p>Добавить изображение</p>
            ) : (
              <p>Изменить изображение</p>
            )}
          </label>
          <div className={s.textareaWrap}>
            <label htmlFor="greetingText">текст приветствия</label>
            <div className={s.textareaSubWrap}>
              <textarea
                value={greetingData.text || ''}
                onChange={handleChange}
                className="scroll"
                id="greetingText"
              ></textarea>
            </div>
          </div>
          <div className={s.btns}>
            <button onClick={handleSave} className="btnLight">
              Сохранить
            </button>
            <button onClick={close} className="btnDark">
              Отмена
            </button>
          </div>
        </div>
      </PopUp>
    )
  );
};

export default EditGreetingPopup;
