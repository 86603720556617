import Endpoints from '../endpoints';
import { axiosInstance } from '../instance';

export const login = (params) =>
  axiosInstance.post(Endpoints.AUTH.LOGIN, JSON.stringify(params));

export const getTgLink = () => axiosInstance.get(Endpoints.AUTH.SENDTG);

export const sendEmail = (params) =>
  axiosInstance.post(Endpoints.AUTH.SENDEMAIL, JSON.stringify(params));

export const refreshToken = (params) =>
  axiosInstance.post(Endpoints.AUTH.REFRESH, JSON.stringify(params));

export const getProfile = () => axiosInstance.get(Endpoints.AUTH.PROFILE);

export const checkTgAccess = (params) =>
  axiosInstance.post(Endpoints.AUTH.CHECKTG, JSON.stringify(params));

// get /tg_login
// post /bot_login {"token":""}

export const logout = (params) =>
  axiosInstance.post(Endpoints.AUTH.LOGOUT, JSON.stringify(params));
