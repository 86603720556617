export default (
  <svg
    width="25"
    height="22"
    viewBox="0 0 25 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.67808 11.4583C11.7002 11.4583 14.1501 8.89328 14.1501 5.72915C14.1501 2.56503 11.7002 0 8.67808 0C5.65597 0 3.20605 2.56503 3.20605 5.72915C3.20605 8.89328 5.65597 11.4583 8.67808 11.4583Z"
      fill="#777A96"
    />
    <path
      d="M16.3956 22H1.11497C0.956005 22.0014 0.798576 21.9708 0.653447 21.9103C0.508318 21.8498 0.378913 21.7608 0.27408 21.6493C0.169247 21.5378 0.0914585 21.4066 0.0460332 21.2645C0.000607872 21.1224 -0.0113827 20.9728 0.0108813 20.826C0.310891 18.8623 1.36048 17.0655 2.96687 15.7656C4.57325 14.4657 6.62856 13.75 8.75527 13.75C10.882 13.75 12.9373 14.4657 14.5437 15.7656C16.1501 17.0655 17.1996 18.8623 17.4997 20.826C17.5219 20.9728 17.5099 21.1224 17.4645 21.2645C17.4191 21.4066 17.3413 21.5378 17.2365 21.6493C17.1316 21.7608 17.0022 21.8498 16.8571 21.9103C16.712 21.9708 16.5545 22.0014 16.3956 22Z"
      fill="#777A96"
    />
    <path
      d="M19.6221 4.58325H23.9997M19.6221 11.4582H23.9997M21.8109 18.3332H23.9997"
      stroke="#777A96"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
