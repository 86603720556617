import api from "../../api";
import s from "./Polls.module.scss";
import PollsTexts from "./PollsTexts";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import PollsSettings from "./PollsSettings";
import PollsQuestions from "./PollsQuestions";
import PollsMainHeader from "./PollsMainHeader";
import Loader from "../../components/common/Loader";
import PollsHeader from "./PollsHeader";

import PopupAddBot from "../Bot/PopupAddBot";
import { useNavigate } from "react-router-dom";
import defPoll from "../../api/polls/defPoll.json";
import defQuest from "../../api/polls/defQuest.json";

const Polls = () => {
	const navigate = useNavigate();
	const [json, setJson] = useState();
	const [polls, setPolls] = useState([]);
	const [questions, setQuestions] = useState([]);

	const [curPoll, setCurPoll] = useState({});
	const [curPollQuestions, setCurPollQuestions] = useState([]);

	const [loading, setLoading] = useState(false);
	const [btnSave, setBtnSave] = useState("Сохранить");
	const [btnPublish, setBtnPublish] = useState("Опубликовать");
	const [activePage, setActivePage] = useState(1);
	const [pages] = useState([
		{
			id: 1,
			title: "Настройки",
		},
		{
			id: 2,
			title: "Тексты",
		},
		{
			id: 3,
			title: "Вопросы",
		},
	]);

	const curBot = useSelector((state) => state.bot.curBot);
	const botIsLoaded = useSelector((state) => state.bot.isLoaded);
	const curProject = useSelector((state) => state.main.curProject);
	const { user_id } = useSelector((state) => state.auth.profileData);

	const getPolls = async (curProject, user_id, curBot) => {
		setLoading(true);
		const res = await api.polls.getPolls(
			curProject.name,
			user_id,
			curBot.username
		);
		if (res) {
			setJson(res);
			// console.log(res);
			res.questions && setQuestions(res.questions);
			if (res.polls?.length) {
				setPolls(res.polls);
				setCurPoll(res.polls[0]);
			}
		}
		setLoading(false);
	};

	useEffect(() => {
		if (curProject.name && curBot.username && user_id) {
			getPolls(curProject, user_id, curBot);
		}
		// eslint-disable-next-line
	}, [curBot.username, curProject, user_id]);

	const switchPage = (pageId) => {
		setActivePage(pageId);
	};

	const publish = async () => {
		setBtnPublish("Публикация...");
		const res = await api.polls.publish({
			...json,
			polls: polls,
			questions: questions,
		});
		// console.log(res);

		if (res.data.result) {
			setBtnPublish("Опубликовано");
		} else {
			setBtnPublish("Ошибка");
		}
		setTimeout(() => {
			setBtnPublish("Опубликовать");
		}, 2000);
	};

	const save = async () => {
		setBtnSave("Сохранение...");
		const res = await api.polls.save(
			curProject.name,
			curBot.username,
			user_id,
			{
				...json,
				polls: polls,
				questions: questions,
			}
		);
		// console.log(res);
		if (res.data) {
			setBtnSave("Сохранено");
		} else {
			setBtnSave("Ошибка");
		}
		setTimeout(() => {
			setBtnSave("Сохранить");
		}, 2000);
	};

	const delPoll = (poll) => {
		const updatedPolls = polls.filter((p) => p.num !== poll.num);
		setPolls(updatedPolls);
		if (curPoll.num === poll.num) {
			updatedPolls[0] ? setCurPoll(updatedPolls[0]) : setCurPoll({});
		}
	};

	const addPoll = () => {
		let createdPoll;
		if (polls.length) {
			const maxNum = polls.reduce((a, b) => (a.num > b.num ? a : b));
			const updatedPollNum = maxNum.num + 1;
			createdPoll = { ...defPoll, num: updatedPollNum };
			setPolls([...polls, createdPoll]);
		} else {
			createdPoll = { ...defPoll };
			setPolls([{ ...createdPoll }]);
		}
		if (createdPoll) {
			setTimeout(() => {
				setCurPoll(createdPoll);
			}, 0);
		}
	};

	useEffect(() => {
		if (questions.length) {
			setCurPollQuestions(
				questions.filter((quest) => quest.in_polls.includes(curPoll.num))
			);
		} else {
			setCurPollQuestions([]);
		}
	}, [curPoll, questions]);

	const changePollData = (val, key1, key2, key3) => {
		let updatedPoll;
		const pollIndex = polls.findIndex((p) => p.num === curPoll.num);
		if (key3) {
			updatedPoll = {
				...curPoll,
				[key1]: {
					...curPoll[key1],
					[key2]: { ...curPoll[key1][key2], [key3]: val },
				},
			};
		} else if (key2) {
			updatedPoll = { ...curPoll, [key1]: { ...curPoll[key1], [key2]: val } };
		} else {
			updatedPoll = { ...curPoll, [key1]: val };
		}
		setCurPoll(updatedPoll);
		setPolls((prev) => [
			...prev.slice(0, pollIndex),
			updatedPoll,
			...prev.slice(pollIndex + 1),
		]);
	};

	const changePollQuestion = (val, num, key1, key2) => {
		const editQuestionIndex = questions.findIndex((q) => q.num === num);
		let updatedQuestion;
		if (key2) {
			updatedQuestion = { ...questions[editQuestionIndex] };
		} else {
			updatedQuestion = { ...questions[editQuestionIndex], [key1]: val };
		}
		if (!updatedQuestion) return;

		setQuestions((prev) => [
			...prev.slice(0, editQuestionIndex),
			updatedQuestion,
			...prev.slice(editQuestionIndex + 1),
		]);
	};

	const addQuestion = () => {
		if (questions.length) {
			const maxNum = questions.reduce((a, b) => (a.num > b.num ? a : b));
			const updatedQuestionNum = maxNum.num + 1;
			setQuestions((prev) => [
				...prev,
				{ ...defQuest, num: updatedQuestionNum, in_polls: [curPoll.num] },
			]);
		} else {
			setQuestions((prev) => [
				...prev,
				{ ...defQuest, in_polls: [curPoll.num] },
			]);
		}
	};

	const delQuestion = (num) => {
		const editQuestionIndex = questions.findIndex((q) => q.num === num);
		const updatedQuestion = {
			...questions[editQuestionIndex],
			in_polls: questions[editQuestionIndex].in_polls.filter(
				(p) => p !== curPoll.num
			),
		};

		setQuestions((prev) => [
			...prev.slice(0, editQuestionIndex),
			updatedQuestion,
			...prev.slice(editQuestionIndex + 1),
		]);
	};

	return (
		<div className={"page " + s.page + " " + (loading ? s.loading : "")}>
			{loading ? (
				<Loader />
			) : !curBot.username && botIsLoaded ? (
				<PopupAddBot close={() => navigate("/")} />
			) : (
				<>
					<PollsHeader
						items={polls}
						addItem={addPoll}
						delItem={delPoll}
						curItem={curPoll}
						setCurItem={setCurPoll}
					/>
					{curPoll.num >= 0 && (
						<div className={s.main}>
							<PollsMainHeader
								save={save}
								pages={pages}
								btnSave={btnSave}
								publish={publish}
								activePage={activePage}
								switchPage={switchPage}
								btnPublish={btnPublish}
							/>
							{activePage === 1 && (
								<PollsSettings
									data={curPoll}
									changeData={changePollData}
								/>
							)}
							{activePage === 2 && (
								<PollsTexts
									data={curPoll}
									changeData={changePollData}
								/>
							)}
							{activePage === 3 && (
								<PollsQuestions
									addItem={addQuestion}
									delItem={delQuestion}
									list={curPollQuestions}
									changeItem={changePollQuestion}
								/>
							)}
						</div>
					)}
				</>
			)}
		</div>
	);
};

export default Polls;
