export default (
  <svg
    width="24"
    height="22"
    viewBox="0 0 24 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="11.8462"
      width="5.14283"
      height="10.1538"
      rx="2.57141"
      fill="#777A96"
    />
    <rect
      x="9.42773"
      y="5.9231"
      width="5.14283"
      height="16.0769"
      rx="2.57141"
      fill="#777A96"
    />
    <rect x="18.8574" width="5.14283" height="22" rx="2.57141" fill="#777A96" />
  </svg>
);
