import s from './Bot.module.scss';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../store';
import { useNavigate } from 'react-router-dom';
import PopUp from '../../components/common/PopUp';
import Input from '../../components/common/Input';
import React, { useEffect, useState } from 'react';
import { updateTree } from '../../store/main/actionCreators';
import { clearBotErrors, delBotData } from '../../store/bot/actionCreators';

const PopupDelBot = ({ close }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const botData = useSelector((state) => state.bot);
  const isLoading = useSelector((state) => state.bot.isLoading);
  const { user_id } = useSelector((state) => state.auth.profileData);
  const updatingTree = useSelector((state) => state.main.updatingTree);

  // useEffect(() => {
  //   return () => {
  //     console.log(13);
  //     dispatch(clearBotErrors());
  //   };
  // }, []);

  useEffect(() => {
    console.log(botData);
    if (botData.isDeleted && !updatingTree) {
      // dispatch(getMyBots());
      dispatch(updateTree());
      dispatch(clearBotErrors());
      setSuccess(true);
      close();
      navigate('/');
    }
    if (botData?.errors?.delBot) {
      setError(botData.errors.delBot);
    }
    // eslint-disable-next-line
  }, [botData]);

  const handleSubmit = async () => {
    if (name.trim() === botData.curBot.name) {
      if (!botData.curBot.username) return setError('Username не найден');
      setError('');
      dispatch(delBotData(botData.curBot.username, user_id));
    } else {
      setError('Название бота введено неверно');
    }
  };

  return (
    <PopUp isOpen={true} close={close}>
      {!success ? (
        <div className={s.popupDelBot}>
          <h3 className={s.title}>
            Вы действительно хотите удалить бот {botData.curBot.name}?
          </h3>
          <p className={s.desc}>
            Введите <span>{botData.curBot.name}</span> чтобы подтвердить
            удаление
          </p>
          <Input
            value={name}
            error={error}
            name={'botName'}
            label={'Название бота'}
            handleChange={(val) => {
              setName(val);
              setError('');
            }}
          />
          <div className={s.btnList}>
            <button
              disabled={isLoading}
              onClick={handleSubmit}
              className="btnRed"
            >
              {isLoading ? 'Удаление...' : 'Подтвердить удаление'}
            </button>
            <button onClick={close} className="btnDark">
              Отмена
            </button>
          </div>
        </div>
      ) : (
        <div className={s.popupDelBot + ' ' + s.success}>
          <h3 className={s.title}>Бот успешно удалён</h3>
          <button onClick={close} className="btnDark">
            Ок
          </button>
        </div>
      )}
    </PopUp>
  );
};

export default PopupDelBot;
