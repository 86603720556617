import React from 'react';
import copy from '../../imgs/copy';
import s from './Quizzes.module.scss';

const QuizHeaderItem = ({
  quiz,
  // quest_numb,
  handleClick,
  // members_numb,
  isActive = false,
}) => {
  return (
    <li
      onClick={() => handleClick(quiz)}
      className={s.quiz__item + ' ' + (isActive ? s.actived : '')}
    >
      <div className={s.quiz__item__top}>
        <p>{quiz.name}</p>
        <button className={s.quiz__item__btnCopy}>{copy}</button>
      </div>
      {/* <div className={s.quiz__item__bottom}>
        <p>{quest_numb} вопросов</p>
        <p>{members_numb} участника</p>
      </div> */}
    </li>
  );
};

export default QuizHeaderItem;
