import { useState } from "react";
import s from "./Challenge.module.scss";
import trashBin from "../../imgs/trashBin";
import plusSelect from "../../imgs/plusSelect";
import Textarea from "../../components/common/Textarea";
import InputImg from "../../components/common/InputImg";

const ChallengeMain = () => {
  const [data, setData] = useState({});
  const [jokes, setJokes] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [defJoke] = useState({
    parameter1: "",
    parameter2: "",
    condition1: "",
    condition2: "",
    text1: "",
    text2: "",
    type: "or",
  });
  const [defTask] = useState({
    task: "",
    tag: "",
  });

  const changeData = (key, val) => {
    setData((prev) => ({ ...prev, [key]: val }));
  };

  const addJoke = () => {
    setJokes((prev) => [...prev, defJoke]);
  };

  const delJoke = (i) => {
    setJokes((prev) => [...prev.slice(0, i), ...prev.slice(i + 1)]);
  };

  const delTask = (i) => {
    setTasks((prev) => [...prev.slice(0, i), ...prev.slice(i + 1)]);
  };

  const addTask = () => {
    setTasks((prev) => [...prev, defTask]);
  };

  const changeJokeData = (key, val, jokeI) => {
    setJokes((prev) => [
      ...prev.slice(0, jokeI),
      { ...prev[jokeI], [key]: val },
      ...prev.slice(jokeI + 1),
    ]);
  };

  const changeTaskData = (key, val, taskI) => {
    setTasks((prev) => [
      ...prev.slice(0, taskI),
      { ...prev[taskI], [key]: val },
      ...prev.slice(taskI + 1),
    ]);
  };

  return (
    <div className={s.main}>
      <div className={s.left}>
        <h3 className={s.subTitle}>Настройки кода</h3>
        <div className={s.inputList}>
          {/* ~ */}
          <div className={s.inputFlex}>
            <Textarea
              placeholder=""
              className={s.textarea}
              val={data.wasUsedText}
              wrapClassName={s.textareaWrap}
              label="Код был использован ранее"
              handleChange={(val) => changeData("wasUsedText", val)}
            />
            <InputImg
              id={`wasUsedImg`}
              img={data.wasUsedImg}
              className={s.imgInput}
              setImg={(img) => changeData("wasUsedImg", img)}
            />
            <p className={s.note}>Примечание</p>
          </div>
          {/* ~ */}
          <div className={s.inputFlex}>
            <Textarea
              placeholder=""
              className={s.textarea}
              val={data.limitExceeded}
              wrapClassName={s.textareaWrap}
              label="Превышен лимит использования кода"
              handleChange={(val) => changeData("limitExceeded", val)}
            />
            <InputImg
              className={s.imgInput}
              id={`limitExceededImg`}
              img={data.limitExceededImg}
              setImg={(img) => changeData("limitExceededImg", img)}
            />
            <p className={s.note}>Примечание</p>
          </div>
          {/* ~ */}
          <div className={s.inputFlex}>
            <Textarea
              placeholder=""
              className={s.textarea}
              val={data.codeSuccess}
              label="Код успешно сработал"
              wrapClassName={s.textareaWrap}
              handleChange={(val) => changeData("codeSuccess", val)}
            />
            <InputImg
              id={`codeSuccessImg`}
              className={s.imgInput}
              img={data.codeSuccessImg}
              setImg={(img) => changeData("codeSuccessImg", img)}
            />
            <p className={s.note}>Примечание</p>
          </div>
          {/* ~ */}
          <div className={s.inputFlex}>
            <Textarea
              placeholder=""
              className={s.textarea}
              val={data.codeWithTagWasUsed}
              label="Вы уже использовали код с этим тегом"
              wrapClassName={s.textareaWrap}
              handleChange={(val) => changeData("codeWithTagWasUsed", val)}
            />
            <InputImg
              className={s.imgInput}
              id={`codeWithTagWasUsedImg`}
              img={data.codeWithTagWasUsedImg}
              setImg={(img) => changeData("codeWithTagWasUsedImg", img)}
            />
            <p className={s.note}>Примечание</p>
          </div>
          {/* ~ */}
          <div className={s.inputFlex}>
            <Textarea
              placeholder=""
              className={s.textarea}
              val={data.fileText}
              wrapClassName={s.textareaWrap}
              label="Текст кода за входящий файл"
              handleChange={(val) => changeData("fileText", val)}
            />
            <InputImg
              id={`fileTextImg`}
              className={s.imgInput}
              img={data.fileTextImg}
              setImg={(img) => changeData("fileTextImg", img)}
            />
            <p className={s.note}>Примечание</p>
          </div>
        </div>

        <h3 className={s.subTitle + " " + s.settingsTitle}>Настройки кода</h3>
        <div className={s.inputList}>
          {/* ~ */}
          <Textarea
            placeholder=""
            className={s.textarea}
            val={data.symbolComplited}
            wrapClassName={s.textareaWrap}
            label="Символ выполненное задание"
            handleChange={(val) => changeData("symbolComplited", val)}
          />
          {/* ~ */}
          <Textarea
            placeholder=""
            className={s.textarea}
            val={data.symbolUncomplited}
            wrapClassName={s.textareaWrap}
            label="Символ невыполненное задание"
            handleChange={(val) => changeData("symbolUncomplited", val)}
          />
          {/* ~ */}
          <Textarea
            placeholder=""
            className={s.textarea}
            val={data.formatComplited}
            wrapClassName={s.textareaWrap}
            label="Формат списка выполненных заданий"
            handleChange={(val) => changeData("formatComplited", val)}
          />
        </div>
      </div>
      <div className={"scroll " + s.right}>
        <div className={s.subTitleWrap}>
          <h3 className={s.subTitle}>Розыгрыш</h3>
          <button
            onClick={addJoke}
            className={[s.btnAdd, s.btnTransp].join(" ")}
          >
            {plusSelect} <span>Добавить розыгрыш</span>
          </button>
        </div>
        <div className={s.jokeList}>
          {jokes.map((joke, i) => (
            <div key={i} className={s.joke}>
              <div className={s.jokeHeader}>
                <p>Розыгрыш {i + 1}</p>
                <button onClick={() => delJoke(i)} className={s.btnDel}>
                  {trashBin}
                </button>
              </div>
              <div className={s.jokeMain}>
                <div className={s.jokeSubBlock}>
                  <Textarea
                    onReset={true}
                    placeholder=""
                    label="Параметр 1"
                    val={joke.parameter1}
                    className={s.textarea}
                    wrapClassName={s.textareaWrap}
                    handleChange={(val) => changeJokeData("parameter1", val, i)}
                  />
                  <Textarea
                    onReset={true}
                    placeholder=""
                    label="Условие 1"
                    val={joke.condition1}
                    className={s.textarea}
                    wrapClassName={s.textareaWrap}
                    handleChange={(val) => changeJokeData("condition1", val, i)}
                  />
                </div>
                <div className={s.switchType}>
                  <button
                    onClick={() => changeJokeData("type", "and", i)}
                    className={joke.type === "and" && s.active}
                  >
                    или
                  </button>
                  <button
                    onClick={() => changeJokeData("type", "or", i)}
                    className={joke.type === "or" && s.active}
                  >
                    и
                  </button>
                </div>
                <div className={s.jokeSubBlock}>
                  <Textarea
                    placeholder=""
                    onReset={true}
                    label="Параметр 2"
                    val={joke.parameter2}
                    className={s.textarea}
                    wrapClassName={s.textareaWrap}
                    handleChange={(val) => changeJokeData("parameter2", val, i)}
                  />
                  <Textarea
                    onReset={true}
                    placeholder=""
                    label="Условие 2"
                    val={joke.condition2}
                    className={s.textarea}
                    wrapClassName={s.textareaWrap}
                    handleChange={(val) => changeJokeData("condition2", val, i)}
                  />
                </div>
                <div className={s.jokeSubBlock + " " + s.transp}>
                  <Textarea
                    placeholder=""
                    onReset={true}
                    label="Текст не в розыгрыше"
                    val={joke.text1}
                    className={s.textarea}
                    wrapClassName={s.textareaWrap}
                    handleChange={(val) => changeJokeData("text1", val, i)}
                  />
                  <Textarea
                    placeholder=""
                    onReset={true}
                    label="Текст в розыгрыше"
                    val={joke.text2}
                    className={s.textarea}
                    wrapClassName={s.textareaWrap}
                    handleChange={(val) => changeJokeData("text2", val, i)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>

        {/*  */}
        <div className={s.subTitleWrap + " " + s.taskTitle}>
          <h3 className={s.subTitle}>Задания</h3>
          <button
            onClick={addTask}
            className={[s.btnAdd, s.btnTransp].join(" ")}
          >
            {plusSelect} <span>Добавить задание</span>
          </button>
        </div>
        <div className={s.taskList}>
          {tasks.map((task, i) => (
            <div key={i} className={s.task}>
              <div className={s.taskMain}>
                <Textarea
                  placeholder=""
                  label="Задание"
                  onReset={true}
                  val={task.parameter1}
                  className={s.textarea}
                  wrapClassName={s.textareaWrap}
                  handleChange={(val) => changeTaskData("parameter1", val, i)}
                />
                <Textarea
                  label="Тег"
                  placeholder=""
                  onReset={true}
                  val={task.condition1}
                  className={s.textarea}
                  wrapClassName={s.textareaWrap}
                  handleChange={(val) => changeTaskData("condition1", val, i)}
                />
                <button onClick={() => delTask(i)} className={s.btnDel}>
                  {trashBin}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChallengeMain;
