import s from "./Polls.module.scss";

const PollsMainHeader = ({
	save,
	pages,
	btnSave,
	publish,
	activePage,
	switchPage,
	btnPublish,
}) => {
	return (
		<div className={s.pageMainHeader}>
			<div className={s.pageSwitcher}>
				{pages.map((page) => (
					<button
						key={page.id}
						onClick={() => switchPage(page.id)}
						className={activePage === page.id ? s.active : ""}
					>
						{page.title}
					</button>
				))}
			</div>

			<div className={s.btns}>
				<button
					onClick={save}
					className={"btnDark"}
					disabled={btnSave !== "Сохранить"}
				>
					{btnSave}
				</button>
				<button
					onClick={publish}
					className={"btnLight"}
					disabled={btnPublish !== "Опубликовать"}
				>
					{btnPublish}
				</button>
			</div>
		</div>
	);
};

export default PollsMainHeader;
