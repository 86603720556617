const trashBin = (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.44444 5.2V12.2C2.44444 13.7464 3.73784 15 5.33333 15H9.66667C11.2622 15 12.5556 13.7464 12.5556 12.2V5.2M8.94444 7.3V11.5M6.05556 7.3L6.05556 11.5M10.3889 3.1L9.37325 1.62342C9.10536 1.23394 8.65436 1 8.1714 1H6.8286C6.34564 1 5.89464 1.23394 5.62675 1.62342L4.61111 3.1M10.3889 3.1H4.61111M10.3889 3.1H14M4.61111 3.1H1"
      stroke="#FF453A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default trashBin;
