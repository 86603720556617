import { useState } from "react";
import s from "./Polls.module.scss";
import Checkbox from "../../components/common/Checkbox";
import Textarea from "../../components/common/Textarea";
import Input from "../../components/common/Input";
import Select from "../../components/common/Select";

const PollsSettings = ({ data, changeData }) => {
	const [showedSelectName, setShowedSelectName] = useState("");
	const [lists] = useState({
		action_after_timeout: ["Следующий", "Пропустить", "Повтор", "Меню"],
		proc_next: ["Следующий", "Пропустить", "Повтор", "Меню"],
		next_ok_after_modul: ["Меню", "Ничего", "Следующий опрос", "Опрос номер"], // после меню
		params1: ["Следующий", "Пропустить", "Меню", "Пропустить"],
		params2: ["Меню"],
		allow_text_answer: ["Да", "Нет"],
	});

	const showList = (key) => {
		key === showedSelectName
			? setShowedSelectName("")
			: setShowedSelectName(key);
	};

	return (
		<>
			<h3 className={s.subTitle}>Настройки</h3>
			<Checkbox
				label={"Опрос ON"}
				className={s.checkbox}
				isOn={data.status.data}
				handleClick={() => changeData(!data.status.data, "status", "data")}
			/>
			<div className={s.row}>
				<Textarea
					id="name"
					label="Имя"
					onReset={false}
					val={data.name}
					className={s.textarea}
					placeholder="Введите текст"
					wrapClassName={s.textareaWrap}
					handleChange={(val) => changeData(val, "name")}
				/>
				<Input
					name="num"
					label="Номер"
					type="number"
					onReset={true}
					value={data.num}
					className={s.input}
					// placeholder="Введите число"
					wrapClassName={[s.inputWrap, s.mini].join(" ")}
					handleChange={(val) => changeData(+val, "num")}
				/>
				<Input
					type="number"
					onReset={true}
					name="time_delay"
					className={s.input}
					label="Время ожидания, с."
					value={data.time_delay.data}
					// placeholder="Введите число"
					wrapClassName={[s.inputWrap, s.mini].join(" ")}
					handleChange={(val) => changeData(+val, "time_delay", "data")}
				/>
			</div>
			<div className={s.row}>
				<Select
					className={s.select}
					topLabel="После таймаута"
					value={data.action_after_timeout.data}
					selectList={lists.action_after_timeout}
					selected={data.action_after_timeout.data}
					showList={() => showList("action_after_timeout")}
					isShowed={showedSelectName === "action_after_timeout"}
					select={(val) => changeData(val, "action_after_timeout", "data")}
				/>
				<Select
					className={s.select}
					topLabel="После ответа"
					value={data.proc_next.data}
					selected={data.proc_next.data}
					selectList={lists.proc_next}
					showList={() => showList("proc_next")}
					isShowed={showedSelectName === "proc_next"}
					select={(val) => changeData(val, "proc_next", "data")}
				/>
			</div>

			<div className={s.row}>
				<Textarea
					id="command1"
					onReset={false}
					label="Команда 1"
					className={s.textarea}
					val={data.inbox[0].text}
					placeholder="Введите текст"
					wrapClassName={s.textareaWrap}
					handleChange={(val) => changeData(val, "inbox", 0, "text")}
				/>
				<Select
					topLabel="Параметр"
					className={s.select}
					selectList={lists.params1}
					value={data.inbox[0].next}
					selected={data.inbox[0].next}
					showList={() => showList("params1")}
					isShowed={showedSelectName === "params1"}
					select={(val) => changeData(val, "inbox", 0, "next")}
				/>
			</div>

			<div className={s.row}>
				<Textarea
					label="Команда 2"
					id="command2"
					onReset={false}
					val={data.inbox[1].text}
					className={s.textarea}
					placeholder="Введите текст"
					wrapClassName={s.textareaWrap}
					handleChange={(val) => changeData(val, "inbox", 1, "text")}
				/>
				<Select
					topLabel="Параметр"
					className={s.select}
					selectList={lists.params2}
					value={data.inbox[1].next}
					selected={data.inbox[1].next}
					showList={() => showList("params2")}
					isShowed={showedSelectName === "params2"}
					select={(val) => changeData(val, "inbox", 1, "next")}
				/>
			</div>
			<Checkbox
				className={s.checkbox}
				label={"Пройти повторно"}
				isOn={data.allow_retry.data}
				handleClick={() =>
					changeData(!data.allow_retry.data, "allow_retry", "data")
				}
			/>
			<div className={s.row}>
				<Input
					type="number"
					name="ballsDefault"
					onReset={true}
					className={s.input}
					label="Баллы по умолчанию"
					value={data.balls.default.data}
					wrapClassName={[s.inputWrap, s.mini].join(" ")}
					handleChange={(val) => changeData(+val, "balls", "default", "data")}
				/>
				<Input
					type="number"
					onReset={true}
					name="ballsStart"
					className={s.input}
					label="Баллы за старт"
					value={data.balls.start.data}
					wrapClassName={[s.inputWrap, s.mini].join(" ")}
					handleChange={(val) => changeData(+val, "balls", "start", "data")}
				/>
				<Input
					type="number"
					onReset={true}
					name="ballsFinal"
					className={s.input}
					label="Баллы за финал"
					value={data.balls.final.data}
					wrapClassName={[s.inputWrap, s.mini].join(" ")}
					handleChange={(val) => changeData(+val, "balls", "final", "data")}
				/>
			</div>
			<div className={s.row}>
				<Select
					className={s.select}
					topLabel="После финала"
					value={data.next_ok_after_modul.data}
					selected={data.next_ok_after_modul.data}
					selectList={lists.next_ok_after_modul}
					showList={() => showList("next_ok_after_modul")}
					isShowed={showedSelectName === "next_ok_after_modul"}
					select={(val) => changeData(val, "next_ok_after_modul", "data")}
				/>
				<Input
					type="number"
					onReset={true}
					className={s.input}
					label="Номер вопроса"
					name="nextOkAfterModulParameters"
					value={
						data.next_ok_after_modul.parameters
							? data.next_ok_after_modul?.parameters[0]
							: ""
					}
					wrapClassName={[s.inputWrap, s.mini].join(" ")}
					handleChange={(val) =>
						changeData([+val], "next_ok_after_modul", "parameters")
					}
				/>
			</div>
			<div className={s.row}>
				<Select
					className={s.select}
					value={data.allow_text_answer.data}
					selectList={lists.allow_text_answer}
					selected={data.allow_text_answer.data}
					showList={() => showList("allow_text_answer")}
					isShowed={showedSelectName === "allow_text_answer"}
					select={(val) => changeData(val, "allow_text_answer", "data")}
					topLabel="При отправке опроса в формате ТГ принимать текстовый ответ"
				/>
			</div>
			<Input
				type="text"
				onReset={true}
				className={s.input}
				placeholder={"5-10000"}
				name="limit_open_answer"
				label="Лимит на открытый вопрос"
				value={data.limit_open_answer.data}
				wrapClassName={[s.inputWrap, s.mini].join(" ")}
				handleChange={(val) => changeData(val, "limit_open_answer", "data")}
			/>

			<Checkbox
				className={s.checkbox}
				isOn={data.recomend.data}
				label={"Рекомендации после опроса"}
				handleClick={() => changeData(!data.recomend.data, "recomend", "data")}
			/>
		</>
	);
};

export default PollsSettings;
