export default (
  <svg
    width="21"
    height="24"
    viewBox="0 0 21 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.85766 14.5882H12.8869L14.9124 17.1337L16.9051 19.4011L20.6606 24H16.2372L13.6533 20.8984L12.3285 19.0588L8.85766 14.5882ZM21 11.2513C21 13.6399 20.5365 15.672 19.6095 17.3476C18.6898 19.0232 17.4343 20.303 15.8431 21.1872C14.2591 22.0642 12.4781 22.5027 10.5 22.5027C8.5073 22.5027 6.71898 22.0606 5.13504 21.1765C3.55109 20.2923 2.29927 19.0125 1.37956 17.3369C0.459854 15.6613 0 13.6328 0 11.2513C0 8.86275 0.459854 6.83066 1.37956 5.15508C2.29927 3.4795 3.55109 2.20321 5.13504 1.3262C6.71898 0.442068 8.5073 0 10.5 0C12.4781 0 14.2591 0.442068 15.8431 1.3262C17.4343 2.20321 18.6898 3.4795 19.6095 5.15508C20.5365 6.83066 21 8.86275 21 11.2513ZM16.1934 11.2513C16.1934 9.7041 15.9562 8.39929 15.4818 7.3369C15.0146 6.27451 14.354 5.46881 13.5 4.91979C12.646 4.37077 11.646 4.09626 10.5 4.09626C9.35401 4.09626 8.35401 4.37077 7.5 4.91979C6.64598 5.46881 5.98175 6.27451 5.5073 7.3369C5.04015 8.39929 4.80657 9.7041 4.80657 11.2513C4.80657 12.7986 5.04015 14.1034 5.5073 15.1658C5.98175 16.2282 6.64598 17.0339 7.5 17.5829C8.35401 18.1319 9.35401 18.4064 10.5 18.4064C11.646 18.4064 12.646 18.1319 13.5 17.5829C14.354 17.0339 15.0146 16.2282 15.4818 15.1658C15.9562 14.1034 16.1934 12.7986 16.1934 11.2513Z"
      fill="#777A96"
    />
  </svg>
);
