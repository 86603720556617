import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  errors: {},
  curProject: {},
  curCompany: {},
  mainLoading: true,
  companyAdded: false,
  companyLoading: false,
  projectAdded: false,
  projectLoading: false,
  updatingTree: false,
  lastShopTab: null,
  tree: [],
  userList: [],
  companyList: [],
  projectList: [],
};

export const mainReducer = createSlice({
  name: 'main',
  initialState,
  reducers: {
    cleanTree: () => ({ ...initialState, mainLoading: false }),
    loadTreeStart: (state) => ({
      ...state,
      errors: {},
      mainLoading: true,
    }),
    loadTreeSuccess: (state, action) => ({
      ...state,
      errors: {},
      mainLoading: false,
      tree: [...action.payload],
    }),
    loadTreeFailure: (state, action) => ({
      ...state,
      mainLoading: false,
      errors: action.payload,
    }),
    // updating tree
    updateTreeStart: (state) => ({
      ...state,
      errors: {},
      updatingTree: true,
    }),
    updateTreeSuccess: (state, action) => ({
      ...state,
      errors: {},
      mainLoading: false,
      updatingTree: false,
      companyAdded: false,
      projectAdded: false,
      tree: [...action.payload],
    }),
    updateTreeFailure: (state, action) => ({
      ...state,
      updatingTree: false,
      errors: action.payload,
    }),
    // add company
    addCompanyStart: (state) => ({
      ...state,
      errors: {},
      companyLoading: true,
    }),
    addCompanySuccess: (state, action) => ({
      ...state,
      errors: {},
      companyAdded: true,
      companyLoading: false,
      curCompany: action.payload,
      companyList: [...state.companyList, action.payload],
    }),
    addCompanyFailure: (state, action) => ({
      ...state,
      companyLoading: false,
      errors: { company: action.payload },
    }),
    // add Project
    addProjectStart: (state) => ({
      ...state,
      errors: {},
      projectLoading: true,
    }),
    addProjectSuccess: (state, action) => ({
      ...state,
      errors: {},
      projectAdded: true,
      projectLoading: false,
      curProject: action.payload,
      projectList: [...state.projectList, action.payload],
    }),
    addProjectFailure: (state, action) => ({
      ...state,
      projectLoading: false,
      errors: { project: action.payload },
    }),
    //
    setCurProjectSuccess: (state, action) => ({
      ...state,
      curProject: action.payload,
    }),
    setCurCompanySuccess: (state, action) => ({
      ...state,
      curCompany: action.payload,
    }),
    //
    setProjectListSuccess: (state, action) => ({
      ...state,
      projectList: action.payload,
    }),
    setCompanyListSuccess: (state, action) => ({
      ...state,
      companyList: action.payload,
    }),
    setLastShopTabSuccess: (state, action) => ({
      ...state,
      lastShopTab: action.payload,
    }),
  },
});

export const {
  cleanTree,
  loadTreeStart,
  loadTreeSuccess,
  loadTreeFailure,
  addCompanyStart,
  addCompanySuccess,
  addCompanyFailure,
  addProjectStart,
  addProjectSuccess,
  addProjectFailure,
  setCurCompanySuccess,
  setCurProjectSuccess,
  setProjectListSuccess,
  setCompanyListSuccess,
  updateTreeSuccess,
  updateTreeStart,
  updateTreeFailure,
  setLastShopTabSuccess,
} = mainReducer.actions;
export default mainReducer.reducer;
