import { useSelector } from 'react-redux';
import ShopTablePage from './ShopTablePage';
import { useAppDispatch } from '../../store';
import { useEffect } from 'react';
import { setLastShopTab } from '../../store/main/actionCreators';

const Shop = () => {
  const dispatch = useAppDispatch();
  const { curProject } = useSelector((state) => state.main);
  const curProjectName = curProject?.name;
  const { curBot } = useSelector((state) => state.bot);
  // const curProjectName = 'ozon-quest-dedic';
  useEffect(() => {
    dispatch(setLastShopTab('/shop'));
    // eslint-disable-next-line
  }, []);

  // const curProjectName = 'mts-true-summer24-serverless221';
  return <ShopTablePage slug={curProjectName || ''} base={curBot.base || ''} />;
};

export default Shop;
