import { addBotApi, delBotApi, getBotInfoApi } from "../../api/bot"; // , getMyBotsApi
import {
	addBotFailure,
	addBotStart,
	addBotSuccess,
	clearBotErrorsSuccess,
	delBotFailure,
	delBotStart,
	delBotSuccess,
	getBotInfoFailure,
	getBotInfoStart,
	getBotInfoSuccess,
	setBotIsLoaded,
	setBotListSuccess,
	// loadMyBotsFailure,
	// loadMyBotsStart,
	// loadMyBotsSuccess,
	setBotSuccess,
} from "./botReducer";

export const delBotData = (botName, user_id) => async (dispatch) => {
	try {
		dispatch(delBotStart());
		const res = await delBotApi(botName, user_id);
		// console.log('del bot', res);
		if (!res.data.error) {
			dispatch(delBotSuccess(res.data));
			return res.data.result;
		} else {
			dispatch(delBotFailure(res.data.error));
		}
	} catch (e) {
		console.error(e);
		dispatch(delBotFailure(e.message));
	}
};

export const clearBotErrors = () => async (dispatch) => {
	dispatch(clearBotErrorsSuccess());
};

export const addBot = (params) => async (dispatch) => {
	try {
		dispatch(addBotStart());
		// console.log('add bot', params);
		const res = await addBotApi(params);
		// console.log('add bot', res);
		if (res.error) {
			return dispatch(addBotFailure(res.error));
		}
		dispatch(addBotSuccess(res.data));
	} catch (e) {
		console.error(e);
		dispatch(addBotFailure(e.message));
	}
};

export const setCurBot = (data) => async (dispatch) => {
	localStorage.setItem("curBot", JSON.stringify(data));
	dispatch(setBotSuccess(data));
	// if (data.username) {
	dispatch(setBotIsLoaded());
	// }
};

export const rebindBot =
	(botname, user_id, company, project) => async (dispatch) => {
		try {
			dispatch(addBotStart());
			const botData = await getBotInfoApi({ bot: { name: botname } });
			if (botData?.token) {
				await delBotApi(botname, user_id);
				const res = await addBotApi({
					token: botData.token,
					user_id,
					company,
					project,
				});
				if (res.error) {
					return dispatch(addBotFailure(res.error));
				}
				dispatch(addBotSuccess(res.data));
			} else {
				return dispatch(addBotFailure(new Error("Rebind bot error").message));
			}
		} catch (e) {
			console.error(e);
			// dispatch(addBotFailure(e.message));
		}
	};

// getBotInfo
export const getBotInfo = (params) => async (dispatch) => {
	try {
		dispatch(getBotInfoStart());
		const res = await getBotInfoApi(params);
		if (res?.token) {
			dispatch(getBotInfoSuccess(res));
		} else {
			dispatch(getBotInfoFailure(""));
		}
	} catch (e) {
		console.error(e);
		dispatch(getBotInfoFailure(e.message));
	}
};

export const setBotList = (botList) => async (dispatch) => {
	// console.log('setProjectList');
	dispatch(setBotListSuccess(botList));
};

// export const getMyBots = () => async (dispatch) => {
//   try {
//     dispatch(loadMyBotsStart());
//     const res = await getMyBotsApi();
//     if (res?.length) {
//       dispatch(loadMyBotsSuccess(res));
//     } else {
//       dispatch(loadMyBotsSuccess([]));
//     }
//     return res;
//   } catch (e) {
//     // console.error(e);
//     dispatch(loadMyBotsFailure(e.message));
//   }
// };
