import s from './Shop.module.scss';
import Loader from '../../components/common/Loader';

const ShopStatisticMobTable = ({ list, loading, lightList }) => {
  return (
    <>
      <div className={s.table + ' ' + (loading ? s.loading : '') + ' ' + s.mob}>
        <ul className={s.mobList}>
          {list.map((item, i) => (
            <li
              className={
                s.mobItem + (lightList.includes(item.uuid) ? ' ' + s.new : '')
              }
              key={i}
            >
              <div className={s.mobItemHeader}>
                <h3>{item.good}</h3>
              </div>
              <div className={s.mobItemInfo}>
                {/* <p className={s.grey}>Название:</p>
                <p className={s.infoItem}>{item.good} </p> */}
                <p className={s.grey}>Продано:</p>
                <p className={s.infoItem}>{item.sold || '0'} </p>
                <p className={s.grey}>Осталось на складе:</p>
                <p className={s.infoItem}>{item.sklad || '0'} </p>
                <p className={s.grey}>Всего:</p>
                <p className={s.infoItem}>{item.ini || '0'} </p>
                <p className={s.grey}>sku:</p>
                <p className={s.infoItem}>{item.sku || ''} </p>
              </div>
            </li>
          ))}
          {!loading && !list.length && (
            <h3 className={s.emptyMes}>Нет записей</h3>
          )}
        </ul>
        {loading && <Loader />}
      </div>
    </>
  );
};

export default ShopStatisticMobTable;
