import 'swiper/css';
import s from './Quizzes.module.scss';
import QuizHeaderItem from './QuizHeaderItem';
import { Swiper, SwiperSlide } from 'swiper/react';
import React, { useEffect, useRef, useState } from 'react';

const QuizHeader = ({ curQuizNum, changeQuiz, quizzesList, questions }) => {
  const [slider, setSlider] = useState({ next: false, back: false });
  const swiperRef = useRef();

  const checkSwiper = (swiper) => {
    if (!swiperRef.current) {
      swiperRef.current = swiper;
    }
    if (swiper.isBeginning) {
      setSlider((prevState) => ({ ...prevState, back: false }));
    } else {
      setSlider((prevState) => ({ ...prevState, back: true }));
    }
    if (swiper.isEnd) {
      setSlider((prevState) => ({ ...prevState, next: false }));
    } else {
      setSlider((prevState) => ({ ...prevState, next: true }));
    }
  };

  const getMembersNumb = (quizNum) => {
    // let members_numb = 0
    return 0;
  };
  useEffect(() => {
    if (quizzesList.length && swiperRef.current) {
      checkSwiper(swiperRef.current);
    }
  }, [quizzesList]);

  const getQuestNumb = (quizIndex) => {
    let count = 0;
    for (let quest of questions) {
      if (quest.in_quizes.includes(quizIndex)) {
        count += 1;
      }
    }
    return count;
  };

  return (
    <>
      <div className={s.quiz__listWrap}>
        <ul className={s.quiz__list}>
          <Swiper
            spaceBetween={10}
            slidesPerView={4}
            onSwiper={(swiper) =>
              setTimeout(() => {
                checkSwiper(swiper);
              }, 0)
            }
            onSlideChange={(swiper) => checkSwiper(swiper)}
          >
            {quizzesList.map((quiz, index) => (
              <SwiperSlide key={index}>
                <QuizHeaderItem
                  quiz={quiz}
                  handleClick={changeQuiz}
                  isActive={curQuizNum === quiz.num}
                  quest_numb={getQuestNumb(quiz.num)}
                  members_numb={getMembersNumb(quiz.num)}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </ul>
        {slider.back && (
          <button
            onClick={() => swiperRef.current?.slidePrev()}
            className={s.quiz__btnBack}
          ></button>
        )}
        {slider.next && (
          <button
            onClick={() => swiperRef.current?.slideNext()}
            className={s.quiz__btnNext}
          ></button>
        )}
      </div>
    </>
  );
};

export default QuizHeader;
