import navHome from "../../imgs/navHome";
import navChat from "../../imgs/navChat";
import navPlay from "../../imgs/navPlay";
import navQuiz from "../../imgs/navQuiz";
import navPolls from "../../imgs/navPolls";
import shopIcon from "../../imgs/shopIcon";
import navSurvey from "../../imgs/navSurvey";
import burgerIcon from "../../imgs/burgerIcon";
import navTg from "../../imgs/navTg";
// import Endpoints from '../endpoints';
// import { axiosInstance } from '../instance';
import newQuizData from "./newQuiz.json";
import newQuestionData from "./newQuestion.json";
import Endpoints from "../endpoints";
import { axiosInstance } from "../instance";
import navChallenge from "../../imgs/navChallenge";

export const getNavList = async (lastShopTab) => {
	const lastShopTabHref = lastShopTab || "/shop/settings";

	return [
		{ icon: navHome, path: "/", name: "Пульт" },
		{ icon: navChat, path: "/chat", name: "Чаты" },
		{
			icon: navPlay,
			path: "/start-modules",
			name: "Стартовые модули",
			subList: [
				{ icon: navPolls, path: "/polls", name: "Опросы" },
				{ icon: navQuiz, path: "/quizzes", name: "Квизы" },
				{ icon: navSurvey, path: "/survey", name: "Анкета" },
				{
					icon: burgerIcon,
					path: "/menu",
					name: "Меню",
					iconMode: "stroke",
					subList: [
						{
							name: "Меню 1",
							path: "/menu",
						},
						{
							name: "Меню 2",
							path: "/menu",
						},
						{
							name: "Меню 3",
							path: "/menu",
						},
						{
							name: "Меню 4",
							path: "/menu",
						},
						{
							name: "Меню 5",
							path: "/menu",
						},
					],
				},
				{ icon: navChallenge, path: "/challenge", name: "Челлендж" },
				{ icon: shopIcon, path: lastShopTabHref, name: "Магазин" },
			],
		},
		// { icon: navPolls, path: '/polls', name: 'Опросы' },
		// { icon: navQuiz, path: '/quizzes', name: 'Квизы' },
		// { icon: navSurvey, path: '/survey', name: 'Анкета' },
		{ icon: navTg, path: "/bot-edit", name: "Настройка бота" },
	];
};

export const getQuizList = async () => {
	return [
		{
			id: 1,
			isActive: false,
			name: "квиз 1",
			quest_numb: 24,
			members_numb: 21,
		},
		{
			id: 2,
			isActive: true,
			name: "квиз 2",
			quest_numb: 224,
			members_numb: 35,
		},
		{
			id: 3,
			isActive: false,
			name: "квиз 3",
			quest_numb: 34,
			members_numb: 41,
		},
		{ id: 4, isActive: false, name: "квиз 4", quest_numb: 64, members_numb: 5 },
		{ id: 5, isActive: false, name: "квиз 5", quest_numb: 94, members_numb: 2 },
		{
			id: 6,
			isActive: false,
			name: "квиз 6",
			quest_numb: 134,
			members_numb: 6,
		},
		{
			id: 7,
			isActive: false,
			name: "квиз 7",
			quest_numb: 10,
			members_numb: 99,
		},
	];
};

export const getQuizTexts = async (quizId) => {
	const allQuizTexts = [
		{
			id: 2,
			quizId: 1,
			value: "Ты уже проходил квиз",
		},
		{
			id: 4,
			quizId: 1,
			value: "Ты уже проходил квиз",
		},
		{
			id: 6,
			quizId: 1,
			value: "Ты уже проходил квиз",
		},
		{
			id: 8,
			quizId: 2,
			value: "Ты уже проходил квиз",
		},
	];
	return allQuizTexts.filter((quizText) => quizText.quizId === quizId);
};

export const getQuizQuestions = async (quizId) => {
	const allQuestions = [
		{
			id: 1,
			quizId: 1,
			value: "Квиз начинается",
			point: 6,
			answers: [
				{ id: 1, text: "Париж", active: false },
				{ id: 2, text: "Мадрид", active: true },
				{ id: 3, text: "Пекин", active: false },
				{ id: 4, text: "Лондон", active: false },
			],
		},
		{
			id: 2,
			quizId: 1,
			value: "Ты уже проходил квиз",
			point: 0,
			answers: [
				{ id: 5, text: "Лиссабон", active: true },
				{ id: 6, text: "Барселона", active: false },
				{ id: 7, text: "Берлин", active: true },
				{ id: 8, text: "Неаполь", active: false },
				{ id: 9, text: "Палермо", active: false },
			],
		},
		{
			id: 3,
			quizId: 2,
			value: "Квиз начинается",
			point: 12,
			answers: [
				{ id: 5, text: "Лиссабон", active: true },
				{ id: 6, text: "Барселона", active: false },
				{ id: 7, text: "Берлин", active: true },
				{ id: 8, text: "Неаполь", active: false },
				{ id: 9, text: "Палермо", active: false },
			],
		},
		{
			id: 4,
			quizId: 2,
			value: "Ты уже проходил квиз",
			point: 2,
			answers: [
				{ id: 1, text: "Париж", active: false },
				{ id: 2, text: "Мадрид", active: true },
				{ id: 3, text: "Пекин", active: false },
				{ id: 4, text: "Лондон", active: false },
			],
		},
	];
	return allQuestions.filter((quest) => quest.quizId === quizId);
};

export const getNewQuestion = async (questNum, quizNum) => {
	const newQuestion = {
		...newQuestionData,
		num: questNum,
	};
	if (quizNum >= 0) {
		newQuestion.in_quizes = [quizNum];
	}
	return newQuestion;
};

export const getNewQuiz = async (num) => {
	const newQuiz = { ...newQuizData, num: num };
	return newQuiz;
};

export const getTreeApi = async () => {
	try {
		const res = await axiosInstance.get(Endpoints.MAIN.GET_TREE);
		if (res?.data) return res.data;
		else return;
	} catch (err) {
		console.log(err);
		return;
	}
};

export const addCompanyApi = async (title, company, user_id) => {
	try {
		const res = await axiosInstance.post(Endpoints.MAIN.ADD_COMPANY, {
			title: title,
			company: company,
			user_id: user_id,
		});
		if (res?.data?.company) return res.data;
		else return;
	} catch (err) {
		console.log(err);
		return;
	}
};

export const addProjectApi = async (params) => {
	try {
		const res = await axiosInstance.post(Endpoints.MAIN.ADD_PROJECT, params);
		if (res.data) return res;
		else return;
	} catch (err) {
		console.log(err);
		return;
	}
};

export const addImgApi = async (params) => {
	try {
		const res = await axiosInstance.post(Endpoints.MAIN.ADD_IMG, params, {
			headers: {
				"Content-type": "multipart/form-data",
			},
		});
		if (res.data?.fileUrls) return res.data.fileUrls;
		else throw new Error("Не удалось добавить изображение");
	} catch (err) {
		console.log(err);
		return;
	}
};
