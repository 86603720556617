const shopIcon = (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.8889 0H1.11111V2.25H18.8889V0ZM20 11.25V9L18.8889 3.375H1.11111L0 9V11.25H1.11111V18H12.2222V11.25H16.6667V18H18.8889V11.25H20ZM10 15.75H3.33333V11.25H10V15.75Z"
      fill="white"
    />
  </svg>
);

export default shopIcon;
