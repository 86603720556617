import s from '../Auth.module.scss';
import React, { useState } from 'react';
import { getCookie, setCookie } from '../../../services/cookie';

const Cookie = () => {
  const [opened, setOpened] = useState(!getCookie('cookies_policy'));

  const handleClick = (res) => {
    if (res) {
      setCookie('cookies_policy', 'true', 365);
    }
    setOpened(false);
  };

  return (
    <div className={s.cookie__wrap + (opened ? '' : ' ' + s.hidden)}>
      <div className={s.cookie}>
        <p>Используем куки, чтобы сделать вашу работу удобнее</p>
        <ul className={s.cookie__btnWrap}>
          <button
            onClick={() => handleClick(1)}
            className={'btnLight ' + s.btnCookie}
          >
            Принять все
          </button>
          <button
            onClick={() => handleClick(0)}
            className={'btnLight ' + s.btnCookie + ' ' + s.light}
          >
            Отказаться
          </button>
        </ul>
      </div>
    </div>
  );
};

export default Cookie;
