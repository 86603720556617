import api from "../../api";
import s from "./Common.module.scss";
import { useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Nav = () => {
	const navigate = useNavigate();
	const visibleSubSubListTimer = useRef();
	const curLocation = useLocation().pathname;
	const [navList, setNavList] = useState([]);
	// const [curPage, setCurPage] = useState('');
	const [visibleSubSubList, setVisibleSubSubList] = useState("");

	const main = useSelector((state) => state.main);
	const botData = useSelector((state) => state.bot.curBot);
	const [hidingList] = useState(["/bot-add"]);

	useEffect(() => {
		getNavList(main.lastShopTab);
	}, [main.lastShopTab]);

	const getNavList = async (lastShopTab) => {
		const navList = await api.main.getNavList(lastShopTab);
		if (navList?.length > 0) {
			setNavList(navList);
		}
	};

	const activeNavItemClass = (navItem) => {
		if (
			(curLocation.includes(navItem.path) && navItem.path !== "/") ||
			(curLocation === navItem.path && navItem.path === "/")
		) {
			return " " + s.actived;
		} else if (
			curLocation.startsWith("/shop") &&
			navItem.path.startsWith("/shop")
		) {
			return " " + s.actived;
		} else return "";
	};

	return (
		!hidingList.includes(curLocation) && (
			<nav className={s.nav}>
				{botData.name ? (
					<h3 className={s.nav__title}>{botData.name}</h3>
				) : (
					<h3 className={s.nav__title + " " + s.dark}>Бот</h3>
				)}
				<ul className={s.nav__list}>
					{navList.map((navItem, index) => {
						return (
							<div key={index}>
								<li
									onClick={() => navigate(navItem.path)}
									className={
										s.nav__item +
										" " +
										((curLocation.includes(navItem.path) &&
											navItem.path !== "/") ||
										(curLocation === navItem.path && navItem.path === "/")
											? s.actived
											: "")
									}
								>
									<div className={s.nav__item__imgWrap}>
										{navItem.icon}
										{/* <img src={navItem.icon} alt="" /> */}
									</div>
									<p>{navItem.name}</p>
								</li>
								{/*  sub list  */}

								{navItem.subList && (
									<ul className={s.nav__subList}>
										{navItem.subList.map((navItem, index) => (
											<li
												key={index}
												onClick={() => navigate(navItem.path)}
												className={
													s.nav__item + " " + activeNavItemClass(navItem)
												}
												onMouseOver={() => {
													if (navItem.subList) {
														visibleSubSubListTimer.current &&
															clearTimeout(visibleSubSubListTimer.current);
														setVisibleSubSubList(navItem.name);
													}
												}}
												onMouseOut={() => {
													if (!navItem.subList) return;
													visibleSubSubListTimer.current = setTimeout(() => {
														setVisibleSubSubList("");
													}, 300);
												}}
											>
												<div
													className={
														s.nav__item__imgWrap +
														(" " + s[navItem.iconMode] || "")
													}
												>
													{navItem.icon}
												</div>
												<p>{navItem.name}</p>
												{/* всплывающий список подсписка */}
												{navItem.subList && (
													<ul
														className={[
															s.nav__subSubList,
															visibleSubSubList === navItem.name
																? s.visible
																: "",
														].join(" ")}
													>
														{navItem.subList.map((subNavItem, k) => (
															<li key={k}>
																<button>{subNavItem.name}</button>
															</li>
														))}
													</ul>
												)}
											</li>
										))}
									</ul>
								)}
							</div>
						);
					})}
				</ul>
			</nav>
		)
	);
};

export default Nav;
