import s from './Quizzes.module.scss';
import addMagic from '../../imgs/addMagic';
import { getNewQuestion } from '../../api/main';
import QuizQuestionsRow from './QuizQuestionsRow';
import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import QuizQuestionsPopup from './QuizQuestionsPopup';
import checkSpelling from '../../imgs/checkSpelling';
import PopupAddQuestFromDB from './PopupAddQuestFromDB';

const QuizQuestions = () => {
  const { curQuiz, questions, changeQuestion, allQuestions, addQuestion } =
    useOutletContext();
  const [popupDBopened, setPopupDBopened] = useState(false);
  const [questList, setQuestList] = useState([...questions]);
  const [editPopupOpened, setEditPopupOpened] = useState(false);
  const [editedAns, setEditedAns] = useState([]);
  const [editType, setEditType] = useState('');
  const [btnsList] = useState([
    {
      icon: addMagic,
      name: 'addMagic',
      text: 'добавить магию',
    },
    {
      name: 'checkSpelling',
      icon: checkSpelling,
      text: 'проверить орфографию',
    },
    {
      text: 'загрузить из базы',
      name: 'loadFromDB',
    },
    {
      text: 'перенести в другой квиз',
      name: 'postpone',
    },
    {
      text: 'удалить',
      name: 'delete',
    },
  ]);

  // eslint-disable-next-line
  const [editedQuest, setEditedQuest] = useState({});

  useEffect(() => {
    if (
      questions.length &&
      JSON.stringify(questions) !== JSON.stringify(questList)
    ) {
      setQuestList([...questions]);
    } else if (!questions.length) {
      editedQuest.num >= 0 && setEditedQuest({});
      editedAns.length && setEditedAns([]);
      questList.length && setQuestList([]);
      editType && setEditType('');
    }
    // eslint-disable-next-line
  }, [questions]);

  const addAnswer = () => {
    setEditedAns((prevState) => [...prevState, '']);
  };

  useEffect(() => {
    if (!editPopupOpened) {
      setEditedAns([]);
      setEditType('');
      setEditedQuest({});
    }
  }, [editPopupOpened]);

  const handleBtnRow = (action, rowId) => {
    console.log(action);
    console.log(rowId);
    switch (action) {
      case 'delete':
        delQuest(rowId);
        break;
      default:
        break;
    }
  };

  const delQuest = (questNum) => {
    const question = questions.find((quest) => quest.num === questNum);

    // in_quizes
    if (question && curQuiz.num >= 0) {
      const newInQuizesList = question.in_quizes.filter(
        (quizNum) => quizNum !== curQuiz.num
      );
      const updatedQuest = {
        ...question,
        in_quizes: newInQuizesList,
      };
      changeQuestion(updatedQuest, questNum);
    }
  };

  const delAnswer = (ansIndex, questNum) => {
    const question = questions.find((quest) => quest.num === questNum);
    if (question && ansIndex >= 0) {
      const updatedQuest = {
        ...question,
        options: [
          ...question.options.slice(0, ansIndex),
          ...question.options.slice(ansIndex + 1),
        ],
      };
      updatedQuest && changeQuestion(updatedQuest, questNum);
    }
  };

  const openPopUpEdit = (ansIndex, questNum) => {
    const question = questions.find((quest) => quest.num === questNum);
    if (question) {
      setEditPopupOpened(true);
      setEditedQuest(question);
      if (ansIndex === 'all') {
        setEditType('all');
        setEditedAns(question.options);
      } else {
        setEditType(ansIndex);
        setEditedAns(question.options.slice(ansIndex, ansIndex + 1));
      }
    }
  };

  const changeAns = (val, ansIndex, key) => {
    if (key === 'text') {
      if (editType === 'all') {
        setEditedAns((prev) => [
          ...prev.slice(0, +ansIndex),
          val,
          ...prev.slice(+ansIndex + 1),
        ]);
      } else {
        setEditedAns([val]);
      }
    } else if (key === 'correct') {
      setEditedQuest((prevState) => ({
        ...prevState,
        correct_option_id:
          prevState.correct_option_id === ansIndex ? null : ansIndex,
      }));
    }
  };

  const saveAnswers = () => {
    setTimeout(() => {
      let newQuestion;
      if (editType === 'all') {
        newQuestion = { ...editedQuest, options: editedAns };
      } else {
        console.log(editType);
        newQuestion = {
          ...editedQuest,
          options: [
            ...editedQuest.options.slice(0, editType),
            editedAns[0],
            ...editedQuest.options.slice(editType + 1),
          ],
        };
      }
      if (newQuestion) {
        changeQuestion(newQuestion, newQuestion.num);
        setEditPopupOpened(false);
      }
    }, 250);
  };

  const addQuest = async (quest) => {
    if (quest) {
      // add from db
      addQuestion(quest, quest.num);
    } else {
      const newNum =
        (allQuestions?.length &&
          allQuestions[allQuestions.length - 1]?.num + 1) ||
        0;
      const newQuestion = await getNewQuestion(newNum, curQuiz.num);
      changeQuestion(newQuestion, newQuestion.num);
    }
  };

  return (
    <>
      <div className={'scroll ' + s.quiz__main__body}>
        <div className={s.questions}>
          <PopupAddQuestFromDB
            addQuest={addQuest}
            isOpen={popupDBopened}
            questions={allQuestions}
            quizQuestList={questions}
            close={() => setPopupDBopened(false)}
          />
          <QuizQuestionsPopup
            save={saveAnswers}
            editType={editType}
            addAnswer={addAnswer}
            changeAns={changeAns}
            editedAns={editedAns}
            editPopupOpened={editPopupOpened}
            setEditPopupOpened={setEditPopupOpened}
            correctAnsIndex={editedQuest.correct_option_id}
          />
          <div className={s.titleWrap}>
            <h3 className={s.title}>Вопросы</h3>
            <button
              onClick={() => addQuest()}
              className={'btnDark add ' + s.btnAdd}
            >
              Создать вопрос
            </button>
            <button
              onClick={() => setPopupDBopened(true)}
              className={'btnDark add ' + s.btnAdd}
            >
              Вопрос из базы
            </button>
          </div>
          <ul className={s.textList}>
            {questList.map((questItem, index) => (
              <QuizQuestionsRow
                index={index}
                btnsList={btnsList}
                key={questItem.num}
                questItem={questItem}
                delAnswer={delAnswer}
                handleBtnRow={handleBtnRow}
                openPopUpEdit={openPopUpEdit}
                changeQuestItem={changeQuestion}
              />
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default QuizQuestions;
