import React from 'react';
import s from './Auth.module.scss';
import { useOutletContext } from 'react-router-dom';

//{ chooseOption }
const FormStart = () => {
  const [chooseOption] = useOutletContext();

  return (
    <>
      <h1 className={s.title}>Войти или создать аккаунт</h1>
      <p className={s.text}>
        Зарегистрируйтесь бесплатно и получите доступ к платформе геймификации
      </p>
      <ul className={s.btnWrap + ' ' + s.vert + ' ' + s.oneWidth}>
        <button
          onClick={() => chooseOption('email')}
          className={'btnLight ' + s.btnForm}
        >
          <i className={'icon ' + s.iconMes}></i>
          <span>С помощью почты</span>
        </button>
        <button
          onClick={() => chooseOption('tg')}
          className={'btnDark ' + s.btnForm}
        >
          <i className={'icon ' + s.iconTg}></i>
          <span>С помощью телеграм</span>
        </button>
      </ul>
    </>
  );
};

export default FormStart;
