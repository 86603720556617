import s from "./Common.module.scss";
import { addImgApi } from "../../api/main";
import { useEffect, useState } from "react";
import addImgIcon from "../../imgs/addImgIcon";

const InputImg = ({ disabled, img, setImg, className = "", id = "" }) => {
	const [src, setSrc] = useState(img);

	useEffect(() => {
		setSrc(img);
	}, [img]);

	const handleChange = async (e) => {
		e.preventDefault();
		const file = e?.target?.files[0];
		if (file) {
			const formData = new FormData();
			formData.append("image", file);
			const data = await addImgApi(formData);
			console.log("data", data);
			if (data?.image) {
				setImg(data.image);
				setSrc(data.image);
			} else {
				console.log("Не удалось загрузить изображение");
			}
			// setImg(e.target.files[0]);
			// setSrc(URL.createObjectURL(e.target.files[0]));
		}
		e.target.value = "";
	};

	return (
		<div
			className={
				s.inputImgWrap +
				" " +
				(disabled ? s.disabled : "") +
				" " +
				(src ? s.fill : "") +
				" " +
				className
			}
		>
			<input
				name="img"
				type="file"
				id={"file_input_" + id}
				accept="image/*"
				disabled={disabled}
				onChange={handleChange}
			/>
			<label htmlFor={"file_input_" + id}></label>
			{src && (
				<img
					className={s.img}
					src={src}
					alt=""
				/>
			)}
			{!src && addImgIcon}
		</div>
	);
};

export default InputImg;
