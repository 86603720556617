import "swiper/css";
import s from "./Polls.module.scss";
import plus from "../../imgs/plusSelect";
import PollsHeaderItem from "./PollsHeaderItem";
import { Swiper, SwiperSlide } from "swiper/react";
import React, { useEffect, useRef, useState } from "react";

const PollsHeader = ({ items = [], curItem, setCurItem, delItem, addItem }) => {
	const [slider, setSlider] = useState({ next: false, back: false });
	const swiperRef = useRef();

	const checkSwiper = (swiper) => {
		if (!swiperRef.current) {
			swiperRef.current = swiper;
		}
		if (swiper.isBeginning) {
			setSlider((prevState) => ({ ...prevState, back: false }));
		} else {
			setSlider((prevState) => ({ ...prevState, back: true }));
		}
		if (swiper.isEnd) {
			setSlider((prevState) => ({ ...prevState, next: false }));
		} else {
			setSlider((prevState) => ({ ...prevState, next: true }));
		}
	};

	useEffect(() => {
		if (items.length && swiperRef.current) {
			checkSwiper(swiperRef.current);
		}
	}, [items]);

	return (
		<div className={s.header}>
			<h1 className={s.title}>Опросы</h1>
			<div className={s.headerItemWrap}>
				<div className={s.headerItemListWrap}>
					<ul className={s.headerItemList}>
						<Swiper
							spaceBetween={10}
							slidesPerView={4}
							onSwiper={(swiper) =>
								setTimeout(() => {
									checkSwiper(swiper);
								}, 0)
							}
							onSlideChange={(swiper) => checkSwiper(swiper)}
						>
							{items.map((item, index) => (
								<SwiperSlide key={index}>
									<PollsHeaderItem
										item={item}
										delItem={delItem}
										isActive={curItem.num === item.num}
										handleClick={() => setCurItem(item)}
									/>
								</SwiperSlide>
							))}
						</Swiper>
					</ul>
					{slider.back && (
						<button
							onClick={() => swiperRef.current?.slidePrev()}
							className={s.swiperBtnBack}
						></button>
					)}
					{slider.next && (
						<button
							onClick={() => swiperRef.current?.slideNext()}
							className={s.swiperBtnNext}
						></button>
					)}
				</div>
				<button
					onClick={addItem}
					className={s.btnAddItem}
				>
					<div className={s.btnAddItemImgWrap}>{plus}</div>
					<p>Создать опрос</p>
				</button>
			</div>
		</div>
	);
};

export default PollsHeader;
