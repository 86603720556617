import s from './Common.module.scss';
import React, { useState } from 'react';
import delIcon from '../../imgs/trashBin';
import plusIcon from '../../imgs/plusSelect';

const InputBlock = ({
  name,
  label,
  disabled,
  value = [],
  placeholder,
  handleChange,
  className = '',
}) => {
  const [val, setVal] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    setVal('');
    handleChange(val, 'add');
  };

  return (
    <div className={s.inputBlock__wrap + ' ' + className}>
      {label && (
        <label htmlFor={name}>
          <span>{label}</span>
        </label>
      )}
      <div className={s.inputBlock}>
        <form onSubmit={handleSubmit} className={s.inputBlock__inputWrap}>
          <input
            name={name}
            value={val}
            disabled={disabled}
            placeholder={placeholder}
            className={s.inputBlock__input}
            onChange={(e) => setVal(e.target.value)}
          />
          <button className={s.inputBlock__btnSave} disabled={!val}>
            {plusIcon}
            <p>Добавить</p>
          </button>
        </form>
        <div className={s.inputBlock__valueList}>
          {value.map((v, i) => (
            <button
              key={i}
              className={s.inputBlock__valueItem}
              onClick={() => handleChange(v, 'del')}
            >
              <span>{v}</span>
              {delIcon}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default InputBlock;
