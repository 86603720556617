import { useState } from "react";
import s from "./Polls.module.scss";
import quizDel from "../../imgs/quizDel";
import quizEdit from "../../imgs/quizEdit";
import copyIcon from "../../imgs/copyIcon";
import Select from "../../components/common/Select";
import InputImg from "../../components/common/InputImg";
import Switcher from "../../components/common/Switcher";
import Textarea from "../../components/common/Textarea";
import QuizQuestionsPopup from "../Quizzes/QuizQuestionsPopup";

const PollsQuestionItem = ({
	num,
	type,
	media,
	index,
	active,
	options,
	question,
	delItem,
	changeItem,
	correct_option_id = -1,
}) => {
	const [editType, setEditType] = useState("");
	const [editedAns, setEditedAns] = useState([]);
	const [showedSelectName, setShowedSelectName] = useState("");
	const [editPopupOpened, setEditPopupOpened] = useState(false);
	const [lists] = useState({
		questionType: [
			"любой ответ",
			"только фото",
			"ТГ рекомендации",
			"ТГ мультивариант",
			"ТГ единственный вариант",
		],
	});

	const showList = (key) => {
		key === showedSelectName
			? setShowedSelectName("")
			: setShowedSelectName(key);
	};

	const openPopUpEdit = (ansIndex, questNum) => {
		// const question = questions.find((quest) => quest.num === questNum);
		setEditPopupOpened(true);
		// setEditedQuest(question);
		if (ansIndex === "all") {
			setEditType("all");
			setEditedAns(options);
		} else {
			setEditType(ansIndex);
			setEditedAns(options.slice(ansIndex, ansIndex + 1));
		}
	};

	const delAnswer = (ansIndex) => {
		const updatedAnswers = [
			...options.slice(0, ansIndex),
			...options.slice(ansIndex + 1),
		];
		changeItem(updatedAnswers, num, "options");
	};

	const changeItemsAns = (val, ansIndex, key) => {
		if (key === "text") {
			if (editType === "all") {
				setEditedAns((prev) => [
					...prev.slice(0, +ansIndex),
					val,
					...prev.slice(+ansIndex + 1),
				]);
			} else {
				setEditedAns([val]);
			}
		} else if (key === "correct") {
			// changeItem(num, "correct_option_id", ansIndex);
			// setEditedQuest((prevState) => ({
			// 	...prevState,
			// 	correct_option_id:
			// 		prevState.correct_option_id === ansIndex ? null : ansIndex,
			// }));
		}
	};

	const addAnswer = (key, val) => {
		setEditedAns((prevState) => [...prevState, ""]);
	};

	const saveAnswers = () => {
		setTimeout(() => {
			let updatedAnswers;
			if (editType === "all") {
				updatedAnswers = [...editedAns];
			} else {
				updatedAnswers = [
					...options.slice(0, editType),
					editedAns[0],
					...options.slice(editType + 1),
				];
			}
			if (updatedAnswers) {
				changeItem(updatedAnswers, num, "options");
				setEditPopupOpened(false);
			}
		}, 250);
	};

	return (
		<li className={s.question}>
			<QuizQuestionsPopup
				withOutCorrect
				save={saveAnswers}
				editType={editType}
				addAnswer={addAnswer}
				editedAns={editedAns}
				changeAns={changeItemsAns}
				editPopupOpened={editPopupOpened}
				setEditPopupOpened={setEditPopupOpened}
			/>

			<div className={s.questionLabel}>
				<label htmlFor="questValue">Вопрос {index + 1}</label>
				<button className={"btnCopy"}>{copyIcon}</button>
				<Switcher
					isOn={active}
					label={"активен"}
					className={s.switcher}
					handleClick={() => changeItem(!active, num, "active")}
				/>
			</div>
			<div className={s.questionInputWrap}>
				<Textarea
					onReset={true}
					placeholder=""
					val={question}
					className={s.questionTextInput}
					handleChange={(val) => changeItem(val, num, "question")}
				/>
				<InputImg
					img={media}
					id={`questImg_${num}`}
					setImg={(img) => changeItem(img, num, "media")}
				/>
				<Select
					value={type}
					selected={type}
					className={s.select}
					topLabel="тип вопроса"
					selectList={lists.questionType}
					showList={() => showList("afterTimeout")}
					isShowed={showedSelectName === "afterTimeout"}
					select={(val) => changeItem(val, num, "type")}
				/>
				<button
					className={s.btnDel}
					onClick={() => delItem(num)}
				>
					{quizDel}
				</button>
			</div>
			<div className={s.questionAnswersWrap}>
				<div className={s.questionAnswersTitle}>
					<span>Варианты ответа:</span>
					<button
						className={s.btnEdit}
						onClick={() => openPopUpEdit("all", num)}
					>
						редактировать все
					</button>
				</div>
				<div className={s.questionAnswerList}>
					{options.map((option, index) => (
						<div
							key={index}
							className={
								s.questionAnswer +
								(index === correct_option_id ? " " + s.active : "")
							}
						>
							<p>{option}</p>
							<button
								className={s.questionAnswerBtn}
								onClick={() => openPopUpEdit(index, num)}
							>
								{quizEdit}
							</button>
							<button
								className={s.questionAnswerBtn}
								onClick={() => delAnswer(index, num)}
							>
								{quizDel}
							</button>
						</div>
					))}
				</div>
			</div>
		</li>
	);
};

export default PollsQuestionItem;
