import React from 'react';
import s from './Controller.module.scss';

const UsersInfo = ({ icon, name, value, isNew = false }) => {
  return (
    <li className={s.users__block}>
      <div className={s.users__block__imgWrap}>
        <img src={icon} alt="" />
        {isNew && <span>new</span>}
      </div>
      <div className={s.users__block__infoWrap}>
        <p className={s.users__block__name}>{name}</p>
        <p className={s.users__block__value + ' ' + (isNew ? s.green : '')}>
          {isNew ? '+' + value : value}
        </p>
      </div>
    </li>
  );
};

export default UsersInfo;
