import { addCompanyApi, addProjectApi, getTreeApi } from '../../api/main';
import {
  addCompanyFailure,
  addCompanyStart,
  addCompanySuccess,
  addProjectFailure,
  addProjectStart,
  addProjectSuccess,
  loadTreeFailure,
  loadTreeStart,
  loadTreeSuccess,
  setCompanyListSuccess,
  setCurCompanySuccess,
  setCurProjectSuccess,
  setLastShopTabSuccess,
  setProjectListSuccess,
  updateTreeFailure,
  updateTreeStart,
  updateTreeSuccess,
} from './mainReducer';

export const getTree = () => async (dispatch) => {
  try {
    dispatch(loadTreeStart());
    const data = await getTreeApi();
    // console.log('getTree', data);
    if (!data) throw new Error('ошибка');
    dispatch(loadTreeSuccess(data));
    return data;
  } catch (e) {
    // console.error(e);
    dispatch(loadTreeFailure(e.message));
  }
};

export const updateTree = () => async (dispatch) => {
  try {
    dispatch(updateTreeStart());
    const data = await getTreeApi();
    // console.log('updateTreeSuccess', data);
    if (!data) throw new Error('ошибка');
    dispatch(updateTreeSuccess(data));
    return data;
  } catch (e) {
    console.error(e);
    dispatch(updateTreeFailure());
  }
};

export const addCompany = (title, company, user_id) => async (dispatch) => {
  try {
    dispatch(addCompanyStart());
    const data = await addCompanyApi(title, company, user_id);
    if (!data) throw new Error('ошибка');
    dispatch(addCompanySuccess({ name: company, title: title }));
    return data;
  } catch (e) {
    // console.error(e);
    dispatch(addCompanyFailure(e.message));
  }
};

export const addProject = (params) => async (dispatch) => {
  try {
    dispatch(addProjectStart());
    const formData = params;
    delete formData.companyIndex;
    const data = await addProjectApi(params);
    if (!data) throw new Error('ошибка');

    dispatch(addProjectSuccess({ name: params.project, title: params.title }));
    return data;
  } catch (e) {
    // console.error(e);
    dispatch(addProjectFailure(e.message));
  }
};

export const setCurCompany = (company) => async (dispatch) => {
  localStorage.setItem('curCompany', JSON.stringify(company));
  // console.log('setCurCompany');
  dispatch(setCurCompanySuccess(company));
};

export const setCurProject = (project) => async (dispatch) => {
  // console.log('setCurProject');
  localStorage.setItem('curProject', JSON.stringify(project));
  dispatch(setCurProjectSuccess(project));
};

//

export const setProjectList = (projectList) => async (dispatch) => {
  // console.log('setProjectList');
  dispatch(setProjectListSuccess(projectList));
};

export const setCompanyList = (companyList) => async (dispatch) => {
  // console.log('setCompanyList');
  dispatch(setCompanyListSuccess(companyList));
};

export const setLastShopTab = (href) => async (dispatch) => {
  // console.log('setCompanyList');
  localStorage.setItem('lastShopTab', JSON.stringify(href));
  dispatch(setLastShopTabSuccess(href));
};
