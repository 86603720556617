import { useEffect, useState } from "react";
import s from "./Survey.module.scss";
import PopUp from "../../components/common/PopUp";
import Input from "../../components/common/Input";
import Select from "../../components/common/Select";
import Checkbox from "../../components/common/Checkbox";
import Textarea from "../../components/common/Textarea";

const PopupEditSurveyModule = ({ close, moduleData, saveModule }) => {
	const [data, setData] = useState(moduleData);
	const [surveyTypeList] = useState(["Общий"]);
	const [showedList, setShowedList] = useState("");
	const [stepList] = useState([
		{ id: 1, name: "Название вопроса" },
		{ id: 2, name: "Настройки" },
		{ id: 3, name: "Запрос" },
		{ id: 4, name: "Подтверждение" },
		{ id: 5, name: "Проверка" },
		{ id: 6, name: "Финал" },
	]);
	const [activeStep, setActiveStep] = useState(1);

	useEffect(() => {
		console.log(data);
	}, [data]);

	const handleChange = (val, key1, key2, key3, i) => {
		if (i >= 0) {
			setData((prev) => ({
				...prev,
				[key1]: {
					...prev[key1],
					[key2]: {
						...prev[key1][key2],
						[key3]: [
							...prev[key1][key2][key3].slice(0, i),
							val,
							...prev[key1][key2][key3].slice(i + 1),
						],
					},
				},
			}));
		} else if (key3) {
			setData((prev) => ({
				...prev,
				[key1]: { ...prev[key1], [key2]: { ...prev[key1][key2], [key3]: val } },
			}));
		}
	};

	const next = () => {
		setActiveStep((prev) => prev + 1);
	};

	const back = () => {
		setActiveStep((prev) => prev - 1);
	};

	const showList = (listName) => {
		setShowedList((prev) => (prev === listName ? "" : listName));
	};

	return (
		<PopUp
			close={close}
			isOpen={true}
			className={s.popup}
			contentClassName={s.popupContent}
		>
			<div className={s.stepList}>
				{stepList.map((step, i) => (
					<div
						key={i}
						className={s.step + " " + (activeStep === step.id ? s.active : "")}
					>
						<div className={s.stepNumb}>{step.id}</div>
						<div className={s.stepName}>{step.name}</div>
					</div>
				))}
			</div>
			{data && (
				<div className={s.popupMain + " scroll"}>
					{activeStep === 1 && (
						<>
							<h2>Придумайте название для вашего вопроса</h2>
							<Input
								onReset={true}
								label={"Название вопроса"}
								className={s.popup__input}
								name={"surveyModNameInput"}
								value={data.common.name.data}
								placeholder={"Введите текст"}
								handleChange={(val) =>
									handleChange(val, "common", "name", "data")
								}
							/>
						</>
					)}
					{activeStep === 2 && (
						<>
							<h2>Настройки: {data.common.name.data}</h2>
							<div className={s.surveyForm}>
								<Select
									topLabel="Тип"
									className={s.popupSelect}
									selectList={surveyTypeList}
									value={data.common.type.data}
									btnClassName={s.btnPopupSelect}
									selected={data.common.type.data}
									isShowed={showedList === "surveyType"}
									showList={() => showList("surveyType")}
									select={(val) => handleChange(val, "common", "type", "data")}
								/>
								<Input
									onReset={true}
									className={s.popup__input}
									name={"surveyModBallsTextInput"}
									label={"Текст баллы"}
									handleChange={(val) =>
										handleChange(val, "common", "balls_text", "data")
									}
									value={data.common.balls_text.data}
								/>
								<Input
									onReset={true}
									label={"Баллы"}
									name={"surveyModBallsInput"}
									value={data.common.balls.data}
									className={s.popup__input + " " + s.mini}
									handleChange={(val) =>
										handleChange(val, "common", "balls", "data")
									}
								/>
								<div className={s.waitingTimeInput}>
									<Input
										onReset={true}
										name={"surveyModDelayInput"}
										value={data.common.delay.data}
										label={"Время ожидания значения"}
										className={s.popup__input + " " + s.mini}
										wrapClassName={s.inputWrap}
										handleChange={(val) =>
											handleChange(val, "common", "delay", "data")
										}
									/>
									<Checkbox
										label="Обязательное значение"
										isOn={data.common.required.data}
										className={s.waitingTimeCheckbox}
										handleClick={
											() =>
												handleChange(
													!data.common.required.data,
													"common",
													"required",
													"data"
												)
											//   handleChangeCheck(row.key, !row.check.value)
										}
									/>
								</div>
							</div>
						</>
					)}
					{activeStep === 3 && (
						<>
							<h2>Запрос: {data.common.name.data}</h2>
							<div className={s.surveyForm}>
								<div className={"row gap-1 " + s.row}>
									<Textarea
										val={data.request.text.data}
										label={"Запрос текста"}
										id={"surveyModReqTextInput"}
										handleChange={(val) =>
											handleChange(val, "request", "text", "data")
										}
										className={s.popup__textarea}
										placeholder={"Введите текст"}
									/>

									<Textarea
										val={data.request.media.data}
										label={"Запрос медиа"}
										className={s.popup__textarea}
										placeholder={"Введите текст"}
										id={"surveyModReqMediaInput"}
										handleChange={(val) =>
											handleChange(val, "request", "media", "data")
										}
									/>
								</div>
								{/* btns req */}
								<div className={s.reqBtnList}>
									{data.request.buts.data?.map((btnVal, i) => (
										<Textarea
											key={i}
											val={btnVal}
											label={"Запрос кнопки " + (i + 1)}
											id={"surveyModReqBtnInput_" + i}
											className={s.popup__textarea}
											placeholder={"Введите текст"}
											handleChange={(val) =>
												handleChange(val, "request", "buts", "data", i)
											}
										/>
									))}
								</div>
								<button
									onClick={() =>
										handleChange(
											"",
											"request",
											"buts",
											"data",
											data.request.buts.data.length
										)
									}
									className={"btnGrey add " + s.btnAddBtn}
								>
									Добавить кнопку
								</button>
								<div className={"row gap-1 " + s.step3bottom}>
									<Select
										selectList={[
											"-",
											"имя",
											"фамилия",
											"тг аккаунт",
											"телефон",
											"аватарка",
											"имя+фамилия",
											"био",
											"день рождения",
											"личный канал",
										]}
										className={s.popupSelect}
										btnClassName={s.btnPopupSelect}
										topLabel="Используем из мессенджера"
										isShowed={showedList === "use_from_messenger"}
										showList={() => showList("use_from_messenger")}
										select={(val) =>
											handleChange(val, "request", "use_from_messenger", "data")
										}
										value={data.request.use_from_messenger.data}
										selected={data.request.use_from_messenger.data}
									/>
									<Input
										onReset={true}
										label={"Время таймаута"}
										name={"surveyModConfTimeoutInput"}
										wrapClassName={s.inputWrap}
										className={s.popup__input + " " + s.mini}
										value={data.confirmation.time_out.data}
										handleChange={(val) =>
											handleChange(+val, "confirmation", "time_out", "data")
										}
									/>
									<div className={s.waitingTimeInput}>
										<Input
											onReset={true}
											wrapClassName={s.inputWrap}
											name={"surveyModReAskInput"}
											value={data.re_ask.count.data}
											label={"Сколько переспрашиваем"}
											className={s.popup__input + " " + s.mini}
											handleChange={(val) =>
												handleChange(val, "re_ask", "count", "data")
											}
										/>
										{/* <Checkbox
                      label="Переспрашиваем"
                      isOn={data.askAgain}
                      className={s.waitingTimeCheckbox}
                      handleClick={
                        () => handleChange()
                        //   handleChangeCheck(row.key, !row.check.value)
                      }
                    /> */}
									</div>
								</div>
							</div>
						</>
					)}
					{activeStep === 4 && (
						<>
							<h2>Подтверждение: {data.common.name.data}</h2>
							<div className={s.surveyForm}>
								<Checkbox
									label="Подтверждаем"
									isOn={data.confirmation.use_confirm.data}
									className={s.waitingTimeCheckbox}
									handleClick={() =>
										handleChange(
											!data.confirmation.use_confirm.data,
											"confirmation",
											"use_confirm",
											"data"
										)
									}
								/>
								<Input
									onReset={true}
									wrapClassName={s.inputWrap}
									name={"surveyModConfTimeoutInput"}
									label={"Время ожидания подтверждения"}
									className={s.popup__input + " " + s.mini}
									handleChange={(val) =>
										handleChange(+val, "confirmation", "delay", "data")
									}
									value={data.confirmation.delay.data}
								/>
								<div className={"row gap-1 " + s.row}>
									<Textarea
										val={data.confirmation.text.data}
										className={s.popup__textarea}
										id={"surveyModConfTextInput"}
										placeholder={"Введите текст"}
										label={"Подтверждение текст"}
										handleChange={(val) =>
											handleChange(val, "confirmation", "text", "data")
										}
									/>
									<Textarea
										className={s.popup__textarea}
										placeholder={"Введите текст"}
										label={"Подтверждение медиа"}
										id={"surveyModConfMediaInput"}
										val={data.confirmation.media.data}
										handleChange={(val) =>
											handleChange(val, "confirmation", "media", "data")
										}
									/>
								</div>
								<div className={s.reqBtnList}>
									{data.confirmation.buts.data?.map((btnVal, i) => (
										<Textarea
											key={i}
											val={btnVal}
											className={s.popup__textarea}
											placeholder={"Введите текст"}
											id={"surveyModConfBtnInput_" + i}
											label={"Кнопка подтверждения " + (i + 1)}
											handleChange={(val) =>
												handleChange(val, "confirmation", "buts", "data", i)
											}
										/>
									))}
								</div>
								<button
									onClick={() =>
										handleChange(
											"",
											"confirmation",
											"buts",
											"data",
											data.confirmation.buts.data.length
										)
									}
									className={"btnGrey add " + s.btnAddBtn}
								>
									Добавить кнопку
								</button>
							</div>
						</>
					)}
					{activeStep === 5 && (
						<>
							<h2>Проверка: {data.common.name.data}</h2>
							<div className={s.surveyForm}>
								<div className={"row gap-1 " + s.row}>
									<Input
										value={data.check.limit.data}
										onReset={true}
										label={"Текстовый лимит"}
										wrapClassName={s.inputWrap}
										name={"surveyModCheckLimitInput"}
										className={s.popup__input + " " + s.mini}
										handleChange={(val) =>
											handleChange(val, "check", "limit", "data")
										}
									/>
									<Input
										value={data.check.format.data}
										onReset={true}
										label={"Формат"}
										wrapClassName={s.inputWrap}
										name={"surveyModCheckFormatInput"}
										className={s.popup__input + " " + s.mini}
										handleChange={(val) =>
											handleChange(val, "check", "format", "data")
										}
									/>
								</div>
								<div className={"row gap-1 " + s.row}>
									<Textarea
										className={s.popup__textarea}
										placeholder={"Введите текст"}
										label={"Текст ошибки лимита"}
										val={data.check.limit_error.data}
										id={"surveyModCheckLimitErrInput"}
										handleChange={(val) =>
											handleChange(val, "check", "limit_error", "data")
										}
									/>
									{/* <button
                  onClick={handleChange}
                  className={'btnGrey add ' + s.btnAddBtn}
                >
                  Добавить текст
                </button> */}
									<Textarea
										className={s.popup__textarea}
										placeholder={"Введите текст"}
										label={"Текст ошибки формата"}
										val={data.check.format_error.data}
										id={"surveyModCheckFormatErrInput"}
										handleChange={(val) =>
											handleChange(val, "check", "format_error", "data")
										}
									/>
								</div>
								{/* <button
                  onClick={handleChange}
                  className={'btnGrey add ' + s.btnAddBtn}
                >
                  Добавить текст
                </button> */}
								<div className={"row gap-1 " + s.row}>
									<Textarea
										label={"Префикс"}
										className={s.popup__textarea}
										placeholder={"Введите текст"}
										id={"surveyModCheckPrefixInput"}
										val={data.check.add_prefix.data}
										handleChange={(val) =>
											handleChange(val, "check", "add_prefix", "data")
										}
									/>
									<Textarea
										label={"Постфикс"}
										className={s.popup__textarea}
										placeholder={"Введите текст"}
										id={"surveyModCheckPostfixInput"}
										val={data.check.add_postfix.data}
										handleChange={(val) =>
											handleChange(val, "check", "add_postfix", "data")
										}
									/>
								</div>
							</div>
						</>
					)}
					{activeStep === 6 && (
						<>
							<h2>Финал: {data.common.name.data}</h2>
							<div className={s.surveyForm}>
								<div className={"row gap-1 " + s.row}>
									<Textarea
										val={data.final.text.data}
										label={"Финал текст"}
										className={s.popup__textarea}
										placeholder={"Введите текст"}
										id={"surveyModFinalTextInput"}
										handleChange={(val) =>
											handleChange(val, "final", "text", "data")
										}
									/>
									<Textarea
										label={"Финал медиа"}
										val={data.final.media.data}
										className={s.popup__textarea}
										placeholder={"Введите текст"}
										id={"surveyModFinalMediaInput"}
										handleChange={(val) =>
											handleChange(val, "final", "media", "data")
										}
									/>
								</div>
							</div>
						</>
					)}
				</div>
			)}
			<div className={s.popupBtns}>
				<div>
					{activeStep > 1 && (
						<button
							onClick={back}
							className={"btnDark"}
						>
							Назад
						</button>
					)}
				</div>
				<div>
					{activeStep < stepList.length && (
						<button
							onClick={next}
							className={"btnLight"}
							disabled={!data.common?.name?.data}
						>
							Далее
						</button>
					)}
					{activeStep === stepList.length && (
						<button
							disabled={false}
							className={"btnLight"}
							onClick={() => saveModule(data)}
						>
							Готово
						</button>
					)}
				</div>
			</div>
		</PopUp>
	);
};

export default PopupEditSurveyModule;
