export default (
  <svg
    width="24"
    height="20"
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.0517 0.22731L0.78878 8.38724C-0.0668675 8.76722 -0.356277 9.52823 0.581933 9.94121L6.03682 11.6664L19.2259 3.55455C19.946 3.04528 20.6833 3.18109 20.0489 3.74127L8.72124 13.9482L8.3654 18.2678C8.69499 18.9348 9.29847 18.9379 9.68339 18.6064L12.8174 15.6553L18.1848 19.6551C19.4314 20.3896 20.1097 19.9156 20.3779 18.5694L23.8985 1.97962C24.264 0.322541 23.6407 -0.407563 22.0517 0.22731Z"
      fill="#777A96"
    />
  </svg>
);
