import './DiagramColors.scss';
import COLORS from './colors.json';
// import s from './Diagram.module.scss';
import {
  Pie,
  Cell,
  Tooltip,
  PieChart as Chart,
  ResponsiveContainer,
} from 'recharts';

const PieChart = ({ data, className }) => {
  return (
    <div className={className}>
      <ResponsiveContainer width="100%" height="100%">
        <Chart width={1000} height={1000}>
          <Pie
            label
            cx="50%"
            cy="50%"
            data={data}
            fill={'#fff'}
            outerRadius={90}
            dataKey={'value'}
            isAnimationActive={false}
          >
            {data.map((entry, index) => (
              <Cell key={index} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
        </Chart>
      </ResponsiveContainer>
    </div>
  );
};

export default PieChart;
