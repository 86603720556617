import Service from './Service';
import tgImg from '../../imgs/serviceTg.svg';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import s from './Controller.module.scss';
import servicePlay from '../../imgs/servicePlay.svg';
import refreshSvg from '../../imgs/serviceRefresh.js';
import serviceServer from '../../imgs/serviceServer.svg';

const BlockService = ({ bot, connectBot }) => {
  const navigate = useNavigate();
  const [defStatuses] = useState({
    bot: { value: true, name: 'Подключен и работает' },
    server: { value: true, name: 'Подключен и работает' },
    startModules: { value: true, name: 'Подключен и работает' },
  });
  const [statuses, setStatuses] = useState(defStatuses);

  useEffect(() => {
    if (!bot.name) {
      setStatuses({
        bot: { value: false, name: 'Не подключен' },
        server: { value: true, name: 'Подключен и работает' },
        startModules: {
          value: false,
          name: 'Нужна настройка',
        },
      });
    } else {
      setStatuses(defStatuses);
    }
  }, [bot, defStatuses]);

  const refreshTg = () => {
    console.log('refreshTg');
  };

  const refreshServer = () => {
    console.log('refreshServer');
  };

  const tuneStartModules = () => {
    navigate('/start-modules');
  };

  return (
    <div className={s.controller__block}>
      <h3 className={s.controller__block__title}>
        Готовность компонентов сервиса
      </h3>
      <ul className={s.controller__service__list}>
        <Service
          icon={tgImg}
          name="Телеграм-бот"
          status={statuses.bot}
          controlImg={bot.name ? refreshSvg : ''}
          controlName={!bot.name ? 'Подключить' : ''}
          handleClick={bot.name ? refreshTg : connectBot}
        />
        <Service
          icon={serviceServer}
          name="Сервера платформы"
          controlImg={refreshSvg}
          handleClick={refreshServer}
          status={statuses.server}
        />
        <Service
          icon={servicePlay}
          name="Стартовые модули"
          controlName="Настроить"
          handleClick={tuneStartModules}
          status={statuses.startModules}
        />
      </ul>
    </div>
  );
};

export default BlockService;
