import App from "./App";
import React from "react";
import "./styles/index.scss";
import { store } from "./store";
import Menu from "./pages/Menu/Menu";
import Auth from "./pages/Auth/Auth";
import Main from "./pages/Main/Main";
import Shop from "./pages/Shop/Shop";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import FormTg from "./pages/Auth/FormTg";
import BotEdit from "./pages/Bot/BotEdit";
import ErrorPage from "./pages/ErrorPage";
import Polls from "./pages/Polls/Polls";
import Survey from "./pages/Survey/Survey";
import Quizzes from "./pages/Quizzes/Quizzes";
import ShopGoods from "./pages/Shop/ShopGoods";
import FormToken from "./pages/Auth/FormToken";
import FormStart from "./pages/Auth/FormStart";
import FormEmail from "./pages/Auth/FormEmail";
import QuizEdit from "./pages/Quizzes/QuizEdit";
import ShopShared from "./pages/Shop/ShopShared";
import QuizCommon from "./pages/Quizzes/QuizCommon";
import Challenge from "./pages/Challenge/Challenge";
import FormKeyCheck from "./pages/Auth/FormKeyCheck";
import ShopSettings from "./pages/Shop/ShopSettings";
import Controller from "./pages/Controller/Controller";
import ShopStatistic from "./pages/Shop/ShopStatistic";
import QuizQuestions from "./pages/Quizzes/QuizQuestions";
import StartModules from "./pages/StartModules/StartModules";
import QuizQuestionsDB from "./pages/Quizzes/QuizQuestionsDB";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <Main />,
        children: [
          { path: "", element: <Controller /> },
          { path: "start-modules", element: <StartModules /> },
          {
            path: "quizzes",
            element: <Quizzes />,
            children: [
              { path: "", element: <QuizCommon /> },
              { path: "edit", element: <QuizEdit /> },
              { path: "questions", element: <QuizQuestions /> },
              { path: "questions-db", element: <QuizQuestionsDB /> },
            ],
          },
          {
            path: "survey",
            element: <Survey />,
          },
          {
            path: "bot-edit",
            element: <BotEdit />,
          },
          {
            path: "menu",
            element: <Menu />,
          },
          {
            path: "challenge",
            element: <Challenge />,
          },
          {
            path: "polls",
            element: <Polls />,
          },
          {
            path: "shop",
            element: <Shop />,
          },
          {
            path: "shop/statistic",
            element: <ShopStatistic />,
          },
          {
            path: "shop/goods",
            element: <ShopGoods />,
          },
          {
            path: "shop/settings",
            element: <ShopSettings />,
          },
        ],
      },
      {
        path: "/share/shop/:slug",
        element: <ShopShared />,
      },
      {
        path: "auth",
        element: <Auth />,
        children: [
          { path: "", element: <FormStart /> },
          { path: "tg", element: <FormTg /> },
          { path: "email", element: <FormEmail /> },
          { path: "token", element: <FormToken /> },
          { path: "keyCheck", element: <FormKeyCheck /> },
        ],
      },

      { path: "*", element: <Main /> },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
  // </React.StrictMode>
);
