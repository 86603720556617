import back from '../../imgs/back';
import s from './Quizzes.module.scss';
import addMagic from '../../imgs/addMagic';
import { getNewQuestion } from '../../api/main';
import QuizQuestionsRow from './QuizQuestionsRow';
import React, { useEffect, useState } from 'react';
import QuizQuestionsPopup from './QuizQuestionsPopup';
import checkSpelling from '../../imgs/checkSpelling';
import { useNavigate, useOutletContext } from 'react-router-dom';

const QuizQuestionsDB = () => {
  const { quizzes, changeQuestion, questions } = useOutletContext();
  const navigate = useNavigate();
  const [editType, setEditType] = useState('');
  const [questList, setQuestList] = useState([]);
  const [editedAns, setEditedAns] = useState([]);
  const [btnSave, setBtnSave] = useState('Сохранить');
  const [editPopupOpened, setEditPopupOpened] = useState(false);
  const [btnsList] = useState([
    {
      icon: addMagic,
      name: 'addMagic',
      text: 'добавить магию',
    },
    {
      name: 'checkSpelling',
      icon: checkSpelling,
      text: 'проверить орфографию',
    },
    {
      text: 'добавить в квиз',
      name: 'addToQuiz',
    },
    {
      text: 'удалить',
      name: 'delete',
    },
  ]);
  // eslint-disable-next-line
  const [editedQuest, setEditedQuest] = useState({});

  useEffect(() => {
    setQuestList([...questions]);
  }, [questions]);

  // useEffect(() => {
  //   getQestions();
  // }, []);

  // const getQestions = async () => {
  //   const questions = await getQuizQuestions(1);
  //   setQuestList(questions);
  // };

  const toBack = () => {
    navigate(-1);
  };

  const handleSave = () => {
    changeQuestion('', '', questList);
    setBtnSave('Сохранено');
    setTimeout(() => {
      setBtnSave('Сохранить');
    }, 2000);
  };

  const handleCancel = () => {
    toBack();
  };

  const changeQuestionLocal = (quest, num) => {
    const questIndex = questList.findIndex((quest) => quest.num === num);
    if (questIndex >= 0) {
      setQuestList((prevState) => [
        ...prevState.slice(0, questIndex),
        quest,
        ...prevState.slice(questIndex + 1),
      ]);
    } else {
      setQuestList((prevState) => [...prevState, quest]);
    }
  };

  const delAnswer = (ansIndex, questNum) => {
    const question = questList.find((quest) => quest.num === questNum);
    if (question && ansIndex >= 0) {
      const updatedQuest = {
        ...question,
        options: [
          ...question.options.slice(0, ansIndex),
          ...question.options.slice(ansIndex + 1),
        ],
      };
      updatedQuest && changeQuestionLocal(updatedQuest, questNum);
    }
  };

  const openPopUpEdit = (ansIndex, questNum) => {
    const question = questList.find((quest) => quest.num === questNum);
    if (question) {
      setEditPopupOpened(true);
      setEditedQuest(question);
      if (ansIndex === 'all') {
        setEditType('all');
        setEditedAns(question.options);
      } else {
        setEditType(ansIndex);
        setEditedAns(question.options.slice(ansIndex, ansIndex + 1));
      }
    }
  };

  const handleBtnRow = (action, rowId) => {
    switch (action) {
      case 'delete':
        delQuest(rowId);
        break;
      default:
        break;
    }
  };

  const delQuest = (questNum) => {
    setQuestList((prev) => prev.filter((quest) => quest.num !== questNum));
  };

  const addQuestion = async () => {
    const newNum = questList[questList.length - 1]?.num + 1 || 0;
    const newQuestion = await getNewQuestion(newNum);
    changeQuestionLocal(newQuestion, newQuestion.num);
  };

  const saveAnswers = () => {
    setTimeout(() => {
      let newQuestion;
      if (editType === 'all') {
        newQuestion = { ...editedQuest, options: editedAns };
      } else {
        console.log(editType);
        newQuestion = {
          ...editedQuest,
          options: [
            ...editedQuest.options.slice(0, editType),
            editedAns[0],
            ...editedQuest.options.slice(editType + 1),
          ],
        };
      }
      if (newQuestion) {
        changeQuestionLocal(newQuestion, newQuestion.num);
        setEditPopupOpened(false);
      }
    }, 250);
  };

  const changeAns = (val, ansIndex, key) => {
    if (key === 'text') {
      if (editType === 'all') {
        setEditedAns((prev) => [
          ...prev.slice(0, +ansIndex),
          val,
          ...prev.slice(+ansIndex + 1),
        ]);
      } else {
        setEditedAns([val]);
      }
    } else if (key === 'correct') {
      setEditedQuest((prevState) => ({
        ...prevState,
        correct_option_id:
          prevState.correct_option_id === ansIndex ? null : ansIndex,
      }));
    }
  };

  const addAnswer = () => {
    setEditedAns((prevState) => [...prevState, '']);
  };

  return (
    <div className={'scroll ' + s.quizQuestionsDB}>
      <button onClick={toBack} className="btnBack">
        {back}
        <span>Назад</span>
      </button>
      <div className={s.header}>
        <div className={'row'}>
          <h3 className={s.title}>База вопросов</h3>
          <button onClick={addQuestion} className={'btnDark add'}>
            Создать вопрос
          </button>
        </div>

        <div className={'row'}>
          <button onClick={handleSave} className={'btnLight'}>
            {btnSave}
          </button>
          <button onClick={handleCancel} className={'btnDark'}>
            Отмена
          </button>
        </div>
      </div>
      <div className={s.quiz__main}>
        <div className={'scroll ' + s.quiz__main__body}>
          <div className={s.questions}>
            <QuizQuestionsPopup
              save={saveAnswers}
              editType={editType}
              addAnswer={addAnswer}
              changeAns={changeAns}
              editedAns={editedAns}
              editPopupOpened={editPopupOpened}
              setEditPopupOpened={setEditPopupOpened}
              correctAnsIndex={editedQuest.correct_option_id}
            />
            <ul className={s.textList}>
              {questList.map((questItem, index) => (
                <QuizQuestionsRow
                  btnsList={btnsList}
                  index={index}
                  quizList={quizzes}
                  key={questItem.num}
                  questItem={questItem}
                  delAnswer={delAnswer}
                  handleBtnRow={handleBtnRow}
                  openPopUpEdit={openPopUpEdit}
                  changeQuestItem={changeQuestionLocal}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizQuestionsDB;
