import Endpoints from "../endpoints";
import { axiosInstance } from "../instance";
import defSurveyData from "./defSurveyJSON.json";

const getSurvey = async (project_name, bot_name, user_id) => {
	try {
		const method = "saved";
		const res = await axiosInstance.get(
			Endpoints.SURVEY.GET + `/${method}/${project_name}?author=${user_id}`
		);
		// `/${method}/${project_name}?ver_num=${ver_num}&tagname=${tag_name}`   -    пока без ver_num

		// res?.data?.data?.data && console.log(1, res.data.data.data);
		// !res?.data?.data &&
		//   res.data &&
		//   console.log(2, {
		//     ...res.data,
		//     data: {
		//       ...defSurveyData,
		//       bot: {
		//         name: bot_name,
		//         slag: bot_name,
		//       },
		//       author: {
		//         account: user_id,
		//         date: '',
		//       },
		//     },
		//   });

		if (res.data && !res.data.data && !res.data.error) {
			// console.log('gt', { ...res.data, data: defSurveyData });
			return {
				// ...res.data,
				// data:
				// {
				...defSurveyData,
				bot: {
					name: bot_name,
					slag: project_name,
				},
				author: {
					account: user_id,
					date: "",
				},
				// },
			};
		} else if (res?.data?.data?.data) {
			return {
				// ...res.data.data,
				// data: {
				...res.data.data.data,
				bot: {
					name: bot_name,
					slag: project_name,
				},
				author: {
					account: user_id,
					date: "",
				},
				// },
			};
		} else {
			throw new Error();
		}
	} catch (e) {
		console.log(e);
		return { error: "Не удалось получить данные о квизах" };
	}
};

export const saveSurvey = async (project_name, bot_name, author_id, params) => {
	try {
		const method = "saved";
		const res = await axiosInstance.post(
			Endpoints.SURVEY.SAVE + `/${method}/${project_name}?author=${author_id}`,
			params
		);
		console.log(1, res);
		// `/${method}/${project_name}?ver_num=${ver_num}&tagname=${tag_name}`   -    пока без ver_num
		if (!res.data.error) {
			return res;
		} else {
			throw new Error();
		}
	} catch (e) {
		console.log(e);
		return { error: "Не удалось получить данные о квизах" };
	}
};

export const publishSurvey = async (params, user_id) => {
	try {
		const res = await axiosInstance.post(Endpoints.SURVEY.PUBLISH, params);
		if (!res.data.error) {
			return res;
		} else {
			throw new Error();
		}
	} catch (e) {
		console.log(e);
		return { error: "Не удалось получить данные о квизах" };
	}
};

const survey = {
	getSurvey,
	saveSurvey,
	publishSurvey,
};

export default survey;
