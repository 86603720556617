const quizDel = (
  <svg
    width="13"
    height="16"
    viewBox="0 0 13 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.39259 0.712479C4.69397 0.267362 5.20135 0 5.74467 0H7.25533C7.79865 0 8.30603 0.267362 8.60741 0.71248L9.34375 1.8H12.3906C12.7272 1.8 13 2.06863 13 2.4C13 2.73137 12.7272 3 12.3906 3H0.609375C0.272826 3 0 2.73137 0 2.4C0 2.06863 0.272826 1.8 0.609375 1.8H3.65625L4.39259 0.712479ZM8.93724 16H4.06224C2.26731 16 0.812236 14.5673 0.812236 12.8V4H12.1872V12.8C12.1872 14.5673 10.7322 16 8.93724 16ZM4.87474 6.6C5.21128 6.6 5.48411 6.86863 5.48411 7.2V12.8C5.48411 13.1314 5.21128 13.4 4.87474 13.4C4.53819 13.4 4.26536 13.1314 4.26536 12.8L4.26536 7.2C4.26536 6.86863 4.53819 6.6 4.87474 6.6ZM8.12474 6.6C8.46128 6.6 8.73411 6.86863 8.73411 7.2V12.8C8.73411 13.1314 8.46128 13.4 8.12474 13.4C7.78819 13.4 7.51536 13.1314 7.51536 12.8V7.2C7.51536 6.86863 7.78819 6.6 8.12474 6.6Z"
      fill="#777A96"
    />
  </svg>
);

export default quizDel;
