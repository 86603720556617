// import api from '../../api';
import exit from '../../imgs/exit';
import s from './Header.module.scss';
import Select from '../common/Select';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import icon from '../../imgs/userIcon.svg';
import logo from '../../imgs/logoMain.svg';
import settings from '../../imgs/settings';
import quizEdit from '../../imgs/quizEdit';
import { useAppDispatch } from '../../store';
import plusSelect from '../../imgs/plusSelect';
import PopupAddBot from '../../pages/Bot/PopupAddBot';
import React, { useEffect, useRef, useState } from 'react';
import PopupChooseBot from '../../pages/Bot/PopupChooseBot';
import { logoutUser } from '../../store/auth/actionCreators';
import PopupAddCompany from '../../pages/Main/PopupAddCompany';
import PopupAddProject from '../../pages/Main/PopupAddProject';
import { setCurCompany } from '../../store/main/actionCreators';
import { setCurProject } from '../../store/main/actionCreators';

const Header = () => {
  const selectEl = useRef(null);
  const dispatch = useAppDispatch();
  const curBot = useSelector((state) => state.bot.curBot);
  const { mainLoading } = useSelector((state) => state.main);
  const curCompany = useSelector((state) => state.main.curCompany);
  const curProject = useSelector((state) => state.main.curProject);

  // const botList = useSelector((state) => state.bot.list);
  // const user = useSelector((state) => state.main.user);
  const userList = useSelector((state) => state.main.userList);
  const companyList = useSelector((state) => state.main.companyList);
  const projectList = useSelector((state) => state.main.projectList);

  const [popupAddBotOpened, setPopupAddBotOpened] = useState(false);
  const [popupChooseBotOpened, setPopupChooseBotOpened] = useState(false);
  const [popupAddCompanyOpened, setPopupAddCompanyOpened] = useState(false);
  const [popupAddProjectOpened, setPopupAddProjectOpened] = useState(false);

  const [showedList, setShowedList] = useState('');

  const logout = () => {
    dispatch(logoutUser());
  };

  const [user, setUser] = useState('Имя пользователя');

  const addToList = (list) => {
    if (list === 'bot') {
      // setPopupAddBotOpened(true);
      setPopupChooseBotOpened(true);
    } else if (list === 'curCompany') {
      setPopupAddCompanyOpened(true);
    } else if (list === 'curProject') {
      setPopupAddProjectOpened(true);
    }
  };

  const showList = (listName) => {
    if (listName === showedList) {
      setShowedList('');
    } else {
      setShowedList(listName);
    }
  };

  useEffect(() => {
    const onClick = (e) => {
      if (selectEl.current) {
        if (!selectEl.current.contains(e.target)) {
          setShowedList('');
          document.removeEventListener('click', onClick);
        }
      }
    };
    if (showedList) document.addEventListener('click', onClick);
  }, [showedList]);

  return (
    <header className={s.header} ref={selectEl}>
      {popupAddBotOpened && (
        <PopupAddBot close={() => setPopupAddBotOpened(false)} />
      )}
      {popupChooseBotOpened && (
        <PopupChooseBot close={() => setPopupChooseBotOpened(false)} />
      )}
      {popupAddCompanyOpened && (
        <PopupAddCompany close={() => setPopupAddCompanyOpened(false)} />
      )}
      {popupAddProjectOpened && (
        <PopupAddProject close={() => setPopupAddProjectOpened(false)} />
      )}

      <div className={s.header__left}>
        <Link to="/">
          <img className={s.header__logo} src={logo} alt="logo" />
        </Link>
        <ul className={s.header__selectListWrap}>
          <Select
            name="Компания"
            value="curCompany"
            showList={showList}
            disabled={mainLoading}
            addToList={addToList}
            selectList={companyList}
            className={s.header__select}
            selected={curCompany.title || curCompany.name || ''}
            isShowed={showedList === 'Компания'}
            select={(item) => dispatch(setCurCompany(item))}
          />
          <Select
            name="Проект"
            value="curProject"
            showList={showList}
            addToList={addToList}
            disabled={!curCompany.name}
            selectList={projectList}
            className={s.header__select}
            selected={curProject.title || curProject.name || ''}
            isShowed={showedList === 'Проект'}
            select={(item) => dispatch(setCurProject(item))}
          />
          {curBot.title || curBot.name ? (
            <button
              className={s.header__btnAdd}
              onClick={() => {
                addToList('bot');
              }}
            >
              <p>Бот: {curBot.title || curBot.name}</p>
              {quizEdit}
            </button>
          ) : (
            <button
              disabled={!curProject.name}
              className={s.header__btnAdd}
              onClick={() => {
                addToList('bot');
              }}
            >
              {plusSelect}
              <p>Добавить бота</p>
            </button>
          )}
          {/* <Select
            name="Бот"
            value="bot"
            showList={showList}
            selectList={botList}
            addToList={addToList}
            selected={curBot.title || curBot.name || ''}
            disabled={!curProject.name}
            className={s.header__select}
            isShowed={showedList === 'Бот'}
            select={(item) => selectBot(item)}
          /> */}
        </ul>
      </div>
      <div className={s.header__right}>
        <Select
          noBorder
          icon={icon}
          name="User"
          selected={user}
          showList={showList}
          selectList={userList}
          select={(item) => setUser(item)}
          isShowed={showedList === 'User'}
        />
        <button className={s.header__right__btn + ' ' + s.fill}>
          {settings}
        </button>
        <button
          onClick={logout}
          className={s.header__right__btn + ' ' + s.stroke}
        >
          {exit}
        </button>
      </div>
    </header>
  );
};

export default Header;
