import Endpoints from '../endpoints';
import { axiosInstance } from '../instance';

export const addBotApi = async (params) => {
  try {
    const res = await axiosInstance.post(Endpoints.BOT.ADD, {
      command: 'new_bot',
      ...params,
    });
    if (res.data.result?.error_code === 500) {
      return {
        error: 'Бот с таким токеном уже зарегистрирован',
      };
    }
    if (res.data.error && !res.data.token) {
      return { error: 'Проверьте правильность введённого вами токена' };
    } else if (res.data.result?.bot) {
      return { data: res.data.result.bot };
    } else if (res.data.error && res.data.token) {
      // Токен уже зарегистрирован
      // return { data: { token: token, name: 'noname' } };
      return { error: 'Токен уже зарегистрирован' };
      // }
      // Токен уже зарегистрирован end
      // else if (!res.data.error) {
      // const botData = await getBotApi(token);
      // const botName = botData?.first_name;
      // if (botName) {
      //   return { data: { token: token, name: botName } };
      // }
      // return { data: { token: token, name: 'noname' } };
    } else {
      throw new Error();
    }
  } catch (e) {
    console.log(e);
    return { error: 'Не удалось осуществить запрос' };
  }
};

export const getBotApi = async (token) => {
  try {
    const res = await axiosInstance.post(
      `https://api.telegram.org/bot${token}/getMe`
    );
    if (res.data.error) return;
    else return res.data.result;
  } catch (err) {
    console.log(err);
    return;
  }
};

export const getMyBotsApi = async () => {
  try {
    const res = await axiosInstance.get(Endpoints.BOT.GET_MY_BOTS);
    if (res?.data) return res.data;
    else return;
  } catch (err) {
    console.log(err);
    return;
  }
};

export const delBotApi = async (bot_name, user_id) => {
  try {
    const res = await axiosInstance.post(Endpoints.BOT.DEL, {
      command: 'delete_bot',
      bot: { name: bot_name },
      user_id: user_id,
    });
    if (!res.data.error) {
      return res;
    } else {
      if (res.data.description) {
        throw new Error(res.data.description);
      } else {
        new Error();
      }
    }
  } catch (e) {
    return {
      error: e.message ? e.message : 'Не удалось удалить бота',
    };
  }
};

export const getBotInfoApi = async (params) => {
  try {
    const res = await axiosInstance.post(Endpoints.BOT.GET_INFO, params);
    if (res.data.error) return;
    else return res.data;
  } catch (err) {
    console.log(err);
    return;
  }
};
