import s from './Main.module.scss';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../store';
import Input from '../../components/common/Input';
import PopUp from '../../components/common/PopUp';
import React, { useEffect, useState } from 'react';
import { addCompany, updateTree } from '../../store/main/actionCreators';

const PopupAddCompany = ({ close }) => {
  const dispatch = useAppDispatch();
  const [title, setTitle] = useState('');
  const [company, setCompany] = useState('');
  const [errors, setErrors] = useState({ company: '' });

  const main = useSelector((state) => state.main);
  const { user_id } = useSelector((state) => state.auth.profileData);
  const isLoading = useSelector((state) => state.main.companyLoading);
  const updatingTree = useSelector((state) => state.main.updatingTree);

  useEffect(() => {
    if (main.companyAdded && !updatingTree) {
      dispatch(updateTree());
      close();
    }
    if (main.errors.company) {
      setErrors({ company: main.errors.company });
    }
    // eslint-disable-next-line
  }, [main]);

  const changeData = (key, val) => {
    setErrors({});
    if (key === 'company') {
      setCompany(val);
    } else if (key === 'title') {
      setTitle(val);
    }
  };

  const handleSubmit = async () => {
    if (!company) {
      setErrors((prevState) => ({ ...prevState, company: 'Заполните поле' }));
    } else {
      dispatch(addCompany(title, company, user_id));
    }
  };

  return (
    <PopUp close={close} isOpen={true}>
      <div className={s.popupAddCompany}>
        <h3 className={s.title}>Добавить компанию</h3>
        <Input
          type="text"
          value={company}
          name={'company'}
          error={errors.company}
          label="Название компании"
          handleChange={(val) => changeData('company', val)}
        />
        <Input
          type="text"
          value={title}
          name={'title'}
          error={errors.title}
          label="Заголовок компании"
          handleChange={(val) => changeData('title', val)}
        />
        <ul className={s.btnWrap}>
          <button
            disabled={isLoading}
            onClick={handleSubmit}
            className="btnLight"
          >
            {isLoading ? 'Длбавление...' : 'Добавить'}
          </button>
          <button onClick={close} className="btnDark">
            Отмена
          </button>
        </ul>
      </div>
    </PopUp>
  );
};

export default PopupAddCompany;
