import s from './Shop.module.scss';
import quizDel from '../../imgs/quizDel';

const ShopMobTable = ({ del, list, loading, lightList, changeStatus }) => {
  return (
    <>
      <div className={s.table + ' ' + (loading ? s.loading : '') + ' ' + s.mob}>
        <ul className={s.mobList}>
          {list.map((item, i) => (
            <li
              className={
                s.mobItem + (lightList.includes(item.uuid) ? ' ' + s.new : '')
              }
              key={i}
            >
              <div className={s.mobItemHeader}>
                <h3>Товар</h3>
                <button onClick={() => del(item)} className={s.btnDel}>
                  {quizDel}
                </button>
              </div>
              <div className={s.mobItemInfo}>
                <p className={s.grey}>Имя товара:</p>
                <p className={s.infoItem}>{item.good} </p>
                <p className={s.grey}>event_id:</p>
                <p className={s.infoItem}>{item.event_id} </p>
                <p className={s.grey}>sku:</p>
                <p className={s.infoItem}>{item.sku} </p>
                <p className={s.grey}>Дата и время:</p>
                <p className={s.infoItem}>{item.date + ' ' + item.time} </p>
                <p className={s.grey}>Цена:</p>
                <p className={s.infoItem}>{item.price} </p>
                <p className={s.grey}>tg:</p>
                <p className={s.infoItem}>{item.tg} </p>
                <p className={s.grey}>Анкета:</p>
                <p className={s.infoItem}>{item.anketa} </p>
                <p className={s.grey}>Статус:</p>
                <div className={s.infoItem}>
                  <p
                    className={
                      s.listItemStatus +
                      ' ' +
                      (item.collected ? s.green : s.red)
                    }
                  >
                    {item.collected ? 'Забрали' : 'Не забрали'}
                  </p>
                </div>
              </div>

              {!item.collected ? (
                <button
                  onClick={() => changeStatus(item)}
                  className={'btnLight ' + s.listItemBtn}
                >
                  Товар забрали
                </button>
              ) : (
                <button
                  onClick={() => changeStatus(item, 'cancel')}
                  className={'btnDark ' + s.listItemBtn}
                >
                  Отменить
                </button>
              )}
            </li>
          ))}
          {!loading && !list.length && (
            <h3 className={s.emptyMes}>Нет записей</h3>
          )}
        </ul>
        {/* {loading && <Loader />} */}
      </div>
    </>
  );
};

export default ShopMobTable;
