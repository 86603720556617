import React from "react";
import IconOk from "../../imgs/ok";
import s from "./Common.module.scss";

const Checkbox = ({ isOn, label, handleClick, className }) => {
  return (
    <button
      onClick={handleClick}
      className={s.checkbox__wrap + " " + className}
    >
      <div className={s.checkbox + " " + (isOn ? s.on : "")}>
        <div className={s.daw}>{IconOk}</div>
      </div>
      <p>{label}</p>
    </button>
  );
};

export default Checkbox;
