import s from './Quizzes.module.scss';
import QuizRowBtns from './QuizRowBtns';
import addMagic from '../../imgs/addMagic';
import Input from '../../components/common/Input';
import React, { useEffect, useState } from 'react';
import checkSpelling from '../../imgs/checkSpelling';
import InputImg from '../../components/common/InputImg';

const QuizCommonRow = ({ textItem, changeText, handleBtnRow, quizIndex }) => {
  const [value, setValue] = useState(textItem.text);
  const btnsList = [
    {
      icon: addMagic,
      name: 'addMagic',
      text: 'добавить магию',
    },
    {
      name: 'checkSpelling',
      icon: checkSpelling,
      text: 'проверить орфографию',
    },
    {
      text: 'рекомендации',
      name: 'recommend',
    },
    {
      text: 'загрузить пример',
      name: 'downloadExample',
    },
    {
      text: 'предпросмотр',
      name: 'preview',
    },
    {
      text: 'проф режим',
      name: 'proMode',
    },
    {
      text: 'помощь',
      name: 'help',
    },
  ];

  useEffect(() => {
    setValue(textItem.text);
  }, [textItem]);

  useEffect(() => {
    if (value !== textItem.text) {
      console.log(textItem.form_name);
      if (textItem.form_name.slice(-5) === '_text') {
        changeText(
          { ...textItem, text: value },
          textItem.form_name.slice(5, -5),
          quizIndex
        );
      } else if (textItem.form_name === 'quiz_final_text_all_right') {
        changeText({ ...textItem, text: value }, 'final_all_right', quizIndex);
      } else if (textItem.form_name === 'quiz_final_text_notall_right') {
        changeText(
          { ...textItem, text: value },
          'final_notall_right',
          quizIndex
        );
      }
    }
    // eslint-disable-next-line
  }, [value]);

  const changeImg = (img, textItem) => {
    if (!img) return;
    console.log(textItem);
    console.log(textItem.form_name);
    console.log(textItem.form_name.slice(-5));
    if (textItem.form_name.slice(-5) === '_text') {
      changeText(
        { ...textItem, media: img },
        textItem.form_name.slice(5, -5),
        quizIndex
      );
    } else if (textItem.form_name === 'quiz_final_text_all_right') {
      changeText({ ...textItem, media: img }, 'final_all_right', quizIndex);
    } else if (textItem.form_name === 'quiz_final_text_notall_right') {
      changeText({ ...textItem, media: img }, 'final_notall_right', quizIndex);
    }
  };

  return (
    <li className={s.textItem}>
      <div className={s.inputWrap}>
        <Input
          max={4096}
          value={value}
          onReset={true}
          placeholder=""
          label={textItem.label}
          name={textItem.form_name}
          handleChange={(val) => setValue(val)}
        />
      </div>
      <InputImg
        img={textItem.media}
        id={textItem.form_name}
        setImg={(img) => changeImg(img, textItem)}
      />
      <ul className={'scroll ' + s.btnRowList}>
        <QuizRowBtns
          btnsList={btnsList}
          rowId={textItem.form_name}
          handleClick={handleBtnRow}
        />
      </ul>
    </li>
  );
};

export default QuizCommonRow;
