import api from '../../api';
import s from './Survey.module.scss';
import { useSelector } from 'react-redux';
import trashBin from '../../imgs/trashBin';
import PopupAddBot from '../Bot/PopupAddBot';
import { useNavigate } from 'react-router-dom';
import dragAndDrop from '../../imgs/dragAndDrop';
import Input from '../../components/common/Input';
import { useEffect, useRef, useState } from 'react';
import Loader from '../../components/common/Loader';
import defServeyModule from './DefSurveyModule.json';
import InputImg from '../../components/common/InputImg';
import Textarea from '../../components/common/Textarea';
import PopupEditSurveyModule from './PopupEditSurveyModule';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

// import Select from '../../components/common/Select';

const Survey = () => {
  const selectEl = useRef(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [surveyData, setSurveyData] = useState();
  // const [showedList, setShowedList] = useState('');
  const curBot = useSelector((state) => state.bot.curBot);
  const botIsLoaded = useSelector((state) => state.bot.isLoaded);
  const { curProject } = useSelector((state) => state.main);
  const [btnPubName, setBtnPubName] = useState('Опубликовать');
  const [editingSurveyModule, setEditingSurveyModule] = useState();
  const { user_id } = useSelector((state) => state.auth.profileData);
  const [btnSaveName, setBtnSaveName] = useState('Сохранить как черновик');
  const [editingSurveyModuleIndex, setEditingSurveyModuleIndex] = useState();

  const getSurvey = async () => {
    setLoading(true);
    const res = await api.survey.getSurvey(
      curProject.name,
      curBot.username,
      user_id
    );
    if (res) {
      setSurveyData(res);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (curBot?.username) {
      getSurvey();
    }
    // eslint-disable-next-line
  }, [curBot]);

  // const showList = (listName) => {
  //   setShowedList((prev) => (prev === listName ? '' : listName));
  // };

  const handleSave = async () => {
    if (!curBot || !user_id || !curProject.name) return;
    setBtnSaveName('Сохранение...');
    console.log('surveyData', surveyData);
    const res = await api.survey.saveSurvey(
      curProject.name,
      curBot.username,
      user_id,
      surveyData
    );
    console.log('save', res);
    const resMes = res?.data?.result;
    if (resMes === 'успешно записали в БД') {
      setBtnSaveName('Сохранено');
    } else if (resMes) {
      setBtnSaveName('Ошибка');
      alert(resMes);
    } else {
      setBtnSaveName('Ошибка');
    }
  };

  useEffect(() => {
    if (!['Сохранить как черновик', 'Сохранение...'].includes(btnSaveName)) {
      setTimeout(() => {
        setBtnSaveName('Сохранить как черновик');
      }, 2000);
    }
  }, [btnSaveName]);

  useEffect(() => {
    if (!['Опубликовать', 'Публикация...'].includes(btnPubName)) {
      setTimeout(() => {
        setBtnPubName('Опубликовать');
      }, 2000);
    }
  }, [btnPubName]);

  const handlePublish = async () => {
    if (!curBot || !user_id || !curProject.name) return;
    setBtnPubName('Публикация...');
    const res = await api.survey.publishSurvey(surveyData);
    console.log('publish', res);
    const resMes = res?.data?.result;
    if (resMes === 'ok') {
      setBtnPubName('Опубликовано');
    } else if (resMes) {
      setBtnPubName('Ошибка');
      alert(resMes);
    } else {
      setBtnPubName('Ошибка');
    }
  };

  const addSurveyModule = () => {
    setEditingSurveyModule(defServeyModule);
  };

  const editSurveyModule = (module, i) => {
    setEditingSurveyModule(module);
    setEditingSurveyModuleIndex(i);
  };

  const changeSurveyData = (val, key1, key2) => {
    // console.log(val);
    // console.log(key1);
    // console.log(key2);
    // console.log(surveyData);

    setSurveyData((prev) => ({
      ...prev,
      data: { ...prev.data, [key1]: { ...prev.data[key1], [key2]: val } },
    }));
  };

  const saveModule = (data) => {
    if (editingSurveyModuleIndex >= 0) {
      setSurveyData((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          modules: [
            ...prev.data.modules.slice(0, editingSurveyModuleIndex),
            data,
            ...prev.data.modules.slice(editingSurveyModuleIndex + 1),
          ],
        },
      }));
      setEditingSurveyModule();
      setEditingSurveyModuleIndex();
    } else {
      console.log(surveyData);
      setSurveyData((prev) => ({
        ...prev,
        data: { ...prev.data, modules: [...prev.data.modules, data] },
      }));
      setEditingSurveyModule();
      setEditingSurveyModuleIndex();
    }
  };

  // useEffect(() => {
  //   console.log(surveyData);
  // }, [surveyData]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }

    const updatedModules = reorder(
      surveyData.data.modules,
      result.source.index,
      result.destination.index
    );

    setSurveyData((prev) => ({
      ...prev,
      data: { ...prev.data, modules: updatedModules },
    }));
  }

  const delModule = (index) => {
    setSurveyData((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        modules: [
          ...prev.data.modules.slice(0, index),
          ...prev.data.modules.slice(index + 1),
        ],
      },
    }));
  };

  const moduleStatus = (module) => {
    // console.log(module);
    const unfilled = [];
    for (let modItemKey of Object.keys(module)) {
      for (let modItemData of Object.values(module[modItemKey])) {
        if (modItemData.data === '') {
          if (!unfilled.includes(modItemKey)) {
            unfilled.push(modItemKey);
          }
        }
      }
    }

    const getItemName = (item) => {
      switch (item) {
        case 'common':
          return 'Настройки';
        case 'request':
          return 'Запрос';
        case 'confirmation':
          return 'Подтверждение';
        case 'check':
          return 'Проверка';
        case 'final':
          return 'Финал';
        case 'ava':
          return 'Аватар';
        default:
          return '';
      }
    };

    // console.log(unfilled);
    if (unfilled.length) {
      return (
        <>
          <span className={s.red}>Не заполнено: </span>
          <span>{unfilled.map((item) => ' ' + getItemName(item))}</span>
        </>
      );
    } else {
      return <span className={s.green}>Модуль полностью заполнен</span>;
    }
  };

  return (
    <div className={'page ' + s.survey} ref={selectEl}>
      {/* {editGreetingOpened && (
        <EditGreetingPopup
          save={changeGreeting}
          module={moduleData.data.start_text}
          close={() => setEditGreetingOpened(false)}
        />
      )} */}
      {!curBot.username && botIsLoaded ? (
        <PopupAddBot close={() => navigate('/')} />
      ) : (
        <>
          <div className={s.header}>
            <div className={s.headerLeft}>
              <h3 className={s.title}>Анкета</h3>
              {!loading && (
                // <div>
                //   <Select
                //     isAdding
                //     name="survey"
                //     value="survey"
                //     showList={showList}
                //     className={s.select}
                //     selectList={surveyList}
                //     label={'Добавить вопрос'}
                //     isShowed={showedList === 'survey'}
                //     select={(item) => addSurvey(item.label)}
                //   />
                // </div>
                <button onClick={addSurveyModule} className={'btnGrey add '}>
                  Добавить модуль
                </button>
              )}
            </div>
            {!loading && (
              <div className={s.headerRight}>
                <button onClick={handleSave} className={'btnDark'}>
                  {btnSaveName}
                </button>
                <button onClick={handlePublish} className={'btnLight'}>
                  {btnPubName}
                </button>
              </div>
            )}
          </div>
          <div className={s.main}>
            {loading ? (
              <Loader />
            ) : (
              surveyData && (
                <>
                  <div className={s.surveyTopForm}>
                    <div className={s.surveyTopForm__top}>
                      <div className={s.surveyTopForm__topLeft}>
                        <InputImg
                          id={'surveyStartMediaInput'}
                          img={surveyData.data?.start?.media || ''}
                          className={s.inputImg}
                          setImg={(img) =>
                            changeSurveyData(img, 'start', 'media')
                          }
                        />
                        <Textarea
                          label={'Старт'}
                          id={'surveyStartTextInput'}
                          handleChange={(val) =>
                            changeSurveyData(val, 'start', 'text')
                          }
                          val={surveyData.data?.start?.text || ''}
                        />
                      </div>
                      <div className={s.surveyTopForm__topRight}>
                        <InputImg
                          className={s.inputImg}
                          id={'surveyFinalMediaInput'}
                          img={surveyData.data?.final?.media || ''}
                          setImg={(img) =>
                            changeSurveyData(img, 'final', 'media')
                          }
                        />
                        <Textarea
                          label={'Финал'}
                          id={'surveyFinalTextInput'}
                          handleChange={(val) =>
                            changeSurveyData(val, 'final', 'text')
                          }
                          val={surveyData.data?.final?.text || ''}
                        />
                      </div>
                    </div>
                    <div className={s.surveyTopForm__bottom}>
                      <Input
                        onReset={true}
                        placeholder=""
                        label={'Баллы за старт'}
                        name={'surveyStartBallsInput'}
                        className={s.surveyTopForm__input}
                        wrapClassName={s.inputWrap}
                        value={surveyData.data?.start?.balls || ''}
                        handleChange={(val) =>
                          changeSurveyData(val, 'start', 'balls')
                        }
                      />
                      <Input
                        onReset={true}
                        placeholder=""
                        name={'surveyFinalBallsInput'}
                        label={'Баллы за финал'}
                        className={s.surveyTopForm__input}
                        wrapClassName={s.inputWrap}
                        value={surveyData.data?.final?.balls || ''}
                        handleChange={(val) =>
                          changeSurveyData(val, 'final', 'balls')
                        }
                      />
                      <Input
                        onReset={true}
                        placeholder=""
                        name={'surveyDefBallsInput'}
                        label={'Баллы по умолчанию'}
                        className={s.surveyTopForm__input}
                        wrapClassName={s.inputWrap}
                        value={surveyData.data?.default?.balls || ''}
                        handleChange={(val) =>
                          changeSurveyData(val, 'default', 'balls')
                        }
                      />
                    </div>
                  </div>
                  {surveyData?.data?.modules?.length > 0 && (
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId={'droppable-2'}>
                        {(provided, _) => (
                          <ul
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className={s.surveyModulesList + ' scroll'}
                          >
                            {surveyData.data.modules.map((module, index) => {
                              const moduleName =
                                module?.common?.name?.data || 'Не установлено';
                              return (
                                <Draggable
                                  index={index}
                                  key={moduleName}
                                  draggableId={'draggable-' + moduleName}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                    >
                                      <li
                                        key={index}
                                        className={s.surveyModule}
                                        style={{
                                          boxShadow: snapshot.isDragging
                                            ? 'rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset'
                                            : 'none',
                                        }}
                                      >
                                        <div className={s.moduleHeader}>
                                          <div className={s.left}>
                                            <button
                                              className="btnDragAndDrop"
                                              {...provided.dragHandleProps}
                                            >
                                              {dragAndDrop}
                                            </button>
                                            <p className={s.name}>
                                              {moduleName}
                                            </p>
                                          </div>
                                          <div className={s.right}>
                                            <button
                                              key={index}
                                              className={s.btnRow}
                                              onClick={() =>
                                                editSurveyModule(module, index)
                                              }
                                            >
                                              <span>Редактировать</span>
                                            </button>
                                            <button
                                              onClick={() => delModule(index)}
                                              className={s.btnTrashBin}
                                            >
                                              {trashBin}
                                            </button>
                                          </div>
                                        </div>
                                        <div className={s.moduleInfo}>
                                          {moduleStatus(module)}
                                        </div>
                                      </li>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </ul>
                        )}
                      </Droppable>
                    </DragDropContext>
                  )}
                </>
              )
            )}
          </div>
        </>
      )}

      {editingSurveyModule && (
        <PopupEditSurveyModule
          saveModule={saveModule}
          moduleData={editingSurveyModule}
          close={() => setEditingSurveyModule()}
        />
      )}
    </div>
  );
};

export default Survey;
