import _ from "lodash";
import { useSelector } from "react-redux";
import addMagic from "../../imgs/addMagic";
import trashBin from "../../imgs/trashBin";
import s from "./StartModules.module.scss";
import PopupAddBot from "../Bot/PopupAddBot";
import { getQuizesApi } from "../../api/quiz";
import { useNavigate } from "react-router-dom";
import GreetingRowBtns from "./GreetingRowBtns";
import dragAndDrop from "../../imgs/dragAndDrop";
import moduleDataDef from "./moduleDataDef.json";
import { useEffect, useRef, useState } from "react";
import Select from "../../components/common/Select";
import Loader from "../../components/common/Loader";
import EditGreetingPopup from "./EditGreetingPopup";
import checkSpelling from "../../imgs/checkSpelling";
import InputImg from "../../components/common/InputImg";
import {
	getStartModulesApi,
	postStartModulesApi,
	saveStartModulesApi,
} from "../../api/startModules";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import api from "../../api";

const StartModules = () => {
	const selectEl = useRef(null);
	const navigate = useNavigate();
	const [quizList, setQuizList] = useState([]);
	const [menuList, setMenuList] = useState([]);
	const [pollsList, setPollsList] = useState([]);
	const [loading, setLoading] = useState(true);
	const [showedList, setShowedList] = useState("");
	const { curProject } = useSelector((state) => state.main);
	const [moduleData, setModuleData] = useState(moduleDataDef);
	const [btnPubName, setBtnPubName] = useState("Опубликовать");
	const { user_id } = useSelector((state) => state.auth.profileData);
	const [btnSaveName, setBtnSaveName] = useState("Сохранить как черновик");
	const [greetingBtnsList] = useState([
		{
			text: "редактировать модуль",
			name: "edit",
		},
		{
			icon: addMagic,
			name: "addMagic",
			text: "добавить магию",
		},
		{
			name: "checkSpelling",
			icon: checkSpelling,
			text: "проверить орфографию",
		},
	]);
	const [editGreetingOpened, setEditGreetingOpened] = useState(false);
	const curBot = useSelector((state) => state.bot.curBot);
	const botLoading = useSelector((state) => state.bot.isLoading);
	const [defStartModules] = useState([
		{ name: "start_text", label: "Приветствие" },
		{ name: "anketa", label: "Анкета" },
		{ name: "start_quiz", label: "Квизы" },
		{ name: "start_poll", label: "Опросы" },
		//
		// { name: 'start_balls', label: 'Баллы на старте' },
		// { name: 'refer', label: 'Реферальная программа' },
		//
		// { name: 'network_reg', label: 'Нетворкинг' },
		// { name: 'menu_conditions', label: 'Выбор меню по условию' },
		{ name: "menu1", label: "Меню" },
	]);
	const [startModulesList, setStartModulesList] = useState([
		...defStartModules,
	]);

	const getData = async () => {
		setLoading(true);
		const res = await getQuizesApi(curBot.username, user_id);
		const respMenuList = await api.menu.getMenu(
			curProject.name,
			curBot.username,
			user_id
		);
		const respPollsList = await api.polls.getPolls(
			curProject.name,
			user_id,
			curBot.username
		);
		console.log(respPollsList);
		if (respPollsList?.polls.length) {
			const pollsLength = respPollsList.polls.length;
			setPollsList(_.range(1, pollsLength + 1));
		}
		if (respMenuList?.data?.menu_items?.length) {
			const menuLength = respMenuList.data.menu_items.length;
			setMenuList(_.range(1, menuLength + 1));
		}
		if (res?.data?.data?.data) {
			const quizes = res.data.data.data.quizes;
			if (quizes.length) {
				setQuizList(quizes.map((quiz) => ({ name: quiz.name, num: quiz.num })));
			} else {
				setQuizList([]);
			}
		}
		//
		const resD = await getStartModulesApi(
			curBot.username,
			curProject.name,
			user_id
		);
		if (resD?.data?.data?.data) {
			setModuleData(resD.data.data.data);
		}

		setLoading(false);
	};

	// useEffect(() => {
	//   console.log('moduleData:', moduleData);
	// }, [moduleData]);

	useEffect(() => {
		setQuizList([]);
		setModuleData(moduleDataDef);
		curBot.username && getData();
		// eslint-disable-next-line
	}, [curBot.username]);

	useEffect(() => {
		let newList = [...defStartModules];
		for (let moduleName of Object.keys(moduleData.data)) {
			const index = newList.findIndex((mod) => mod.name === moduleName);
			if (index >= 0) {
				newList = [...newList.slice(0, index), ...newList.slice(index + 1)];
			}
		}
		setStartModulesList([...newList]);
	}, [moduleData, defStartModules]);

	const handleSave = async () => {
		if (!curBot || !user_id) return;
		setBtnSaveName("Сохранение...");
		const res = await saveStartModulesApi(
			curBot.username,
			curProject.name,
			user_id,
			moduleData
		);
		console.log("save", res);
		const resMes = res?.data?.result;
		if (resMes === "успешно записали в БД") {
			setBtnSaveName("Сохранено");
		} else if (resMes) {
			setBtnSaveName("Ошибка");
			alert(resMes);
		} else {
			setBtnSaveName("Ошибка");
		}
	};

	const handlePublish = async () => {
		if (!curBot || !user_id) return;
		setBtnPubName("Публикация...");
		const res = await postStartModulesApi(
			curProject.name,
			curBot.username,
			user_id,
			moduleData
		);
		console.log("publish", res);
		const resMes = res?.data?.result;
		if (resMes === "ok") {
			setBtnPubName("Опубликовано");
		} else if (resMes) {
			setBtnPubName("Ошибка");
			alert(resMes);
		} else {
			setBtnPubName("Ошибка");
		}
	};

	const showList = (listName) => {
		setShowedList((prev) => (prev === listName ? "" : listName));
	};

	const addStartModule = (moduleLabel) => {
		let newModuleName = "";
		let moduleData = {};
		switch (moduleLabel) {
			case "Приветствие":
				newModuleName = "start_text";
				moduleData = {
					start_text: {
						text: "",
						media: "",
						form_name: "start_text",
						form_name_media: "start_media",
					},
				};
				break;
			case "Анкета":
				moduleData = {
					anketa: {
						form_name: "start_anketa",
					},
				};
				newModuleName = "anketa";
				break;
			case "Квизы":
				newModuleName = "start_quiz";
				moduleData = {
					start_quiz: {
						form_name: "start_quiz_index",
						index: 0,
					},
				};
				break;
			case "Баллы на старте":
				moduleData = {
					start_balls: {
						text: "",
						form_name: "start_balls_text",
						media: "",
						form_name_media: "start_balls_media",
						balls: {
							data: 0,
							form_name: "start_balls",
						},
					},
				};
				newModuleName = "start_balls";
				break;
			case "Реферальная программа":
				moduleData = {
					refer: {
						text: "",
						form_name: "referal_text",
						media: "",
						form_name_media: "media_referal",
						text_refer: "тексту реферу",
						form_name_refer: "refer_text",
						balls: {
							data: 0,
							form_name: "referal_balls",
						},
					},
				};
				newModuleName = "refer";
				break;
			case "Нетворкинг":
				moduleData = {
					network_reg: {
						text: "",
						form_name: "network_reg_text",
						media: "",
						form_name_media: "media_network_reg",
						text_refer: "тексту реферу",
						form_name_refer: "refer_text",
						balls: {
							data: 0,
							form_name: "referal_balls",
						},
					},
				};
				newModuleName = "network_reg";
				break;
			case "Выбор меню по условию":
				moduleData = {
					menu_conditions: {
						condition_key: "condition_key data",
						form_name_key: "condition_key",
						condition_value: "condition_value_data",
						form_name_value: "form_name_value",
					},
				};
				newModuleName = "menu_conditions";
				break;

			case "Меню":
				moduleData = {
					menu1: {
						// condition_key: 'condition_key data',
						// form_name_key: 'condition_key',
						// condition_value: 'condition_value_data',
						// form_name_value: 'form_name_value',
						num: null,
						form_name: "menu_num",
					},
				};
				newModuleName = "menu1";
				break;

			case "Опросы":
				moduleData = {
					start_poll: {
						num: 0,
					},
				};
				newModuleName = "start_poll";
				break;
			default:
				break;
		}
		setModuleData((prev) => ({
			...prev,
			data: { ...prev.data, ...moduleData },
			modules: [...prev.modules, newModuleName],
		}));

		// setStartModules((prev) => [
		//   ...prev,
		//   { label: moduleLabel, order: prev.length + 1 },
		// ]);
	};

	useEffect(() => {
		const onClick = (e) => {
			if (selectEl.current) {
				if (!selectEl.current.contains(e.target)) {
					setShowedList("");
					document.removeEventListener("click", onClick);
				}
			}
		};
		if (showedList) document.addEventListener("click", onClick);
	}, [showedList]);

	const handleBtnGreeting = (btnName) => {
		if (btnName === "edit") {
			setEditGreetingOpened(true);
		}
	};

	const handleBtnQuiz = (btnName) => {
		if (btnName === "edit") {
			// setEditGreetingOpened(true);
		}
	};

	const setQuiz = (item) => {
		setModuleData((prev) => ({
			...prev,
			data: {
				...prev.data,
				start_quiz: { ...prev.data.start_quiz, index: item.num },
			},
		}));
	};

	const setMenuNumb = (item) => {
		setModuleData((prev) => ({
			...prev,
			data: {
				...prev.data,
				menu1: { ...prev.data.menu1, num: item },
			},
		}));
	};

	const setPoll = (item) => {
		setModuleData((prev) => ({
			...prev,
			data: {
				...prev.data,
				start_poll: { ...prev.data.start_poll, num: item },
			},
		}));
	};

	const getSelectedQuiz = () => {
		if (moduleData?.data?.start_quiz?.index >= 0) {
			return (
				quizList.find((quiz) => quiz.num === moduleData.data.start_quiz.index)
					?.name || ""
			);
		}
		return "";
	};

	// const getSelectedPoll = () => {
	// 	console.log(moduleData?.data);
	// 	if (moduleData?.data?.start_poll?.index >= 0) {
	// 		return pollsList.find(
	// 			(item) => item === moduleData.data.start_poll.index
	// 		);
	// 	}
	// 	return "";
	// };

	const moduleHeaderBtns = (moduleName) => {
		switch (moduleName) {
			case "start_text":
				return (
					<div className={"row gap-1 scroll " + s.greetingBtnsList}>
						<GreetingRowBtns
							btnsList={greetingBtnsList}
							handleClick={(item) => handleBtnGreeting(item)}
						/>
					</div>
				);
			case "anketa":
				return "";
			case "start_quiz":
				return (
					<div className={"row gap-1 scroll " + s.greetingBtnsList}>
						<GreetingRowBtns
							btnsList={[{ text: "редактировать модуль", name: "edit" }]}
							handleClick={(item) => handleBtnQuiz(item)}
						/>
					</div>
				);
			case "start_poll":
				return "";
			default:
				return "";
		}
	};

	const moduleInfoContent = (moduleName) => {
		const module = moduleData.data[moduleName];
		switch (moduleName) {
			case "start_text":
				return (
					<div className={"row gap-1 " + s.moduleInfoContentWrap}>
						<InputImg
							img={module.media}
							className={s.inputImg}
							disabled
						/>
						<div className={s.moduleInfoContent}>
							<p className={s.moduleInfoTitle}>Текст приветствия</p>
							<p className={s.greetingText}>{module.text}</p>
						</div>
					</div>
				);
			case "anketa":
				return (
					<div className={"row gap-1 " + s.moduleInfoHead}>
						<div
							className={
								s.statusWrap + " " + s.green
								// + ' ' + (module.status ? s.green : s.green)
							}
						>
							Готов загружен в бота
						</div>
						<button
							onClick={() => navigate("/survey")}
							className={"btnGrey"}
						>
							Настроить модуль
						</button>
					</div>
				);
			case "start_quiz":
				return (
					<div className={s.moduleInfoWrap}>
						<div className={"row gap-1 " + s.moduleInfoHead}>
							<div
								className={
									s.statusWrap + " " + s.green
									// + ' ' + (module.status ? s.green : s.green)
								}
							>
								Готов загружен в бота
							</div>
							<button
								onClick={() => navigate("/quizzes")}
								className={"btnGrey"}
							>
								Настроить модуль
							</button>
						</div>
						<div className={s.moduleInfoContent}>
							<p className={s.moduleInfoTitle}>Название квиза</p>
							<div className={s.quizSelect}>
								<Select
									name="Квиз"
									value="quiz"
									showList={showList}
									selectList={quizList}
									className={s.select}
									selected={getSelectedQuiz()}
									isShowed={showedList === "Квиз"}
									select={(item) => setQuiz(item)}
								/>
							</div>
						</div>
					</div>
				);
			case "start_poll":
				return (
					<div className={s.moduleInfoWrap}>
						<div className={"row gap-1 " + s.moduleInfoHead}>
							<div
								className={
									s.statusWrap + " " + s.green
									// + ' ' + (module.status ? s.green : s.green)
								}
							>
								Готов загружен в бота
							</div>
							<button
								onClick={() => navigate("/polls")}
								className={"btnGrey"}
							>
								Настроить модуль
							</button>
						</div>
						<div className={s.moduleInfoContent}>
							<p className={s.moduleInfoTitle}>Название опроса</p>
							<div className={s.quizSelect}>
								<Select
									name="Опрос"
									value="poll"
									showList={showList}
									className={s.select}
									selectList={pollsList}
									select={(item) => setPoll(item)}
									isShowed={showedList === "Опрос"}
									selected={moduleData?.data?.start_poll?.num || ""}
								/>
							</div>
						</div>
					</div>
					//
					// <div className="row gap-1">
					//   <div className={s.quizSelect}>
					//     <Select
					//       name="Опрос"
					//       value="poll"
					//       selectList={[]}
					//       showList={showList}
					//       className={s.select}
					//       selected={getSelectedPoll()}
					//       select={(item) => setPoll(item)}
					//       isShowed={showedList === 'Опрос'}
					//     />
					//   </div>
					//   <div
					//     className={
					//       s.statusWrap + ' ' + s.green
					//       // + ' ' + (module.status ? s.green : s.green)
					//     }
					//   >
					//     Готов загружен в бота
					//   </div>
					//   <button onClick={() => navigate('/polls')} className={'btnGrey'}>
					//     Настроить модуль
					//   </button>
					// </div>
				);
			case "menu1":
				return (
					<div className={s.moduleInfoWrap}>
						<div className={"row gap-1 " + s.moduleInfoHead}>
							<div className={s.moduleInfoContent}>
								<p className={s.moduleInfoTitle}>Номер меню</p>
								<div className={s.quizSelect}>
									<Select
										name="Меню"
										value="menu1"
										showList={showList}
										className={s.select}
										selectList={menuList}
										isShowed={showedList === "Меню"}
										select={(item) => setMenuNumb(item)}
										selected={moduleData?.data?.menu1?.num || ""}
									/>
								</div>
							</div>
							<button
								onClick={() => navigate("/menu")}
								className={"btnGrey"}
							>
								Настроить модуль
							</button>
						</div>
					</div>
				);
			default:
				return "";
		}
	};

	const delModule = (moduleName) => {
		const newData = { ...moduleData.data };
		delete newData[moduleName];
		setModuleData((prev) => ({
			...prev,
			modules: prev.modules.filter((mod) => mod !== moduleName),
			data: newData,
		}));
	};

	const changeGreeting = ({ text, media }) => {
		// const greetingModuleIndex = startModules.findIndex(
		//   (module) => module.label === 'Приветствие'
		// );

		setModuleData((prev) => ({
			...prev,
			data: {
				...prev.data,
				start_text: { ...prev.data.start_text, media, text },
			},
		}));

		// if (greetingModuleIndex >= 0) {
		// setStartModules((prev) => [
		//   ...prev.slice(0, greetingModuleIndex),
		//   { text, media, label: 'Приветствие' },
		//   ...prev.slice(greetingModuleIndex + 1),
		// ]);
		// }
	};

	useEffect(() => {
		if (!["Сохранить как черновик", "Сохранение..."].includes(btnSaveName)) {
			setTimeout(() => {
				setBtnSaveName("Сохранить как черновик");
			}, 2000);
		}
	}, [btnSaveName]);

	useEffect(() => {
		if (!["Опубликовать", "Публикация..."].includes(btnPubName)) {
			setTimeout(() => {
				setBtnPubName("Опубликовать");
			}, 2000);
		}
	}, [btnPubName]);

	const getModuleLabel = (moduleName) => {
		const module = defStartModules.find((module) => module.name === moduleName);
		if (module) {
			return module.label;
		} else {
			return moduleName;
		}
	};

	// DnD handlers

	// const dropHandler = (e, moduleName) => {
	//   e.preventDefault();
	//   e.stopPropagation();

	//   const li = e.target.closest(`.${s.startModule}`);
	//   li.style.filter = 'none';

	//   if (li) {
	//     e.target.cursor = 'default';
	//   }

	//   const curModuleIndex = moduleData.modules.findIndex(
	//     (module) => module === curModule
	//   );
	//   const areaModuleIndex = moduleData.modules.findIndex(
	//     (module) => module === moduleName
	//   );
	//   if (
	//     curModuleIndex >= 0 &&
	//     areaModuleIndex >= 0 &&
	//     curModuleIndex !== areaModuleIndex
	//   ) {
	//     let newModules = [
	//       ...moduleData.modules.slice(0, curModuleIndex),
	//       moduleData.modules[areaModuleIndex],
	//       ...moduleData.modules.slice(curModuleIndex + 1),
	//     ];

	//     newModules = [
	//       ...newModules.slice(0, areaModuleIndex),
	//       moduleData.modules[curModuleIndex],
	//       ...newModules.slice(areaModuleIndex + 1),
	//     ];

	//     setModuleData((prev) => ({ ...prev, modules: newModules }));
	//   }
	// };

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	};

	function onDragEnd(result) {
		if (!result.destination) {
			return;
		}
		if (result.destination.index === result.source.index) {
			return;
		}

		const updatedModules = reorder(
			moduleData.modules,
			result.source.index,
			result.destination.index
		);

		setModuleData((prev) => ({ ...prev, modules: updatedModules }));
	}

	return (
		<div
			className={"page " + s.startModules}
			ref={selectEl}
		>
			{editGreetingOpened && (
				<EditGreetingPopup
					save={changeGreeting}
					module={moduleData.data.start_text}
					close={() => setEditGreetingOpened(false)}
				/>
			)}
			{!curBot.username && !botLoading ? (
				<PopupAddBot close={() => navigate("/")} />
			) : (
				<>
					<div className={s.header}>
						<div className={s.headerLeft}>
							<h3 className={s.title}>Стартовые модули</h3>
							{/* <button onClick={handleSave} className={'btnGrey add '}>
                Добавить модуль
              </button> */}
							{!loading && (
								<div>
									<Select
										isAdding
										name="startModule"
										value="startModule"
										showList={showList}
										className={s.select}
										label={"Добавить модуль"}
										selectList={startModulesList}
										isShowed={showedList === "startModule"}
										select={(item) => addStartModule(item.label)}
									/>
								</div>
							)}
						</div>
						{!loading && (
							<div className={s.headerRight}>
								<button
									disabled={btnSaveName !== "Сохранить как черновик"}
									onClick={handleSave}
									className={"btnDark"}
								>
									{btnSaveName}
								</button>
								<button
									disabled={btnPubName !== "Опубликовать"}
									onClick={handlePublish}
									className={"btnLight"}
								>
									{btnPubName}
								</button>
							</div>
						)}
					</div>
					<div className={s.main}>
						{loading ? (
							<Loader />
						) : (
							<>
								{!moduleData.modules.length ? (
									<p className={s.empty}>Стартовых модулей нет</p>
								) : (
									<DragDropContext onDragEnd={onDragEnd}>
										<Droppable droppableId={"droppable-1"}>
											{(provided, _) => (
												<ul
													ref={provided.innerRef}
													{...provided.droppableProps}
													className={s.startModulesList}
												>
													{moduleData.modules.map((moduleName, index) => (
														<Draggable
															key={moduleName}
															index={index}
															draggableId={"draggable-" + moduleName}
														>
															{(provided, snapshot) => (
																<div
																	ref={provided.innerRef}
																	{...provided.draggableProps}
																>
																	<li
																		key={index}
																		className={s.startModule}
																		style={{
																			boxShadow: snapshot.isDragging
																				? "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
																				: "none",
																		}}
																	>
																		<div className={s.moduleHeader}>
																			<div className={s.left}>
																				<button
																					className="btnDragAndDrop"
																					{...provided.dragHandleProps}
																				>
																					{dragAndDrop}
																				</button>
																				<p className={s.name}>
																					{getModuleLabel(moduleName)}
																				</p>
																			</div>
																			<div className={s.right}>
																				{moduleHeaderBtns(moduleName)}
																				<button
																					onClick={() => delModule(moduleName)}
																					className={s.btnTrashBin}
																				>
																					{trashBin}
																				</button>
																			</div>
																		</div>
																		<div className={s.moduleInfo}>
																			{moduleInfoContent(moduleName)}
																		</div>
																	</li>
																</div>
															)}
														</Draggable>
													))}
													{provided.placeholder}
												</ul>
											)}
										</Droppable>
									</DragDropContext>
								)}
							</>
						)}
					</div>
				</>
			)}
		</div>
	);
};

export default StartModules;
