import back from '../../imgs/back';
import PopupDelBot from './PopupDelBot';
import PopupAddBot from './PopupAddBot';
import { getBotApi } from '../../api/bot';
import { useSelector } from 'react-redux';
import trashBin from '../../imgs/trashBin';
import s from './Bot.module.scss';
import { useNavigate } from 'react-router-dom';
import Input from '../../components/common/Input';
import React, { useEffect, useState } from 'react';
// import SelectMany from '../../components/common/SelectMany';
// import { getProjectList } from '../../api/main';

const BotEdit = () => {
  const navigate = useNavigate();
  const botData = useSelector((state) => state.bot.curBot);
  const botIsLoaded = useSelector((state) => state.bot.isLoaded);
  // const [showedList, setShowedList] = useState('');
  // const [projects, setProjects] = useState([]);
  // const [showProjects, setShowProjects] = useState([]);
  const [infoList, setInfoList] = useState([]);
  const [popupDelBot, setPopupDelBot] = useState(false);
  const [localBotData, setLocalBotData] = useState(botData);
  const [botInfoLoading, setBotInfoLoading] = useState(false);

  const changeData = (val, key) => {
    setLocalBotData((prevState) => ({ ...prevState, [key]: val }));
  };

  useEffect(() => {
    setInfoList([]);
    setLocalBotData(botData);
  }, [botData]);

  // useEffect(() => {
  //   getProjects();
  // }, []);
  // const getProjects = async () => {
  //   const projects = await getProjectList();
  //   setProjects(projects);
  //   setShowProjects(projects);
  // };
  // const showList = (listName) => {
  //   if (listName === showedList) {
  //     setShowedList('');
  //   } else {
  //     setShowedList(listName);
  //   }
  // };
  // const updateShowProjects = (newArr) => {
  //   setShowProjects(projects.filter((proj) => !newArr.includes(proj)));
  // };
  // const addProject = (item) => {
  //   const newProjArr = [...botData.project];
  //   newProjArr.push(item);
  //   setBotData((prevState) => ({ ...prevState, project: newProjArr }));
  //   updateShowProjects(newProjArr);
  // };
  // const createProject = () => {
  //   console.log();
  // };
  // const delProject = (item) => {
  //   const projIndex = botData.project.findIndex((proj) => proj === item);
  //   if (projIndex < 0) return;
  //   const newProjArr = [
  //     ...botData.project.slice(0, projIndex),
  //     ...botData.project.slice(projIndex + 1),
  //   ];
  //   setBotData((prevState) => ({ ...prevState, project: newProjArr }));
  //   updateShowProjects(newProjArr);
  // };

  const getBotInfo = async (token) => {
    if (!token) return;
    setBotInfoLoading(true);
    const data = await getBotApi(token);
    if (data) {
      const info = [];
      data.company &&
        info.push({ name: 'Название компании', value: data.company });
      data.project &&
        info.push({ name: 'Название проекта', value: data.project });
      data.username &&
        info.push({ name: 'user_name tg bot', value: data.username });
      data.first_name &&
        info.push({ name: 'first_name', value: data.first_name });
      info.push({ name: 'can_join_groups', value: data.can_join_groups });
      info.push({
        name: 'can_read_all_group_messages',
        value: data.can_read_all_group_messages,
      });
      info.push({
        name: 'supports_inline_queries',
        value: data.supports_inline_queries,
      });
      setInfoList(info);
    } else {
      setInfoList([]);
    }
    setBotInfoLoading(false);
  };

  const handleSave = () => {
    console.log('save');
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <div className={'page ' + s.botEdit}>
      {!botData.username && botIsLoaded ? (
        <PopupAddBot close={() => navigate('/')} />
      ) : (
        <>
          {popupDelBot && <PopupDelBot close={() => setPopupDelBot(false)} />}
          <button onClick={() => navigate(-1)} className="btnBack">
            {back}
            <span>Назад</span>
          </button>
          <div className={s.header}>
            <div className={s.headerLeft}>
              <h3 className={s.title}>Редактирование бота {botData.name}</h3>
              <button onClick={handleSave} className={'btnLight'}>
                Сохранить
              </button>
              <button onClick={handleCancel} className={'btnDark'}>
                Отмена
              </button>
            </div>
            <div className={s.headerRight}>
              {botData.username && (
                <button
                  className={'btnRed ' + s.btnDel}
                  onClick={() => setPopupDelBot(true)}
                >
                  {trashBin}
                  <span>Удалить</span>
                </button>
              )}
            </div>
          </div>
          <div className={s.main}>
            <div className={s.inputWrap}>
              <Input
                name={'name'}
                label={'Название бота'}
                value={localBotData.name}
                onReset={() => changeData('', 'name')}
                handleChange={(val) => changeData(val, 'name')}
              />
            </div>
            {/* <div className={s.inputWrap}>
          <Input
            name={'company'}
            value={botData.company}
            label={'Название компании'}
            onReset={() => changeData('', 'company')}
            handleChange={(val) => changeData(val, 'company')}
          />
        </div>
        <div className={s.inputWrap + ' ' + s.select}>
          <SelectMany
            name="Проект"
            value="project"
            showList={showList}
            delSelected={delProject}
            selectList={showProjects}
            addToList={createProject}
            selected={botData.project}
            isShowed={showedList === 'project'}
            select={(item) => addProject(item)}
          />
        </div> */}
            <div className={s.inputWrap}>
              <Input
                name={'token'}
                disabled={true}
                label={'Токен'}
                type="password"
                defaultPasN={46}
                value={localBotData.token}
                handleChange={(val) => changeData(val, 'token')}
              />
            </div>
            <ul
              className={s.infoList + ' ' + (botInfoLoading ? s.loading : '')}
            >
              {infoList.map((item, index) => (
                <li key={index} className={s.infoItem}>
                  <span className={s.name}>{item.name}</span>
                  <span className={s.value}>{String(item.value)}</span>
                </li>
              ))}
            </ul>
            <button
              disabled={!botData.token}
              onClick={() => getBotInfo(botData.token)}
              className={'btnDark ' + s.btnGetBotInfo}
            >
              Получить информацию о боте из телеграмм
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default BotEdit;
