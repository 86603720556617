import React from "react";
import s from "./Common.module.scss";

const Switcher = ({ isOn, label, handleClick, className }) => {
  return (
    <div className={s.switcher + " " + (isOn ? s.on : "") + " " + className}>
      <button onClick={handleClick} className={s.switcher}>
        <div className={s.bg}>
          <div className={s.round}></div>
        </div>
      </button>
      <label>{label}</label>
    </div>
  );
};

export default Switcher;
